import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { getUserId } from 'helper/utils';
import { store } from 'store/index';
import { showDanger, showWarning } from 'components/nudges/Alert';
import { addPolicyData, removeOtherState } from 'store/reducers/premiumPolicy';
import useNetworkStatus from 'hooks/useNetworkStatus';

const formatDate = (dateString) => {
  const date = new Date(dateString)    
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};


export default function useGetPoliciesTable() {
  const { makeRequest } = useApiCall();
  const navigate = useNavigate();
  const network = useNetworkStatus();
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const initializeColumns = () => {
      const tsa = localStorage.getItem('@user');
      const type = JSON.parse(tsa)?.tsA_Type__c;

      const motorColumns = [
        { id: 'sr', label: 'Sr.', minWidth: 100 },
        { id: 'vrn', label: 'Vehicle Registration No.', minWidth: 220 },
        { id: 'cname', label: 'Customer Name', minWidth: 180 },
        { id: 'psd', label: 'Policy Start', minWidth: 150 },
        { id: 'ped', label: 'Policy End', minWidth: 150 },
        { id: 'pa', label: 'Premium Amt.', minWidth: 150 },
        // { id: 'commision', label: 'Commission', minWidth: 155 },
        { id: 'Pno', label: 'Policy No.', minWidth: 150 },
        { id: 'policystatus', label: 'Status', minWidth: 150, sortable: false },
        { id: 'Action', label: 'Action', minWidth: 110, sortable: false }
      ];

      const foreignWorkerColumns = [
        { id: 'sr', label: 'Sr.', minWidth: 100 },
        { id: 'cw', label: 'Count of worker', minWidth: 220 },
        { id: 'cname', label: 'Customer Name', minWidth: 180 },
        { id: 'psd', label: 'Policy Start', minWidth: 150 },
        { id: 'ped', label: 'Policy End', minWidth: 150 },
        { id: 'pa', label: 'Premium Amt.', minWidth: 150 },
        // { id: 'commision', label: 'Commission', minWidth: 155 },
        { id: 'Pno', label: 'Policy No.', minWidth: 150 },
        { id: 'policystatus', label: 'Status', minWidth: 150, sortable: false },
        { id: 'Action', label: 'Action', minWidth: 110, sortable: false }
      ];

      const domesticHelperColumns = [
        { id: 'sr', label: 'Sr.', minWidth: 100 },
        { id: 'transDate', label: 'Trans Date', minWidth: 220 },
        { id: 'cname', label: 'Customer Name', minWidth: 180 },
        { id: 'hn', label: 'Helper Name', minWidth: 220 },
        { id: 'psd', label: 'Policy Start', minWidth: 150 },
        { id: 'ped', label: 'Policy End', minWidth: 150 },
        { id: 'Pno', label: 'Policy No.', minWidth: 150 },
        { id: 'pa', label: 'Premium Amt.', minWidth: 150 },
        // { id: 'commision', label: 'Commission', minWidth: 155 },
        { id: 'policystatus', label: 'Status', minWidth: 150, sortable: false },
        { id: 'Action', label: 'Action', minWidth: 110, sortable: false }
      ];

      switch (type) {
        case 'Motor':
          setColumns(motorColumns);
          break;
        case 'Foreign Worker':
          setColumns(foreignWorkerColumns);
          break;
        case 'Domestic Helper':
          setColumns(domesticHelperColumns);
          break;
        default:
          setColumns([]);
          break;
      }
    };

    initializeColumns();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!network) {
          showDanger('No internet connection.');
          return;
        }

        let UserId = await getUserId();
        let params = { UserId };
        let res = await makeRequest({ method: 'POST', url: API.AgentSpecificPoliciesGet, params });

        if (res?.code === 200) {
          let arr = [];

          res?.data?.forEach((item, index) => {
            let isActive = item?.data?.policy?.policy_status === 'Active';
            let statusText = isActive ? 'Active' : 'Inactive';
            let statusColor = isActive ? '#6fc56b' : '#dc4a4a';

            const vehicleName = item?.data?.policy?.vehicleName || '-';
            const recordType = item?.data?.policy?.recordType;
            const transdate = item?.data?.policy?.createdDate || '-';

            let vrn = '-';
            let cw = '-';
            let hn = '-';
            let transDate = '-';

            switch (recordType) {
              case 'Motor':
                vrn = vehicleName;
                break;
              case 'Foreign Worker':
                cw = vehicleName;
                break;
              case 'Domestic Helper':
                transDate = formatDate(transdate);
                hn = vehicleName ? vehicleName?.toUpperCase() : '-';
                break;
              default:
                break;
            }
            arr.push({
              sr: index + 1,
              nricno: `${item?.data?.customer?.nricfin || '-'}`,
              vrn,
              cw,
              hn,
              transDate,
              cname: item?.data?.customer?.customerName ? item.data.customer.customerName.toUpperCase() : '-',
              psd: formatDate(item?.data?.policy?.periodOfInsuranceStartDate || '-'),
              ped: formatDate(item?.data?.policy?.periodOfInsuranceEndDate || '-'),
              pa: item?.data?.policy?.premiumWithGST > 0 ? `$${item.data.policy.premiumWithGST.toFixed(2)}` : '-',
              // commision: item?.data?.policy?.tsaComissionEarned > 0 ? `$${item.data.policy.tsaComissionEarned.toFixed(2)}` : '-',
              createdat: formatDate(item?.data?.policy?.createdDate.split(',')[0] || '-'),
              Pno: `${item?.data?.policy?.policyNumber || '-'}`,
              statusText,
              policystatus: (
                <span
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    color: statusColor,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {statusText}
                </span>
              ),
              Action: (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {item?.data?.policy?.policyNumber == null ? (
                    <div
                      style={{
                        backgroundColor: 'grey',
                        color: '#fff',
                        borderRadius: 20,
                        fontSize: '12px',
                        cursor: 'pointer',
                        width: '55px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: '3px',
                        fontFamily: 'Medium'
                      }}
                    >
                      <VisibilityIcon style={{ fontSize: '15px' }} />
                      View
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#4cb748',
                        color: '#fff',
                        borderRadius: 20,
                        fontSize: '12px',
                        cursor: 'pointer',
                        width: '55px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: '3px',
                        fontFamily: 'Medium'
                      }}
                      onClick={() => {
                        store.dispatch(removeOtherState());
                        store.dispatch(addPolicyData(item));
                        navigate('/dashboard/viewpolicy');
                      }}
                    >
                      <VisibilityIcon style={{ fontSize: '12px' }} />
                      View
                    </div>
                  )}

                  {item?.data?.policy?.recordType !== 'Domestic Helper' && (
                    <div
                      style={{
                        backgroundColor: item?.data?.policy.renewable ? '#4cb748' : 'grey',
                        color: '#fff',
                        borderRadius: 20,
                        fontSize: '12px',
                        cursor: item?.data?.policy.renewable ? 'pointer' : 'not-allowed',
                        width: '55px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: '3px',
                        fontFamily: 'Medium',
                        marginLeft: '5px'
                      }}
                      onClick={() => {
                        if (item?.data?.policy.renewable) {
                          store.dispatch(removeOtherState());
                          store.dispatch(addPolicyData(item));
                          navigate('/dashboard/viewpolicy');
                        }
                      }}
                    >
                      Renew
                    </div>
                  )}
                </div>
              )
            });
          });

          setData(arr);
          setIsLoading(false);
        } else if (res?.code === 404) {
          showWarning('No Data found');
          setIsLoading(false);
        }
      } catch (e) {
        console.error('Error in useGetPoliciesTable', e);
      }
    })();
  }, [network]);

  return [data, loading, columns];
}
