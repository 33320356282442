import React from 'react';
import { Grid, Typography } from '@mui/material';
import { usePremiumCalculationState } from '../provider/PremiumCalculationProvider';

const PremiumCard = () => {
  const { apiData } = usePremiumCalculationState();
  const data = [
    {
       Coverage: apiData?.productSubType,
      'Base Premium': `$${(apiData?.basicPremiumAmount).toFixed(2)}`,
      'Premium With Loading': `$${(apiData?.premiumWithLoadingAmount).toFixed(2)}`,
      'Discount %': apiData?.finalDiscountPercentage,
      'Discount Amount': `$${(apiData?.finalDiscountAmount).toFixed(2)}`,
      'Premium after Discount': `$${(apiData?.premiumAfterDiscount).toFixed(2)}`,
      'Additional Rider Amount': `$${(apiData?.afterAddition3RiderAmount).toFixed(2)}`,
      'NCD %': apiData?.ncDpercentage,
      'NCD Amount': `$${(apiData?.ncdAmount).toFixed(2)}`,
      Excess: `$${(apiData?.excessAmount).toFixed(2)}`,
      'Food Delivery with GST': `$${(apiData?.fdExtentionAmount).toFixed(2)}`,
    }
  ];

  return (
    <Grid
      item
      xs={12}
      md={7.8}
      lg={7.8}
      mt={1}
      mb={2}
      style={{
        backgroundColor: '#fff',
        boxShadow: '10px 10px 30px 10px  #F7F7F8',
        borderRadius: '15px',
        padding: '15px',
        maxWidth: '100%'
      }}
    >
      <Typography variant="h5" sx={{ fontFamily: 'Light', fontWeight: 600, color: '#000' }}>
        Premium Calculation
      </Typography>
      <Grid style={{ border: '1px solid #ddd', width: '100%', borderRadius: '10px', padding: '10px', minHeight: '250px', marginTop: 5 }}>
        {data && (
          <Grid container sx={{ width: '100%' }}>
            {data?.map((item, index) => (
              <React.Fragment key={index}>
                {Object.keys(item).map((key) => (
                  <Grid key={key} item xs={6} md={6} lg={3} sx={{ marginTop: index === 0 ? '20px' : '50px' }}>
                    <Typography variant="body2" sx={{ fontFamily: 'Thin', fontWeight: 600, color: '#636e72', fontSize: 14 }}>
                      {key}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#000', fontFamily: 'Light', fontWeight: 600, fontSize: 14 }}>
                      {item[key]}
                    </Typography>
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PremiumCard;
