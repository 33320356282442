import React, { useState, useEffect } from 'react';
import Stepper from '../../domestic helper policy view/components/Stepper';
import { Grid } from '@mui/material';
import MotorVehicleRidingExperienceContainer from './MotorVehicleRidingExperienceContainer';
import { store } from 'store/index';
import { setActiveStep } from 'store/reducers/premiumPolicy';
import { useSelector, useDispatch } from 'react-redux';
import MotorVehicleDetails from './MotorVehcileDetails';
import QrCode from './QrCode';
import MotorVehiclePremium from './MotorVehcilePremium';
import MotorVehicleCoverage from './MotorVehicleCoverage';
import { showWarning } from 'components/nudges/Alert';

const MotorVehicleForm = ({ handleGoBack }) => {
  const dispatch = useDispatch();
  const activeSteps = useSelector((state) => state.persistedReducer.premiumPolicy.activeSteps);
  const otherState = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const [data, setData] = useState(null);
  const { activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { CustomerriderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setActiveStep(activeSteps));
  }, [dispatch, activeSteps]);

  const handleNextButton = (receivedData) => {
    let { vehicleRegistration, vehicleId, ageOfVehicle, claimamount, isFoodParcel, claimcount, POIStartDate, RegistrationDate } =
      otherState;
    const currentDate = new Date();
    const registrationDate = new Date(otherState?.RegistrationDate);

    // const registrationDate = new Date(otherState.RegistrationDate);
    const currentDates = new Date();

    const diffTime = Math.abs(currentDates - registrationDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    let leapDays = 0;
    for (let year = registrationDate.getFullYear(); year <= currentDates.getFullYear(); year++) {
      if (isLeapYear(year)) leapDays++;
    }

    const maxAllowedDaysComprehensive = 6 * 365 + leapDays;
    const maxAllowedDaysTPFT = 21 * 365 + leapDays;

    if (activeParams.productSubType === 'Comprehensive' && diffDays > maxAllowedDaysComprehensive) {
      // setIsButtonDisabled(false);
      showWarning('The vehicle age must be less than 6 years ( for Comprehensive Policies )');
      return;
    }

    if (activeParams.productSubType === 'TPFT' && diffDays > maxAllowedDaysTPFT) {
      // setIsButtonDisabled(false);
      showWarning('The vehicle age must be less than 21 years ( for TPFT Policies )');
      return;
    }
    if (!claimamount && claimcount === 1) {
      // setIsButtonDisabled(false);
      showWarning('Please fill the claim amount.');
      return;
    }

    if (claimcount === 1 && claimamount > 6000) {
      // setIsButtonDisabled(false);
      showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
      return;
    }
    // if (claimcount === 1 && claimamount > 15000 && isFoodParcel) {
    //   setIsButtonDisabled(false);
    //   showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
    //   return;
    // }

    if (claimcount === 2) {
      // setIsButtonDisabled(false);
      showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
      return;
    }
    // if (otherState?.isFoodParcel && otherState.age > 65) {
    //   showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
    //   return;
    // }
    // if (!otherState?.isFoodParcel && otherState.age > 75) {
    //   showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
    //   return;
    // }

    if (!otherState?.vehicleId || !otherState?.ageOfVehicle || !otherState?.vehicleRegistration) {
      showWarning('Fill All Required Fields!');
      return;
    } /* else if (otherState?.claimcount === 1 && otherState?.claimamount > 15000) {
      showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
      return;
    } else if (otherState?.claimcount === 1 && otherState?.claimamount > 20000) {
      showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
      return;
    } else if (!otherState?.claimamount && otherState?.claimcount === 1) {
      showWarning('Please fill the claim amount.');
      return;
    } */ else if (otherState.claimcount === '') {
      showWarning('Please select the claim count.');
      return;
    } /* else if (!otherState.claimamount && otherState.claimcount === 1) {
      showWarning('Please fill the claim amount.');
      return;
    } else if (otherState?.claimcount === 2) {
      showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
      return;
    } */ else if (registrationDate > currentDate) {
      showWarning('Registration date must be the current date or earlier.');
      return;
    } else if (otherState?.RegistrationDate === '') {
      showWarning('Please select the registration date.');
      return;
    }
    dispatch(setActiveStep(activeSteps + 1));
    setData(receivedData);
  };

  const handlePreviousButton = () => {
    if (activeSteps === 0) {
      handleGoBack();
    } else {
      dispatch(setActiveStep(activeSteps - 1));
    }
  };
  const StepperSteps = otherState?.isCompanyRegistered
    ? ['Vehicle Details', 'Coverage Period', 'Premium Details', 'Payment']
    : ['Vehicle Details', 'Coverage Period', 'Riding Details', 'Premium Details', 'Payment'];

  const renderPageComponent = () => {
    if (otherState?.isCompanyRegistered) {
      switch (activeSteps) {
        case 0:
          return <MotorVehicleDetails handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
        case 1:
          return <MotorVehicleCoverage handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
        case 2:
          return <MotorVehiclePremium handlePreviousButton={handlePreviousButton} handleNextButton={handleNextButton} data={data} />;
        case 3:
          return <QrCode handlePreviousButton={handlePreviousButton} data={data} />;
        default:
          return null;
      }
    } else {
      switch (activeSteps) {
        case 0:
          return <MotorVehicleDetails handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
        case 1:
          return <MotorVehicleCoverage handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
        case 2:
          return <MotorVehicleRidingExperienceContainer handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
        case 3:
          return <MotorVehiclePremium handlePreviousButton={handlePreviousButton} handleNextButton={handleNextButton} data={data} />;
        case 4:
          return <QrCode handlePreviousButton={handlePreviousButton} data={data} />;
        default:
          return null;
      }
    }
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        marginTop: '20px'
      }}
    >
      <Grid item xs={12} md={12} lg={9} style={{ position: 'relative', width: '100%', height: 'auto' }}>
        <Stepper width="100%" steps={StepperSteps} activeSteps={activeSteps} boxwidth={'100%'} />
      </Grid>
      <Grid item xs={12} md={12} lg={9} mt={4} sx={{ width: '100%' }}>
        {renderPageComponent()}
      </Grid>
    </Grid>
  );
};

export default MotorVehicleForm;
