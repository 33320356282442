import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { TextSnippetOutlined } from '@mui/icons-material';
import DataChart from 'components/charts/DataChart';
import { useTheme } from 'components/Theme/ThemeProvider';
import useApiCall from 'api/useApiCall';
import DropDown from 'components/dropdown/DropDown';
import '../../../src/modules/packages & premium/styles/InformationForm.css';
import { activeItem } from 'store/reducers/menu';
import { store } from 'store/index';
import Skeleton from '@mui/material/Skeleton';
import DoubleDataChart from 'components/charts/DoubleDataChart';
import { API } from 'api/API';
import { showDanger } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { useMediaQuery } from '../../../node_modules/@mui/material/index';
// import { useMediaQuery } from '../../../../../../node_modules/@mui/material/index';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const options = [
  { label: 'Current Year', id: 'Y' },
  { label: 'Current Quarter', id: 'Q' },
  { label: 'Current Month', id: 'M' },
  { label: 'Current Week', id: 'W' }
];

const DashboardDefault = () => {
  const { currentTheme } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const [dashboardData, setDashboardData] = useState(null);
  const [filterBy, setFilterBy] = useState('Y');
  const [filterByProductSales, setFilterByProductSales] = useState('Y');
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const network = useNetworkStatus();
  const isMobile = useMediaQuery('(max-width:450px)');
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const loginData = localStorage?.getItem('@login');
  //   const data = loginData ? JSON.parse(loginData) : null;
  //   const isFinanceAccess = data?.finance_Access__c;

  //   console.log('isFinanceAccess:', isFinanceAccess);

  //   if (isFinanceAccess) {
  //     dispatch(activeItem({ openItem: ['dashboard1'] }));
  //   } else {
  //     dispatch(activeItem({ openItem: ['policies'] }));
  //   }
  // }, [dispatch]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      const loginData = localStorage.getItem('@login');
      console.log('loginDat', loginData);
      if (loginData) {
        const loginDataObj = JSON.parse(loginData);
        const userMasterId = loginDataObj.userMasterId;

        try {
          if (!network) {
            showDanger('No internet connection.');
            setLoading(false);
            return;
          }
          const response = await makeRequest({
            method: 'GET',
            url: `${API.AgentDashboardAPI}?UserId=${userMasterId}`
          });
          console.log('response', response);
          if (refresh === true) {
            console.log('5');
            // window.location.reload();
            navigate(0);
          }

          setDashboardData(response.data);
        } catch (error) {
          console.error(error);
          showDanger(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchDashboardData();
    console.log('4');
  }, []);

  return (
    <Grid container rowSpacing={2} columnSpacing={3.5}>
      {/* row 1 */}
      <Grid item xs={12} sx={{ mt: -0.15 }}>
        <Typography variant="h5" color={currentTheme.textColor} style={{ fontFamily: 'Medium' }}>
          Dashboard
        </Typography>
      </Grid>

      {loading ? (
        <>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={80} animation="wave" style={{ borderRadius: '10px' }} />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={80} animation="wave" style={{ borderRadius: '10px' }} />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={80} animation="wave" style={{ borderRadius: '10px' }} />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={80} animation="wave" style={{ borderRadius: '10px' }} />
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sm={6}
            // md={4}
            lg={3}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <AnalyticEcommerce
              title="MTD Policy Value"
              count="$1,452.12"
              icon={
                <TextSnippetOutlined
                  sx={{ fontSize: 40, backgroundColor: '#D5FFEF', color: '#4ABC8C', borderRadius: '20%', padding: '8px' }}
                />
              }
              dashboardData={`$${(dashboardData?.mtD_PolicyBasicValue || 0).toFixed(2)}`}
              // dashboardData={`$${(dashboardData?.mtD_PolicyBasicValue || 0).toFixed(2)}`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            // md={4}
            lg={3}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <AnalyticEcommerce
              title="YTD Sold Policies"
              count="475"
              color="error"
              icon={
                <TextSnippetOutlined
                  sx={{ fontSize: 40, backgroundColor: '#E9F2FF', color: '#7AAFFF', borderRadius: '20%', padding: '8px' }}
                />
              }
              dashboardData={dashboardData?.totalPolicyCount || '0'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            // md={4}
            lg={3}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <AnalyticEcommerce
              title="YTD Commission Earned"
              count="$2,145.12"
              color="success"
              icon={
                <TextSnippetOutlined
                  sx={{ fontSize: 38, backgroundColor: '#FFF9E8', color: '#FED66E', borderRadius: '20%', padding: '8px' }}
                />
              }
              dashboardData={`$${(dashboardData?.ytD_CommissionEarned || 0).toFixed(2)}`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            // md={4}
            lg={3}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <AnalyticEcommerce
              title="YTD Policy Value"
              color="error"
              icon={
                <TextSnippetOutlined
                  sx={{ fontSize: 40, backgroundColor: '#EFE2FF', color: '#B1A2E8', borderRadius: '20%', padding: '10px' }}
                />
              }
              dashboardData={`$${(dashboardData?.ytD_PolicyBasicValue || 0).toFixed(2)}`}
            />
          </Grid>
        </>
      )}

      {/* row 2 */}
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        sx={{
          paddingTop: '24px !important',
          paddingLeft: '24px !important',
          paddingRight: '0px !important',
          paddingBottom: '0px !important'
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item></Grid>
        </Grid>

        <MainCard style={{ backgroundColor: currentTheme.cardColor }}>
          <Typography
            variant="h5"
            color={'#000000'}
            style={{ fontFamily: 'Medium', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            Commission Breakup
            <DropDown
              width={isMobile ? '250px' : '150px'}
              heightsss={'40px'}
              placeholder={'Current Year'}
              height={'auto'}
              backgroundColor={'#fafafa'}
              options={options}
              setSelectedValue={setFilterBy}
            />
          </Typography>
          <DataChart filterBy={filterBy} setFilterBy={setFilterBy} />
        </MainCard>
        {/* )} */}
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        sx={{
          paddingTop: '24px !important',
          paddingLeft: '24px !important',
          paddingRight: '0px !important',
          paddingBottom: '0px !important'
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item></Grid>
        </Grid>

        <MainCard style={{ backgroundColor: currentTheme.cardColor }}>
          <Typography
            variant="h5"
            color={currentTheme.textColor}
            style={{ fontFamily: 'Medium', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            Product Sales Performance
            <DropDown
              width={isMobile ? '250px' : '150px'}
              heightsss={'40px'}
              placeholder={'Current Year'}
              height={'auto'}
              backgroundColor={'#fafafa'}
              options={options}
              setSelectedValue={setFilterByProductSales}
            />
          </Typography>

          <DoubleDataChart filterByProductSales={filterByProductSales} setFilterByProductSales={setFilterByProductSales} />
        </MainCard>
        {/* )} */}
      </Grid>
    </Grid>
  );
};

export default DashboardDefault;
