import React, { useState } from 'react';
import { Button, TextField, Grid, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import DropDown from 'components/dropdown/DropDown';
import useGetCountriesList from '../hooks/useGetCountriesList';
import useHandleForeignWorker from '../hooks/useHandleForeignWorker';
import { nricValidator } from 'helper/utils';
import { showWarning } from 'components/nudges/Alert';

const ForeignWorkerSidebar = (props) => {
  const { buttonLabel, isEditMode } = props;
  const selectedRowData = props.selectedRow;
  const [firstName, setFirstName] = useState(selectedRowData ? selectedRowData.firstName : '');
  const [lastName, setLastName] = useState(selectedRowData ? selectedRowData.lastName : '');
  const [workPermit, setWorkPermit] = useState(selectedRowData ? selectedRowData.workPermit : '');
  const [gender, setGender] = useState(selectedRowData ? selectedRowData.gender : '');
  const [dob, setDob] = useState(selectedRowData ? selectedRowData.dob : '');
  const [nric, setNric] = useState(selectedRowData ? selectedRowData.nric : '');
  const [passport, setPassport] = useState(selectedRowData ? selectedRowData.passport : '');
  const [nationality, setNationality] = useState(selectedRowData ? selectedRowData.nationality : '');
  const [contactNumber, setContactNumber] = useState(selectedRowData ? selectedRowData.contactNumber : '');
  const [email, setEmail] = useState(selectedRowData ? selectedRowData.email : '');
  const getCountriesListData = useGetCountriesList();

  const [handleAddWorker] = useHandleForeignWorker(
    buttonLabel,
    selectedRowData,
    props.setShow,
    firstName,
    lastName,
    workPermit,
    dob,
    nationality,
    nric,
    gender,
    contactNumber,
    email,
    passport,
    isEditMode ? 'edit' : 'add'
  );

  const options = [
    { label: 'Male', id: 1 },
    { label: 'Female', id: 2 }
  ];

  // const handleDOBChange = (newValue) => {
  //   const currentDate = new Date();
  //   const selectedDate = new Date(newValue);
  //   if (selectedDate > currentDate) {
  //     newValue = currentDate.toISOString().split('T')[0];
  //   }
  //   setDob(newValue);
  // };

  const handleDOBChange = (dob) => {
    setDob(dob);
  };

  const handleDOBBlur = (dob) => {
    const currentDate = new Date().toISOString().split('T')[0];
    if (new Date(dob) > new Date(currentDate)) {
      setDob(currentDate);
    }
  };

  const handleNumericKeyPress = (event) => {
    const keyPressed = event.key;
    if (!/^\d$/.test(keyPressed) && !event.ctrlKey && !event.metaKey && !event.altKey) {
      event.preventDefault();
    }
  };
  const handleButtonClick = () => {
    if (nric && !nricValidator(nric)) {
      showWarning('Please enter a valid NRIC Number');
    } else {
      handleAddWorker();
    }
  };

  const handleNricChange = (e) => {
    const value = e.target.value.toUpperCase();
    const regex = /^[A-Z0-9]*$/;
    if (regex.test(value)) {
      setNric(value);
    }
  };

  return (
    <Grid className="topContainer_SelectCustomerSideBar">
      <Grid mt={5} item xs={12} className="heading_SelectCustomerSideBar">
        <Typography style={{ color: '#000', fontFamily: 'Medium', marginTop: '15px' }} variant="h5">
          Foreign Worker
        </Typography>
      </Grid>

      <hr />
      <Grid item xs={12} sm={12} md={12} className="allinputpositioningstyling">
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>First Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Last Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Work Permit No</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={workPermit}
              onChange={(e) => setWorkPermit(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}> DOB </InputLabel>
            </div>
            <TextField
              size="small"
              type="date"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={dob}
              onChange={(e) => handleDOBChange(e.target.value)}
              onBlur={(e) => handleDOBBlur(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                max: new Date().toISOString().split('T')[0],
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              // inputProps={{
              //   style: {
              //     fontSize: '13px' // Change the font size of the input text
              //   }
              // }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Nationality</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <DropDown
              marginTop={'1px'}
              width={'88%'}
              heightsss={'37px'}
              height={'40px'}
              placeholder="Nationality"
              options={getCountriesListData}
              onValueChange={(i) => {
                setNationality(i.label);
              }}
              value={nationality}
              required
              onClick={(e) => e.stopPropagation()}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>NRIC / FIN No.</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              placeholder="e.g. S3272118D"
              // InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={nric}
              onChange={handleNricChange}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Gender</InputLabel>
            </div>

            <DropDown
              marginTop={'1px'}
              width={'88%'}
              heightsss={'37px'}
              height={'40px'}
              placeholder="Gender"
              options={options}
              onClick={(e) => e.stopPropagation()}
              onValueChange={(i) => {
                setGender(i.label);
              }}
              value={gender}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}> Contact Number </InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={contactNumber}
              onKeyPress={handleNumericKeyPress}
              onChange={(e) => setContactNumber(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Email Address</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}> Passport Number </InputLabel>
              {/* <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel> */}
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={passport}
              onChange={(e) => setPassport(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          {/* Buttons */}
          <Grid item xs={12} sm={12} md={12}>
            <Button
              size="small"
              variant="contained"
              // className="creditNoteButton"
              style={{
                borderRadius: '8px',
                color: '#fff',
                backgroundColor: '#002d8c',
                minWidth: '50px',
                marginRight: '10px',
                padding: '2%',
                fontFamily: 'Medium',
                fontSize: '13px'
              }}
              onClick={() => handleButtonClick()}
            >
              {isEditMode ? 'Edit Worker' : 'Add Worker'}
            </Button>
            <Button
              size="small"
              variant="contained"
              // className="creditNoteButton"
              style={{
                borderRadius: '8px',
                color: '#002d8c',
                backgroundColor: '#ffffff',
                minWidth: '50px',
                marginRight: '10px',
                padding: '2%',
                fontFamily: 'Medium',
                border: '1px solid #002d8c',
                fontSize: '13px'
              }}
              onClick={() => props.setShow(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForeignWorkerSidebar;
