import React, { useState } from 'react';
import CardLayout from '../CardLayout';
import RiderSectionTable from '../RiderSectionTable';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { AddaddOnsData, removeAddOnData } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';

const DomesticHelperAddOns = React.memo((props) => {
  const { addOn } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const [isOpen, setIsOpen] = useState(true);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (item, checked) => {
    let obj = {
      itemCode: item?.productID,
      salesPrice: item?.addOnAmount,
      quantity: 1
    };
    if (checked) {
      store.dispatch(AddaddOnsData([item.fwdhProductPricingListId, obj]));
    } else {
      store.dispatch(removeAddOnData(item.fwdhProductPricingListId));
    }
  };

  const DomesticHelperAddons = [
    { id: 'sr', label: 'Sr.', minWidth: 50, align: 'center' },
    { id: 'productName', label: 'Product Name', minWidth: 150, align: 'center' },
    { id: 'Prices', label: 'Price', minWidth: 90, align: 'center' },
    { id: 'price', label: 'Action', minWidth: 60, align: 'center' }
  ];
  const DomesticHelperAddonRows = props?.data?.foreignWorkerCalculation?.map((item, index) => {
    return {
      sr: index + 1,
      productName: item.productName,
      Prices: `$ ${item.addOnAmount || '-'}`,
      price: (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={addOn[item?.fwdhProductPricingListId] ? true : false}
                onChange={(e) => handleCheckboxChange(item, e.target.checked)}
              />
            }
          />
        </div>
      )
    };
  });

  return (
    <>
      <CardLayout isOpen={isOpen} setIsOpen={setIsOpen} toggleForm={toggleForm} heading="Add-Ons">
        {DomesticHelperAddonRows && DomesticHelperAddonRows?.length > 0 ? (
          <RiderSectionTable columns={DomesticHelperAddons} rows={DomesticHelperAddonRows} />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              fontSize: '13px',
              color: '#000'
            }}
          >
            No Add-Ons Found
          </div>
        )}
      </CardLayout>
    </>
  );
});

export default DomesticHelperAddOns;
