// hooks/useBodyScrollLock.js
import { useState, useEffect } from 'react';

let modalCount = 0;

const useBodyScrollLock = () => {
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    const bodyStyle = document.body.style;

    if (isLocked) {
      modalCount++;
    } else {
      modalCount = Math.max(modalCount - 1, 0);
    }

    // Set body overflow based on modalCount
    bodyStyle.overflowY = modalCount > 0 || isLocked ? 'hidden' : 'auto';

    return () => {
      modalCount = Math.max(modalCount - 1, 0);

      // Ensure body overflow is set to 'hidden' if there are still modals open
      if (modalCount > 0) {
        bodyStyle.overflowY = 'hidden';
      } else {
        // Otherwise, set it to 'auto' only if isLocked is false
        if (!isLocked) {
          bodyStyle.overflowY = 'auto';
        }
      }
    };
  }, [isLocked]);

  return [isLocked, setIsLocked];
};

export default useBodyScrollLock;
