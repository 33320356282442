import React, { useEffect, useState } from 'react';
import { Grid, TextField, InputLabel } from '@mui/material';
import { getUserDetails } from 'helper/utils';
import '../../myprofile/styles/Profile.css';

const ProfileView = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    (async () => {
      let res = await getUserDetails();
      setUserData(res);
    })();
  }, []);

  return (
    <Grid container alignItems="center">
      <Grid
        item
        xs={12}
        sm={3}
        md={3}
        className="profilepicture_container"
        sx={{
          padding: '24px'
        }}
      >
        <div className="profilepicture_profile">
          <img src={userData?.profileUrl} alt="profile" className="image_viewprofile" style={{ width: '100%' }} />
        </div>
      </Grid>

      <Grid item xs={12} sm={9} md={9} sx={{ padding: '24px' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <InputLabel className="input_label_top">Name</InputLabel>
            <InputLabel className="input_label">{userData?.firstName || '-'}</InputLabel>

            {/* <TextField
              disabled
              value={userData?.firstName || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
              sx={{ width: '100%' }}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <InputLabel className="input_label_top">UEN No</InputLabel>
            <InputLabel className="input_label">{userData?.uenNo || '-'}</InputLabel>
            {/* <TextField
              disabled
              value={userData?.uenNo || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
              sx={{ width: '100%' }}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <InputLabel className="input_label_top">TSA Type</InputLabel>
            <InputLabel className="input_label">{userData?.tsA_Type__c || '-'}</InputLabel>

            {/* <TextField
              disabled
              value={userData?.tsA_Type__c || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
              sx={{ width: '100%' }}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <InputLabel className="input_label_top">Address</InputLabel>
            <InputLabel className="input_label_address">{userData?.address || '-'}</InputLabel>

            {/* <TextField
              disabled
              value={userData?.address || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
              sx={{ width: '100%' }}
              multiline
              rows={4} // Adjust the number of rows as needed
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <InputLabel className="input_label_top">Postal Code</InputLabel>
            <InputLabel className="input_label">{userData?.postalCode || '-'}</InputLabel>

            {/* <TextField
              disabled
              value={userData?.postalCode || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
              sx={{ width: '100%' }}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <InputLabel className="input_label_top">Country</InputLabel>
            <InputLabel className="input_label">{userData?.country || '-'}</InputLabel>
            {/* <TextField
              disabled
              value={userData?.country || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
              sx={{ width: '100%' }}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <InputLabel className="input_label_top">Contact</InputLabel>
            <InputLabel className="input_label">{userData?.mobilePhone || '-'}</InputLabel>
            {/* <TextField
              disabled
              value={userData?.mobilePhone || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
              sx={{ width: '100%' }}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              paddingTop: '24px !important',
              paddingLeft: '24px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important'
            }}
          >
            <InputLabel className="input_label_top">Email</InputLabel>
            <InputLabel className="input_label">{userData?.finance_Email__c || '-'}</InputLabel>
            {/* <TextField
              disabled
              value={userData?.finance_Email__c || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
              sx={{ width: '100%' }}
            /> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileView;
