import React from 'react';
import { Grid } from '@mui/material';
import '../styles/InformationForm.css';
import DomesticHelperWorkerInformation from './domestichelper/DomesticHelperWorkerInformation';
import DomesticHelperInsurance from './domestichelper/DomesticHelperInsurance';
import DomesticHelperBottomPortion from './domestichelper/DomesticHelperBottomPortion';
import DomesticHelperAddOns from './domestichelper/DomesticHelperAddOns';
import useGetCountriesList from '../hooks/useGetCountriesList';
import useFwDhCalculationGet from '../hooks/useFwDhCalculationGet';

const DomesticHelperPremiumView = React.memo(() => {
  const { data } = useFwDhCalculationGet();

  const getCountriesListData = useGetCountriesList();

  return (
    <Grid container xs={12} width="100%">
      <DomesticHelperInsurance data={data} />
      <DomesticHelperAddOns data={data} />
      <DomesticHelperWorkerInformation getCountriesListData={getCountriesListData} />
      <DomesticHelperBottomPortion />
    </Grid>
  );
});

export default DomesticHelperPremiumView;
