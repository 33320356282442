import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
const RiderSectionTable = ({ rows, columns }) => {
  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%', width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, maxWidth: column.maxWidth,fontSize:'13px' }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    maxWidth: column.minWidth,
                    margin: '2px',
                    flexWrap: 'wrap',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    fontSize:'13px',
                     textAlign:'center'
                  }}
                  align={column.align}
                >
                  {row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default RiderSectionTable;
RiderSectionTable.defaultProps = {
  rows: [
    {
      OrderId: 'A 1',
      WareHouse: 'Herman Beck',
      CreaatAt: 'Oct 16, 2017',
      'Total Qty': '45.00',
      'Total Amount': ' 12',
      'Order status': 'Order Placed',
      Source: 'TrueSales Mobile App'
    },
    {
      OrderId: 'B 2',
      WareHouse: 'Mary Adams',
      CreaatAt: 'Oct 12, 2017',
      'Total Qty': '245.30',
      'Total Amount': '75040',
      'Order status': 'Order Accepted',
      Source: 'TrueSales Mobile App'
    },
    {
      OrderId: 'C 3',
      WareHouse: 'Caleb Richards',
      CreaatAt: 'May 18, 2017',
      'Total Qty': '38.00',
      'Total Amount': '4500',
      'Order status': 'GRN Pending',
      Source: 'TrueSales Mobile App'
    },
    {
      OrderId: 'D 4',
      WareHouse: 'June Lane',
      CreaatAt: 'Apr 28, 2017',
      'Total Qty': '77.99',
      'Total Amount': '8700',
      'Order status': 'Order Completed',
      Source: 'TrueSales Mobile App'
    },
    {
      OrderId: 'E 5',
      WareHouse: 'June Lane',
      CreaatAt: 'Apr 28, 2017',
      'Total Qty': '77.99',
      'Total Amount': '8700',
      'Order status': 'Order Completed',
      Source: 'TrueSales Mobile App'
    },
    {
      OrderId: 'F 6',
      WareHouse: 'June Lane',
      CreaatAt: 'Apr 28, 2017',
      'Total Qty': '77.99',
      'Total Amount': '8700',
      'Order status': 'Order Completed',
      Source: 'TrueSales Mobile App'
    },
    {
      OrderId: 'G 7',
      WareHouse: 'June Lane',
      CreaatAt: 'Apr 28, 2017',
      'Total Qty': '77.99',
      'Total Amount': '8700',
      'Order status': 'Order Completed',
      Source: 'TrueSales Mobile App'
    },
    {
      OrderId: 'H 8',
      WareHouse: 'June Lane',
      CreaatAt: 'Apr 28, 2017',
      'Total Qty': '77.99',
      'Total Amount': '8700',
      'Order status': 'Order Completed',
      Source: 'TrueSales Mobile App'
    },
    {
      OrderId: 'I 9',
      WareHouse: 'June Lane',
      CreaatAt: 'Apr 28, 2017',
      'Total Qty': '77.99',
      'Total Amount': '8700',
      'Order status': 'Order Completed',
      Source: 'TrueSales Mobile App'
    }
  ],
  columns: [
    { id: 'OrderId', label: 'Order Id', minWidth: 1 },
    { id: 'WareHouse', label: 'WareHouse', minWidth: 1  },
    { id: 'CreaatAt', label: 'Order date & time', minWidth: 1,  align: 'center' },
    { id: 'Total Qty', label: 'Total QTY', minWidth: 1,  align: 'center' },
    { id: 'Total Amount', label: 'Total Amount', minWidth: 1, align: 'center' },
    { id: 'Order status', label: 'Order Status', minWidth: 1, align: 'center' },
    { id: 'Source', label: 'Source', minWidth: 1,  align: 'center' },
    { id: 'Action', label: 'Action', minWidth: 1, align: 'center' }
  ],

  actionButton: {
    key: 'Action',
    value: (
      <div style={{ backgroundColor: '#4cb748', color: '#fff', borderRadius: 20, minWidth: 55, fontSize: 12, cursor: 'pointer' }}>
        <VisibilityIcon style={{ fontSize: 10 }} />
        View
      </div>
    )
  }
};
