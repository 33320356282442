import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import useNetworkStatus from 'hooks/useNetworkStatus';

const DataChart = ({ filterByProductSales }) => {
  const network = useNetworkStatus();
  const { makeRequest } = useApiCall();
  const [chartData, setChartData] = useState({ categories: [], series: [] });
  const [seriesNames, setSeriesNames] = useState([]);
  const [fullCategoryNames, setFullCategoryNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 580);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const fetchChartData = useCallback(async () => {
    const loginData = localStorage.getItem('@login');

    const shortenName = (name, maxLength = 10) => {
      return name.length > maxLength ? `${name?.substring(0, maxLength)}...` : name;
    };

    if (loginData) {
      const loginDataObj = JSON.parse(loginData);
      const userMasterId = loginDataObj.userMasterId;
      try {
        const response = await makeRequest({
          method: 'GET',
          url: `${API.PolicyConversionDetailsGet}?UserId=${userMasterId}&filterBy=${filterByProductSales}`,
          skipDateTimeWarning: true
        });
        if (response?.data) {
          const categories = response?.data?.categories;
          const processedData = {
            ...response?.data,
            categories: response?.data?.categories?.map((category) => shortenName(category))
          };
          setChartData(processedData);
          setFullCategoryNames(categories);
          const names = response?.data?.series?.map((s) => s?.name);
          setShowScrollbar(categories?.length > 0);
          setSeriesNames(names);
          console.log('Fetched Data:', processedData);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [filterByProductSales]);

  useEffect(() => {
    if (!network) {
      // showDanger('No internet connection.');
      setLoading(false);
      return;
    }
    fetchChartData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 580);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fetchChartData, filterByProductSales]);

  const formatNumber = (number) => {
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + 'k';
    } else {
      return number;
    }
  };

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 365,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      pan: {
        enabled: false
      },
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '45px',
        borderRadius: 4,
        distributed: false
      }
    },
    colors: ['#008FFB', '#00E396'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#2f3640']
      },
      formatter: function (val) {
        return formatNumber(val);
      }
    },
    xaxis: {
      categories: chartData.categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
      scrollbar: {
        show: chartData?.categories?.length > 0,
        height: 10,
        borderColor: '#90A4AE',
        barBackgroundColor: '#90A4AE',
        barBorderColor: '#90A4AE',
        position: 'bottom',
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: [
      {
        seriesName: seriesNames[0],
        show: true,
        title: {
          text: seriesNames[0]
        },
        labels: {
          style: {
            fontFamily: 'Medium',
            fontSize: 13,
            color: '#76777D'
          }
        },
        min: 0
      },
      {
        seriesName: seriesNames[1],
        show: true,
        title: {
          text: seriesNames[1]
        },
        labels: {
          style: {
            fontFamily: 'Medium',
            fontSize: 13,
            color: '#76777D'
          }
        },
        opposite: true,
        min: 0
      }
    ],
    grid: {
      show: true
    },
    theme: {
      mode: 'light',
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: '#4cb748',
        shadeTo: 'light',
        shadeIntensity: 0.65
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none'
        }
      }
    },
    tooltip: {
      x: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          return fullCategoryNames[dataPointIndex];
        }
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex }) {
          return seriesIndex === 0 ? `Amount: ${formatNumber(value)}` : `Count: ${formatNumber(value)}`;
        }
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const category = fullCategoryNames[dataPointIndex];
        const value = series[seriesIndex][dataPointIndex];
        const label = seriesIndex === 0 ? 'Amount' : 'Count';
        const color = seriesIndex === 0 ? '#008FFB' : '#00E396';
        // const tooltipWidth = isMobile ? '180px' : '220px';
        return `
          <div style="max-width:  220px; word-wrap: break-word; white-space: normal; border-radius: 5px;">
            <div style="margin: 5px; background-color: #f9f9f9; border: 1px solid #ddd; padding: 5px; border-radius: 5px;">
              <strong style="color: #333; font-size: 14px;">${category}</strong><br />
              <span style="color: ${color}; font-size: 13px; font-weight: bold;">${label}:</span>
              <span style="color: ${color}; font-size: 13px;">${seriesIndex === 0 ? '$' : ''}${formatNumber(value)}</span>
            </div>
          </div>`;
      },
      offsetY: 10 // Add offsetY here to move the tooltip box lower
    },
    legend: {
      show: false
    }
  };

  const series = [
    {
      name: seriesNames[0] || 'Series 1',
      data: chartData.series[0]?.data.length > 0 ? chartData.series[0]?.data : [],
      yAxis: 0
    },
    {
      name: seriesNames[1] || 'Series 2',
      data: chartData.series[1]?.data.length > 0 ? chartData.series[1]?.data : [],
      yAxis: 1
    }
  ];

  // console.log('Series Data:', series);

  return (
    <>
      {loading ? (
        <Grid item xs={12} sm={6} md={12} lg={12}>
          <Skeleton variant="rectangular" width="100%" height={350} animation="wave" style={{ borderRadius: '10px' }} />
        </Grid>
      ) : (
        <div className="chart-container-doubleDataChart">
          <div className="chart-wrapper-doubleDataChart">
            <ReactApexChart options={barChartOptions} series={series} type="bar" height={350} />
          </div>
        </div>
      )}
      <style jsx>{`
        .chart-container-doubleDataChart {
          width: 100%;
          overflow-x: ${showScrollbar || chartData?.categories?.length > 0 || isMobile ? 'auto' : 'hidden'};
          scrollbar-color: ${showScrollbar || chartData?.categories?.length > 0 || isMobile ? '#26A0FC #f0f0f0' : 'auto'};
          scrollbar-width: ${showScrollbar || chartData?.categories?.length > 0 || isMobile ? 'thin' : 'none'};
          padding-top: 15px;
          padding-bottom: 15px;
        }
        .chart-wrapper-doubleDataChart {
          min-width: ${isMobile && chartData?.categories?.length < 3
            ? chartData?.categories?.length * 520
            : isMobile && chartData?.categories?.length >= 3
            ? chartData?.categories?.length * 280
            : chartData?.categories?.length <= 3
            ? chartData?.categories?.length * 200
            : chartData?.categories?.length > 3
            ? chartData?.categories?.length * 150
            : chartData?.categories?.length > 0
            ? chartData?.categories?.length * 350
            : chartData?.categories?.length * 150}px;
          max-width: 100%;
        }
        .apexcharts-tooltip {
          max-width: none; /* Remove max-width restriction */
          overflow: visible; /* Ensure tooltip is visible */
        }
        .chart-container::-webkit-scrollbar {
          height: 8px;
        }
        .chart-container::-webkit-scrollbar-track {
          background: #f0f0f0;
        }
        .chart-container::-webkit-scrollbar-thumb {
          background-color: #26a0fc;
          border-radius: 10px;
        }
        .chart-container-doubleDataChart::-webkit-scrollbar-button {
          display: none;
        }
      `}</style>
    </>
  );
};

DataChart.propTypes = {
  filterByProductSales: PropTypes.string.isRequired
};

export default DataChart;
