// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import auth from './auth';
import premiumPolicy from './premiumPolicy';
import constant from './constant';


// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, auth,premiumPolicy,constant });

export default reducers;
