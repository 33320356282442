import { useState } from 'react';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { showWarning } from 'components/nudges/Alert';
import { getUserDetails } from 'helper/utils';
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
export default function useIncorporateFinanceTable() {
  const { makeRequest } = useApiCall();
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const handleVerifyClick = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      let UserData = await getUserDetails();
      let body = {
        ueN_No: UserData?.uenNo,
        startDate,
        endDate
      };
      let res = await makeRequest({ method: 'POST', url: API.CommissionEarnPolicyGet, body });
      if (res?.code === 200) {
        let arr = [];
        res?.data?.data?.forEach((item, index) => {
          arr.push({
            transaction: formatDate(item.transactionDate || '-'),
            invoice: `${item.invoiceNo || '-'}`,
            phn: `${item.policyholderName || '-'}`,
            pno: `${item.policyNo || '-'}`,
            insured: `${item.insuredDescription || '-'}`,
            insurer: `${item.insurer || '-'}`,
            premium: `$ ${item.premium.toFixed(2)}`,
            tsa: `$ ${item.tsaFee.toFixed(2)}`,
            outstanding: `$ ${item.outstanding.toFixed(2)}`,
            status: `${item.status || '-'}`
          });
        });
        setData(arr);
        setIsLoading(false);
      } else if (res?.code === 404) {
        showWarning('No Data found');
        setIsLoading(false);
      }
    } catch (e) {
      console.error('Error in useEffect to getting the packages or premium data', e);
    }
  };

  return { handleVerifyClick, data, loading };
}
