export const successModalStyles = {
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%', 
    padding: '20px', 
    marginTop: '10px' 
  },
  image: {
    width: '100%',
    maxWidth: '150px',
    maxHeight: '140px',
    marginBottom: '12%' 
  },
  textContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%', 
    margin: '15px 0' 
  },
  title: {
    fontFamily: 'Medium',
    fontSize: '1.5rem', 
    marginBottom: '5px' 
  },
  subtitle: {
    fontFamily: 'Thin',
    fontSize: '1rem', 
    color: '#b2bec3',
    fontWeight: '600',
    marginTop: '5px'
  },
  button: {
    borderRadius: '5px',
    backgroundColor: '#002d8c',
    fontFamily: 'Medium',
    marginTop: '20px' ,
    width:'100%'
  }
};
