import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import Rightsidebar from 'components/rightsidebar/Rightsidebar';
import { usePremiumCalculationState } from 'modules/packages & premium/provider/PremiumCalculationProvider';
import ForeignAddCustomerSidebar from './ForeignAddCustomerSidebar';

const ForeignWorkerAmountCard = () => {
  const [show, setShow] = useState(false);
  const { apiData,params } = usePremiumCalculationState();

  const handleOpenSideBar = () => {
    setShow(true);
  };

  const handlePaymentButton = () => {
    setShow(false);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{
          backgroundColor: '#fff',
          borderRadius: '15px',
          boxShadow: '10px 10px 30px 10px  #F7F7F8',
          padding: '10px',
          maxWidth: '100%'
        }}
      >
        <Grid item xs={12} direction="column">
          <Typography variant="h5" style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>
            Summary
          </Typography>

          <div style={{ border: '1px solid #ddd', width: '100%', marginTop: '10px' }} />

          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>Premium w/o GST</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${(apiData?.premiumAmount).toFixed(2)}`}</Typography>
          </Grid>

          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>
              GST {`(${apiData?.gstPercentage}%)`}
            </Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${(apiData?.gstAmount).toFixed(2)}`}</Typography>
          </Grid>

          {/* <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>Commision</Typography>
            <Typography style={{ color: '#4cb748', fontFamily: 'Light', fontWeight: 600 }}>{`$${(apiData?.tasCommissionAmount).toFixed(2)}`}</Typography>
          </Grid> */}

          <div style={{ border: '1px solid #ddd', width: '100%', marginTop: '10px' }} />

          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Heavy', fontWeight: 600 }}>Total Premium</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${(apiData?.premiumWithGST).toFixed(2)}`}</Typography>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#002D8C',
                borderRadius: '5px',
                fontFamily: 'Thin',
                fontWeight: 600,
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                fontSize: 13,
                width: '15%'
              }}
              onClick={handleOpenSideBar}
            >
              Proceed to next
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Rightsidebar
        Rcontent={<ForeignAddCustomerSidebar setShow={setShow} apiData={apiData} params={params}/>}
        show={show}
        closeSidebar={handlePaymentButton}
        maxWidth="400px"
      />
    </>
  );
};

export default ForeignWorkerAmountCard;
