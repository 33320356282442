import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import '../styles/InformationForm.css';
import ForeignWorkerPackageInformation from './foreign worker/ForeignWorkerPackageInformation';
import ForeignWorkerInformation from './foreign worker/ForeignWorkerInformation';
import ForeignWorkerAddOns from './foreign worker/ForeignWorkerAddOns';
import ForeignWorkerBottomPortion from './foreign worker/ForeignWorkerBottomPortion';
import useFwDhCalculationGet from '../hooks/useFwDhCalculationGet';

const ForeignWorkerPremiumView = React.memo(() => {
  const { data } = useFwDhCalculationGet();

  return (
    <Grid container xs={12}>
      <ForeignWorkerPackageInformation data={data} />
      {<ForeignWorkerAddOns data={data} />}
      <ForeignWorkerInformation />
      <ForeignWorkerBottomPortion />
    </Grid>
  );
});

export default ForeignWorkerPremiumView;
