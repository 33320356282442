// material-ui
import { Box } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useMenuItems from 'menu-items/index';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menuItem =useMenuItems()
  const navGroups = menuItem?.items?.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return <></>;
    }
  });

  return <Box sx={{ width: '95%' }}>{navGroups}</Box>;
};

export default Navigation;
