import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import GRNTabel from 'components/GRNTable/GRNTabel';
import ProfileInformationForm from '../components/ProfileInformationForm';
import useCustomerPolicesGet from '../hooks/useCustomerPolicesGet';
import RaiseEndorsementModal from '../components/RaiseEndorsementModal';
import useEndorsementGet from '../hooks/useEndorsementGet';
import { setProfileStep } from 'store/reducers/premiumPolicy';
import { useDispatch, useSelector } from 'react-redux';

const headings = [{ name: 'Profile Information' }, { name: 'My Policies' }, { name: 'Endorsements' }];

const LoginCustomer = () => {
  const [data, loading, isOpen, setIsOpen, selectedPolicy] = useCustomerPolicesGet();
  const [endorsementData, refreshEndorsementData] = useEndorsementGet();
  // const [selectedHeading, setSelectedHeading] = useState(0);
  const selectedHeading = useSelector((state) => state.persistedReducer.premiumPolicy.profileSteps);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedProfileStep = localStorage.getItem('profileStep');
    if (savedProfileStep) {
      dispatch(setProfileStep(Number(savedProfileStep)));
    }
  }, [dispatch]);

  const handleHeadingClick = (index) => {
    // Update Redux and localStorage
    dispatch(setProfileStep(Number(index)));
    localStorage.setItem('profileStep', index);

    if (index === 1 || index === 2) {
      window.location.reload(); // Reloads the current page
    }
  };

  const columns1 = [
    { id: 'sr', label: 'Sr.', minWidth: 100 },
    { id: 'psd', label: 'Policy Start', minWidth: 150 },
    { id: 'ped', label: 'Policy End', minWidth: 150 },
    { id: 'pa', label: 'Premium Amt.', minWidth: 150 },
    { id: 'createdat', label: 'Created at', minWidth: 150 },
    { id: 'Pno', label: 'Policy No.', minWidth: 150 },
    { id: 'policystatus', label: 'Status', minWidth: 150 },
    { id: 'Action', label: 'Action', minWidth: 110 }
  ];

  const columns = [
    { id: 'sr', label: 'Sr.', minWidth: 120, align: 'left' },
    { id: 'Pt', label: 'Product', minWidth: 120, align: 'left' },
    { id: 'et', label: 'Endorsements type', align: 'center', minWidth: 160 },
    { id: 'rd', label: 'Raised date', align: 'center', minWidth: 160 },
    { id: 'pno', label: 'Policy Number', align: 'center', minWidth: 160 },
    { id: 'status', label: 'Status', align: 'center', minWidth: 160 }
  ];

  // const rows = [
  //   {
  //     sr: '1',
  //     Pt: 'Motor',
  //     et: 'Add/Delete named order',
  //     rd: '12 May 2024',
  //     status: 'open',
  //     Action: (
  //       <Button
  //         variant="contained"
  //         style={{
  //           backgroundColor: '#00C7B1',
  //           color: '#fff',
  //           borderRadius: 20,
  //           fontSize: 12,
  //           width: '60px'
  //         }}
  //       >
  //         View
  //       </Button>
  //     )
  //   },
  //   {
  //     sr: '2',
  //     Pt: 'Corporate',
  //     et: 'Add/Delete named order',
  //     rd: '12 May 2024',
  //     status: 'open',
  //     Action: (
  //       <Button
  //         variant="contained"
  //         style={{
  //           backgroundColor: '#00C7B1',
  //           color: '#fff',
  //           borderRadius: 20,
  //           fontSize: 12,
  //           width: '60px'
  //         }}
  //       >
  //         View
  //       </Button>
  //     )
  //   }
  // ];

  const renderTable = () => {
    const tables = [
      <ProfileInformationForm />,
      data.length > 0 ? (
        <GRNTabel rows={data} columns={columns1} />
      ) : (
        <div
          style={{
            backgroundColor: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '95px',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px'
          }}
        >
          <p style={{ fontFamily: 'Medium', fontSize: '15px', fontWeight: 600 }}>No Policies Available</p>
        </div>
      ),
      endorsementData.length > 0 ? (
        <GRNTabel rows={endorsementData} columns={columns} />
      ) : (
        <div
          style={{
            backgroundColor: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '95px',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px'
          }}
        >
          <p style={{ fontFamily: 'Medium', fontSize: '15px', fontWeight: 600 }}>No Endorsement Request Available</p>
        </div>
      )
    ];
    return tables[selectedHeading];
  };

  return (
    <Grid container justifyContent="center" alignItems="center" mt={4}>
      <Grid item xs={12} md={8}>
        <Grid
          container
          justifyContent="center"
          style={{ width: '100%', backgroundColor: '#fff', borderTopLeftRadius: 10, borderTopRightRadius: 10, height: '60px' }}
        >
          {headings.map((item, index) => (
            <Grid
              key={index}
              item
              xs={4}
              style={{
                display: 'flex',
                height: '3.7rem',
                cursor: 'pointer',
                fontFamily: 'Medium',
                fontSize: '16px',
                color: selectedHeading === index ? '#002D8C' : '#000',
                borderBottom: selectedHeading === index ? '5px solid #002D8C' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}
              onClick={() => handleHeadingClick(index)}
            >
              <Grid item style={{ margin: 'auto' }}>
                {item.name}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid style={{ width: '100%' }}>{renderTable()}</Grid>
        <RaiseEndorsementModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => setIsOpen(false)}
          selectedPolicy={selectedPolicy}
          onFormSubmit={refreshEndorsementData}
        />
      </Grid>
    </Grid>
  );
};

export default LoginCustomer;
