import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {  headers, SERVER_URL } from 'helper/utils';
import { API } from '../../api/API';

export const ForeignWorkerAPI = createApi({
  reducerPath: 'ForeignWorkerAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: async (headers, { getState }) => {
      const appToken = localStorage.getItem('AppToken');
      headers.set('Content-Type', 'application/json');
      if (appToken) {
        headers.set('apptoken', JSON.parse(appToken));
      }
      return headers;
    }
  }),

  endpoints: (builder) => ({
    getFWDHCalculationGet: builder.query({
      queryFn: async ({ UserId, productMasterID }) => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'GET' });
        const response = await fetch(`${baseURL}${API.FWDHCalculationGet}?UserId=${UserId}&ProductMasterID=${productMasterID}`, header);
        if (!response.ok) {
          throw new Error('Failed to fetch getForeignWorkerAndDomesticHelperCalculationGet status');
        }
        const data = await response.json();
        return { data };
      }
    })
  })
});

export const { useGetFWDHCalculationGetQuery } = ForeignWorkerAPI;
