import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { headers, SERVER_URL } from 'helper/utils';
import { API } from '../../api/API';

export const VehicleDetailsAPI = createApi({
  reducerPath: 'VehicleDetailsAPI',
  tagTypes: ['searchnames'],
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: async (headers, { getState }) => {
      const appToken = localStorage.getItem('AppToken');
      headers.set('Content-Type', 'application/json');
      if (appToken) {
        headers.set('apptoken', JSON.parse(appToken));
      }
      return headers;
    }
  }),

  endpoints: (builder) => ({
    getWorkShopType: builder.query({
      queryFn: async () => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'GET' });
        const response = await fetch(`${baseURL}${API.WorkShopTypeGet}`, header);
        if (!response.ok) {
          throw new Error('Failed to fetch getWorkShopType status');
        }
        const data = await response.json();
        return { data };
      }
    }),

    getNCDDetails: builder.query({
      queryFn: async () => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'GET' });
        const response = await fetch(`${baseURL}${API.NCDGet}`, header);
        if (!response.ok) {
          throw new Error('Failed to fetch getNCDDetails status');
        }
        const data = await response.json();
        return { data };
      }
    }),

    getVehicleCapacityDetails: builder.query({
      queryFn: async () => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'GET' });
        const response = await fetch(`${baseURL}${API.VehicleCapacityGet}`, header);
        if (!response.ok) {
          throw new Error('Failed to fetch getVehicleCapacityDetails status');
        }
        const data = await response.json();
        return { data };
      }
    }),

    getAgeOfMotorcycleDetails: builder.query({
      queryFn: async () => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'GET' });
        const response = await fetch(`${baseURL}${API.AgeOfMotorcycleGet}`, header);
        if (!response.ok) {
          throw new Error('Failed to fetch getAgeOfMotorcycleDetails status');
        }
        const data = await response.json();
        return { data };
      }
    }),

    getVehicleDetails: builder.query({
      queryFn: async () => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'GET' });
        const response = await fetch(`${baseURL}${API.VehicleDetailsGet}`, header);
        if (!response.ok) {
          throw new Error('Failed to fetch getVehicleDetails status');
        }
        const data = await response.json();
        return { data };
      }
    }),

    getCustomerMasterbyNRICGet: builder.query({
      queryFn: async ({ nric }) => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'GET' });
        const response = await fetch(`${baseURL}${API.CustomerMasterbyNRICGet}?NRIC=${nric}`, header);
        if (!response.ok) {
          throw new Error('Failed to fetch getCustomerMasterbyNRICGet status');
        }
        const json = await response.json();
        const data = json?.data || [];
        return { data };
      },
      providesTags: ['searchnames']
    }),

    postCustomerMasterInsertUpdate: builder.mutation({
      queryFn: async ({requestedData}) => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'POST' });
        const response = await fetch(`${baseURL}${API.CustomerMasterInsertUpdate}`, { body: JSON.stringify(requestedData), header });
        if (!response.ok) {
          throw new Error('Failed to fetch PostCustomerMasterInsertUpdate status');
        }
        const json = await response.json();
        const data = json?.data || [];
        return { data };
      },
      invalidatesTags: ['searchnames']
    })
  })
});

export const {
  useGetWorkShopTypeQuery,
  useGetNCDDetailsQuery,
  useGetVehicleCapacityDetailsQuery,
  useGetAgeOfMotorcycleDetailsQuery,
  useGetVehicleDetailsQuery,
  useGetCustomerMasterbyNRICGetQuery,
  usePostCustomerMasterInsertUpdateMutation
} = VehicleDetailsAPI;
