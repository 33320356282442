import { useEffect, useState } from 'react';
import {
  useGetAgeOfMotorcycleDetailsQuery,
  useGetNCDDetailsQuery,
  useGetVehicleDetailsQuery,
  useGetWorkShopTypeQuery
} from 'store/services/VehicleDetails';
import { useSelector } from 'react-redux';
import { MotarOwnerData, MotarPackageData, MotorPolicyDetails, MotorVehiclesDetails } from '../static';
import { store } from 'store/index';
import { removeOtherIndividualState1 } from 'store/reducers/premiumPolicy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { showDanger } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';

const useProductDetails = (params) => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  useEffect(() => {
    let result = MotarPackageData.map((item) => ({ ...item, value: params[item.value] }));
    setData(result);
  }, []);

  return { data, isLoading, isError, setData };
};

const useOwnerDetails = (params) => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);
  useEffect(() => {
    setData(MotarOwnerData);
  }, []);

  return { data, isLoading, isError, setData };
};

//A Hook to getPolicyDetails
const usePolicyDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setData(MotorPolicyDetails);
  }, []);

  return { data, isLoading, isError };
};

//A Hook to get the vehicle list
const useVehiclesDetails = () => {
  const { data: getVehicleDetails, isError: error } = useGetVehicleDetailsQuery();
  const { data: NCDDetails } = useGetNCDDetailsQuery();
  const { data: getAgeOfMotorcycleDetails } = useGetAgeOfMotorcycleDetailsQuery();
  const { data: workshopType } = useGetWorkShopTypeQuery();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const network = useNetworkStatus();

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!network) {
          showDanger('No internet connection.');
          return;
        }
        if (error) {
          setIsError(true);
          setIsLoading(false);
        } else {
          await delay(1000);
          if (getVehicleDetails && getAgeOfMotorcycleDetails && workshopType && NCDDetails) {
            let res = MotorVehiclesDetails.map((item) => {
              let obj = { ...item };
              switch (item?.label) {
                case 'Vehicle List':
                  obj['ddoptions'] =
                    getVehicleDetails?.data?.map((item) => ({ id: item?.id, label: item?.vehicleModel, value: 'vehicleId',cckw: item?.cckw  })) || '';
                  break;

                case 'Age of Motorcycle (No. of years)':
                  obj['ddoptions'] =
                    getAgeOfMotorcycleDetails?.data?.data?.map((item) => ({ id: item, label: item, value: 'ageOfVehicle' })) || '';
                  break;

                case 'Workshop Type':
                  obj['ddoptions'] =
                    workshopType?.data?.data?.map((item) => ({
                      id: 'Authorized Workshop' /* item */,
                      label: 'Authorized Workshop' /* item */,
                      value: 'workShopType'
                    })) || [];
                  break;

                case 'No Claim Discount (NCD)':
                  obj['ddoptions'] = NCDDetails?.data?.data?.map((item) => {
                    return { id: item, label: item, value: 'NCD' };
                  });
                  break;
              }
              return obj;
            });
            setData(res);
          }
          setIsLoading(false);
        }
      } catch (e) {
        console.error('Error in useEffect', e);
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [getVehicleDetails, getAgeOfMotorcycleDetails, workshopType, NCDDetails]);

  return { data, isLoading, isError };
};

const useRiderDetails = (handleClickOnEdit) => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);
  const { riderDataSection } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);

  const handleDelete = (id) => {
    try {
      store.dispatch(removeOtherIndividualState1(id));
    } catch (e) {
      console.error('Error in useRiderDetails' + e);
    }
  };

  useEffect(() => {
    try {
      const rows = Object?.values(riderDataSection)?.map((rider, index) => ({
        sr: index + 1,
        id: rider?.id,
        firstName: rider?.firstName,
        lastName: rider?.lastName,
        nric: rider?.nric,
        age: rider?.age,
        gender: rider?.gender,
        nationality: rider?.nationality,
        contactNumber: rider?.contactNumber,
        email: rider?.email,
        Action: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                backgroundColor: '#4cb748',
                color: '#fff',
                borderRadius: 20,
                fontSize: '13px',
                cursor: 'pointer',
                width: '55px',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                padding: '3px',
                fontFamily: 'Medium'
              }}
              onClick={() => handleClickOnEdit(rider, 'edit')}
            >
              <EditIcon style={{ fontSize: '15px', marginRight: '2.5px' }} />
              Edit
            </div>
            <div
              style={{
                backgroundColor: rider?.isCheckboxChecked ? 'grey' : '#0174BF',
                color: '#fff',
                borderRadius: 20,
                fontSize: '13px',
                cursor: 'pointer',
                width: '75px',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                padding: '3px',
                fontFamily: 'Medium',
                marginLeft: '10px'
              }}
              onClick={() => {
                if (rider?.isCheckboxChecked !== true) {
                  handleDelete(rider.id);
                }
              }}
            >
              <DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} />
              Delete
            </div>
          </div>
        )
      }));
      setData(rows);
    } catch (e) {
      console.error('Error in useEffect useRiderDetails', e);
    }
  }, [riderDataSection]);

  return { data, isLoading, isError };
};

export { useProductDetails, usePolicyDetails, useVehiclesDetails, useRiderDetails, useOwnerDetails };
