import { showDanger, showWarning } from 'components/nudges/Alert';
import { SERVER_URL } from 'helper/utils';
import { updateAgendAuth, updateUserAuth } from 'store/reducers/auth';
import { useNavigate } from 'react-router-dom';
import { store } from 'store/index';
import { useDispatch } from 'react-redux';
import { setInactivityTokenModalOpen, setWrongDateTimeModalOpen } from 'store/reducers/premiumPolicy';

const useApiCall = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const makeRequest = async ({ method = 'GET', url, params, body, token = false, skipDateTimeWarning = false }) => {
    try {
      const config = {
        method: method.toUpperCase(),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*'
        }
      };

      // Add Authorization header with bearer token
      let storedToken = localStorage.getItem('AppToken');
      if (storedToken) {
        storedToken = JSON.parse(storedToken);
        config.headers['Authorization'] = `Bearer ${storedToken}`;
      } else {
        // showDanger('Authorization token not found. Please log in again.');
        navigate('/', { state: {}, replace: false });
        store.dispatch(updateUserAuth(false));

        localStorage.removeItem('AppToken');
        localStorage.removeItem('@login');
        localStorage.removeItem('@user');
        store.dispatch(updateAgendAuth({ status: false, data: {} }));
        navigate('/', { state: {}, replace: false });
        return null;
      }

      let serverUrl = SERVER_URL();
      let url_with_params = `${serverUrl}${url}`;
      if (params) {
        const encoded_params = await serializeQuery(params);
        url_with_params += encoded_params;
      }
      if (body) {
        config.body = JSON.stringify(body);
      }

      const response = await fetch(url_with_params, config);
      const result = await response.json();

      // console.log('result-------> useapicall------>', result);

      if (result) {
        const serverDateTime = new Date(result.serverDateTime);
        const systemDateTime = new Date();
        // console.log('systemDateTime-------> useapicall------>', systemDateTime);
        // console.log('serverDateTime-------> useapicall------>', serverDateTime);

        dispatch(setWrongDateTimeModalOpen(false));
        dispatch(setInactivityTokenModalOpen(false));
        /* if (skipDateTimeWarning && Math.abs(serverDateTime - systemDateTime) > 60000) {
          // showWarning("To continue, please ensure the device's date and time settings are accurate.");
          // alert('no skipdatetimewarning');
          return null;
        } else */  if (Math.abs(serverDateTime - systemDateTime) <= -600000 || Math.abs(serverDateTime - systemDateTime) > 600000) {
          dispatch(setWrongDateTimeModalOpen(true));
          // showWarning("To continue, please ensure the device's date and time settings are accurate.");
        } else if (result?.code === 500) {
          showDanger(result?.message || 'Internal server error');
        } else if (result?.code === 401) {
          // showDanger('You have been logged out because your session expired due to inactivity');
          dispatch(setInactivityTokenModalOpen(true));
          localStorage.removeItem('AppToken');
          localStorage.removeItem('@login');
          localStorage.removeItem('@user');
          store.dispatch(updateUserAuth(false));
          store.dispatch(updateAgendAuth({ status: false, data: {} }));
          navigate('/', { state: {}, replace: false });
        } else {
          return result;
        }
      }
    } catch (err) {
      showDanger(`${url} : ${err}`);
      return null;
    }
  };

  const makeRequestWithoutLogin = async ({ method = 'GET', url, params, body, skipDateTimeWarning = false }) => {
    try {
      const config = {
        method: method.toUpperCase(),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*'
        }
      };

      let url_with_params = `${SERVER_URL()}${url}`;

      if (params) {
        const encoded_params = await serializeQuery(params);
        url_with_params += encoded_params;
      }

      if (body) {
        config.body = JSON.stringify(body);
      }

      const response = await fetch(url_with_params, config);
      const result = await response.json();

      if (result) {
        const serverDateTime = new Date(result.serverDateTime);
        const systemDateTime = new Date();

        dispatch(setWrongDateTimeModalOpen(false));
        dispatch(setInactivityTokenModalOpen(false));

        /* if (skipDateTimeWarning && Math.abs(serverDateTime - systemDateTime) > 60000) {
          // showWarning("To continue, please ensure the device's date and time settings are accurate.");
          // alert('no skipdatetimewarning');
          return null;
        } else */  if (Math.abs(serverDateTime - systemDateTime) <= -600000 || Math.abs(serverDateTime - systemDateTime) > 600000) {
          dispatch(setWrongDateTimeModalOpen(true));
          // showWarning("To continue, please ensure the device's date and time settings are accurate.");
        } else if (result?.code === 500) {
          showDanger(result?.message || 'Internal server error');
        } else if (result?.code == 401) {
          //If Authorization is Requierd or token expired
          // showDanger('You have been logged out because your session expired due to inactivity');
          dispatch(setInactivityTokenModalOpen(true));
          localStorage.removeItem('AppToken');
          localStorage.removeItem('@user');
          localStorage.removeItem('@login');
          store.dispatch(updateUserAuth(false));
          store.dispatch(updateAgendAuth({ status: false, data: {} }));
          navigate('/', { state: {}, replace: false });
        } else {
          return result;
        }
      }
    } catch (err) {
      showDanger(`${url} : ${err}`);
      return null;
    }
  };

  const serializeQuery = async (query) => {
    return Object.keys(query)
      .map((key, index) => {
        const separator = index === 0 ? '?' : '&';
        return `${separator}${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
      })
      .join('');
  };

  return { makeRequest, makeRequestWithoutLogin };
};

export default useApiCall;
