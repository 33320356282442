import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, FormHelperText, Grid, Link, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'components/@extended/AnimateButton';
import { API } from 'api/API';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';
import useApiCall from 'api/useApiCall';
import logo from '../../../assets/images/ANDA Logo_RGB.png';
import { useLocation } from 'react-router-dom';
import OverlayLoader from 'components/loader/OverlayLoader';

const VerifyOtp = (props) => {
  const location = useLocation();
  const { data } = location?.state || {};
  const [getData] = useState(data || {});
  const [loading, setLoading] = useState(false);

  const network = useNetworkStatus();

  const { makeRequest } = useApiCall();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOnResendOTP = async () => {
    try {
      setLoading(true);
      
      let params = {
        UserId: getData?.UserId,
        emailId: getData?.emailId
      };
      let res = await makeRequest({ method: 'POST', url: API.PasswordChangeOTPSent, params });
      if (res.code === 200) {
        setLoading(false);
        showSuccess('OTP resend successfully');
      } else {
        setLoading(false);
        showWarning(res?.message)
      }
    } catch (e) {
      setLoading(false);
      showDanger(e);
    }
  };

  return (
    <>
      <OverlayLoader show={loading} />

      <Formik
        initialValues={{
          otp: '',
          Password: '',
          VerifyPassword: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          otp: Yup.string().max(10).required('OTP is required'),
          Password: Yup.string().max(255).required('Password is required'),
          VerifyPassword: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match') 
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (!network) {
              showDanger('No internet connection.');
              return;
            } else {
              setLoading(true);
              let params = {
                otp: values.otp,
                Password: values.Password
              };
              let res = await makeRequest({ method: 'POST', url: API.OtpWiseChangePassword, params });
              if (res.code == 200) {
                setLoading(false);
                props?.setIsOpen(true);
              } else {
                setLoading(false);
                showWarning(res?.message);
              }
            }
          } catch (e) {
            setLoading(false);
            showDanger(`${e}`);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} xs={12} alignItems="center" justifyContent="center" p={4}>
              <Grid item xs={12} justifyContent={'end'}>
                <img
                  src={logo}
                  alt="Anda Logo"
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: 'auto',
                    maxWidth: '12rem',
                    marginBottom: '2%',
                    alignItems: 'left'
                  }}
                />

                <p style={{ fontFamily: 'Thin', fontSize: 15, color: '#CACACB', fontWeight: 700, marginBottom: '3px' }}>Verify OTP </p>

                <Grid item xs={12} sx={{ marginTop: '8px' }}>
                  <Stack spacing={1}>
                    <OutlinedInput
                      sx={{
                        width: '18rem',
                        marginTop: '10px'
                      }}
                      id="otp"
                      name="otp"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter OTP"
                      error={Boolean(touched.otp && errors.otp)}
                    />
                    {touched.otp && errors.otp && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.otp}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '-10px' }}>
                <Stack spacing={1}>
                  <OutlinedInput
                    sx={{
                      width: '18rem'
                    }}
                    id="Password"
                    name="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Set new password"
                    error={Boolean(touched.Password && errors.Password)}
                  />
                  {touched.Password && errors.Password && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.Password}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '-10px' }}>
                <Stack spacing={1}>
                  <OutlinedInput
                    sx={{
                      width: '18rem'
                    }}
                    id="VerifyPassword"
                    name="VerifyPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Verify new password"
                    error={Boolean(touched.VerifyPassword && errors.VerifyPassword)}
                  />
                  {touched.VerifyPassword && errors.VerifyPassword && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.VerifyPassword}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12} sx={{ width: '100%', alignItems: 'center', display: 'flex', marginTop: '-10px' }}>
                <AnimateButton>
                  <Button
                    sx={{
                      width: '18rem'
                    }}
                    style={{ backgroundColor: '#002d8c', fontFamily: 'Medium' }}
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Verify OTP
                  </Button>
                </AnimateButton>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '-10px' }}>
                <Stack alignItems="left">
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: '5px' }}>
                      <p style={{ fontFamily: 'Light', color: '#C9C9C9', fontWeight: 600 }}>Didn't received OTP yet ? </p>
                    </div>

                    <Link
                      variant="h6"
                      component={RouterLink}
                      to="#"
                      onClick={handleClickOnResendOTP}
                      color="text.primary"
                      sx={{
                        fontFamily: 'Medium',
                        fontSize: 13,
                        color: '#4868ac'
                        // marginTop: '13.5px'
                      }}
                    >
                      Resend OTP
                    </Link>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default VerifyOtp;
