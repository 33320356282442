import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { HorizontalContainer } from '../corporate/components/HorizontalContainer';
import { removeOtherState, resetActiveSteps, setShowMotorVehicleForm } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';

export default function CalculateMotorVehicleContainer({ data, handleCalculatePremium }) {
  useEffect(() => {
    store.dispatch(removeOtherState());
    store.dispatch(resetActiveSteps());
    store.dispatch(setShowMotorVehicleForm(false));
  }, []);
  return (
    <div>
      <Grid container justifyContent="center" alignItems="center" mt={2}>
        <Grid item xs={12} md={11} lg={9}>
          <Grid
            container
            style={{
              width: '100%',
              backgroundColor: '#F2F9FF',
              borderRadius: 20,
              padding: '10px',
              maxHeight: '350px'
            }}
          >
            <Typography style={{ fontFamily: 'Bold', fontSize: 20, padding: 12 }}>Get your Motorcycle Quote</Typography>
            {data.map((item, index) => {
              let obj = { Plan: item.productName };
              return (
                <HorizontalContainer
                  data={obj}
                  sizes={[8, 4]}
                  key={index}
                  onClick={() => handleCalculatePremium(item)}
                  style={{ marginBottom: '10px' }}
                  buttonTitle="Get Quote"
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
