import React, { useContext, createContext, useState, useMemo } from 'react';
import { useTheme } from 'components/Theme/ThemeProvider';

const createPackagePremiumContext = createContext();

const PremiumCalculationStateProvider = ({ children, params, apiData, young }) => {
  const { currentTheme } = useTheme();

  const contextValue = useMemo(
    () => ({
      currentTheme,
      params,
      apiData,
      young
    }),
    [currentTheme, params, apiData, young]
  );

  return <createPackagePremiumContext.Provider value={contextValue}>{children}</createPackagePremiumContext.Provider>;
};

export const usePremiumCalculationState = () => {
  const context = useContext(createPackagePremiumContext);
  if (!context) {
    throw new Error('usePackagePremiumState must be used within a PremiumCalculationStateProvider');
  }
  return context;
};

export default PremiumCalculationStateProvider;
