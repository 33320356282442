import React, { useState } from 'react';
import '../styles/ContactUs.css';
import { Grid, TextField, Typography, Button } from '@mui/material';
import DropDown from 'components/dropdown/DropDown';
import NavBar from './NavBar';
import Footer from './Footer';
import NavBarSidebar from './NavBarSidebar';
import emailjs from 'emailjs-com';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const [firstName, setFirstName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [insuranceType, setInsuranceType] = useState(null);
  const [enquiryType, setEnquiryType] = useState(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const insuranceOptions = [
    { id: 'Domestic Helper', label: 'Domestic Helper' },
    { id: 'Motor Vehicle', label: 'Motor Vehicle' },
    { id: 'Foreign Worker', label: 'Foreign Worker' }
  ];
  const enquiryOptions = [
    { id: 'Purchase Insurance', label: 'Purchase Insurance' },
    { id: 'Become Agent', label: 'Become Agent' },
    { id: 'Enquiry', label: 'Enquiry' },
    { id: 'Feedback', label: 'Feedback' },
    { id: 'Claims', label: 'Claims' },
    { id: 'Others', label: 'Others' }
  ];

  const validateEmail = (email) => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email) && !email.includes('..');
  };

  const validateContact = (contactNumber) => {
    const contactPattern = /^[0-9]+$/; // Regular expression to match only numbers
    return contactPattern.test(contactNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    // Basic validations
    if (!firstName || !familyName || !email || !contactNumber || !insuranceType || !enquiryType || !message) {
      showWarning('All fields are required.');
      return; // Return early if any field is missing
    }

    // Validate the email
    if (!validateEmail(email)) {
      showWarning('Please enter a valid email address.');
      return; // Return early if the email is invalid
    }

    // Validate the contact number
    if (!validateContact(contactNumber)) {
      showWarning('Please enter a valid contact number with only digits.');
      return; // Return early if the contact number is invalid
    }

    setLoading(true);
    setDisable(true);

    const templateParams = {
      firstName: firstName,
      familyName: familyName,
      email: email,
      contactNumber: contactNumber,
      insuranceType: insuranceType,
      enquiryType: enquiryType,
      message: message
    };

    emailjs.send('service_uwet5sp', 'template_y5hkpz6', templateParams, 'nWUYW5uqWahlmKMii').then(
      (response) => {
        showSuccess('Email sent successfully!');
        setLoading(false);
        setDisable(false);
        console.log('Request sent successfully!', response.status, response.text);
        navigate('/');
      },
      (error) => {
        console.error('Failed to send email.', error);
        showDanger('Failed to send email');
        setLoading(false);
        setDisable(false);
        navigate('/');
      }
    );

    // Clear form fields
    setFirstName('');
    setFamilyName('');
    setEmail('');
    setContactNumber('');
    setInsuranceType('');
    setEnquiryType('');
    setMessage('');
  };

  return (
    <>
      <Grid container xs={12} mb={5}>
        <NavBar />
      </Grid>
      <div className="page_container">
        <div className="page">
          <div className="body">
            <div className="body claiminfo-body">
              <div className="contactus-body-content-holder col-md-adjsut xs-adjust">
                <div className="contact-body-content col-md-adjsut-content">
                  <div className="heading-wrapper contact-us-title white-heading">
                    <div className="heading-div">Contact Us</div>
                  </div>
                  <div className="contactus-body-content">
                    <div className="col-md-12 col-sm-12 col-xs-12 col-lg-4 office-info float-left">
                      <span className="block-span contactus-sub-title">Office Location</span>
                      <span className="block-span contactus-sub-content contactus-address">
                        1 King George's Avenue <br /> #06-00 Rehau Building <br />
                        Singapore 208557
                      </span>
                      <span className="block-span contactus-sub-title">Telephone Numbers</span>
                      <span className="block-span  contactus-sub-content">+65 6534 2288</span>
                      <span className="block-span contactus-sub-title">Email Us</span>
                      <span className="block-span  contactus-sub-content">enquiries@anda.com.sg</span>
                      <span className="block-span contactus-sub-title">Operating Hours</span>
                      <div className="container contactus-address-container">
                        <div className="row contactus-address-row">
                          <div className="col-sm-7 contactus-sub-content contactus-address-col">Mondays to Fridays</div>
                          <div className="col-sm-5 contactus-sub-content contactus-address-col">9.00 am to 6.00 pm</div>
                        </div>
                        <div className="row">
                          <div className="col-sm-7 contactus-sub-content contactus-address-col">Weekends and Public Holidays</div>
                          <div className="col-sm-5 contactus-sub-content contactus-address-col">
                            <span className="text-red">Closed</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12 col-lg-8 float-left">
                      <div className="contactus-map">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.780838714371!2d103.85700031945271!3d1.3066542611154313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da173af8000003%3A0x66291cf35647f7e7!2sAnda%20Insurance%20Agencies%20Pte%20Ltd!5e0!3m2!1sen!2ssg!4v1640065491757!5m2!1sen!2ssg"
                          width="100%"
                          height="450"
                          style={{ marginLeft: '4%', border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>

              <Grid container style={{ width: '100%' }}>
                <OverlayLoader show={loading} />
                {/* <Grid item xs={12} backgroundColor="#ffffff">
                  <NavBar />
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: '#002D8C',
                    padding: '2%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h2" style={{ color: 'white', margin: '2% 0' }}>
                      Interested or have questions?
                    </Typography>
                    <hr style={{ width: '100%', borderColor: '#ffffff' }} />
                  </div>
                  <Grid container spacing={3} style={{ paddingTop: '2%', width: '60%' }}>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ color: '#ffffff' }}>First Name</Typography>
                      <TextField
                        size="small"
                        variant="standard"
                        className="commonInput"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        style={{ width: '100%' }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontFamily: 'Medium'
                          }
                        }}
                        inputProps={{
                          style: {
                            fontSize: '13px'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ color: '#ffffff' }}>Family Name</Typography>
                      <TextField
                        size="small"
                        variant="standard"
                        className="commonInput"
                        value={familyName}
                        onChange={(e) => setFamilyName(e.target.value)}
                        required
                        style={{ width: '100%' }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontFamily: 'Medium'
                          }
                        }}
                        inputProps={{
                          style: {
                            fontSize: '13px'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ color: '#ffffff' }}>Email Address</Typography>
                      <TextField
                        size="small"
                        variant="standard"
                        className="commonInput"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        style={{ width: '100%' }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontFamily: 'Medium'
                          }
                        }}
                        inputProps={{
                          style: {
                            fontSize: '13px'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ color: '#ffffff' }}>Contact Number</Typography>
                      <TextField
                        size="small"
                        variant="standard"
                        className="commonInput"
                        value={contactNumber}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
                          setContactNumber(newValue);
                        }}
                        required
                        style={{ width: '100%' }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontFamily: 'Medium'
                          }
                        }}
                        inputProps={{
                          style: {
                            fontSize: '13px'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ color: '#ffffff' }}>Insurance Type</Typography>
                      <DropDown
                        marginTop={'1px'}
                        width={'99%'}
                        heightsss={'37px'}
                        height={'40px'}
                        placeholder="Insurance Type"
                        options={insuranceOptions}
                        // onValueChange={(i) => setInsuranceType(i.label)}
                        value={insuranceType}
                        onClick={(e) => e.stopPropagation()}
                        setSelectedValue={setInsuranceType}
                        onValueChange={(item) => setInsuranceType(item.id)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ color: '#ffffff' }}>Enquiry Type</Typography>
                      <DropDown
                        marginTop={'1px'}
                        width={'99%'}
                        heightsss={'37px'}
                        height={'40px'}
                        placeholder="Enquiry Type"
                        options={enquiryOptions}
                        // onValueChange={(i) => setEnquiryType(i.label)}
                        value={enquiryType}
                        onClick={(e) => e.stopPropagation()}
                        setSelectedValue={setEnquiryType}
                        onValueChange={(item) => setEnquiryType(item.id)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ color: '#ffffff' }}>How can we help you?</Typography>
                      <TextField
                        size="small"
                        variant="standard"
                        className="commonInput"
                        value={message}
                        multiline
                        rows={4}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        style={{ width: '100%' }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontFamily: 'Medium'
                          }
                        }}
                        inputProps={{
                          style: {
                            fontSize: '13px'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <Button
                        size="small"
                        variant="contained"
                        disable={disable}
                        style={{
                          width: '50%',
                          height: '50px',
                          borderRadius: '8px',
                          color: '#ffffff',
                          backgroundColor: '#002d8c',
                          minWidth: '50px',
                          marginRight: '10px',
                          padding: '2px 6px 2px 6px',
                          fontFamily: 'Medium',
                          border: '1px solid #ffffff'
                        }}
                        onClick={(e) => handleSubmit(e)} // Added onClick handler
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                  <Footer />
                </Grid> */}
              </Grid>
            </div>
          </div>
          <div className="footer container">
            {/* <div className="row footer-text-area">
            <div className="col-3 col-footer">
              <div className="footer-text">
                <p>Location</p>
                <p>
                  1 King George's Avenue <br />
                  #06-00 Rehau Building
                  <br />
                  Singapore 208557
                </p>
              </div>
            </div>
            <div className="col-4 col-footer">
              <div className="footer-text">
                <p>Contact information</p>
                <p className="footer-contact">
                  <i className="fa fa-envelope-o footer-text-icon" aria-hidden="true"></i>
                  <span>enquiries@anda.com.sg</span>
                  <br />
                  <i className="fal fa-phone-alt footer-text-icon" aria-hidden="true"></i>
                  <span>+65 6534 2288 </span>
                  <br />
                </p>
              </div>
            </div>
            <div className="col-2 col-footer"> </div>
          </div> */}
            {/* <div className="row row-copyright xs-adjust">
            <div className="col-6 footer-copyright-holder col-footer">
              <span className="footer-copyright">© 2022 ANDA Insurance Agencies Pte Ltd. All rights reserved.</span>
            </div>
            <div className="col-6 footer-contact-holder col-footer">
              <a href="../Static/terms-of-business.html" target="_blank" rel="noopener noreferrer">
                Terms Of Use
              </a>
              &nbsp;&nbsp;
              <a href="../Static/privacy-policy.html" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              &nbsp;&nbsp;
              <a href="ContactUs.html">Contact Us</a>
            </div>
          </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
