import React from 'react';
import { Grid, Button } from '@mui/material';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useMediaQuery } from '../../../../../node_modules/@mui/material/index';

export const HorizontalContainer = ({ data, sizes, onClick, buttonTitle }) => {
  console.log('dataHorizontalContainer', data);
  const formatValue = (value) => {
    if (typeof value === 'string' && !isNaN(parseFloat(value))) {
      return parseFloat(value).toFixed(2);
    }

    return value;
  };

  const isPriceKey = (key) => key.toLowerCase().includes('price');

  const isMobileView = useMediaQuery('(max-width:950px)');

  return (
    <Grid item xs={12} style={{ marginBottom: '15px' }}>
      <div style={{ borderRadius: 15, padding: '5px', border: '1px solid lightgrey', backgroundColor: '#fff' }}>
        <Grid container>
          {Object.keys(data).map((item, index) => {
            return (
              <Grid item xs={12} sm={sizes[index] || 4} key={index} style={{ marginTop: '8px' }}>
                <div>
                  <p
                    style={{
                      fontFamily: 'Medium',
                      marginBottom: '4px',
                      marginLeft: '3%',
                      display: 'flex',
                      justifyContent: 'left',
                      fontWeight: '600',
                      fontSize: '16px'
                    }}
                  >
                    {item}
                  </p>
                  <p
                    style={{
                      fontFamily: 'Medium',
                      fontWeight: '500',
                      fontSize: 13,
                      display: 'flex',
                      marginBottom: '4px',
                      marginLeft: '3%',
                      justifyContent: 'left'
                    }}
                  >
                    {isPriceKey(item) ? `$ ${formatValue(data[item])}` : formatValue(data[item])}
                  </p>
                </div>
              </Grid>
            );
          })}
          <Grid
            container
            xs={3}
            sm={4}
            md={4}
            lg={3}
            style={{ display: 'flex', alignItems: 'center', justifyContent: isMobileView ? 'center' : 'right' }}
          >
            <Button
              // fullWidth
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#002D8C',
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                height: '40px'
              }}
              onClick={onClick}
            >
              {buttonTitle}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
