// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
 previousVersion:1.1,   
 currentVersion:1.2,
};

// ==============================|| SLICE - MENU ||============================== //

const constant = createSlice({
  name: 'constant',
  initialState,
  reducers: {
    changeVersion(state, action) {
      state.currentVersion = action.payload;
    },
    
  }
});

export default constant.reducer;

export const { changeVersion} = constant.actions;
