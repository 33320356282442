// import React from 'react';
// import employee1 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-01.png';
// import employee2 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-02.png';
// import employee3 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-03.png';
// import '../styles/ImageCard.css';
// import { Grid } from '../../../../../../node_modules/@mui/material/index';

// const ImageCard = () => {
//   const imageData = [
//     {
//       image: employee1,
//       heading: 'Require Policy Documents?',
//       description: 'Enjoy a hassle-free experience and easily locate the right documents for your policy.'
//     },
//     {
//       image: employee2,
//       heading: 'Got a Question?',
//       description: 'Explore answers to common queries from customers like yourself across our platform.'
//     },
//     {
//       image: employee3,
//       heading: 'Policy Check-in Made Easy',
//       description: 'Log in to the customer portal to review your purchased policies and renewal details.'
//     }
//   ];

//   return (
//     <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//       <Grid item xs={12} sm={12} md={12} lg={12} justifyContent="space-evenly" className="imageCard_mainContainer">
//         <div className="headingContainer_imageCard">
//           <p className="always_heading_imagecard">Always Available, 24/7</p>
//           {/* <p className="secondary_heading_imagecard">24/7</p> */}
//         </div>
//         <div className="image_container_imagecard">
//           {imageData.map((item, index) => (
//             <Grid item xs={12} sm={12} md={12} lg={12} key={index} className="container_images_imagecard">
//               <Grid item xs={12} sm={12} md={12} lg={12} className="image___container_imagecard">
//                 <img src={item.image} className="image_imageCard" alt={`employee-${index}`} />
//                 <Grid item xs={12} sm={12} md={12} lg={12} className="positioning_image_undercard_imagecard">
//                   <div className="underImage_heading_imageCard">
//                     <span className="first_heading_imagecard">{item.heading}</span>
//                     <span className="second_heading_imagecard">{item.description}</span>
//                   </div>
//                 </Grid>
//               </Grid>
//             </Grid>
//           ))}
//         </div>
//       </Grid>
//     </Grid>
//   );
// };

// export default ImageCard;

// import React from 'react';
// import employee1 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-01.png';
// import employee2 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-02.png';
// import employee3 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-03.png';
// import '../styles/ImageCard.css';
// import { Grid } from '@mui/material';

// const ImageCard = () => {
//   const imageData = [
//     {
//       image: employee1,
//       heading: 'Require Policy Documents?',
//       description: 'Enjoy a hassle-free experience and easily locate the right documents for your policy.'
//     },
//     {
//       image: employee2,
//       heading: 'Got a Question?',
//       description: 'Explore answers to common queries from customers like yourself across our platform.'
//     },
//     {
//       image: employee3,
//       heading: 'Policy Check-in Made Easy',
//       description: 'Log in to the customer portal to review your purchased policies and renewal details.'
//     }
//   ];

//   return (
//     <Grid container className="imageCard_mainContainer">
//       <Grid item className="headingContainer_imageCard">
//         <p className="always_heading_imagecard">Always Available, 24/7</p>
//       </Grid>
//       <Grid container className="image_container_imagecard">
//         {imageData.map((item, index) => (
//           <Grid item key={index} className="container_images_imagecard">
//             <Grid item className="image___container_imagecard">
//               <img src={item.image} className="image_imageCard" alt={`employee-${index}`} />
//               <Grid item className="positioning_image_undercard_imagecard">
//                 <div className="underImage_heading_imageCard">
//                   <span className="first_heading_imagecard">{item.heading}</span>
//                   <span className="second_heading_imagecard">{item.description}</span>
//                 </div>
//               </Grid>
//             </Grid>
//           </Grid>
//         ))}
//       </Grid>
//     </Grid>
//   );
// };

// export default ImageCard;

import React from 'react';
import employee1 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-01.png';
import employee2 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-02.png';
import employee3 from '../../../../../assets/images/Anda New Images/ANDA_homepage_24-7Thumb-03.png';
import '../styles/ImageCard.css';
import { Grid } from '@mui/material';

const ImageCard = () => {
  const imageData = [
    {
      image: employee1,
      heading: 'Require Policy Documents?',
      description: 'Enjoy a hassle-free experience and easily locate the right documents for your policy.'
    },
    {
      image: employee2,
      heading: 'Got a Question?',
      description: 'Explore answers to common queries from customers like yourself across our platform.'
    },
    {
      image: employee3,
      heading: 'Policy Check-in Made Easy',
      description: 'Log in to the customer portal to review your purchased policies and renewal details.'
    }
  ];

  return (
    <Grid container className="imageCard_mainContainer">
      <Grid item className="headingContainer_imageCard">
        <p className="always_heading_imagecard">Always Available, 24/7</p>
      </Grid>
      <Grid container className="image_container_imagecard">
        {imageData.map((item, index) => (
          <Grid item key={index} className="container_images_imagecard">
            <Grid item className="image___container_imagecard">
              <img src={item.image} className="image_imageCard" alt={`employee-${index}`} />
              <Grid item className="positioning_image_undercard_imagecard">
                <div className="underImage_heading_imageCard">
                  <span className="first_heading_imagecard">{item.heading}</span>
                  <span className="second_heading_imagecard">{item.description}</span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ImageCard;
