import React, { useState } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Grid } from '@mui/material';
import Logo_RGB from '../../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.png';
import { useNavigate } from 'react-router-dom';
export default function Footer({ onContactClick, onTermsOfUseClick }) {
  const navigate = useNavigate();

  return (
    
      
        <div class="heading">
          <div class="contai1ner" style={{ padding: '20px 10px', backgroundColor: '#F3F6FF' }}>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <div class="row">
                <Grid xs={12} md={12} lg={12} sm={12} container spacing={2}>
                  <Grid item xs={12} md={3} lg={3} sm={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        height: '100%'
                      }}
                    >
                      <img
                        src={Logo_RGB}
                        alt="ANDA Logo_RGB"
                        style={{
                          width: '100%',
                          maxWidth: '150px',
                          marginBottom: '3%'
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3} sm={4}>
                    {' '}
                    <div class="col-sm-12">
                      <h6 style={{ color: '#1F469B', fontWeight: 700 }}>Products</h6>
                      <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10
                        }}
                      >
                        <a href="/DomesticHelper" target="_self" style={{ color: '#000', textDecoration: 'none' }}>
                          Domestic Helper
                        </a>
                      </div>
                      <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10
                        }}
                      >
                        <a href="/MotorVehicle" target="_self" style={{ color: '#000', textDecoration: 'none' }}>
                          Motor Vehicle
                        </a>
                      </div>
                      <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10
                        }}
                      >
                        <a href="/ForeignWorkers" target="_self" style={{ color: '#000', textDecoration: 'none' }}>
                          Foreign Workers
                        </a>
                      </div>
                      {/* <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10
                        }}
                      >
                        Lifestyle
                      </div> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3} sm={4}>
                    {' '}
                    <div class="col-sm-12">
                      <h6 style={{ color: '#1F469B', fontWeight: 700 }}>Useful links</h6>
                      <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10,
                          cursor: 'pointer'
                        }}
                        onClick={() => navigate('/AboutAnda')}
                      >
                        About ANDA
                      </div>
                      {/* <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10
                        }}
                      >
                        Help Center
                      </div>*/}
                      {/* <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10
                        }}
                      >
                        Become Agent
                      </div> */}
                      {/* <div
                        style={{ color: '#000', fontWeight: 500, fontSize: 12, marginTop: 10, cursor: 'pointer' }}
                        onClick={() => navigate('/ContactUs')}
                      >
                        Contact Us
                      </div> */}
                      {/* <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10,
                          cursor: 'pointer'
                        }}
                        onClick={() => navigate('/BecomeAgent')}
                      >
                        Become Agent
                      </div> */}
                      {/* <div
                        style={{
                          color: '#000',
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 10
                        }}
                      >
                        <a href="UsefulInformation/FAQAll.html" target="_self" style={{ color: '#000', textDecoration: 'none' }}>
                          FAQs
                        </a>
                      </div> */}
                    </div>
                  </Grid>

                  <Grid item xs={12} md={3} lg={3} sm={4}>
                    <div class="col-sm-12">
                      <h6 style={{ color: '#1F469B', fontWeight: 700 }}>Get in touch</h6>
                      <div
                        style={{
                          flexDirection: 'row',
                          width: '90%',
                          display: '-webkit-inline-box',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          marginBottom: 20
                        }}
                      >
                        <div
                          style={{
                            color: '#000',
                            fontWeight: 500,
                            fontSize: 12,
                            marginTop: 8
                          }}
                        >
                          1, King George's Avenue #06-00 Rehau Building Singapore 208575
                        </div>
                      </div>
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          marginBottom: 20
                        }}
                      >
                        <div
                          style={{
                            border: '2px solid #212D3D',
                            backgroundColor: '#002D8C',
                            marginRight: 10,
                            borderRadius: 50,
                            height: 35,
                            width: 35,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <PhoneIcon style={{ color: '#fff', fontSize: 20 }} />
                        </div>

                        <div
                          style={{
                            color: '#000',
                            fontWeight: 500,
                            fontSize: 12,
                            marginTop: 8
                          }}
                        >
                          <h6
                            style={{
                              color: '#002D8C',
                              fontSize: 13,
                              fontWeight: 700
                            }}
                          >
                            +65 6534 2288
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Grid item style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 10 }}>
                <Grid style={{ border: '1px solid #ddd', width: '100%' }} />
              </Grid>
              <div class="row">
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6} lg={6} sm={6}>
                    <div class="col-sm-12">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          marginTop: 20
                        }}
                      >
                        <div style={{ marginRight: 10 }}>
                          <div
                            style={{
                              color: '#000',
                              fontSize: 13,
                              fontWeight: 500,
                              marginRight: '10px',
                              cursor: 'pointer'
                            }}
                            onClick={() => navigate('/TermsOfUse')}
                          >
                            Terms of use
                          </div>
                        </div>
                        <div style={{ marginRight: 10 }}>
                          <div
                            style={{
                              color: '#000',
                              fontSize: 13,
                              fontWeight: 500,
                              marginRight: '10px',
                               cursor: 'pointer'
                            }}
                            onClick={() => navigate('/PrivacyPolicy')}
                          >
                            Privacy Policy
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              color: '#000',
                              fontSize: 13,
                              fontWeight: 500,
                              marginRight: '10px',
                              cursor:'pointer'
                            }}
                            onClick={() => navigate('/ContactUs')}
                          >
                            Contact us
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={6}>
                    <div class="col-sm-12">
                      <div
                        style={{
                          flexDirection: 'row',
                          width: '90%',
                          display: 'flex',
                          justifyContent: 'center',
                          //   alignItems: 'center',
                          marginTop: 10
                        }}
                      >
                        <div
                          style={{
                            height: 35,
                            width: 35,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <CopyrightIcon style={{ color: '#000', fontSize: 20 }} />
                        </div>

                        <div
                          style={{
                            color: '#000',
                            fontWeight: 500,
                            fontSize: 12,
                            marginTop: 8
                          }}
                        >
                          2024, ANDA Insurance Agencies Pte Ltd. All rights reserved.
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>
      
    
  );
}
