import React from 'react';
import PropTypes from 'prop-types';
import ReusableModal from '../routemodal/ResuableModal';
import { Button } from '@mui/material';
import '../../../assets/fonts/Fonts.css';
import { successModalStyles } from '../modalcss/Successmodal_style';
import download_image from '../../../assets/images/Warningicon.png';
import { useDispatch, useSelector } from 'react-redux';
import { setWrongDateTimeModalOpen } from 'store/reducers/premiumPolicy';
// import { setWrongDateTimeModalOpen } from '../../redux/slices/premiumPolicy';

const WrongDateTimeModal = (props) => {
  return (
    <div style={successModalStyles.modalContainer}>
      <ReusableModal
        showCloseButton={false}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        height="auto"
        width="100%"
        maxWidth="325px"
        borderRadius="10px"
        onClose={props.handleCloseModal}
        disableBackdropClick={true}
        ModalContent={
          <div style={successModalStyles.contentContainer}>
            <img src={download_image} alt="confirmation" style={successModalStyles.image} />
            <div style={successModalStyles.textContainer}>
              <span style={successModalStyles.title}>{props.title}</span>
              <span style={successModalStyles.subtitle}>{props.subtitle}</span>
            </div>
            {/* <Button size="normal" sx={successModalStyles.button} variant="contained" onClick={props.handleCloseModal}>
              Thank You
            </Button> */}
          </div>
        }
      />
    </div>
  );
};

WrongDateTimeModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default WrongDateTimeModal;
