import React from 'react';
import { Grid } from '@mui/material';
import useIncorporateFinanceTable from '../hooks/useIncorporateFinanceTable';
import BasicTable from 'pages/dashboard/BasicTable';
import OverlayLoader from 'components/loader/OverlayLoader';
import { showWarning } from 'components/nudges/Alert';
const columns = [
  { id: 'transaction', label: 'Transaction Date', minWidth: 180, align: 'center' },
  {
    id: 'invoice',
    label: 'Invoice No.',
    minWidth: 130,
    align: 'center'
  },

  {
    id: 'pno',
    label: 'Policy No.',
    minWidth: 130,
    align: 'center'
  },
  {
    id: 'phn',
    label: 'Name',
    minWidth: 130,
    align: 'center'
  },
  {
    id: 'insured',
    label: 'Insured Description',
    minWidth: 200,
    align: 'center'
  },
  {
    id: 'insurer',
    label: 'Insurer',
    minWidth: 130,
    align: 'center'
  },
  {
    id: 'premium',
    label: 'Premium',
    minWidth: 130,
    align: 'center'
  },
  {
    id: 'tsa',
    label: 'TSA Fee',
    minWidth: 130,
    align: 'center'
  },
  {
    id: 'outstanding',
    label: 'Outstanding',
    minWidth: 150,
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 110,
    align: 'center'
  }
];

const IncorporateFinanceTable = () => {
  const { loading, data, handleVerifyClick } = useIncorporateFinanceTable();
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const handleDateChange = () => {
    if (startDate > endDate) {
      showWarning('End date cannot be earlier than start date.');
    } else {
      handleVerifyClick(startDate, endDate);
    }
  };

  if (loading && data) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
        <OverlayLoader show={true} />
      </div>
    );
  }

  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      sx={{
        paddingTop: '24px !important',
        paddingLeft: '24px !important',
        paddingRight: '0px !important',
        paddingBottom: '0px !important'
      }}
    >
      <BasicTable
        columns={columns}
        rows={data}
        searchOn={['cname', 'psd', 'ped']}
        showSearchBar={false}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleVerifyClick={handleDateChange}
      />
    </Grid>
  );
};

export default IncorporateFinanceTable;
