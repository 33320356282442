import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';

import PremiumCustomerCard from './PremiumCustomerCard';
import CustomerMotorAmountCard from './CustomerMotorAmountCard';
import CustomerLoginModal from 'modules/customer/corporate/customerlandingpage/components/CustomerLoginModal';
import { showDanger, showSuccess } from 'components/nudges/Alert';
import { useSelector } from 'react-redux';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import { getUserId } from 'helper/utils';
import ReusableCard from 'components/cards/ResuableCard';
import OverlayLoader from 'components/loader/OverlayLoader';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { setMotorQrRefrenceNumber } from 'store/reducers/premiumPolicy';
import { useDispatch } from 'react-redux';

const ContainerContent = ({ handleNextButton, handlePreviousButton, data }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <PremiumCustomerCard data={data} />
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomerMotorAmountCard data={data} />
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={2.5} style={{ marginLeft: 20 }}>
          <Button
            size="small"
            variant="contained"
            style={{
              backgroundColor: '#f7f7f7',
              width: '100%',
              padding: '5%',
              color: '#000000',
              height: '100%',
              border: '1px solid #ddd'
            }}
            onClick={handlePreviousButton}
          >
            Previous
          </Button>
        </Grid>
        <Grid item xs={12} md={2.5}>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff', height: '100%' }}
            onClick={handleNextButton}
          >
            Proceed to Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MotorVehiclePremium = ({ handleNextButton, handlePreviousButton, data }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { otherState, uenData } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { CustomerriderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const { makeRequest } = useApiCall();
  const [isLoading, setIsLoading] = useState(false);
  const network = useNetworkStatus();
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem('@user');
    setIsLoggedIn(!!user);
  }, []);

  const handleFinalSubmission = async () => {
    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      let userData = localStorage.getItem('@user');
      let user = JSON.parse(userData);
      let userID = user?.userId;
      let isFoodParcel = otherState?.isFoodParcel;
      isFoodParcel = isFoodParcel ? 'yes' : 'no';
      const finalAmount = parseFloat(data[0]?.finalPremiumAmount) + parseFloat(data[0]?.fdExtentionAmountWithoutGST);
      setIsLoading(true);
      // let nricValue = user?.nricNo;

      // Extract riders from CustomerriderDataSection
      const riders = Object.values(CustomerriderDataSection);
      let nricValue = '';
      let contactDetails = {};

      if (riders.length > 0) {
        // Use the first rider's details
        const firstRider = riders[0];
        nricValue = firstRider.nric || '';
        contactDetails = {
          nricNo: firstRider.nric || '',
          firstName: firstRider.firstName || '',
          lastName: firstRider.lastName || '',
          gender: firstRider.gender || '',
          email: firstRider.email || '',
          maritalStatus: null,
          source: null,
          mobile: firstRider.contactNumber || '',
          designation: null,
          occupation: null,
          licensePassDate: firstRider?.license || null,
          street: null,
          city: null,
          state: null,
          postalCode: null,
          country: null,
          remarks: null
        };
      } else {
        // Use user's details if no riders are present
        nricValue = user?.nricNo;
        contactDetails = {
          nricNo: nricValue,
          firstName: user?.firstName || null,
          lastName: user?.lastName || null,
          gender: null,
          email: user?.finance_Email__c || null,
          maritalStatus: null,
          source: null,
          mobile: null,
          designation: null,
          occupation: null,
          licensePassDate: null,
          street: null,
          city: null,
          state: null,
          postalCode: null,
          country: null,
          remarks: null
        };
      }

      const body = {
        nric: nricValue,
        uen: otherState.uen,
        isIndividual: otherState.isCompanyRegistered ? false : true,
        insuranceStart: otherState.POIStartDate,
        insuranceEnd: otherState.POIEndDate,
        opportunityType: 'Motor',
        portalUserId: userID,
        creditTerm: otherState.isCompanyRegistered ? true : false,
        durationInMonths: '',
        transactionType: otherState.transactionType,
        companyRegistered: otherState.isCompanyRegistered,
        vehicleId: otherState.vehicleId,
        vrn: otherState.vehicleRegistration,
        coverageType: '',
        deliveryExtension: isFoodParcel,
        chasisNo: otherState.chasisNumber,
        engineNo: otherState.engineNumber,
        drivingLicenseDate: null,
        excess: data[0].excessAmount,
        claimsDetails: otherState.claimDetails,
        nameOfHirePurchase: otherState.nameOfHirePurchase,
        workshopType: otherState.workShopType,
        ncdAmount: data[0].ncdAmount,
        ncd: otherState?.NCD.toString(),
        reg_manufactureDate: '',
        bondDuration: '',
        reTransmissionReminder: null,
        reviewedByInsurer: false,
        basisOfCover: '',
        mainContractorName: '',
        cpfSubmissionNo: '',
        transmissionStatus: '',
        dischargeDate: null,
        loiStatus: '',
        bondCallStatus: '',
        loiType: '',
        contact: contactDetails,
        account: {
          accName: otherState.cm || '',
          accEmail: otherState.email || '',
          phone: otherState.phone?.toString() || '',
          source: '',
          bankAccountName: '',
          bankCode: '',
          bankName: '',
          bankAccountNo: '',
          street: '',
          city: '',
          state: '',
          postalCode: '',
          country: ''
        },
        items: [
          {
            itemCode: data[0]?.productID,
            salesPrice: finalAmount.toFixed(2),
            quantity: 1
          }
        ],
        productId: data[0].productID,
        loginDetailsId: data[0]?.loginDetailsId,
        customerPolicyId: data[0]?.customerPolicyId || null,
        opportunityId: data[0]?.opportunityId || null,
        totalPremiumAmount: data[0].finalTotalPremiumAmount || 0
      };

      if (Object.values(CustomerriderDataSection).length > 0) {
        body['insuredParty'] = Object.values(CustomerriderDataSection).map((rider) => ({
          firstName: rider.firstName || '',
          lastName: rider.lastName || '',
          nric: rider.nric || '',
          dob: rider.dob || null,
          gender: rider.gender || '',
          drivingLicense: rider.license || null,
          nationality: rider.nationality || '',
          contact: rider.contactNumber || '',
          email: rider.email || '',
          active: true,
          workPermitNo: '',
          passportNo: '',
          claimsIn3Year: 0,
          amountOfClaimsIn3Year: 0,
          age: rider.age || 0,
          experienceInMonths: rider.experience || '',
          ageOfDate: '',
          experienceInMonthsOfDate: '',
          fin: '',
          isOwner: rider?.isCheckboxChecked || false,
          isRider: rider?.isRiderCheckboxChecked || false
        }));
      } else {
        body['insuredParty'] = [
          {
            firstName: user?.firstName || null,
            lastName: '',
            nric: '',
            dob: null,
            gender: '',
            drivingLicense: null,
            nationality: '',
            contact: '',
            email: '',
            active: true,
            workPermitNo: '',
            passportNo: '',
            claimsIn3Year: 0,
            amountOfClaimsIn3Year: 0,
            age: 0,
            experienceInMonths: '',
            ageOfDate: '',
            experienceInMonthsOfDate: '',
            fin: '',
            isOwner: false,
            isRider: false
          }
        ];
      }

      const response = await makeRequest({ method: 'POST', url: API.FinalSubmissionCustomerPortalForFWDHAPI, body });

      if (response?.code === 200) {
        showSuccess('Policy request saved successfully.');
        setIsLoading(false);
        handleNextButton(response.data);
        dispatch(setMotorQrRefrenceNumber(response.data.referenceNumber));
      } else {
        showDanger('Error');
        setIsLoading(false);
      }
    } catch (error) {
      showDanger('Something went wrong. Please try again later.');
      setIsLoading(false);
    }
  };

  const handleNextButtonClick = () => {
    const user = localStorage.getItem('@user');
    if (user) {
      setIsLoggedIn(true);
      handleFinalSubmission();
    } else {
      setIsLoggedIn(false);
      setShowLoginModal(true);
    }
  };

  const handleLoginClose = () => {
    setShowLoginModal(false);
    const user = localStorage.getItem('@user');
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  return (
    <>
      <OverlayLoader show={isLoading} />
      <ReusableCard
        backgroundColor={'#fff'}
        border="1px solid #ddd"
        padding={'3%'}
        borderRadius={15}
        content={<ContainerContent handleNextButton={handleNextButtonClick} handlePreviousButton={handlePreviousButton} data={data} />}
      />
      <CustomerLoginModal isOpen={showLoginModal} onClose={handleLoginClose} setShow={setShowLoginModal} />
    </>
  );
};

export default MotorVehiclePremium;
