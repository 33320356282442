import React, { useEffect, useState } from 'react';
import CardLayout from '../CardLayout';
import { Grid, TextField, InputLabel, Button, Typography } from '@mui/material';
import '../../styles/InformationForm.css';
import DropDown from 'components/dropdown/DropDown';
import { usePackageDetails } from 'modules/packages & premium/hooks/useForeignWorker';
import { store } from 'store/index';
import { useSelector } from 'react-redux';
import useApiCall from 'api/useApiCall';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import { useDropdown } from '../../hooks/useForeignWorker';
import { useLocation } from 'react-router-dom';
import useUenVerification from '../../hooks/useUenVerification';
import { usePackagePremiumState } from 'modules/packages & premium/provider/PackagePremiumStateProvider';
function RenderFunction({ item, index, otherState, verificationMessage, dropdownData, handleVerifyClick }) {
  console.log('itemmmmmmmmm', item);
  const [value, setValue] = useState(otherState[item?.value]);
  const { params } = usePackagePremiumState();
  const [dateErrorMessage, setDateErrorMessage] = useState('');

  // console.log('otherState[item?.value]',item);

  const onChangeText = (txt, value) => {
    setValue(txt);
    if (item?.value === 'fromDate') {
      setDateErrorMessage('');
    }
  };

  const onBlur = (value) => {
    const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
    const currentDate = new Date().toISOString().split('T')[0];
    if (item?.texttype === 'date') {
      if (allowedCharsForDate.test(value)) {
        if (value >= currentDate) {
          store.dispatch(addOtherIndividualState([item.value, value]));
        } else {
          console.warn('Invalid date. Please enter a date not greater than today.');
          // setValue(currentDate);

          if (item?.value === 'fromDate') {
            setDateErrorMessage("The start date must be the same as today's date");
            store.dispatch(addOtherIndividualState([item.value, value]));
          } else {
            store.dispatch(addOtherIndividualState([item.value, currentDate]));
          }
        }
      } else {
        console.warn('Invalid date format. Please enter in YYYY-MM-DD format.');
      }
    } else {
      store.dispatch(addOtherIndividualState([item.value, value]));
    }
  };

  // const onChangeText = (txt, value,canAcceptSpecialChar) => {
  //   const allowedChars = /^[a-zA-Z0-9 ]*$/;
  //   const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
  //   console.log('txt--', txt);
  //   try {
  //     if (item?.texttype === 'date') {
  //       if (allowedCharsForDate.test(txt)) {
  //         store.dispatch(addOtherIndividualState([value, txt]));
  //         setValue(txt);
  //       } else {
  //         console.warn('Invalid date format. Please enter in DD-MM-YYYY format.');
  //       }
  //     } else {
  //        if(!canAcceptSpecialChar){
  //       if (allowedChars.test(txt)) {
  //         console.log('txt for date--', txt);
  //         setValue(txt);
  //         store.dispatch(addOtherIndividualState([value, txt]));
  //         console.log('value for date / txt for date--', value, txt);
  //       } else {
  //         console.warn('Special characters are not allowed. Please enter only letters, numbers, and spaces.');
  //       }
  //     }else{
  //       setValue(txt);
  //         store.dispatch(addOtherIndividualState([value, txt]));
  //     }

  //     }
  //   } catch (e) {
  //     console.error('Error during change text inside motorVehiclePolicyInformation ->', e);
  //   }
  // };

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([item?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };
  console.log("otherState['navigateFrom'] ===", otherState['navigateFrom']);
  useEffect(() => {
    try {
      if (otherState['uen'].length === 0) {
        store.dispatch(addOtherIndividualState(['UEMSTATUS', null]));
      }
      // if (item.value === 'todate') {
      //   let newDate = new Date(otherState['fromDate']);
      //   newDate.setFullYear(newDate.getFullYear() + 1);
      //   store.dispatch(addOtherIndividualState(['todate', newDate.toISOString().split('T')[0]]));
      //   setValue(newDate.toISOString().split('T')[0]);
      // }
    } catch (error) {
      console.error('An error occurred in useEffect:', error);
    }
  }, [otherState, item]);

  useEffect(() => {
    if (item?.label === 'To date' && otherState && params.duration) {
      const fromDate = otherState?.fromDate;
      const numOfMonths = parseInt(params.duration);

      if (fromDate && numOfMonths) {
        const fromDateObj = new Date(fromDate);
        fromDateObj.setMonth(fromDateObj.getMonth() + numOfMonths);
        const newDate = new Date(fromDateObj);
        newDate.setDate(newDate.getDate() - 1);

        const toDate = newDate.toISOString().slice(0, 10);
        store.dispatch(addOtherIndividualState(['todate', toDate]));
        setValue(toDate);
      }
    }
  }, [otherState, params.duration]);

  // item.value === 'uen' && otherState[navigateFrom] == 'renewal'  ? false :
  console.log('item====', item);
  let isFlagm;
  if (otherState['UEMSTATUS'] === false) {
    isFlagm = true;
  } else {
    isFlagm = item.isFlag;
  }
  const isLabelVisible = item.type !== 'dropdown' || item?.ddoptions?.length > 0;
  return (
    <>
      {isFlagm && (
        <Grid item xs={12} md={index === 2 ? 3 : 4} alignItems="center">
          {isFlagm ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InputLabel className="inputs_styless_">{item.label}</InputLabel>
              {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
            </div>
          ) : (
            <></>
          )}

          {item.type === 'textField' && isFlagm ? (
            <>
              <TextField
                type={item?.texttype}
                disabled={item?.value == 'uen' && otherState['navigateFrom'] == 'renewal' ? true : item?.isEnable}
                onChange={(txt) => onChangeText(txt.target.value, item?.value, item?.canAcceptSpecialChar)}
                onBlur={() => onBlur(value)}
                required={item?.isRequired}
                size="small"
                variant="standard"
                // InputProps={{ disableUnderline: true }}
                className="txtinputs_stylingg"
                value={value}
                style={{ marginTop: '5px', fontSize: '13px' }}
                inputProps={{
                  min: new Date().toISOString().split('T')[0],
                  style: {
                    fontSize: '13px' // Change the font size of the input text
                  },
                  max: (() => {
                    const maxDate = new Date();
                    maxDate.setFullYear(maxDate.getFullYear() + 10);
                    return maxDate.toISOString().split('T')[0];
                  })()
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontFamily: 'Medium' // Change the font family
                  }
                }}
                // inputProps={{
                //   style: {
                //     fontSize: '13px' // Change the font size of the input text
                //   }
                // }}
              />
              <InputLabel className="inputs_styless_" style={{ marginLeft: 0, color: 'red' }}>
                {dateErrorMessage}
              </InputLabel>
            </>
          ) : (
            <></>
          )}
          {index === 2 && value.length > 1 && (
            <Typography variant="body2" style={{ color: otherState['UEMSTATUS'] ? 'lightgreen' : 'red', marginLeft: '4px' }}>
              {otherState['UEMSTATUS'] == null ? '' : otherState['UEMSTATUS'] ? ' This UEN is verified.' : 'This UEN is not registered.'}
            </Typography>
          )}
          {item.type === 'dropdown' && (
            <DropDown
              width={'96%'}
              heightsss={'37px'}
              height={'40px'}
              marginTop={'6px'}
              options={item?.ddoptions}
              onValueChange={onValueChange}
              defaultValue={otherState[item?.value]}
            />
          )}
        </Grid>
      )}

      {index == 2 && otherState['navigateFrom'] == undefined && (
        <Grid item xs={12} md={1} mt={verificationMessage ? 0 : 3}>
          <Button
            variant="contained"
            size="small"
            disabled={value == 0 ? true : false}
            style={{
              backgroundColor: value == 0 ? 'grey' : '#4CB748',
              color: value > 0 && '#FFF'
            }}
            onClick={() => handleVerifyClick(true, value)}
          >
            Verify
          </Button>
        </Grid>
      )}
    </>
  );
}

const ForeignWorkerPackageInformation = React.memo((props) => {
  // const paramsData = useLocation()
  // console.log("paramsData ====",paramsData)
  const { data } = usePackageDetails();
  console.log('data from params ===', data);
  const dropdownData = props.data;
  console.log('dropdownData', dropdownData);
  const [verificationMessage, setVerificationMessage] = useState('');
  const { handleVerifyClick } = useUenVerification();
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { data: dropdownOptions, isLoading } = useDropdown(data, dropdownData);
  console.log('OTHER STATE IN Foreign Worker ====>', data);

  useDropdown(data);

  const [isOpen, setIsOpen] = useState(true);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CardLayout isOpen={isOpen} setIsOpen={setIsOpen} toggleForm={toggleForm} heading="Package Information">
      {data.map((item, index) => (
        <RenderFunction
          key={index}
          item={item}
          index={index}
          otherState={otherState}
          verificationMessage={verificationMessage}
          setVerificationMessage={setVerificationMessage}
          handleVerifyClick={handleVerifyClick}
        />
      ))}
    </CardLayout>
  );
});

export default ForeignWorkerPackageInformation;
