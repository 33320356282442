import React, { useState } from 'react';
import NavBar from './NavBar';
import '../../../../customer/corporate/customerlandingpage/styles/BecomeAgent.css';
import { Grid, TextField, Typography, Button } from '@mui/material';
import DropDown from 'components/dropdown/DropDown';
import Footer from './Footer';
import emailjs from 'emailjs-com';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useNavigate } from 'react-router-dom';

const BecomeAgentTemplate = () => {
  const [firstName, setFirstName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [insuranceType, setInsuranceType] = useState(null);
  const [enquiryType, setEnquiryType] = useState('Become Agent');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const insuranceOptions = [
    { id: 'Domestic Helper', label: 'Domestic Helper' },
    { id: 'Motor Vehicle', label: 'Motor Vehicle' },
    { id: 'Foreign Worker', label: 'Foreign Worker' }
  ];
  const enquiryOptions = [
    { id: 'Purchase Insurance', label: 'Purchase Insurance' },
    { id: 'Become Agent', label: 'Become Agent' },
    { id: 'Enquiry', label: 'Enquiry' },
    { id: 'Feedback', label: 'Feedback' },
    { id: 'Claims', label: 'Claims' },
    { id: 'Others', label: 'Others' }
  ];

  const validateEmail = (email) => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email) && !email.includes('..');
  };

  const validateContact = (contactNumber) => {
    const contactPattern = /^[0-9]+$/; // Regular expression to match only numbers
    return contactPattern.test(contactNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    // Basic validations
    if (!firstName || !familyName || !email || !contactNumber || !insuranceType || !enquiryType || !message) {
      showWarning('All fields are required.');
      return; // Return early if any field is missing
    }

    // Validate the email
    if (!validateEmail(email)) {
      showWarning('Please enter a valid email address.');
      return; // Return early if the email is invalid
    }

    // Validate the contact number
    if (!validateContact(contactNumber)) {
      showWarning('Please enter a valid contact number with only digits.');
      return; // Return early if the contact number is invalid
    }

    setLoading(true);
    setDisable(true);

    const templateParams = {
      firstName: firstName,
      familyName: familyName,
      email: email,
      contactNumber: contactNumber,
      insuranceType: insuranceType,
      enquiryType: enquiryType,
      message: message
    };

    emailjs.send('service_uwet5sp', 'template_y5hkpz6', templateParams, 'nWUYW5uqWahlmKMii').then(
      (response) => {
        showSuccess('Request sent successfully!');
        setLoading(false);
        setDisable(false);
        console.log('Email sent successfully!', response.status, response.text);
      },
      (error) => {
        console.error('Failed to send email.', error);
        showDanger('Failed to send email');
        setLoading(false);
        setDisable(false);
      }
    );

    // Clear form fields
    setFirstName('');
    setFamilyName('');
    setEmail('');
    setContactNumber('');
    setInsuranceType('');
    setEnquiryType('');
    setMessage('');
  };

  return (
    <Grid container style={{ width: '100%' }}>
      <OverlayLoader show={loading} />
      <Grid item xs={12} backgroundColor="#ffffff">
        <NavBar />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: '#002D8C',
          padding: '2%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h2" style={{ color: 'white', margin: '2% 0' }}>
            Interested or have questions?
          </Typography>
          <hr style={{ width: '100%', borderColor: '#ffffff' }} />
        </div>
        <Grid container spacing={3} style={{ paddingTop: '2%', width: '60%' }}>
          <Grid item xs={12} md={6}>
            <Typography style={{ color: '#ffffff' }}>First Name</Typography>
            <TextField
              size="small"
              variant="standard"
              className="commonInput"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              style={{ width: '100%' }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium'
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ color: '#ffffff' }}>Family Name</Typography>
            <TextField
              size="small"
              variant="standard"
              className="commonInput"
              value={familyName}
              onChange={(e) => setFamilyName(e.target.value)}
              required
              style={{ width: '100%' }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium'
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ color: '#ffffff' }}>Email Address</Typography>
            <TextField
              size="small"
              variant="standard"
              className="commonInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: '100%' }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium'
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ color: '#ffffff' }}>Contact Number</Typography>
            <TextField
              size="small"
              variant="standard"
              className="commonInput"
              value={contactNumber}
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
                setContactNumber(newValue);
              }}
              required
              style={{ width: '100%' }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium'
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ color: '#ffffff' }}>Insurance Type</Typography>
            <DropDown
              marginTop={'1px'}
              width={'99%'}
              heightsss={'37px'}
              height={'40px'}
              placeholder="Insurance Type"
              options={insuranceOptions}
              // onValueChange={(i) => setInsuranceType(i.label)}
              value={insuranceType}
              onClick={(e) => e.stopPropagation()}
              setSelectedValue={setInsuranceType}
              onValueChange={(item) => setInsuranceType(item.id)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ color: '#ffffff' }}>Enquiry Type</Typography>
            <DropDown
              marginTop={'1px'}
              width={'99%'}
              heightsss={'37px'}
              height={'40px'}
              placeholder="Enquiry Type"
              options={enquiryOptions}
              // onValueChange={(i) => setEnquiryType(i.label)}
              value={enquiryType}
              onClick={(e) => e.stopPropagation()}
              setSelectedValue={setEnquiryType}
              onValueChange={(item) => setEnquiryType(item.id)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ color: '#ffffff' }}>How can we help you?</Typography>
            <TextField
              size="small"
              variant="standard"
              className="commonInput"
              value={message}
              multiline
              rows={4}
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{ width: '100%' }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium'
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <Button
              size="small"
              variant="contained"
              disable={disable}
              style={{
                width: '50%',
                height: '50px',
                borderRadius: '8px',
                color: '#ffffff',
                backgroundColor: '#002d8c',
                minWidth: '50px',
                marginRight: '10px',
                padding: '2px 6px 2px 6px',
                fontFamily: 'Medium',
                border: '1px solid #ffffff'
              }}
              onClick={(e) => handleSubmit(e)} // Added onClick handler
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default BecomeAgentTemplate;

// import React, { useState } from 'react';
// import NavBar from './NavBar';
// import '../../../../customer/corporate/customerlandingpage/styles/BecomeAgent.css';
// import { Grid, TextField, Typography, Button } from '@mui/material';
// import DropDown from 'components/dropdown/DropDown';
// import Footer from './Footer';
// import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
// import OverlayLoader from 'components/loader/OverlayLoader';
// import { useNavigate } from 'react-router-dom';

// const BecomeAgentTemplate = () => {
//   const [firstName, setFirstName] = useState('');
//   const [familyName, setFamilyName] = useState('');
//   const [email, setEmail] = useState('');
//   const [contactNumber, setContactNumber] = useState('');
//   const [insuranceType, setInsuranceType] = useState(null);
//   const [enquiryType, setEnquiryType] = useState('Become Agent');
//   const [message, setMessage] = useState('');
//   const navigate = useNavigate();

//   const [loading, setLoading] = useState(false);
//   const [disable, setDisable] = useState(false);

//   const insuranceOptions = [
//     { id: 'Domestic Helper', label: 'Domestic Helper' },
//     { id: 'Motor Vehicle', label: 'Motor Vehicle' },
//     { id: 'Foreign Worker', label: 'Foreign Worker' }
//   ];
//   const enquiryOptions = [
//     { id: 'Purchase Insurance', label: 'Purchase Insurance' },
//     { id: 'Become Agent', label: 'Become Agent' },
//     { id: 'Enquiry', label: 'Enquiry' },
//     { id: 'Feedback', label: 'Feedback' },
//     { id: 'Claims', label: 'Claims' },
//     { id: 'Others', label: 'Others' }
//   ];

//   const validateEmail = (email) => {
//     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
//     return emailRegex.test(email) && !email.includes('..');
//   };

//   const validateContact = (contactNumber) => {
//     const contactPattern = /^[0-9]+$/; // Regular expression to match only numbers
//     return contactPattern.test(contactNumber);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!firstName || !familyName || !email || !contactNumber || !insuranceType || !enquiryType || !message) {
//       showWarning('All fields are required.');
//       return;
//     }

//     if (!validateEmail(email)) {
//       showWarning('Please enter a valid email address.');
//       return;
//     }

//     if (!validateContact(contactNumber)) {
//       showWarning('Please enter a valid contact number with only digits.');
//       return;
//     }

//     setLoading(true);
//     setDisable(true);

//     let accessKey;
//     let emailSubject;

//     switch (insuranceType) {
//       case 'Domestic Helper':
//         accessKey = 'cf9814b4-0b5b-4d92-8993-fdc922b4957f'; // replace with actual access key
//         emailSubject = 'Domestic Helper Enquiry';
//         break;
//       case 'Motor Vehicle':
//         accessKey = '009fc50b-3631-414f-9587-ab09f073923e'; // replace with actual access key
//         emailSubject = 'Motor Vehicle Enquiry';
//         break;
//       case 'Foreign Worker':
//         accessKey = 'foreign_worker_access_key'; // replace with actual access key
//         emailSubject = 'Foreign Worker Enquiry';
//         break;
//       default:
//         accessKey = 'default_access_key'; // fallback access key for other types
//         emailSubject = 'General Enquiry';
//     }

//     // W3Forms request data
//     const formData = new FormData();
//     formData.append('access_key', accessKey); // Replace with your actual W3Forms access key
//     formData.append('subject', emailSubject); // Optional subject for the email
//     formData.append('first_name', firstName);
//     formData.append('family_name', familyName);
//     formData.append('email', email);
//     formData.append('contact_number', contactNumber);
//     formData.append('insurance_type', insuranceType);
//     formData.append('enquiry_type', enquiryType);
//     formData.append('message', message);

//     fetch('https://api.web3forms.com/submit', {
//       method: 'POST',
//       body: formData
//     })
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.success) {
//           showSuccess('Request sent successfully!');
//         } else {
//           showDanger('Failed to send email.');
//         }
//         setLoading(false);
//         setDisable(false);
//       })
//       .catch((error) => {
//         console.error('Failed to send email.', error);
//         showDanger('Failed to send email');
//         setLoading(false);
//         setDisable(false);
//       });

//     // Clear form fields
//     setFirstName('');
//     setFamilyName('');
//     setEmail('');
//     setContactNumber('');
//     setInsuranceType('');
//     setEnquiryType('');
//     setMessage('');
//   };

//   return (
//     <Grid container style={{ width: '100%' }}>
//       <OverlayLoader show={loading} />
//       <Grid item xs={12} backgroundColor="#ffffff">
//         <NavBar />
//       </Grid>
//       <Grid
//         item
//         xs={12}
//         style={{
//           backgroundColor: '#002D8C',
//           padding: '2%',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flexDirection: 'column'
//         }}
//       >
//         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//           <Typography variant="h2" style={{ color: 'white', margin: '2% 0' }}>
//             Interested or have questions?
//           </Typography>
//           <hr style={{ width: '100%', borderColor: '#ffffff' }} />
//         </div>
//         <Grid container spacing={3} style={{ paddingTop: '2%', width: '60%' }}>
//           <Grid item xs={12} md={6}>
//             <Typography style={{ color: '#ffffff' }}>First Name</Typography>
//             <TextField
//               size="small"
//               variant="standard"
//               className="commonInput"
//               value={firstName}
//               onChange={(e) => setFirstName(e.target.value)}
//               required
//               style={{ width: '100%' }}
//               InputProps={{
//                 disableUnderline: true,
//                 style: {
//                   fontFamily: 'Medium'
//                 }
//               }}
//               inputProps={{
//                 style: {
//                   fontSize: '13px'
//                 }
//               }}
//             />
//           </Grid>
//           {/* ... other form fields remain the same ... */}
//           <Grid item xs={12} md={6}>
//             <Typography style={{ color: '#ffffff' }}>Family Name</Typography>
//             <TextField
//               size="small"
//               variant="standard"
//               className="commonInput"
//               value={familyName}
//               onChange={(e) => setFamilyName(e.target.value)}
//               required
//               style={{ width: '100%' }}
//               InputProps={{
//                 disableUnderline: true,
//                 style: {
//                   fontFamily: 'Medium'
//                 }
//               }}
//               inputProps={{
//                 style: {
//                   fontSize: '13px'
//                 }
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography style={{ color: '#ffffff' }}>Email Address</Typography>
//             <TextField
//               size="small"
//               variant="standard"
//               className="commonInput"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//               style={{ width: '100%' }}
//               InputProps={{
//                 disableUnderline: true,
//                 style: {
//                   fontFamily: 'Medium'
//                 }
//               }}
//               inputProps={{
//                 style: {
//                   fontSize: '13px'
//                 }
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography style={{ color: '#ffffff' }}>Contact Number</Typography>
//             <TextField
//               size="small"
//               variant="standard"
//               className="commonInput"
//               value={contactNumber}
//               onChange={(e) => {
//                 const newValue = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
//                 setContactNumber(newValue);
//               }}
//               required
//               style={{ width: '100%' }}
//               InputProps={{
//                 disableUnderline: true,
//                 style: {
//                   fontFamily: 'Medium'
//                 }
//               }}
//               inputProps={{
//                 style: {
//                   fontSize: '13px'
//                 }
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography style={{ color: '#ffffff' }}>Insurance Type</Typography>
//             <DropDown
//               marginTop={'1px'}
//               width={'99%'}
//               heightsss={'37px'}
//               height={'40px'}
//               placeholder="Insurance Type"
//               options={insuranceOptions}
//               // onValueChange={(i) => setInsuranceType(i.label)}
//               value={insuranceType}
//               onClick={(e) => e.stopPropagation()}
//               setSelectedValue={setInsuranceType}
//               onValueChange={(item) => setInsuranceType(item.id)}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography style={{ color: '#ffffff' }}>Enquiry Type</Typography>
//             <DropDown
//               marginTop={'1px'}
//               width={'99%'}
//               heightsss={'37px'}
//               height={'40px'}
//               placeholder="Enquiry Type"
//               options={enquiryOptions}
//               // onValueChange={(i) => setEnquiryType(i.label)}
//               value={enquiryType}
//               onClick={(e) => e.stopPropagation()}
//               setSelectedValue={setEnquiryType}
//               onValueChange={(item) => setEnquiryType(item.id)}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography style={{ color: '#ffffff' }}>How can we help you?</Typography>
//             <TextField
//               size="small"
//               variant="standard"
//               className="commonInput"
//               value={message}
//               multiline
//               rows={4}
//               onChange={(e) => setMessage(e.target.value)}
//               required
//               style={{ width: '100%' }}
//               InputProps={{
//                 disableUnderline: true,
//                 style: {
//                   fontFamily: 'Medium'
//                 }
//               }}
//               inputProps={{
//                 style: {
//                   fontSize: '13px'
//                 }
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6} sm={6} lg={6}>
//             <Button
//               size="small"
//               variant="contained"
//               disable={disable}
//               style={{
//                 width: '50%',
//                 height: '50px',
//                 borderRadius: '8px',
//                 color: '#ffffff',
//                 backgroundColor: '#002d8c',
//                 minWidth: '50px',
//                 marginRight: '10px',
//                 padding: '2px 6px 2px 6px',
//                 fontFamily: 'Medium',
//                 border: '1px solid #ffffff'
//               }}
//               onClick={(e) => handleSubmit(e)}
//             >
//               Send Message
//             </Button>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs={12}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default BecomeAgentTemplate;
