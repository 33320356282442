import ReusableCard from 'components/cards/ResuableCard';
import React from 'react';
import { Grid, Button, TextField, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { useSelector, useDispatch } from 'react-redux';

const ForeignWorkerPremiumAmount = ({ handleNextButton, handlePreviousButton }) => {
  const { premiumCalculation } = useSelector((state) => state?.persistedReducer?.premiumPolicy);
  return (
    <div>
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'1%'}
        boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container p={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '500' }}>Premium Amount</Typography>
            </Grid>

            <Grid container p={1} xs={12} style={{ border: '2px solid  #002d8c', marginTop: '5%', borderRadius: '10px' }}>
              <Grid item xs={10}>
                <p style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  Premium W/O GST
                </p>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                <p
                  style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}
                >{`$ ${(premiumCalculation?.premiumAmount).toFixed(2)}`}</p>
              </Grid>
              <Grid item xs={10}>
                <p style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  Add-on total
                </p>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                <p
                  style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}
                >{`$ ${(premiumCalculation?.addOnAmount).toFixed(2)}`}</p>
              </Grid>
              <Grid item xs={10}>
                <p style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  GST ( 9% )
                </p>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                <p
                  style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}
                >{`$ ${(premiumCalculation?.gstAmount).toFixed(2)}`}</p>
              </Grid>
              <Grid item xs={10}>
                <p style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  Premium with GST
                </p>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                <p
                  style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}
                >{`$ ${(premiumCalculation?.premiumWithGST).toFixed(2)}`}</p>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={2.5} style={{ marginLeft: 20 }}>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    backgroundColor: '#f7f7f7',
                    width: '100%',
                    padding: '5%',
                    color: '#000000',
                    height: '100%',
                    border: '1px solid #ddd'
                  }}
                  onClick={handlePreviousButton}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item xs={12} md={2.5}>
                <Button
                  size="small"
                  variant="contained"
                  style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff', height: '100%' }}
                  onClick={handleNextButton}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default ForeignWorkerPremiumAmount;

const planDetail = { 'Plan Premium': 'Basic Plan (With Co-Payment)', '26 months': '$490.50', '14 Months': '$367.88' };
