import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers/index';
import { VehicleDetailsAPI } from './services/VehicleDetails';
import { CountriesListAPI } from './services/CountriesList';
import { ForeignWorkerAPI } from './services/ForeignWorker';
import { DomesticHelperAPI } from './services/DomesticHelperCustomerRTK';
// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistConfig = {
  key: '1.2',
  version: 1.2,
  storage,
  migrate: (state) => {
    return Promise.resolve(state);
  }
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: {
    persistedReducer,
    // reducers,
    [VehicleDetailsAPI.reducerPath]: VehicleDetailsAPI.reducer,
    [CountriesListAPI.reducerPath]: CountriesListAPI.reducer,
    [ForeignWorkerAPI.reducerPath]: ForeignWorkerAPI.reducer,
    [DomesticHelperAPI.reducerPath]: DomesticHelperAPI.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([VehicleDetailsAPI.middleware, CountriesListAPI.middleware, ForeignWorkerAPI.middleware, DomesticHelperAPI.middleware])
});

const persistor = persistStore(store);
//Persist config

// Add an event listener for beforeunload
window.addEventListener('beforeunload', () => {
  // persistor.purge(); // Clear persisted data
});
// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const { dispatch } = store;

export { store, dispatch, persistor };
