import React, { useState } from 'react';
import CardLayout from '../CardLayout';
import RiderSectionTable from '../RiderSectionTable';
import { ForeignAddWorkerTableColumns } from 'modules/packages & premium/static';
import Rightsidebar from 'components/rightsidebar/Rightsidebar';
import ForeignWorkerSidebar from '../ForeignWorkerSidebar';
import { useWorkerDetails } from 'modules/packages & premium/hooks/useForeignWorker';

const ForeignWorkerInformation = React.memo((props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data } = useWorkerDetails(handleClickOnEdit);
  const [getEditText, setEditText] = useState('');
  const [selectedRow, setSelectedRow] = useState({});
  const [isOpen, setIsOpen] = useState(true);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOnTopButton = () => {
    setIsSidebarOpen(true);
    setSelectedRow({});
    setEditText('add');
  };
  function handleClickOnEdit(rider, action) {
    setIsSidebarOpen(true);
    setSelectedRow(rider);
    setEditText(action);
  }
  const hasWorkers = data && data.length > 0;
  return (
    <>
      {isSidebarOpen && (
        <Rightsidebar
          Rcontent={
            <ForeignWorkerSidebar
              setShow={setIsSidebarOpen}
              closeSidebar={() => setIsSidebarOpen(false)}
              buttonLabel={getEditText === 'edit' ? 'Edit Worker' : 'Add Worker'}
              isEditMode={getEditText === 'edit'}
              selectedRow={selectedRow}
            />
          }
          closeSidebar={() => setIsSidebarOpen(true)}
          show={isSidebarOpen}
          maxWidth="400px"
        />
      )}
      <CardLayout
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleForm={toggleForm}
        heading="Worker Section"
        headerButtonTitle="+ Add Worker"
        isTopButtonVisible
        handleClickOnTopButton={handleClickOnTopButton}
      >
        {hasWorkers ? (
          <RiderSectionTable columns={ForeignAddWorkerTableColumns} rows={data} />
        ) : (
          <RiderSectionTable columns={ForeignAddWorkerTableColumns} rows={[]} />
        )}
        {!hasWorkers && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              fontSize: '13px',
              color: '#000',
              marginTop: '14px'
            }}
          >
            Worker list is empty. Kindly add a worker to continue.
          </div>
        )}
      </CardLayout>
    </>
  );
});

export default ForeignWorkerInformation;
