import React, { useEffect, useState } from 'react';
import '../../../../assets/fonts/Fonts.css';
import ReusableModal from 'components/modals/routemodal/ResuableModal';
import DropDown from 'components/dropdown/DropDown';
import { Button, TextField, Grid, InputLabel, Typography, Box } from '@mui/material';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { showSuccess, showWarning } from 'components/nudges/Alert';
import OverlayLoader from 'components/loader/OverlayLoader';

export default function RaiseEndorsementModal(props, selectedPolicy) {
  const { makeRequest } = useApiCall();
  const [options, setOptions] = useState([]);
  const [category, setCategory] = useState('');
  const [remarks, setRemarks] = useState('');
  const [loading, setLoading] = useState(false);
  let userData = localStorage.getItem('@user');
  let user = JSON.parse(userData);
  let userID = user?.userId;

  const handleCancelButton = () => {
    props.setIsOpen(false);
  };

  const fetchEndorsementType = async () => {
    try {
      let response = await makeRequest({ method: 'GET', url: API.EndorsementCategoryGet });
      console.log('response', response);
      let res = response?.data?.data;
      let dropdownTypes = res.map((item) => {
        return { label: item, id: item };
      });
      setOptions(dropdownTypes);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEndorsementType();
  }, []);

  const handleSubmit = async () => {
    if (!category || !remarks) {
      showWarning('Please fill in all required fields');
      return false;
    }
    setLoading(true);
    try {
      let body = {
        userId: userID,
        productId: props?.selectedPolicy?.baseProductId,
        category: category,
        description: remarks,
        policy_number: props?.selectedPolicy?.policy_number,
        status: 'Open',
        oppty_no: props?.selectedPolicy?.opportunityNo
      };
      let res = await makeRequest({ method: 'POST', url: API.EndorsementDetailsInsertUpdate, body });
      if (res.code === 200) {
        showSuccess(res?.message);
        setCategory('');
        setRemarks('');
        props.setIsOpen(false);
        props.onFormSubmit();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <ReusableModal
        width="400px"
        height="auto"
        borderRadius="10px"
        padding="15px"
        showCloseButton={false}
        isOpen={props.isOpen}
        onClose={props.onClose}
        ModalContent={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box borderBottom={1} borderColor="grey.300">
                <Typography
                  style={{ color: '#000', fontFamily: 'Medium', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}
                  variant="h5"
                >
                  Raise Endorsements
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Type</InputLabel>
              </div>
              <DropDown
                marginTop={'9px'}
                width={'100%'}
                heightsss={'37px'}
                height={'40px'}
                options={options}
                placeholder="Select type"
                value={category}
                onValueChange={(i) => {
                  setCategory(i.label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                variant="standard"
                rows={4}
                InputProps={{ disableUnderline: true }}
                style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                required
                onClick={(e) => e.stopPropagation()}
                placeholder="Remarks"
                multiline
              />
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={3} style={{ marginLeft: 15 }}>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    backgroundColor: '#002D8C',
                    width: '100%',
                    padding: '10%',
                    color: '#fff',
                    height: '100%',
                    border: '1px solid #ddd'
                  }}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  size="small"
                  variant="contained"
                  style={{ backgroundColor: '#f7f7f7', width: '100%', padding: '10%', color: '#000', height: '100%' }}
                  onClick={handleCancelButton}
                  // disabled={isButtonDisabled}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <OverlayLoader show={loading} />
    </>
  );
}
