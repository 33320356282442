import SubHeader from 'components/subHeader/SubHeader';
import React from 'react';
import { Grid } from '@mui/material';
import IncorporateFinanceTable from '../components/IncorporateFinanceTable';

const ActiveComponents = () => {
  return (
    <Grid >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2.75}
        alignItems="center"
        justifyContent="space-between"
        style={{ backgroundColor: '#ffffff' }}
      >
        <SubHeader title="Finance" />
        <IncorporateFinanceTable />
      </Grid>
    </Grid>
  );
};

const IncorporateFinance = () => {
  return <ActiveComponents />;
};

export default IncorporateFinance;
