import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import ReusableCard from 'components/cards/ResuableCard';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DropDown from 'components/dropdown/DropDown';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector } from 'react-redux';
import { usePolicyDetails } from 'modules/packages & premium/hooks/useVehicle';
import useUenVerification from 'modules/packages & premium/hooks/useUenVerification';
import OverlayLoader from 'components/loader/OverlayLoader';
import { API } from 'api/API';
import { getLoginDetailsId, getUserId } from 'helper/utils';
import useNetworkStatus from 'hooks/useNetworkStatus';
import useApiCall from 'api/useApiCall';
import { showDanger, showWarning } from 'components/nudges/Alert';

const ContainerContent = ({ item }) => {
  const { otherState, activeParams } = useSelector((state) => state?.persistedReducer?.premiumPolicy);
  const [value, setValue] = useState(otherState[item?.value || '']);
  const [error, setError] = useState('');
  const [startDateMessage, setStartDateMessage] = useState('');

  const validateDate = (date) => {
    const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
    const currentDate = new Date().toISOString().split('T')[0];

    if (allowedCharsForDate.test(date)) {
      if (date >= currentDate) {
        setError('');
        if (date === currentDate) {
          setStartDateMessage('Start date is today.');
        } else {
          setStartDateMessage('');
        }
        return true;
      } else {
        setError('Date cannot be earlier than today.');
        setStartDateMessage('');
        return false;
      }
    } else {
      setError('Invalid date format. Please enter in YYYY-MM-DD format.');
      setStartDateMessage('');
      return false;
    }
  };

  const onChangeText = (event) => {
    const { value } = event.target;
    if (item?.texttype === 'date') {
      const year = value.split('-')[0];
      if (year.length <= 4) {
        setValue(value);
        validateDate(value); // Validate immediately on change
      }
    } else {
      setValue(value);
    }
  };
  const onBlur = () => {
    if (item?.texttype === 'date') {
      if (validateDate(value)) {
        // If the date is valid, dispatch it to Redux
        store.dispatch(addOtherIndividualState([item?.value, value]));
      } else {
        // If the date is invalid (past date), set the value to the current date
        const currentDate = new Date().toISOString().split('T')[0];
        setValue(currentDate);
        store.dispatch(addOtherIndividualState([item?.value, currentDate]));
      }
    } else {
      store.dispatch(addOtherIndividualState([item?.value, value]));
    }
  };
  // const onChangeText = (txt, value) => {
  //   setValue(txt);
  // };

  // const onBlur = (value) => {
  //   const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
  //   const currentDate = new Date().toISOString().split('T')[0];
  //   if (item?.texttype === 'date') {
  //     if (allowedCharsForDate.test(value)) {
  //       if (value >= currentDate) {
  //         store.dispatch(addOtherIndividualState([item.value, value]));
  //       } else {
  //         console.warn('Invalid date. Please enter a date not greater than today.');
  //         setValue(currentDate);
  //         store.dispatch(addOtherIndividualState([item.value, currentDate]));
  //       }
  //     } else {
  //       console.warn('Invalid date format. Please enter in YYYY-MM-DD format.');
  //     }
  //   } else {
  //     store.dispatch(addOtherIndividualState([item.value, value]));
  //   }
  // };

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };
  useEffect(() => {
    try {
      if (otherState['UEM'].length === 0) {
        store.dispatch(addOtherIndividualState(['UEMSTATUS', null]));
      }
      // if (item.value === 'POIEndDate') {
      //   let newDate = new Date(otherState['POIStartDate']);
      //   newDate.setFullYear(newDate.getFullYear() + 1);

      //   // Subtract one day from the new end date
      //   newDate.setDate(newDate.getDate() - 1);
      //   store.dispatch(addOtherIndividualState(['POIEndDate', newDate.toISOString().split('T')[0]]));
      //   setValue(newDate.toISOString().split('T')[0]);
      // }
    } catch (error) {
      console.error('An error occurred in useEffect:', error);
    }
  }, [otherState, item]);
  let isFlagm = item.isFlag;
  if (item.value === 'uen' || item.value === 'email' || item.value === 'cm') {
    isFlagm = otherState['isCompanyRegistered'];
  }

  useEffect(() => {
    if (otherState?.isCompanyRegistered === false) {
      store.dispatch(addOtherIndividualState(['uen', '']));
      store.dispatch(addOtherIndividualState(['email', '']));
      store.dispatch(addOtherIndividualState(['cm', '']));
    }
  }, [otherState?.isCompanyRegistered]);

  return (
    <>
      {isFlagm && (
        <Grid item xs={12} md={4} alignItems="center">
          {/* {isFlagm ? ( */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel className="inputs_styless_" style={{ marginLeft: 0 }}>
              {item.label}
            </InputLabel>
            {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
          </div>
          {/* ) : (
              <></>
            )} */}
          {item.type === 'textField' ? (
            <>
              <TextField
                type={item?.texttype}
                disabled={item?.value == 'UEM' && otherState['navigateFrom'] == 'renewal' ? true : item?.isEnable}
                onChange={onChangeText}
                onBlur={onBlur}
                required={item?.isRequired}
                size="small"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                className="txtinputs_stylingg"
                value={value}
                style={{ marginTop: '5px', marginLeft: 0 }}
                inputProps={{
                  min: new Date().toISOString().split('T')[0],
                  max: (() => {
                    const maxDate = new Date();
                    maxDate.setFullYear(maxDate.getFullYear() + 10);
                    return maxDate.toISOString().split('T')[0];
                  })()
                }}
              />
              {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
              {startDateMessage && <div style={{ color: 'red', marginTop: '5px' }}>{startDateMessage}</div>}
            </>
          ) : (
            <></>
          )}

          {item.type === 'dropdown' && (
            <DropDown
              width={'96%'}
              heightsss={'37px'}
              height={'40px'}
              marginTop={'7px'}
              options={item?.ddoptions || []}
              onValueChange={onValueChange}
              defaultValue={otherState[item?.value]}
            />
          )}
        </Grid>
      )}
    </>
  );
};

const MotorVehicleCoverage = ({ handleNextButton, handlePreviousButton }) => {
  const { data } = usePolicyDetails();
  const { otherState, activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const network = useNetworkStatus();
  const { makeRequest } = useApiCall();
  const [isLoading, setIsLoading] = useState(false);

  const validateFields = () => {
    const requiredFields = ['POIStartDate', 'POIEndDate'];
    for (const field of requiredFields) {
      if (otherState['isCompanyRegistered'] && (!otherState[field] || otherState[field].trim() === '')) {
        showWarning(`Field ${field} is required.`);
        return false;
      }
    }
    return true;
  };

  const validateEmail = (email) => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email) && !email.includes('..');
  };

  const handleClick = async () => {
    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }

      if (!validateFields()) {
        showWarning('Fill Date Fields!');
        return;
      }
      if (otherState?.uen === '' && otherState?.isCompanyRegistered === true) {
        showWarning('Field UEN is Required!');
        return;
      }
      if (otherState?.email === '' && otherState?.isCompanyRegistered === true) {
        showWarning('Field Email is Required!');
        return;
      }
      if (!validateEmail(otherState?.email) && otherState?.isCompanyRegistered === true) {
        showWarning('Please enter valid Email Address!');
        return;
      }
      if (otherState?.cm === '' && otherState?.isCompanyRegistered === true) {
        showWarning('Field Company Name is Required!');
        return;
      }

      let userData = localStorage.getItem('@user');
      let user = JSON.parse(userData);
      let userID = user?.userId;
      let loginDetailsId = await getLoginDetailsId();
      let mergedStates = { ...otherState };
      let ncd = mergedStates?.NCD;

      setIsLoading(true);
      let body = {
        userId: userID || null,
        productMasterID: activeParams?.productID,
        businessType: mergedStates.businessType,
        vehicleId: mergedStates?.vehicleId || '',
        capacity: mergedStates?.vehicleCapacity || '',
        ageOfMotorCycle: mergedStates?.ageOfVehicle || '',
        isCompanyRegisted: mergedStates?.isCompanyRegistered,
        isFoodDelivery: mergedStates?.isFoodParcel,
        riderAge: 0,
        ridingExperience: '',
        gender: mergedStates?.ownerGender || '',
        claimsIn3Year: mergedStates?.claimcount || '0',
        amountOfClaimsIn3Year: mergedStates?.claimamount || '0',
        ncdPercentage: ncd.replace('%', '') || 0,
        riderCount: 0,
        vehicleRegistrationNumber: mergedStates?.vehicleRegistration || '',
        chassisNumber: mergedStates?.chasisNumber || '',
        engineNumber: mergedStates?.engineNumber || '',
        nameofHirePurchase: mergedStates?.nameOfHirePurchase || '',
        claimsDetails: '0',
        registrationDate: mergedStates?.RegistrationDate || '',
        loginDetailsId: loginDetailsId || 0,
        poiStartDate: mergedStates?.POIStartDate || '',
        poiEndDate: mergedStates?.POIEndDate || '',
        uen: mergedStates?.uen || '',
        companyName: mergedStates?.cm || '',
        email: mergedStates?.email || '',
        countLessthan1YearExp: 0
      };

      let res = await makeRequest({ method: 'POST', url: API.PackagesAndPremiumCalculation, body });

      if (res?.code === 200) {
        setIsLoading(false);
        handleNextButton(res.data);
      } else {
        showDanger(res?.message);
        setIsLoading(false);
      }
    } catch (e) {
      showDanger(e);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
        <OverlayLoader show={true} />
      </div>
    );
  }
  return (
    <ReusableCard
      backgroundColor={'#fff'}
      border="1px solid #ddd"
      padding={'24px'}
      borderRadius={15}
      content={
        <>
          <Grid container>
            {data.map((item, index) => (
              <ContainerContent
                key={index}
                item={item}
                otherState={otherState}
                handleNextButton={handleNextButton}
                handlePreviousButton={handlePreviousButton}
              />
            ))}
          </Grid>
          <Grid
            container
            xs={12}
            sm={6}
            md={4}
            lg={4}
            mt={2}
            style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
          >
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#f7f7f7',
                color: '#000000',
                border: '1px solid #ddd',
                width: '48%',
                padding: 0,
                fontSize: '13px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={handlePreviousButton}
            >
              Previous
            </Button>

            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#002D8C',
                fontSize: '13px',
                padding: 0,
                width: '48%',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={handleClick}
            >
              Next
            </Button>
          </Grid>
        </>
      }
    />
  );
};
export default MotorVehicleCoverage;
