import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import SubHeader from 'components/subHeader/SubHeader';
import { useTheme } from 'components/Theme/ThemeProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReusableCard from 'components/cards/ResuableCard';
import InputLabel from '@mui/material/InputLabel';
import '../../policies/styles/ViewPolicy.css';
import Successmodal from 'components/modals/contentmodal/Successmodal';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { useSelector } from 'react-redux';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import { getUserId } from 'helper/utils';
import { store } from 'store/index';
import { addOtherCompleteState } from 'store/reducers/premiumPolicy';
import { showDanger } from 'components/nudges/Alert';
import moment from 'moment';
const ViewPolicyForm = ({ policy }) => {
  console.log('policyyyyyyyy', policy);
  const formatDate = (date) => {
    console.log('datee', date);
    return date ? moment(date).format('DD-MM-YYYY') : '';
  };
  const formatCreatedDate = (date) => {
    console.log('datee', date);
    return date ? moment(date).format('DD-MM-YYYY & hh:mm:ss') : '';
  };
  const renderField = (label, value) => {
    if (!value) return null;

    // Append "%" symbol for commission percentage fields
    if (label.includes('Comission Percent')) {
      value = `${value}%`;
    }
    if (label.includes('TSA ComissionPercent')) {
      value = `${value}%`;
    }
    if (label.includes('TSA Comission Earned')) {
      value = `$ ${value}`;
    }
    if (label.includes('TSA ComissionEarned')) {
      value = `$ ${value}`;
    }
    if (label.includes('Premium Amt')) {
      value = `$ ${value}`;
    }

    return (
      <Grid item xs={12} sm={4} md={4} lg={4} className="input_label_container">
        <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginLeft: '1%', color: '#383c4d' }}>{label}</InputLabel>
        <InputLabel
          style={{
            backgroundColor: '#F8F8F8',
            width: '90%',
            margin: '2px',
            padding: '10px',
            borderRadius: '8px',
            display: 'flex',
            color: '#383c4d'
          }}
        >
          {value}
        </InputLabel>
      </Grid>
    );
  };

  function maskNRIC(nric) {
    if (!nric) return '';
    return 'X'.repeat(nric.length - 4) + nric.slice(-4);
  }
  return (
    <>
      {policy?.policy?.recordType === 'Motor' && (
        <Grid item xs={12} className="secondary_head_cont" mt={2}>
          <Grid container spacing={2}>
            {renderField('Policy Number', policy?.policy?.policyNumber)}
            {renderField('Customer Name', policy?.customer?.customerName.toUpperCase())}
            {renderField('Vehicle Registration No.', policy?.policy?.vehicleName)}
            {renderField('Vehicle Model', policy?.policy?.vehicleModel)}
            {renderField('Policy Type', policy?.policy?.recordType)}
            {renderField('NRIC', maskNRIC(policy?.customer?.nricfin))}
            {/* {renderField('TSA Comission Percent', policy?.policy?.tsaComissionPercent)} */}
            {/* {renderField('TSA Comission Earned', policy?.policy?.tsaComissionEarned)} */}
            {renderField('Number of Claims', policy?.policy?.noOfClaims)}
            {renderField('Claim Amount', policy?.policy?.claimAmount)}
            {renderField('Product Name', policy?.policy?.productName)}
            {renderField('Insurer', policy?.products[0]?.insurer)}
            {renderField('Policy Renewable', policy?.products[0]?.policy_Renewable__c.toString())}
            {renderField('Email', policy?.customer?.customerEmail)}
            {renderField('Phone', policy?.customer?.mobilePhone)}
            {renderField('Driving License Pass Date', policy?.customer?.drivingLicensePassDate)}
            {renderField('Policy Start Date', formatDate(policy?.policy?.periodOfInsuranceStartDate))}
            {renderField('Policy End Date', formatDate(policy?.policy?.periodOfInsuranceEndDate))}
            {renderField('Created Date & Time', formatCreatedDate(policy?.policy?.createdDate))}
          </Grid>
        </Grid>
      )}
      {policy?.policy?.recordType === 'Foreign Worker' && (
        <Grid item xs={12} className="secondary_head_cont" mt={2}>
          <Grid container spacing={2}>
            {renderField('Policy Number', policy?.policy?.policyNumber)}
            {renderField('Customer Name', policy?.customer?.customerName.toUpperCase())}
            {renderField('Count of worker', policy?.policy?.vehicleName)}
            {renderField('Policy Type', policy?.policy?.recordType)}
            {/* {renderField('TSA Comission Percent', policy?.policy?.tsaComissionPercent)} */}
            {/* {renderField('TSA Comission Earned', policy?.policy?.tsaComissionEarned)} */}
            {renderField('Product Name', policy?.policy?.productName)}
            {renderField('Policy Renewable', policy?.products[0]?.policy_Renewable__c.toString())}
            {renderField('Insurer', policy?.products[0]?.insurer)}
            {renderField('NRIC', maskNRIC(policy?.customer?.nricfin))}
            {renderField('Premium Amt.', policy?.policy?.premiumAmount)}
            {renderField('Email', policy?.customer?.customerEmail)}
            {renderField('Phone', policy?.customer?.mobilePhone)}
            {renderField('Policy Start Date', formatDate(policy?.policy?.periodOfInsuranceStartDate))}
            {renderField('Policy End Date', formatDate(policy?.policy?.periodOfInsuranceEndDate))}
            {renderField('Created Date & Time', formatCreatedDate(policy?.policy?.createdDate?.split(',')[0]))}
          </Grid>
        </Grid>
      )}
      {policy?.policy?.recordType === 'Domestic Helper' && (
        <Grid item xs={12} className="secondary_head_cont" mt={2}>
          <Grid container spacing={2}>
            {renderField('Policy Number', policy?.policy?.policyNumber)}
            {renderField('Customer Name', policy?.customer?.customerName.toUpperCase())}
            {renderField('Helper Name', policy?.policy?.vehicleName)}
            {renderField('Policy Type', policy?.policy?.recordType)}
            {/* {renderField('TSA ComissionPercent', policy?.policy?.tsaComissionPercent)} */}
            {/* {renderField('TSA ComissionEarned', policy?.policy?.tsaComissionEarned)} */}
            {renderField('Product Name', policy?.policy?.productName)}
            {renderField('Policy Renewable', policy?.products[0]?.policy_Renewable__c.toString())}
            {renderField('Insurer', policy?.products[0]?.insurer)}
            {renderField('NRIC', maskNRIC(policy?.customer?.nricfin))}
            {renderField('Policy Start Date', formatDate(policy?.policy?.periodOfInsuranceStartDate))}
            {renderField('Policy End Date', formatDate(policy?.policy?.periodOfInsuranceEndDate))}
            {renderField('Created Date & Time', formatCreatedDate(policy?.policy?.createdDate))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const ActiveComponents = ({ policy }) => {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { makeRequest } = useApiCall();

  const handleDownloadButton = () => {
    setIsOpen(true);
  };

  const handleThankYouButton = () => {
    setIsOpen(false);
  };

  const handleRenew = async (policyData) => {
    try {
      const userId = await getUserId();
      let body = {
        userId: userId,
        productId: policyData?.products[0]?.productcode,
        customerPolicyId: policyData?.policy?.customerPolicyId
      };
      let res = await makeRequest({ method: 'POST', url: API.RenewPolicyDetailsGet, body });
      if (res?.code === 200) {
        let data = res?.data;
        let ncd = data?.ncd;

        let rider = data?.insuredParty;
        let domestic = [];
        rider?.forEach((itm) => {
          domestic.push(itm);
        });

        let addOn = data.items;

        let add = {};
        if (addOn != null) {
          addOn?.forEach((itm) => {
            if (itm?.isAdd_On) {
              let obj = {
                itemCode: itm.itemCode,
                salesPrice: itm.salesPrice,
                quantity: itm.quantity
              };
              add[itm.itemCode] = obj;
            }
          });
        }

        let arr = [];
        let riderData = {};
        if (data?.insuredParty != null) {
          data?.insuredParty?.forEach((item, index) => {
            let d = new Date();

            let innerData = {
              ...item,
              age: item?.age,
              lastName: item?.lastName,
              claims: item?.claimsIn3Year,
              amountOfClaims: item.amountOfClaimsIn3Year,
              contactNumber: item.contact,
              experience: item?.experienceInMonths,
              dob: item.dob,
              license: item.drivingLicense,
              passport: item.passportNo,
              workPermit: item.workPermitNo,
              firstName: item?.firstName,
              nric: item.nric,
              isCheckboxChecked: item.isOwner,
              isRiderCheckboxChecked: item.isRider,
              id: `${d}@${index}`
            };
            riderData[`${d}@${index}`] = innerData;
          });
        }
        arr.push(rider);
        const isDeliveryExtension = data.deliveryExtension.toLowerCase() === 'yes';
        let initialObj = {
          // Motor policy information
          UEM: data.uen,
          UEMSTATUS: null,
          POIStartDate: data?.insuranceStart,
          POIEndDate: data?.insuranceEnd,
          isCompanyRegistered: data?.companyRegistered,
          isFoodParcel: isDeliveryExtension,
          businessType: 1,
          cm: data?.account?.accName,
          email: data?.account?.accEmail,
          phone: data?.account?.phone,
          cckw: data?.capacity,
          //motor vehicle information
          vehicleId: data?.vehicleId || '',
          ageOfVehicle: data?.ageOfMotorCycle || '',
          vehicleRegistration: data?.vrn,
          chasisNumber: data?.chasisNo,
          engineNumber: data?.engineNo,
          nameOfHirePurchase: data?.nameOfHirePurchase,
          workShopType: data?.workshopType,
          // NCD: ncd,
          claimcount: data?.claimsIn3Year,
          claimamount: data?.amountOfClaimsIn3Year,
          RegistrationDate: data?.reg_manufactureDate,
          riderDataSection: riderData,
          contact: data?.contact,
          transactionType: 'Renewal',
          //Foreign Worker package information
          basisofcover: data?.basisOfCover,
          maincontractor: data?.mainContractorName,
          uen: data?.uen,
          CPF: data?.cpfSubmissionNo,
          dischargeDate: data?.dischargeDate,
          transmissionstatus: data?.transmissionStatus,
          bondcall: '',
          bondduration: data?.bondDuration,
          LOIstatus: data?.loiStatus,
          LOItype: data?.loiType,
          fromDate: data?.insuranceStart,
          todate: data?.insuranceEnd,
          durationInMonths: data?.basisOfCover,
          durationInDays: data?.basisOfCover,
          subcategory: data?.basisOfCover,
          durationwise: '',
          workerSection: riderData,

          //Domestic Worker information
          ndw: domestic[0]?.name,
          fin: domestic[0]?.fin,
          passportno: domestic[0]?.passportNo,
          dob: domestic[0]?.dob,
          workpermit: domestic[0]?.workPermitNo,
          nationalityDHInformation: domestic[0]?.nationality,

          // Domestic Helper Insurance
          UENDHInsurance: '',
          fromdateDHInsurance: data?.insuranceStart,
          noofmonthsDHInsurance: data?.durationInMonths,
          noofdaysDHInsurance: '',
          todateDHinsurance: data.insuranceEnd,
          subcategoryDHInsurance: data?.subCategory,
          compnaynameDHInsurance: '',
          emailDHInsurance: '',
          phoneDHInsurance: '',
          navigateFrom: 'renewal'
        };

        initialObj['addOn'] = add;
        initialObj['NCD'] = ncd;

        store.dispatch(addOtherCompleteState(initialObj));
        let newObj = {
          productName: policy?.data?.products[0]?.productName,
          productDescription: '',
          productID: policy?.data?.products[0]?.productcode,
          policyClass: policy?.data?.policy?.recordType,
          insurer: policy?.data?.products[0]?.insurer
        };

        navigate('/dashboard/PremiumCalculateView', {
          state: { data: { item: { ...res?.data, ...newObj }, policyData: policy, navigateFrom: 'renewal' } }
        });
      } else {
        showDanger(res?.message);
      }
    } catch (e) {
      console.error('Error during renew', e);
      showDanger(e);
    }
  };

  return (
    <Grid height={'120vh'}>
      <Grid container rowSpacing={2} alignItems="center" justifyContent="space-between" style={{ backgroundColor: currentTheme.cardColor }}>
        <SubHeader
          title="View Policy"
          isLeftEnable={true}
          leftComponent={
            <Button
              size="small"
              variant="contained"
              style={{ borderRadius: 12, color: '#fff', backgroundColor: '#2d2d2d', minWidth: '30px', marginRight: '10px' }}
              onClick={() => navigate('/dashboard/policies', { replace: true })}
            >
              <ArrowBackIcon />
            </Button>
          }
          isRightEnable={true}
          rightComponent={
            <Grid>
              {policy?.data?.policy?.renewable && policy?.data?.policy?.recordType !== 'Domestic Helper' && (
                <Button
                  size="small"
                  sx={{ borderRadius: 10, backgroundColor: '#0174bf' }}
                  variant="contained"
                  onClick={() => handleRenew(policy?.data)}
                >
                  Renew Policy
                </Button>
              )}
            </Grid>
          }
          isRightEnableSecond={false}
        />

        <Grid
          className="top_container"
          style={{ color: currentTheme.textColor }}
          minWidth={'100%'}
          sx={{
            paddingTop: '24px !important',
            paddingLeft: '24px !important',
            paddingRight: '0px !important',
            paddingBottom: '0px !important'
          }}
        >
          <ReusableCard
            backgroundColor={currentTheme.bodyColor}
            boxShadow={'0 0 0px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1)'}
            border="#f8f8f8"
            padding={'2%'}
            borderRadius={15}
            content={<ViewPolicyForm policy={policy?.data} />}
          />
        </Grid>
        <Successmodal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleCloseModal={handleThankYouButton}
          title={'Downloading Packages & Premium'}
          subtitle={'Packages & Premium file is downloading...'}
        />
      </Grid>
    </Grid>
  );
};

const ViewPolicy = () => {
  const { policy } = useSelector((state) => state.persistedReducer.premiumPolicy);

  return <ActiveComponents policy={policy} />;
};

export default ViewPolicy;
