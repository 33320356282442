import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { usePackagePremiumState } from 'modules/packages & premium/provider/PackagePremiumStateProvider';
import { useSelector } from 'react-redux';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useNavigate } from 'react-router-dom';
import { getLoginDetailsId, getUserId } from 'helper/utils';
import { showDanger, showWarning } from 'components/nudges/Alert';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import _ from 'lodash';
import { clearRiderDataSection, removeOtherState } from '../../../../store/reducers/premiumPolicy';
import { store } from 'store/index';
import useNetworkStatus from 'hooks/useNetworkStatus';

const MotorVehicleBottomPortion = (props) => {
  const { params } = usePackagePremiumState();
  const { activeParams, otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const isCompanyRegistered = otherState.isCompanyRegistered;
  console.log('isCompanyRegistered:', isCompanyRegistered);
  const { riderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  console.log('paramsSSSSSSS', params);

  console.log('riderDataSection', riderDataSection);
  let riderLength = Object?.keys(riderDataSection)?.length;
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { makeRequest } = useApiCall();
  // console.log('riderLength', riderLength);
  console.log('isCompanyRegistered:', isCompanyRegistered);
  console.log('isButtonDisabled:', isButtonDisabled);
  const navigate = useNavigate();
  const network = useNetworkStatus();
  const compareDates = (date1, date2) => {
    return new Date(date1) - new Date(date2);
  };

  const findTheYoungest = () => {
    try {
      let d = Object.values(riderDataSection);
      console.log('dssssssssssssssssss', d);
      d.sort((a, b) => compareDates(a.age, b.age));
      console.log('Youngest Person:', d[0]);
      return d[0] || null;
    } catch (e) {
      console.error('Error during figure out the youngest person', e);
    }
  };

  useEffect(() => {
    if (isCompanyRegistered) {
      store.dispatch(clearRiderDataSection());
    }
  }, [isCompanyRegistered]);

  const handleClick = async () => {
    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      let userId = await getUserId();
      let loginDetailsId = await getLoginDetailsId();
      let mergedStates = { ...otherState };
      let ncd = mergedStates?.NCD;
      let selectedOwner = Object.values(riderDataSection).find((owner) => owner.isCheckboxChecked);
      let selectedOwnerRider = Object.values(riderDataSection).find((owner) => owner.isRiderCheckboxChecked);
      let ownerGender = selectedOwner?.gender || Object.values(riderDataSection).find((rider) => rider.gender)?.gender;
      let currentDate = new Date().toISOString().split('T')[0];

      let { vehicleRegistration, vehicleId, ageOfVehicle, claimamount, isFoodParcel, claimcount, POIStartDate, RegistrationDate } =
        otherState;
      if (vehicleRegistration === '') {
        setIsButtonDisabled(false);
        showWarning('Please enter vehicle registration number');
        return;
      }

      if (claimcount === '') {
        setIsButtonDisabled(false);
        showWarning('Please select the claim count.');
        return;
      }
      if (RegistrationDate === '') {
        setIsButtonDisabled(false);
        showWarning('Please select the registration date.');
        return;
      }
      if (POIStartDate < currentDate) {
        setIsButtonDisabled(false);
        showWarning("The start date must be the same as today's date");
        return;
      }

      if (vehicleId === '') {
        setIsButtonDisabled(false);
        showWarning('Please select the vehicle from vehicle list');
        return;
      }

      if (ageOfVehicle === '') {
        setIsButtonDisabled(false);
        showWarning('Please select the age of vehicle');
        return;
      }
      if (!claimamount && claimcount === 1) {
        setIsButtonDisabled(false);
        showWarning('Please fill the claim amount.');
        return;
      }

      if (claimcount === 1 && claimamount > 6000) {
        setIsButtonDisabled(false);
        showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
        return;
      }
      // if (claimcount === 1 && claimamount > 15000 && isFoodParcel) {
      //   setIsButtonDisabled(false);
      //   showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
      //   return;
      // }

      if (claimcount === 2) {
        setIsButtonDisabled(false);
        showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
        return;
      }
      if (selectedOwner && !selectedOwner.isRiderCheckboxChecked) {
        showWarning('At least one rider must be selected to proceed if the owner is not riding the vehicle.');
        return;
      }
      const POIStartDates = new Date(otherState.POIStartDate);
      const currentDat = new Date();

      const maxAcceptableDate = new Date(currentDat);
      maxAcceptableDate.setDate(currentDat.getDate() + 90);

      if (POIStartDates > maxAcceptableDate) {
        setIsButtonDisabled(false);
        showWarning('Please select a start date that is within the next 90 days.');
        return;
      }
      const registrationDate = new Date(otherState.RegistrationDate);
      const currentDates = new Date();

      const diffTime = Math.abs(currentDates - registrationDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      };

      let leapDays = 0;
      for (let year = registrationDate.getFullYear(); year <= currentDates.getFullYear(); year++) {
        if (isLeapYear(year)) leapDays++;
      }

      const maxAllowedDaysComprehensive = 6 * 365 + leapDays;
      const maxAllowedDaysTPFT = 21 * 365 + leapDays;

      if (activeParams.productSubType === 'Comprehensive' && diffDays > maxAllowedDaysComprehensive) {
        setIsButtonDisabled(false);
        showWarning('The vehicle age must be less than 6 years ( for Comprehensive Policies )');
        return;
      }

      if (activeParams.productSubType === 'TPFT' && diffDays > maxAllowedDaysTPFT) {
        setIsButtonDisabled(false);
        showWarning('The vehicle age must be less than 21 years ( for TPFT Policies )');
        return;
      }
      if (Object.values(riderDataSection).length > 0) {
        const existingOwner = Object.values(riderDataSection).some((rider) => rider.isCheckboxChecked);
        if (!existingOwner && !otherState?.isCheckboxChecked) {
          showWarning('At least one rider must be selected as the owner');
          return;
        }
      }

      let riderAge;
      let ridingExperience;
      let young;
      if (!isCompanyRegistered) {
        young = findTheYoungest();
        riderAge = parseInt(young?.age) || 0;
        if (selectedOwner && selectedOwner.isRiderCheckboxChecked) {
          ridingExperience = selectedOwner.experience;
        } else {
          ridingExperience = '13 months';
        }
      }
      let countLessthan1YearExp = 0;
      let riderCount = 0;

      Object.values(riderDataSection).forEach((rider) => {
        const isOwner = rider === selectedOwner;
        const isOwnerRider = rider === selectedOwnerRider;

        if (!isOwner) {
          const totalMonths = parseInt(rider.experience, 10);
          if (totalMonths < 12) {
            countLessthan1YearExp++;
          }
          riderCount++;
        }
      });

      if (selectedOwner && selectedOwner.isRiderCheckboxChecked) {
      }
     


      if (isCompanyRegistered) {
        riderCount = 0;
      }

      if (selectedOwner && selectedOwner.isRiderCheckboxChecked && riderCount === 2 && Object.values(riderDataSection).length === 3) {
        riderCount = 3;
      }

      setIsLoading(true);
      let body = {
        userId: userId || '',
        productMasterID: params?.productID || '',
        businessType: mergedStates?.businessType,
        vehicleId: mergedStates?.vehicleId || '',
        capacity: mergedStates?.vehicleCapacity || '',
        ageOfMotorCycle: mergedStates?.ageOfVehicle || '',
        isCompanyRegisted: mergedStates?.isCompanyRegistered,
        isFoodDelivery: mergedStates?.isFoodParcel,
        riderAge: riderAge || 0,
        ridingExperience: ridingExperience,
        gender: ownerGender || '',
        claimsIn3Year: mergedStates?.claimcount || '0',
        amountOfClaimsIn3Year: mergedStates?.claimamount || '0',
        ncdPercentage: ncd.replace('%', '') || 0,
        riderCount: riderCount,
        vehicleRegistrationNumber: mergedStates?.vehicleRegistration || '',
        chassisNumber: mergedStates?.chasisNumber || '',
        engineNumber: mergedStates?.engineNumber || '',
        nameofHirePurchase: mergedStates?.nameOfHirePurchase || '',
        claimsDetails: '0',
        registrationDate: mergedStates?.RegistrationDate || '',
        loginDetailsId: loginDetailsId || '',
        poiStartDate: mergedStates?.POIStartDate || '',
        poiEndDate: mergedStates?.POIEndDate || '',
        uen: mergedStates?.UEM || '',
        companyName: mergedStates?.cm || '',
        email: mergedStates?.email || '',
        phone: mergedStates?.phone || '',
        countLessthan1YearExp: countLessthan1YearExp
      };
      console.log('Final Body --->', body);
      let res = await makeRequest({ method: 'POST', url: API.PackagesAndPremiumCalculation, body });
      console.log('response received from premium policy calculation --->', res);

      if (res?.code === 200) {
        setIsButtonDisabled(true);
        setIsLoading(false);
        navigate('/dashboard/PremiumCalculation', { state: { data: params, apiData: res?.data[0], young: young } });
      } else {
        showDanger(res?.message);
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    } catch (e) {
      showDanger(e);
      setIsLoading(false);
    }
  };
  const handleResetClick = () => {
    store.dispatch(removeOtherState());
    window.location.reload();
  };

  return (
    <>
      <OverlayLoader show={isLoading} />
      <Grid
        container
        xs={12}
        sm={6}
        md={4}
        lg={4}
        mt={2}
        ml={2}
        mb={1}
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginLeft: '4.9%', padding: '1% 0% 0.5% 0%' }}
      >
        <Button
          variant="contained"
          disabled={riderLength === 0 && !isCompanyRegistered}
          sx={{
            background: isCompanyRegistered || riderLength > 0 ? '#002D8C' : 'grey',
            borderRadius: '5px',
            fontFamily: 'Medium',
            color: isCompanyRegistered ? '#FFFFFF' : '#fff',
            width: '48%',
            padding: 0,
            fontSize: '13px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: '#002D8C'
            },
            '&.Mui-disabled': {
              backgroundColor: 'grey',
              color: '#FFFFFF'
            }
          }}
          onClick={handleClick}
        >
          Calculate Premium
        </Button>
        <Button
          onClick={() => handleResetClick()}
          variant="contained"
          sx={{
            backgroundColor: '#fff',
            borderRadius: '5px',
            color: '#002D8C',
            fontFamily: 'Medium',
            width: '48%',
            border: '1px solid #002D8C',
            fontSize: '13px',
            padding: 0,
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: '#fff'
            }
          }}
        >
          Reset
        </Button>
      </Grid>
    </>
  );
};

export default MotorVehicleBottomPortion;
