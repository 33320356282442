import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIcon } from '@mui/material';

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <StepIcon
      {...props}
      completed={completed}
      style={{
        color: active || completed ? '#00c7b1' : 'gray'
      }}
    />
  );
};

export default function HorizontalStepper({ steps, activeSteps, width, boxwidth }) {
  const stepWidth = `${100 / steps.length}%`;

  return (
    <Box sx={{ width: boxwidth, display: 'flex' }}>
      <Stepper
        activeStep={activeSteps}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F2F9FF',
          borderRadius: '15px',
          width: width,
          padding: '2.2%',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        }}
        connector={false}
      >
        {steps.map((label, index) => (
          <Step key={label} style={{ display: 'flex', justifyContent: 'center', width: stepWidth }}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginTop: '0.5rem' }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p style={{ color: activeSteps >= index ? '#00c7b1' : 'gray', margin: '10px', width: '100%' }}>{label}</p>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

HorizontalStepper.defaultProps = {
  steps: ['Select master blaster campaign settings', 'Create an ad group', 'Create an ad'],
  width: '520px',
  boxwidth: '100vw'
};
