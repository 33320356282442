import { useEffect, useState } from 'react';
import { useGetCountriesListGetQuery } from 'store/services/CountriesList';

export default function useGetCountriesList() {
  const [getCountriesListData, setGetCountriesListData] = useState([]);
  const { data: countriesListData } = useGetCountriesListGetQuery();
  useEffect(() => {
    if (countriesListData) {
      const updatedCountriesListData = countriesListData?.data?.data?.map((item) => ({ id: item, label: item })) || [];
      setGetCountriesListData(updatedCountriesListData);
    }
  }, [countriesListData]);
  return getCountriesListData;
}
