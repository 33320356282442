import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import '../../policies/styles/Policies.css';
import useGetPoliciesTable from '../hooks/useGetPoliciesTable';
import OverlayLoader from 'components/loader/OverlayLoader';
import BasicTable from 'pages/dashboard/BasicTable';

const PoliciesTable = () => {
  const [data, loading, columns] = useGetPoliciesTable();
  const [showLoader, setShowLoader] = useState(true);
  const timeoutDuration = 5000;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, timeoutDuration);

    return () => clearTimeout(timeout);
  }, []);

  if (loading && showLoader) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
        <OverlayLoader show={true} />
      </div>
    );
  }

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="flex-start"
      sx={{
        paddingTop: '24px !important',
        paddingLeft: '24px !important',
        paddingRight: '0px !important',
        paddingBottom: '0px !important'
      }}
    >
      <BasicTable rows={data} columns={columns} searchOn={['cname', 'Pno', 'nricno', 'statusText', 'vrn']} showDatePicker={false} />
    </Grid>
  );
};

export default PoliciesTable;
