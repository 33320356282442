import PropTypes from 'prop-types';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import { useTheme } from 'components/Theme/ThemeProvider';

const AnalyticEcommerce = ({ percentage, isLoss, icon, title, dashboardData, color }) => {
  const { currentTheme } = useTheme();
  return (
    <MainCard contentSX={{ backgroundColor: '#fff' }}>
      <Stack style={{ height: '100%' }}>
        <Grid container alignItems="center" style={{ height: '100%' }}>
          <Grid item xs={3}>
            {icon && (
              <Typography variant="h4" color="inherit" sx={{}}>
                {icon}
              </Typography>
            )}
          </Grid>

          <Grid item container alignItems="center" xs={9}>
            <Grid item xs={5}>
              <Typography variant="h5" color={'#000000'} fontFamily={'Medium'}>
                {dashboardData}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {percentage && (
                <Chip
                  variant="outlined"
                  color={isLoss ? 'error' : 'success'}
                  icon={
                    <>
                      {!isLoss && <RiseOutlined style={{ fontSize: '0.75rem', color: '#66d790' }} />}
                      {isLoss && <FallOutlined style={{ fontSize: '0.75rem', color: '#f56b6c' }} />}
                    </>
                  }
                  label={`${percentage}%`}
                  sx={{
                    width: '80px',
                    fontSize: 12,
                    outline: 'None',
                    display: 'flex',
                    justifyContent: 'start',
                    border: 'none',
                    fontWeight: '400',
                    fontFamily: 'Medium'
                  }}
                  size="small"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ fontFamily: 'Medium', fontSize: 13, color: color }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </MainCard>
  );
};

AnalyticEcommerce.propTypes = {
  title: PropTypes.string,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  icon: PropTypes.node,
  dashboardData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
};


export default AnalyticEcommerce;
