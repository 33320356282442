import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, CircularProgress, Button } from '@mui/material';
import ReusableCard from 'components/cards/ResuableCard';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import SuccessModal from 'components/modals/contentmodal/Successmodal';
import { store } from 'store/index';
import { removeOtherState, resetActiveSteps } from 'store/reducers/premiumPolicy';
import { useNavigate } from 'react-router-dom';
import { showWarning } from 'components/nudges/Alert';
import { useSelector } from 'react-redux';
import OverlayLoader from 'components/loader/OverlayLoader';

const QrCode = () => {
  const { makeRequest } = useApiCall();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [loading, setLoading] = useState(false);
  // const intervalIdRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [QRImage, setQRImage] = useState('');
  const [show, setShow] = useState(true);
  // const timeoutIdRef = useRef(null);

  const motorQrRefrenceNumber = useSelector((state) => state.persistedReducer?.premiumPolicy.motorQrRefrenceNumber);

  useEffect(() => {
    let intervalId;
    let timeoutId;

    const checkPaymentStatus = async () => {
      try {
        setLoading(true);
        let params = {
          referenceNumber: motorQrRefrenceNumber
        };
        const response = await makeRequest({ method: 'GET', url: API.PolicyPaymentDetailsGet, params });
        setPaymentStatus(response.data.paymentStatus);
        setQRImage(response?.data?.qrCode);

        if (response.data.paymentStatus === 'Success' || response.data.paymentStatus === 'Close') {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          setOpen(true);
          setShow(false);
        }
        if (response.data.paymentStatus === 'Close') {
          setOpen(true);
          setShow(false);
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        setShow(false);
      } finally {
        setLoading(false);
        setShow(false);
      }
    };

    const startFetching = () => {
      intervalId = setInterval(checkPaymentStatus, 3000);

      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        showWarning('Session timeout');
        store.dispatch(removeOtherState());
        store.dispatch(resetActiveSteps());
        navigate('/');
      }, 180000);
    };

    startFetching();

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [motorQrRefrenceNumber]);

  const handleThankYouButton = () => {
    setOpen(false);
    store.dispatch(removeOtherState());
    store.dispatch(resetActiveSteps());
    navigate('/');
  };

  const renderStatusMessage = () => {
    switch (paymentStatus) {
      case 'Open':
        return <Typography variant="body1">Waiting for payment...</Typography>;
      case 'Success':
        return (
          <Typography variant="body1" style={{ color: 'green' }}>
            Payment successful!
          </Typography>
        );
      case 'Close':
        return (
          <Typography variant="body1" style={{ color: 'red' }}>
            Payment closed.
          </Typography>
        );
      default:
        return <Typography variant="body1">Unknown payment status.</Typography>;
    }
  };

  return (
    <>
      <OverlayLoader show={show} />
      <ReusableCard
        backgroundColor={'#fff'}
        border="1px solid #ddd"
        padding={'3%'}
        borderRadius={15}
        content={
          <Grid container direction="column" alignItems="center" spacing={2}>
            {/* <Grid item>
              <Typography variant="h5">Scan the QR Code</Typography>
            </Grid> */}
            <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {!QRImage ? (
                <Typography>Payment QR Generating...</Typography>
              ) : (
                <img src={QRImage} alt="QR Code" style={{ width: '70%', height: 'auto' }} />
              )}
            </Grid>
            {/* <Grid item>
              <Typography variant="body1">Scan this code to pay</Typography>
            </Grid> */}
            <Grid item>{loading ? <CircularProgress /> : renderStatusMessage()}</Grid>
            <Grid item xs={12} md={2.5}>
              <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000', height: '100%' }}
                onClick={() => {
                  navigate('/');
                }}
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        }
      />
      <SuccessModal
        isOpen={open}
        setIsOpen={setOpen}
        handleCloseModal={handleThankYouButton}
        title={'Payment Successful'}
        subtitle={' Your payment has been successfully processed.'}
      />
    </>
  );
};

export default QrCode;
