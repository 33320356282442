import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Typography } from '@mui/material';
import { activeItem } from 'store/reducers/menu';
import { API } from 'api/API';
import { showDanger, showSuccess } from 'components/nudges/Alert';
import { useNavigate } from 'react-router-dom';
import useApiCall from 'api/useApiCall';
import { updateAgendAuth } from 'store/reducers/auth';
import { setFlag } from 'store/reducers/premiumPolicy';
 
const NavItem = ({ item, level }) => {
  const [openSubItems, setOpenSubItems] = useState(false);
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
 
  const dispatch = useDispatch();
  const { pathname } = useLocation();
 
  const { drawerOpen, openItem } = useSelector((state) => state.persistedReducer.menu);
 
  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }
 
  let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }
 
  const itemHandler = async (id) => {
    if (id == 'logout') {
      try {
        localStorage.removeItem('@user');
        localStorage.removeItem('@login');
        localStorage.removeItem('AppToken');
        localStorage.removeItem('hasFinanceTabBeenAdded');
        showSuccess('Logout successfully');
        dispatch(updateAgendAuth({ status: false, data: {} }));
      } catch (e) {
        showDanger(e);
      }
    } 
    else {
      dispatch(activeItem({ openItem: [id] }));
    }
  };
 
  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;
 
  // const isSelected = openItem.findIndex((id) => id === item.id) > -1;
  const isSelected = Array.isArray(openItem) && openItem.findIndex((id) => id === item.id) > -1;
 

 
 
  const textColor = '#636e72';
  const iconSelectedColor = '#FFF';
 
  return (
    <>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        onClick={() => {
          itemHandler(item.id);
        }}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          marginLeft: 1.4,
          pl: drawerOpen ? `${level * 28}px` : 1.2,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: '#FFF'
            },
            '&.Mui-selected': {
              bgcolor: '#00C7B1',
              borderRadius: '10px',
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: '#00C7B1'
              }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent'
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent'
              },
              bgcolor: 'transparent'
            }
          })
        }}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: isSelected ? iconSelectedColor : textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: 'red'
                }
              }),
              ...(!drawerOpen &&
                isSelected && {
                  bgcolor: 'primary.lighter',
                  '&:hover': {
                    bgcolor: 'primary.lighter'
                  }
                })
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{ color: isSelected ? iconSelectedColor : textColor }}
                style={{ fontSize: 13, fontFamily: 'Medium' }}
              >
                {item.title}
              </Typography>
            }
          />
        )}
      </ListItemButton>
     
    </>
  );
};
 
NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};
 
export default NavItem;