import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/Logo';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <>
      <DrawerHeaderStyled theme={theme} open={open} sx={{ justifyContent: 'center', paddingLeft: '0px', padding: '0px' }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Logo />
        </Stack>
      </DrawerHeaderStyled>
    </>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
