// project import
import { useEffect, useState } from 'react';

import usePages from './usePages';

// ==============================|| MENU ITEMS ||============================== //
const useMenuItems = () => {
  const [menuItem, setMenuItems] = useState({});
  const pages = usePages();
  useEffect(() => {
    const menuItems = {
      items: [pages]
    };
    setMenuItems(menuItems);
  }, [pages]);

  return menuItem;
};

export default useMenuItems;
