import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';

const CustomerMotorAmountCard = ({ data }) => {
  return (
    <Grid
      item
      xs={12}
      md={4}
      lg={4}
      mt={2}
      ml={2}
      style={{
        backgroundColor: '#fff',
        borderRadius: '15px',
        boxShadow: '10px 10px 30px 10px  #F7F7F8',
        padding: '15px',
        maxWidth: '100%',
        border: '2px solid #002d8c'
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>
            Summary
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Grid style={{ border: '1px solid #ddd', width: '100%' }} />

          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>Premium W/O GST</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${data[0]?.finalPremiumAmount}`}</Typography>
          </Grid>

          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>GST {`(${data[0]?.gstPercentage}%)`}</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${data[0]?.gstAmount}`}</Typography>
          </Grid>
          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>Food Delivery with GST</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${data[0]?.fdExtentionAmount}`}</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid style={{ border: '1px solid #ddd', width: '100%' }} />
          </Grid>
          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ color: '#76777D', fontFamily: 'Heavy', fontWeight: 600, marginRight: '2px' }}>Total Premium</Typography>
              <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>with GST</Typography>
            </div>
            <Typography
              style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}
            >{`$${data[0]?.finalTotalPremiumAmount}`}</Typography>
          </Grid>

          {/* <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>Commission</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${data[0]?.agentCommissionAmount}`}</Typography>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerMotorAmountCard;
