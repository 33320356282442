const API = {
  login: 'api/v1/login',
  UserLogin: 'api/v1/UserLogin',
  AgentMasterGet: 'api/v1/AgentMasterGet',
  PasswordChangeOTPSent: 'api/v1/PasswordChangeOTPSent',
  OtpWiseChangePassword: 'api/v1/OtpWiseChangePassword',
  PackagesAndPremiumGet: 'api/v1/PackagesAndPremiumGet',
  WorkShopTypeGet: 'api/v1/WorkShopTypeGet',
  NCDGet: 'api/v1/NCDGet',
  VehicleCapacityGet: 'api/v1/VehicleCapacityGet',
  AgeOfMotorcycleGet: 'api/v1/AgeOfMotorcycleGet',
  VehicleDetailsGet: 'api/v1/VehicleDetailsGet',
  CountriesListGet: 'api/v1/CountriesListGet',
  CustomerNameWiseSearch: 'api/v1/CustomerNameWiseSearch',
  CompanyUENNoWiseGet: 'api/v1/CompanyUENNoWiseGet',
  PackagesAndPremiumCalculation: 'api/v1/PackagesAndPremiumCalculation',
  FinalSubmissionCustomerPortalForMotorAPI: 'api/v1/FinalSubmissionCustomerPortalForMotorAPI',
  FinalSubmissionCustomerPortalForFWDHAPI: 'api/v1/FinalSubmissionCustomerPortalForFWDHAPI',
  AgentSpecificPoliciesGet: 'api/v1/AgentSpecificPoliciesGet',
  FWDHCalculationGet: 'api/v1/FWDHCalculationGet',
  CustomerMasterbyNRICGet: 'api/v1/CustomerMasterbyNRICGet',
  FWDHCalculationPost: 'api/v1/FWDHCalculationPost',
  RenewPolicyDetailsGet: 'api/v1/RenewPolicyDetailsGet',
  CommissionEarnPolicyGet: 'api/v1/CommissionEarnPolicyGet',
  AgentDashboardAPI: 'api/v1/AgentDashboardAPI',
  CommissionBreakUpDetailsGet: 'api/v1/CommissionBreakUpDetailsGet',
  PolicyConversionDetailsGet: 'api/v1/PolicyConversionDetailsGet',
  CustomerMasterInsertUpdate: 'api/v1/CustomerMasterInsertUpdate',
  CustomerMasterUserIdWiseGet: 'api/v1/CustomerMasterUserIdWiseGet',
  CustomerPackagesAndPremiumGet: 'api/v1/CustomerPackagesAndPremiumGet',
  EndorsementCategoryGet: 'api/v1/EndorsementCategoryGet',
  AccessTokenGet: 'api/v1/AccessTokenGet',
  PolicyPaymentDetailsGet:'api/v1/PolicyPaymentDetailsGet',
  CustomerSpecificPolicies:'api/v1/CustomerSpecificPolicies',
  EndorsementDetailsInsertUpdate:'api/v1/EndorsementDetailsInsertUpdate',
  EndorsementDetailsGet:'api/v1/EndorsementDetailsGet',
};

export { API };
