import useBodyScrollLock from 'hooks/useBodyScrollLock';
import React, { useEffect, useRef } from 'react';
const ReusableModal = (props) => {
  const modalRef = useRef();
  //  const [isLocked, setIsLocked] = useBodyScrollLock();

  useEffect(() => {
    const handleCloseOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && props.isOpen) {
        if (!props.disableBackdropClick) {
          props.onClose();
        }
      }
    };

    document.addEventListener('mousedown', handleCloseOutside);

    // if (props.isOpen) {
    //   setIsLocked(true);
    // } else {
    //   setIsLocked(false);
    // }

    return () => {
      document.removeEventListener('mousedown', handleCloseOutside);
      // setIsLocked(false);
    };
  }, [props.isOpen, props.onClose, props.disableBackdropClick]);

  const handleClose = () => {
    if (props?.onClose) {
      props?.onClose();
    }
  };

  const handleKeyPress = (event) => {
    if (event?.key === 'Enter' && props?.onClose) {
      props?.onClose();
    }
  };

  return (
    <>
      {props.isOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 90
          }}
        >
          <div
            ref={modalRef}
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {props?.showCloseButton && (
              <div
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  borderRadius: 30,
                  width: 40,
                  height: 39,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: props.top
                }}
                onClick={handleClose}
                onKeyDown={handleKeyPress}
                role="button"
                tabIndex={0}
              >
                <div style={{ fontSize: 26, fontWeight: '700', color: '#949494' }}>&times;</div>
              </div>
            )}
            <div
              style={{
                backgroundColor: props.backgroundColor,
                padding: props.padding,
                borderRadius: props.borderRadius,
                width: props.width,
                height: props.height,
                maxWidth: props.maxWidth,
                margin: props.margin,
                border: props.border,
                maxHeight: props.maxHeight,
                boxShadow: props.boxShadow,
                marginTop: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div style={{ marginBottom: props.marginBottom }}>{props.ModalContent}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ReusableModal.defaultProps = {
  isOpen: false,
  showCloseButton: true,
  disbleBackdropClick: true,
  height: '320px',
  width: '400px',
  maxWidth: '1500px',
  maxHeight: '1000px',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  borderRadius: '13px',
  ModalContent: <div>ModalContent</div>,
  top: '-10%'
};

export default ReusableModal;
