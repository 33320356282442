import ReusableCard from 'components/cards/ResuableCard';
import React, { useEffect, useState } from 'react';
import { Grid, Button, IconButton } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import { showDanger, showWarning } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SuccessModal from 'components/modals/contentmodal/Successmodal';
import OverlayLoader from 'components/loader/OverlayLoader';

const ForeignWorkerQRCode = React.memo(({ loading }) => {
  const { makeRequest } = useApiCall();
  const network = useNetworkStatus();
  const { finalResponse } = useSelector((state) => state?.persistedReducer?.premiumPolicy);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => {
    setIsOpen(false);
    navigate('/');
  };
  const handlePaymentFunc = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (!finalResponse?.qrCode) return;

    let intervalId;
    let timeoutId;

    const fetchRefrenceStatusData = async () => {
      try {
        if (!network) {
          showDanger('No internet connection.');
          return;
        }

        const refrenceNumber = finalResponse?.referenceNumber;
        const response = await makeRequest({
          method: 'GET',
          url: `${API.PolicyPaymentDetailsGet}?referenceNumber=${refrenceNumber}`
        });

        if (response?.code === 200) {
          if (response?.data?.paymentStatus === 'Close' || response?.data?.paymentStatus === 'Success') {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            handlePaymentFunc();
            // navigate('/');
          }
        }
      } catch (error) {
        console.error(error);
        showDanger(error);
        navigate('/');
      }
    };

    const startFetching = () => {
      intervalId = setInterval(fetchRefrenceStatusData, 3000);

      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        showWarning('Session Timeout');
        navigate('/');
      }, 180000);
    };

    startFetching();
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [finalResponse?.qrCode]);

  return (
    <>
      {finalResponse?.qrCode && <OverlayLoader show={loading} />}
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'5%'}
        boxShadow={'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        // height="350px"
        marginTop={'2%'}
        content={
          <Grid container spacing={2}>
            {/* <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h5>Scan the QR for Policy Purchase</h5>
            </Grid> */}
            <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={finalResponse?.qrCode} style={{ width: '40%' }} />
            </Grid>

            <Grid item xs={6} sm={6} md={3}  lg={12} display="flex" justifyContent="center" alignItems="center">
              <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: '#e4e4e4', width: '12%', color: '#000000' }}
                onClick={() => {
                  navigate('/');
                }}
              >
                <IconButton style={{ marginRight: '5px', color: '#000000' }}>
                  <WestIcon />
                </IconButton>
                Home
              </Button>
            </Grid>
          </Grid>
        }
      />
      <SuccessModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'Policy Purchase'}
        subtitle={'Payment Successful'}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
});

export default ForeignWorkerQRCode;
