import React, { useState } from 'react';
import CardLayout from '../CardLayout';
import RiderSectionTable from '../RiderSectionTable';
import { MotorAddRiderTableColumns } from 'modules/packages & premium/static';
import Rightsidebar from 'components/rightsidebar/Rightsidebar';
import AddRiderSidebar from '../AddRiderSidebar';
import { useRiderDetails } from 'modules/packages & premium/hooks/useVehicle';
import { showWarning } from 'components/nudges/Alert';

const MotarRiderInformation = React.memo((props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data } = useRiderDetails(handleClickOnEdit);
  const [selectedRow, setSelectedRow] = useState({});
  const [getEditText, setEditText] = useState('');
  const [isOpen, setIsOpen] = useState(true);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOnTopButton = () => {
    if (data.length >= 3) {
      showWarning('You can not add more than 3 Riders in a policy');
    } else {
      setSelectedRow({});
      setIsSidebarOpen(true);
      setEditText('add');
    }
  };

  function handleClickOnEdit(rider, action) {
    setIsSidebarOpen(true);
    setSelectedRow(rider);
    setEditText(action);
  }

  const hasRiders = data && data?.length > 0;

  return (
    <>
      {isSidebarOpen && (
        <Rightsidebar
          Rcontent={
            <AddRiderSidebar
              setShow={setIsSidebarOpen}
              closeSidebar={() => setIsSidebarOpen(false)}
              buttonLabel={getEditText === 'edit' ? 'Edit Rider' : 'Add Rider'}
              isEditMode={getEditText === 'edit'}
              selectedRow={selectedRow}
            />
          }
          closeSidebar={() => setIsSidebarOpen(true)}
          show={isSidebarOpen}
          maxWidth="400px"
        />
      )}

      <CardLayout
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleForm={toggleForm}
        heading="Rider Section"
        headerButtonTitle="+ Add Rider"
        isTopButtonVisible
        handleClickOnTopButton={handleClickOnTopButton}
      >
        {hasRiders ? (
          <RiderSectionTable columns={MotorAddRiderTableColumns} rows={data} />
        ) : (
          <RiderSectionTable columns={MotorAddRiderTableColumns} rows={[]} />
        )}
        {!hasRiders && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              fontSize: '13px',
              color: '#000',
              marginTop: '14px'
            }}
          >
            No Rider Found.
          </div>
        )}
      </CardLayout>
    </>
  );
});

export default MotarRiderInformation;
