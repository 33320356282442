import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API } from '../../api/API';
import { headers, SERVER_URL } from 'helper/utils';
// import { app } from '../../../node_modules/@electron/remote/index';

export const DomesticHelperAPI = createApi({
  reducerPath: 'domesticHelperAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: async (headers, { getState }) => {
      const appToken = localStorage.getItem('AppToken');
      headers.set('Content-Type', 'application/json');
      if (appToken) {
        headers.set('apptoken', JSON.parse(appToken));
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getFWDHCalculation: builder.query({
      queryFn: async (productId) => {
        const baseURL = await SERVER_URL();
        let header = await headers({ method: 'GET' });
        const response = await fetch(`${baseURL}${API.FWDHCalculationGet}?UserId=&productMasterID=${productId}`, header);
        if (!response.ok) {
          throw new Error('Failed to fetch FWDH Calculation');
        }
        const data = await response.json();
        return { data };
      }
    }),
    postFWDHCalculation: builder.mutation({
      queryFn: async (body, { getState }) => {
        const baseURL = await SERVER_URL();
        const appToken = localStorage.getItem('AppToken');
        let token = JSON.parse(appToken);
        let config = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*'
          }
        };
        config.headers['Authorization'] = `Bearer ${token}`;
        if (body) {
          config.body = JSON.stringify(body);
        }

        // const headers = {
        //   'Content-Type': 'application/json',
        //   apptoken: JSON.parse(appToken)
        // };

        const response = await fetch(`${baseURL}${API.FWDHCalculationPost}`, config);
        if (!response.ok) {
          throw new Error('Failed to post FWDH Calculation');
        }
        const data = await response.json();
        return { data };
      }
    }),
    postFinalSubmission: builder.mutation({
      queryFn: async (body, { getState }) => {
        const baseURL = await SERVER_URL();
        const appToken = localStorage.getItem('AppToken');
        let token = JSON.parse(appToken);
        let config = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*'
          }
        };
        config.headers['Authorization'] = `Bearer ${token}`;
        if (body) {
          config.body = JSON.stringify(body);
        }
        // const headers = {
        //   'Content-Type': 'application/json',
        //   apptoken: JSON.parse(appToken)
        // };
        const response = await fetch(`${baseURL}${API.FinalSubmissionCustomerPortalForFWDHAPI}`, config);
        if (!response.ok) {
          throw new Error('Failed to post final submission');
        }
        const data = await response.json();
        return { data };
      }
    })
  })
});
export const { useGetFWDHCalculationQuery, usePostFWDHCalculationMutation, usePostFinalSubmissionMutation } = DomesticHelperAPI;
