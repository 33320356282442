import React from 'react';
import { Grid } from '@mui/material';
import PremiumCalculateSubHeader from '../components/PremiumCalculateSubHeader';
import { useLocation } from 'react-router-dom';
import PackagePremiumStateProvider, { usePackagePremiumState } from '../provider/PackagePremiumStateProvider';
import PremiumPolicyTypes from '../components/PremiumPolicyTypes';
import { handleDownload } from 'helper/utils';
import { useSelector } from 'react-redux';
import '../../packages & premium/styles/InformationForm.css';

const InitialComponent = React.memo(() => {
  const { currentTheme, params } = usePackagePremiumState();
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);

  const url = params?.displayUrl;
  const key = params.productID;

  return (
    <>
      {params && (
        <Grid >
          <Grid
            container
            rowSpacing={2}
            // columnSpacing={2.75}
            alignItems="center"
            justifyContent="space-between"
            style={{ backgroundColor: currentTheme.cardColor }}
          >
            <PremiumCalculateSubHeader url={url} key={key} isRightEnable={false} renewalKey={otherState?.navigateFrom} />
            <PremiumPolicyTypes />
          </Grid>
        </Grid>
      )}
    </>
  );
});

function PremiumCalculateView() {
  const location = useLocation();
  const { data } = location?.state || {};
  const { activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const opportunityId = data?.policyData?.data?.policy?.opportunityId;
  const params = {
    ...data?.item,
    ...activeParams,
    opportunityId
  };
  return (
    <PackagePremiumStateProvider params={params}>
      <InitialComponent handleDownload={handleDownload} />
    </PackagePremiumStateProvider>
  );
}

export default PremiumCalculateView;
