import * as React from 'react';
import { Box, TextField, Select, MenuItem, Grid, Button, InputAdornment } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../../assets/fonts/Fonts.css';
import CustomPagination from 'components/pagination/Pagination';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from 'components/Theme/ThemeProvider';
import { showWarning } from 'components/nudges/Alert';
const BasicTable = ({
  rows,
  searchOn,
  columns,
  statusKey,
  onSearch,
  showSearchBar = true,
  showDatePicker = true,
  // actionButton,
  handleVerifyClick,
  amountKey,
  statusColorFun,
  borderRadius,
  rowHeight,
  verticalAlign,
  getStatusBackgroundColor,
  getStatusColor,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  EmptyRowPlaceholder
}) => {
  const [page, setPage] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState();
  const [order, setOrder] = React.useState('asc');
  const [searchText, setSearchText] = React.useState('');
  const [showRecords, setShowRecords] = React.useState(50);
  const [newData, setNewData] = React.useState([]);
  const [newDataTemp, setNewDataTemp] = React.useState([]);

  const { currentTheme } = useTheme();

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (e) => {
    try {
      setSearchText(e);

      let tempData = newDataTemp?.filter((i) => {
        const searchFields = searchOn?.map((field) => {
          const fieldValue = i?.[field];

          if (field == 'statusText') {
            const lowerCaseFieldValue = typeof fieldValue === 'string' ? fieldValue?.toLowerCase() : fieldValue;
            return typeof lowerCaseFieldValue === 'string'
              ? lowerCaseFieldValue?.startsWith(e.toLowerCase())
              : fieldValue?.toString()?.includes(e);
          } else {
            const lowerCaseFieldValue = typeof fieldValue === 'string' ? fieldValue?.toLowerCase() : fieldValue;
            return typeof lowerCaseFieldValue === 'string'
              ? lowerCaseFieldValue?.includes(e.toLowerCase())
              : fieldValue?.toString()?.includes(e);
          }

          // return lowerCaseFieldValue?.includes(e.toLowerCase());
        });
        return searchFields.some((fieldResult) => fieldResult);
      });

      setNewData(tempData);
      setPage(1);
    } catch (e) {
      console.error('Error in handleSearch' + e);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setShowRecords(event.target.value);
    setPage(1);
  };
  const itemsPerPage = showRecords;
  const startIndex = (page - 1) * showRecords;
  const endIndex = Math.min(startIndex + itemsPerPage, newData.length);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    const isNumeric = !isNaN(a[orderBy]) && !isNaN(b[orderBy]);
    if (isNumeric) {
      return b[orderBy] - a[orderBy];
    } else {
      const aValue = a[orderBy] != null ? a[orderBy].toString() : '';
      const bValue = b[orderBy] != null ? b[orderBy].toString() : '';
      return bValue.localeCompare(aValue);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      alert('handleKeyPress');
    }
  };

  React.useEffect(() => {
    if (rows) {
      setNewData(rows);
      setNewDataTemp(rows);
    }
  }, [rows]);
 
 
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        style={{
          backgroundColor: '#fff',
          borderRadius: borderRadius,
          width: '100%',
          // top: '10%',
          border: '1px solid #ddd',
          height: 'auto',
          padding: '10px'
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', sm: 'space-between' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: { xs: 2, sm: 0 },
                width: { xs: '100%', sm: 'auto' },
                justifyContent: { xs: 'center', sm: 'flex-start' }
              }}
            >
              Shows
              <Select
                value={showRecords}
                onChange={handleChangeRowsPerPage}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '150px',
                      width: '130px',
                      backgroundColor: '#f8f8f8',
                      borderRadius: '9px'
                    }
                  }
                }}
                size="small"
                sx={{
                  backgroundColor: '#f8f8f8',
                  marginLeft: '8px',
                  minWidth: '100px',
                  borderRadius: '9px',
                  height: '33px'
                }}
              >
                {/* <MenuItem value={10}>10 Records</MenuItem> */}
                {/* <MenuItem value={25}>25 Records</MenuItem> */}
                <MenuItem value={50}>50 Records</MenuItem>
              </Select>
            </Box>

            {showSearchBar && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: { xs: 2, sm: 0 },
                  width: { xs: '100%', sm: 'auto' },
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  ml: { sm: 'auto' }
                }}
              >
                <TextField
                  placeholder="Search here ..."
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                    borderRadius: '9px',
                    backgroundColor: '#f8f8f8',
                    height: '34px',
                    border: 'none',
                    outline: 'none',
                    fontFamily: 'Medium',
                    display: 'flex',
                    padding: 0
                  }}
                  variant="standard"
                  size="small"
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
                        <SearchIcon color="action" sx={{ fontSize: '20px', marginRight: '4px' }} />
                      </InputAdornment>
                    ),
                    sx: {
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      paddingLeft: 0
                    }
                  }}
                />
              </Box>
            )}

            {showDatePicker && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: { xs: 2, sm: 0 },
                  width: { xs: '100%', sm: 'auto' },
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  ml: { sm: 2 }
                }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item alignItems="center" display="flex" justifyContent="center">
                    Start Date
                    <TextField
                      type="date"
                      size="small"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        backgroundColor: '#F8F8F8',
                        width: '120px',
                        margin: '0 8px',
                        padding: '5px',
                        borderRadius: '8px'
                      }}
                      value={startDate}
                      onChange={(e)=>setStartDate(e.target.value)}
                      required
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Grid>
                  <Grid item alignItems="center" display="flex" justifyContent="center">
                    End Date
                    <TextField
                      type="date"
                      size="small"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        backgroundColor: '#F8F8F8',
                        width: '120px',
                        margin: '0 8px',
                        padding: '5px',
                        borderRadius: '8px'
                      }}
                      value={endDate}
                      onChange={(e)=>setEndDate(e.target.value)}
                      required
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      disabled={!startDate || !endDate}
                      sx={{
                        backgroundColor: !startDate || !endDate ? 'grey' : '#4CB748',
                        color: '#fff',
                        height: '33px'
                      }}
                      onClick={handleVerifyClick}
                    >
                      Verify
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <TableContainer
        style={{ overflowX: 'scroll', width: '800px' }}
        sx={{
          width: '100%',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          minWidth: '100%',
          backgroundColor: currentTheme.bodyColor,
          color: currentTheme.textColor,
          borderRight: '1px solid #ddd',
          borderLeft: '1px solid #ddd'
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || 'center'}
                  sx={{
                    fontFamily: 'Medium',
                    fontSize: '14px',
                    color: '#000000',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    backgroundColor: '#ffffff',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign:'center',
                   
                    minWidth: column.minWidth || column.label.length * 10,
                    maxWidth: column.maxWidth || 'none'
                  }}
                >
                  {column.label}
                  {column.id !== 'Action' && column.id !== 'policystatus'&& (
                    <span
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => handleKeyPress(e)}
                      onClick={() => handleRequestSort(column.id)}
                      style={{ cursor: 'pointer', fontSize: 11 }}
                    >
                      {orderBy === column.id ? (order === 'asc' ? ' ▲' : ' ▼') : ' ↕'}
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {newData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center" style={{ fontSize: '13px', fontFamily: 'Medium' }}>
                  {EmptyRowPlaceholder}
                </TableCell>
              </TableRow>
            ) : (
              stableSort(newData, getComparator(order, orderBy))
                .slice(startIndex, endIndex)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    hover
                    role="checkbox"
                    sx={{ position: 'relative', height: rowHeight, verticalAlign: verticalAlign }}
                    style={{
                      backgroundColor: row[statusKey] === 'Failed' ? '#FDECEC' : 'white',
                      borderRadius: '7px'
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      const cellStyle = column.id === statusKey ? { ...statusColorFun(value) } : {};
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align || 'left'}
                          style={{
                            ...cellStyle,
                            fontFamily: 'Medium',
                            height: row.offer ? '75px' : '50px',
                            verticalAlign: row.offer ? 'top' : 'middle',
                            // backgroundColor: column.id === 'policystatus',
                            // color: column.id === 'policystatus' ? getStatusColor(row[column.id]) : '#616471',
                            minWidth: column.minWidth || column.label.length * 10,
                            whiteSpace: 'normal',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontWeight: '200',
                            position: 'relative',
                            textAlign:'center'
                          }}
                        >
                          {column.id === amountKey ? (
                            <>₹{value}</>
                          ) : column.id === row.actionButton?.key ? (
                            <>{row.actionButton?.value}</>
                          ) : column.format && typeof value === 'number' ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                          {column.id === 'instock' && row.instock < 15 && (
                            <div
                              style={{
                                position: 'absolute',
                                bottom: row.offer ? '40px' : '13px',
                                right: '20px'
                              }}
                            >
                              <ErrorIcon style={{ width: '15px', color: 'red' }} />
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        onChange={handleChangePage}
        count={Math.ceil(newData.length / itemsPerPage)}
        totalRecord={newData.length}
        startIndex={startIndex}
        endIndex={endIndex}
        backgroundColor={currentTheme.bodyColor}
        color={currentTheme.textColor}
      />
    </Box>
  );
};

export default BasicTable;

BasicTable.defaultProps = {
  EmptyRowPlaceholder: 'No Data Available',
  rowHeight: '50px',
  verticalAlign: 'middle',
  rows: [],
  columns: [
    { id: 'OrderId', label: 'Order Id', minWidth: 140 },
    { id: 'WareHouse', label: 'WareHouse', minWidth: 150 },
    {
      id: 'CreaatAt',
      label: 'Order date & time',
      maxWidth: 170,
      align: 'center'
    },
    {
      id: 'Total Qty',
      label: 'Total QTY',
      maxWidth: 150,
      align: 'center'
    },
    {
      id: 'Total Amount',
      label: 'Total Amount',
      maxWidth: 170,
      align: 'center'
    },
    {
      id: 'Order status',
      label: 'Order Status',
      maxWidth: 150,
      align: 'center'
    },
    {
      id: 'Source',
      label: 'Source',
      maxWidth: 170,
      align: 'center'
    },
    {
      id: 'Action',
      label: 'Action',
      align: 'center'
    }
  ],
  statusKey: 'Order status',
  amountKey: 'Total Amount',
  actionButton: {
    key: 'Action',
    value: (
      <div style={{ backgroundColor: '#4cb748', color: '#fff', borderRadius: 20, minWidth: 55, fontSize: 12, cursor: 'pointer' }}>
        <VisibilityIcon style={{ fontSize: 10 }} />
        View
      </div>
    )
  },
  borderRadius: '15px 15px 0px 0px'
};
