import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Offcanvas } from 'react-bootstrap';

function Rightsidebar({
  Rcontent,
  closeSidebar,
  show,
  maxWidth,
  placement,
  borderBottomLeftRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius
}) {
  const sidebarRef = useRef(null);

  // useEffect(() => {
  //   const handleEscapeKey = (event) => {
  //     // if (event.key === 'Escape') {
  //     //   closeSidebar();
  //     // }
  //   };

  //   document.addEventListener('keydown', handleEscapeKey);

  //   return () => {
  //     document.removeEventListener('keydown', handleEscapeKey);
  //   };
  // }, [closeSidebar]);

  return (
    <>
      <Offcanvas
        placement={placement}
        show={show}
        onHide={() => {}}
        style={{
          backgroundColor: '#fff',
          maxWidth: maxWidth,
          borderTopLeftRadius: borderTopLeftRadius,
          borderBottomLeftRadius: borderBottomLeftRadius,
          borderTopRightRadius: borderTopRightRadius,
          borderBottomRightRadius: borderBottomRightRadius
        }}
      >
        <Offcanvas.Body style={{ justifyContent: 'center', display: 'flex' }} ref={sidebarRef}>
          {Rcontent}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default Rightsidebar;
Rightsidebar.defaultProps = {
  Rcontent: <div>Rcontent</div>,
  placement: 'end',
  borderTopLeftRadius: 20,
  borderBottomLeftRadius: 20,
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20
};
