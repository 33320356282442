import { showDanger, showSuccess } from 'components/nudges/Alert';
import { store } from 'store/index';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { API } from 'api/API';
// import { makeRequestWithoutLogin } from 'api/Request';
const CryptoJS = require('crypto-js');
const nric = require('nric');

//When we want to use redux state outside the hooks so there is a function to get the redux global state
export const getValueFromRedux = (slice) => {
  const value = store.getState();
  const { persistedReducer } = value;
  //console.log('value==>', value);
  return persistedReducer[slice];
};

export const getUserDetails = async () => {
  try {
    let getData = localStorage.getItem('@user');
    // //console.log('getData',getData);
    let employee = JSON.parse(getData);
    return employee;
  } catch (e) {
    console.error('erro in getUserDetails utils', e);
  }
};

function SERVER_URL() {
  try {
    switch (process.env.REACT_APP_ENV) {
      case 'production':
        return process.env.REACT_APP_PROD_BACKEND_URL;
      case 'uat':
        return process.env.REACT_APP_UAT_BACKEND_URL;
      default:
        return process.env.REACT_APP_DEV_BACKEND_URL;
    }
  } catch (e) {
    console.error('Error:', e);
  }
}

function print(prefixString = '', txt) {
  try {
    if (process.env.REACT_APP_IS_LOGGING) {
      console.log(prefixString, txt);
    }
  } catch (e) {
    console.error('Error during print', e);
  }
}

export const RemoveLocalData = async () => {
  try {
    const clearData = localStorage.clear('@login');
    return clearData;
  } catch (e) {
    //console.log('Error:', e);
  }
};

export const getUserId = async () => {
  try {
    let getData = localStorage.getItem('@user');
    let user = JSON.parse(getData);
    //console.log('user========>', user);
    return user?.userId;
  } catch (e) {
    console.error('Error during getUserId inside utils line 61', e);
  }
};

export const getUserEmail = async () => {
  try {
    let getData = localStorage.getItem('@user');
    let user = JSON.parse(getData);
    return user?.finance_Email__c;
  } catch (e) {
    console.error('Error during getUserEmail inside utils line 74', e);
  }
};

export const getTSAType = async () => {
  try {
    let getData = localStorage.getItem('@user');
    let user = JSON.parse(getData);
    return user?.tsA_Type__c;
  } catch (e) {
    console.error('Error during tsA_Type__c inside utils line 84', e);
  }
};

export const getLoginDetailsId = async () => {
  try {
    let getData = localStorage.getItem('@login');
    let user = JSON.parse(getData);
    return user?.loginDetailsId;
  } catch (e) {
    console.error('Error during getLoginDetailsId inside utils line 84', e);
  }
};

export const headers = ({ method }) => {
  const appToken = localStorage.getItem('AppToken');
  const config = {
    method: method?.toUpperCase(),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${appToken ? JSON.parse(appToken) : ''}`
    }
  };
  return config;
};
const handleDownload = async ({ url, key }) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch PDF');
    }

    const blob = await response.blob();
    if (blob.size === 0) {
      throw new Error('Empty PDF blob');
    }

    const blobUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `document_${key}.pdf`);

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading PDF:', error);
    // Handle error, show message to the user if needed
  }
};


const downloadPdf = async ({ url, key }) => {
  print("PDF URL -->",url)
  try {
    const response = await axios.get(url, {
      responseType: 'blob'
    });

    // Create a Blob from the response data
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a temporary URL for the Blob
    const tempUrl = window.URL.createObjectURL(pdfBlob);

    // Create a temporary <a> element to trigger the download
    const tempLink = document.createElement('a');
    tempLink.href = tempUrl;
    tempLink.setAttribute('download', `${key}_${new Date()}.pdf`); // Set the desired filename for the downloaded file

    // Append the <a> element to the body and click it to trigger the download
    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(tempUrl);
  } catch (error) {
    alert(error);
    console.error('Error downloading PDF:', error);
  }
};

const EncryptValue = (value) => {
  const passPhrase = 'd1arfa14k&';
  const initVector = '@1B2c3D4e5F6g7H8';

  // Reverse function
  const Reverse = (s) => {
    return s.split('').reverse().join('');
  };

  const plainText = value;
  const saltValue = Reverse(passPhrase);

  const initVectorBytes = CryptoJS.enc.Utf8.parse(initVector);
  const saltValueBytes = CryptoJS.enc.Utf8.parse(saltValue);
  const plainTextBytes = CryptoJS.enc.Utf8.parse(plainText);

  const keyBytes = CryptoJS.PBKDF2(passPhrase, saltValueBytes, {
    keySize: 128 / 32,
    iterations: 1,
    hasher: CryptoJS.algo.SHA1
  });

  const encrypted = CryptoJS.AES.encrypt(plainTextBytes, keyBytes, {
    iv: initVectorBytes,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.toString();
};
// function nricValidator(nricInput) {
//   // Validation rules
//   const nricRegex = /^([STFGM])(\d{7})([A-Z])$/; // Regex to include M prefix
//   const nricTypeRegex = /S|T|F|G|M/;
//   const weightArr = [2, 7, 6, 5, 4, 3, 2];
//   const nricLetterST = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
//   const nricLetterFG = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
//   const nricLetterM = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L','R'];

//   // Set NRIC to all uppercase to remove case sensitivity
//   const nric = nricInput.toUpperCase();

//   // Return false if basic validation fails
//   const nricMatch = nricRegex.exec(nric);
//   if (!nricMatch) {
//     console.log('NRIC basic validation failed.');
//     return false;
//   }

//   // Get NRIC type
//   const nricType = nricMatch[1];

//   // Return false if invalid NRIC type
//   if (!nricTypeRegex.exec(nricType)) {
//     console.log('NRIC type validation failed.');
//     return false;
//   }

//   // Multiply each digit in the NRIC number by its weight
//   const nricDigitsArr = nricMatch[2].split('');
//   let total = 0;
//   for (let i = 0; i < nricDigitsArr.length; i++) {
//     total += parseInt(nricDigitsArr[i]) * weightArr[i];
//   }

//   // If NRIC type is T or G, add 4 to the total
//   if (['T', 'G'].indexOf(nricType) >= 0) {
//     total += 4;
//   }

//   // Check last letter of NRIC for Singapore locals
//   const letterIndex = total % 11;
//   const nricLetter = nricMatch[3];
//   if (['S', 'T'].indexOf(nricType) >= 0) {
//     console.log(`Singapore NRIC: expected ${nricLetterST[letterIndex]}, got ${nricLetter}`);
//     return nricLetterST[letterIndex] === nricLetter;
//   }

//   // Check last letter of NRIC for foreigners
//   if (['F', 'G'].indexOf(nricType) >= 0) {
//     console.log(`Foreign NRIC: expected ${nricLetterFG[letterIndex]}, got ${nricLetter}`);
//     return nricLetterFG[letterIndex] === nricLetter;
//   }

//   // Check last letter of NRIC for Malaysian NRICs
//   if (nricType === 'M') {
//     // Malaysian NRIC uses a similar but different checksum calculation
//     console.log(`Malaysian NRIC: expected ${nricLetterM[letterIndex]}, got ${nricLetter}`);
//     return nricLetterM[letterIndex] === nricLetter;
//   }

//   console.log('Default case reached.');
//   return false; // Default case
// }

// function nricValidator(nricInput) {
//   // Validation rules
//   const nricRegex = /^([STFGM])(\d{7})([A-Z])$/; // Regex to include M prefix
//   const nricTypeRegex = /^[STFGM]$/;
//   const weightArr = [2, 7, 6, 5, 4, 3, 2];
//   const nricLetterST = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
//   const nricLetterFG = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
//   const nricLetterM = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'R']; // Updated to include the letter 'R'

//   // Set NRIC to all uppercase to remove case sensitivity
//   const nric = nricInput.toUpperCase();

//   // Return false if basic validation fails
//   const nricMatch = nricRegex.exec(nric);
//   if (!nricMatch) {
//     console.log('NRIC basic validation failed.');
//     return false;
//   }

//   // Get NRIC type
//   const nricType = nricMatch[1];

//   // Return false if invalid NRIC type
//   if (!nricTypeRegex.test(nricType)) {
//     console.log('NRIC type validation failed.');
//     return false;
//   }

//   // Multiply each digit in the NRIC number by its weight
//   const nricDigitsArr = nricMatch[2].split('');
//   let total = 0;
//   for (let i = 0; i < nricDigitsArr.length; i++) {
//     total += parseInt(nricDigitsArr[i]) * weightArr[i];
//   }

//   // If NRIC type is T or G, add 4 to the total
//   if (['T', 'G'].includes(nricType)) {
//     total += 4;
//   }

//   // Check last letter of NRIC for Singapore locals
//   const letterIndex = total % 11;
//   const nricLetter = nricMatch[3];
//   if (['S', 'T'].includes(nricType)) {
//     console.log(`Singapore NRIC: expected ${nricLetterST[letterIndex]}, got ${nricLetter}`);
//     return nricLetterST[letterIndex] === nricLetter;
//   }

//   // Check last letter of NRIC for foreigners
//   if (['F', 'G'].includes(nricType)) {
//     console.log(`Foreign NRIC: expected ${nricLetterFG[letterIndex]}, got ${nricLetter}`);
//     return nricLetterFG[letterIndex] === nricLetter;
//   }

//   // Check last letter of NRIC for Malaysian NRICs
//   if (nricType === 'M') {
//     // Malaysian NRIC uses a similar but different checksum calculation
//     console.log(`Malaysian NRIC: expected ${nricLetterM[letterIndex]}, got ${nricLetter}`);
//     return nricLetterM[letterIndex] === nricLetter;
//   }

//   console.log('Default case reached.');
//   return false; // Default case
// }

function nricValidator(nricInput){
  return nric.validate(nricInput);
}



// // Example test
// console.log(nricValidator('M8177483P')); // Test with the provided NRIC


// // Example test
// console.log(nricValidator('M0825548W')); // Test with the provided NRIC





export { SERVER_URL, handleDownload, EncryptValue, downloadPdf, print,nricValidator };
