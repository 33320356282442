import { useRoutes } from 'react-router-dom';
import SamplePage from 'pages/extra-pages/SamplePage';
import ErrorPage from 'pages/extra-pages/ErrorPage';
import Routes from './routes';
import { useEffect } from 'react';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from 'store/index';
import { changePersona } from 'store/reducers/auth';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { showDanger } from 'components/nudges/Alert';

// ==============================|| ROUTING RENDER ||============================== //
let flag = false;

export default function ThemeRoutes() {
  const { previousVersion, currentVersion } = useSelector((state) => state.persistedReducer.constant);
  const { isAgendLogin, isUserLogin, activePersona } = useSelector((state) => state.persistedReducer.auth);
  const navigate = useNavigate();

  const { makeRequestWithoutLogin } = useApiCall();
  const location = useLocation();
  const network = useNetworkStatus();

  // useEffect(() => {
  //   if (!network) {
  //     showDanger('No internet connection.');
  //     // navigate('/');
  //   }
  // }, [network]);

  const getAccessToken = async () => {
    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }

      const body = {
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        refresh_token: process.env.REACT_APP_REFRESH_TOKEN,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET
      };
      const response = await makeRequestWithoutLogin({ method: 'POST', url: API.AccessTokenGet, body, skipDateTimeWarning: true });
      if (response.code === 200) {
        const token = response?.data?.access_token;
        localStorage.setItem('AppToken', JSON.stringify(token));
      }
    } catch (error) {
      console.error('Failed to fetch access token:', error);
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('AppToken');
    if (!storedToken) {
      if (!flag) {
        getAccessToken();
      }
    }
  }, [location, getAccessToken]);

  useEffect(() => {
    store.dispatch(changePersona());
    if (currentVersion > previousVersion) {
      if (!isAgendLogin) {
        if (activePersona != 'Customer') {
          localStorage.removeItem('@user');
          localStorage.removeItem('@login');
          localStorage.removeItem('AppToken');

          navigate('/');
        }
      }
    }
  }, []);
  let routes = Routes();
  return useRoutes([
    routes,
    {
      path: '/*',
      element: <SamplePage />
    },
    {
      path: '/error',
      element: <ErrorPage />
    }
  ]);
}
