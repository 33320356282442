import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useMediaQuery } from '../../../../../../node_modules/@mui/material/index';
import img1 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/Home icons/ANDA_Decades of-Trusted Expertise-14.svg'
import img2 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/Home icons/ANDA_Unparalleled-Service Standards.svg'
import img3 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/Home icons/ANDA_Strong Industry-Partnerships.svg'
import img4 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/Home icons/ANDA_Decades of-Trusted Expertise-17.svg'
import img5 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/Home icons/ANDA_Comprehensive-Coverage.svg'
import img6 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/Home icons/ANDA_One-stop Portal.svg'

const data = [
  {
    id: 1,
    img: img1,
    heading: 'Decades of Trusted Expertise',
    text: 'Over 30 years of trusted industry experience, providing tailored advice for your unique needs'
  },
  {
    id: 2,
    img: img2,
    heading: 'Unparalleled Service Standards',
    text: 'Exceeding expectations with seamless support at every step.'
  },
  {
    id: 3,
    img: img3,
    heading: 'Strong Industry Partnerships',
    text: 'Competitive prices secured through established industry relationships.'
  },
  {
    id: 4,
    img: img4,
    heading: 'Tailored Insurance Solutions',
    text: 'Crafted insurance solutions ensuring your business operates optimally.'
  },
  {
    id: 5,
    img: img5,
    heading: 'Comprehensive Coverage Options',
    text: 'Diverse insurance options for vehicles, domestic helpers, foreign workers, and companies.'
  },
  {
    id: 6,
    img: img6,
    heading: 'One-stop portal with account management facility',
    text: 'Aligned with innovation and excellence through one-stop portal with account management facility'
  }
];

export default function ButtonGrid() {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Grid item xs={12} mb={5} width="100vw" flexDirection="row" display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h4" align="center">
          Why Choose ANDA Insurance
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ textAlign: 'center' }}>
          <Grid container spacing={1} style={{ display: 'flex', width: '100vw ' }}>
            {data.map((item, index) => (
              <Grid item lg={4} md={6} xs={12} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  src={item.img}
                  alt="ANDA Logo_RGB"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '70px',
                    marginTop: '14px'
                  }}
                />
                <Typography
                  style={{
                    textAlign: 'center',
                    width: isMobile ? '100%' : '50%',
                    fontFamily: 'Medium',
                    fontWeight: '600',
                    marginTop: '2%'
                  }}
                >
                  {item.heading}
                </Typography>
                <div style={{ textAlign: 'center', width: isMobile ? '100%' : '50%', fontFamily: 'Medium' }}>{item.text}</div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </>
  );
}
