import React, { useEffect, useState } from 'react';
import { Grid, Button, IconButton, TextField, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import RiderSectionTable from 'modules/packages & premium/components/RiderSectionTable';
import ForeignWorkerAddWorkerModalCustomer from './ForeignWorkerAddWorkerModalCustomer';
import { useSelector } from 'react-redux';
import { store } from 'store/index';
import { addOtherIndividualState, removeOtherIndividualState } from 'store/reducers/premiumPolicy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ReusableCard from 'components/cards/ResuableCard';
import DropDown from 'components/dropdown/DropDown';
import OverlayLoader from 'components/loader/OverlayLoader';
import { saveAs } from 'file-saver';
import AddWorkerModal from './ForeignWorkerAddWorkerModalCustomer';
// import DownloadWorkerExcelButton from './DownloadWorkerExcelButton';

const RenderFunction = ({ item, index, otherState, dropdownData }) => {
  const [value, setValue] = useState(otherState[item?.value]);

  const onChangeText = (txt, value) => {
    try {
      store.dispatch(addOtherIndividualState([value, txt]));
      setValue(txt);
    } catch (e) {
      console.error(e);
    }
  };

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };

  return (
    <Grid item xs={12} md={4} key={index}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <InputLabel className="inputs_styless_">{item.label}</InputLabel>
        {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
      </div>

      {item.type === 'textField' ? (
        <TextField
          type={item?.texttype}
          onChange={(txt) => onChangeText(txt.target.value, item?.value)}
          required={item?.isRequired}
          size="small"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          className="txtinputs_stylingg"
          value={value}
          style={{ marginTop: '13px' }}
          inputProps={{
            pattern: /^\d{4}$/,
            max: new Date().toISOString().split('T')[0]
          }}
        />
      ) : (
        <></>
      )}
      {item?.type === 'dropdown' && (
        <div style={{ marginTop: '12px' }}>
          <DropDown
            width={'96%'}
            heightsss={'38px'}
            backgroundColor="#F8FAFB"
            options={item?.ddoptions || dropdownData}
            onValueChange={onValueChange}
            defaultValue={otherState[item?.value]}
          />
        </div>
      )}
    </Grid>
  );
};

const ForeignWorkerDetailsCustomer = ({ handleNextButton, handlePreviousButton, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [getCompanyData, setCompanyData] = useState([]);
  const [verificationMessage, setVerificationMessage] = useState('');
  const { workerSection } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { finalResponse } = useSelector((state) => state?.persistedReducer?.premiumPolicy);
  const { premiumCalculation } = useSelector((state) => state?.persistedReducer?.premiumPolicy);

  const totalWorkerTableSection = Object?.values(workerSection)?.length;

  const handleOpen = () => {
    setSelectedRow(null);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClickOnEdit = (worker) => {
    setSelectedRow(worker);
    setIsOpen(true);
  };

  const ForeignCompanyData = [
    { label: 'UEN Number', value: 'UENnoCompanyForeign', type: 'textField', astric: true },
    { label: 'Company Name', value: 'companyNameCompanyForeign', type: 'textField', astric: true },
    { label: 'Email Address', value: 'emailaddressCompanyForeign', type: 'textField', astric: true }
  ];

  useEffect(() => {
    if (otherState?.policytypeFWCustomer === 'Company') {
      setCompanyData(ForeignCompanyData);
    } else {
      setCompanyData([]);
    }
  }, [otherState?.policytypeFWCustomer]);

  useEffect(() => {
    const rows = Object?.values(workerSection)?.map((worker, index) => ({
      sr: index + 1,
      id: worker?.id,
      name: worker?.firstName + ' ' + worker?.lastName,
      nationality: worker?.nationality,
      dob: worker?.dob,
      fin: worker?.fin,
      wp: worker?.workPermit,
      Action: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#4cb748',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '55px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium'
            }}
            onClick={() => handleClickOnEdit(worker)}
          >
            <EditIcon style={{ fontSize: '15px', marginRight: '2.5px' }} />
            Edit
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#0174BF',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '75px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium',
              marginLeft: '10px'
            }}
            onClick={() => handleDelete(worker.id)}
          >
            <DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} />
            Delete
          </Button>
        </div>
      )
    }));
    setData(rows);
  }, [workerSection]);

  const handleDelete = (id) => {
    try {
      store.dispatch(removeOtherIndividualState(id));
    } catch (e) {
      console.error(e);
    }
  };

  // const handleDownload = () => {
  //   // Create sample data
  //   const data = [
  //     { name: 'John Doe', age: 30, email: 'john@example.com' },
  //     { name: 'Jane Doe', age: 25, email: 'jane@example.com' }
  //   ];

  //   // Convert JSON data to worksheet
  //   const worksheet = XLSX.utils.json_to_sheet(data);

  //   // Create a new workbook and append the worksheet
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  //   // Generate Excel file and trigger download
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   saveAs(blob, 'template.xlsx');
  // };

  // const handleDownload = () => {
  //   const url =
  //     'https://plus91labs1-my.sharepoint.com/:x:/g/personal/raj_plus91labs_com/EU_pwYrSv-tMj4GtRJfCqGMBFwL_VhH1TRsVsbKmk11oWA?e=GifQyY';
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'template.xlsx'); // You can set the file name here
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // };

  const handleDownload = () => {
    const fileUrl =
      'https://plus91labs1-my.sharepoint.com/:x:/g/personal/raj_plus91labs_com/EaFB0GGfl89Emau4X9xMGO8BfKdkSmS0sfRjbSULB5RDew?e=bvEvSB'; // Replace with your file URL
    saveAs(fileUrl, 'template.xlsx');
  };

  return (
    <div>
      {(!finalResponse?.qrCode || !premiumCalculation) && <OverlayLoader show={loading} />}
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'1%'}
        boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container p={2}>
            {getCompanyData.length > 0 && (
              <>
                <Grid item xs={12}>
                  <p style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '700' }}>Company Details</p>
                </Grid>
                {getCompanyData.map((item, index) => (
                  <RenderFunction
                    key={index}
                    item={item}
                    index={index}
                    setVerificationMessage={setVerificationMessage}
                    verificationMessage={verificationMessage}
                    otherState={otherState}
                  />
                ))}
              </>
            )}

            <Grid item xs={12} sm={12} md={12} lg={12} mt={1.5} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '700' }}>Workers Detail</span>
              <Grid item xs={12} sm={7} md={7} lg={4} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                {/* <a
                  href="https://plus91labs1-my.sharepoint.com/:x:/g/personal/raj_plus91labs_com/EarEDxySyhJBnEz2lUpAWm8Bdey-0b_uoKxOEymyWPx1lQ?e=lCuvcs"
                  download="workerDetails.xls"
                  style={{ width: '100%', margin: '1%' }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#0174BF', width: '100%', borderRadius: '5px', padding: '10px', margin: '1%' }}
                    // onClick={handleDownload}
                  >
                    Download Template
                  </Button>
                </a> */}
                <Grid item xs={6} md={6} sm={6} lg={6} style={{ width: '100%' }}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#0174BF', borderRadius: '5px', padding: '10px' }}
                    onClick={handleDownload}
                  >
                    Download Template
                  </Button>
                </Grid>
                {/* <DownloadWorkerExcelButton
                  path="https://plus91labs1-my.sharepoint.com/:x:/g/personal/raj_plus91labs_com/EarEDxySyhJBnEz2lUpAWm8Bdey-0b_uoKxOEymyWPx1lQ?e=lCuvcs"
                  fileName="workerExcel.xls"
                /> */}
                <Grid item xs={6} md={6} sm={6} lg={6} style={{ width: '100%' }}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#00C7B1', width: '100%', borderRadius: '5px', padding: '10px' }}
                    onClick={handleOpen}
                  >
                    + Add Worker
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid container spacing={2} xs={12} md={12} mt={2} mb={2}>
                {data.length > 0 ? (
                  <RiderSectionTable columns={ForeignAddWorkerTableColumns} rows={data} />
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Typography>No Worker Added.</Typography>
                  </div>
                )}
              </Grid>

              <Grid spacing={2} xs={12} md={12} lg={12} mt={12} mb={2}>
                {otherState?.noofworkerFWCustomer > 2 && (
                  <>
                    <p>
                      {`You can also download the Excel template and send the worker details separately via email by filling out the sheet. Please send the completed worker details to `}
                      <a href="fw@anda.com.sg">fw@anda.com.sg</a>
                    </p>
                  </>
                )}
              </Grid>

              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={2.5}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{
                      backgroundColor: '#f7f7f7',
                      width: '100%',
                      padding: '3%',
                      color: '#000000',
                      height: '100%',
                      border: '1px solid #ddd'
                    }}
                    onClick={handlePreviousButton}
                  >
                    Previous
                  </Button>
                </Grid>
                <Grid item xs={12} md={2.5}>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={totalWorkerTableSection === 0}
                    style={{
                      backgroundColor: totalWorkerTableSection === 0 ? 'grey' : '#002D8C',
                      width: '100%',
                      padding: '3%',
                      color: '#ffffff',
                      height: '100%'
                    }}
                    onClick={handleNextButton}
                  >
                    Proeed To Payment
                    {/* <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
                      <EastIcon />
                    </IconButton> */}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <AddWorkerModal isOpen={isOpen} onClose={handleClose} selectedRow={selectedRow} />
          </Grid>
        }
      />
    </div>
  );
};

export default ForeignWorkerDetailsCustomer;

const ForeignAddWorkerTableColumns = [
  { id: 'sr', label: 'Sr.', minWidth: 30, align: 'center' },
  { id: 'name', label: 'Name', minWidth: 120, align: 'center' },
  { id: 'nationality', label: 'Nationality', minWidth: 100, align: 'center' },
  { id: 'dob', label: 'DOB', minWidth: 100, align: 'center' },
  { id: 'fin', label: 'FIN', minWidth: 100, align: 'center' },
  { id: 'wp', label: 'Work Permit ', minWidth: 100, align: 'center' },
  { id: 'Action', label: 'Action', minWidth: 100, align: 'left' }
];

// const ForeignCompanyData = [
//   { label: 'UEN Number', value: 'UENnoCompanyForeign', type: 'textField', astric: true },
//   { label: 'Company Name', value: 'companyNameCompanyForeign', type: 'textField', astric: true },
//   { label: 'Email Address', value: 'emailaddressCompanyForeign', type: 'textField', astric: true }
// ];
