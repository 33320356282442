import '../styles/InformationForm.css';
import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useSelector } from 'react-redux';

const CardLayout = ({
  heading = 'Heading',
  children,
  headerButtonTitle,
  isTopButtonVisible = false,
  handleClickOnTopButton = () => {},
  setIsOpen,
  isOpen,
  toggleForm = () => {}
}) => {
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const isCompanyRegistered = otherState?.isCompanyRegistered;

  return (
    <div className="containerrr">
      <div className={`form-headerrr ${isOpen ? 'open' : 'closed'}`}>
        <button
          onClick={() => toggleForm()}
          style={{
            width: '48%',
            marginLeft: '5px',
            fontFamily: 'Medium',
            color: '#000',
            fontSize: 14,
            backgroundColor: '#ffffff',
            border: 'none',
            cursor: 'pointer',
            padding: '0',
            textAlign: 'left'
          }}
        >
          {heading}
        </button>
        <span style={{ marginRight: '5px' }}>
          {isTopButtonVisible && (
            <Button
              size="medium"
              onClick={() => handleClickOnTopButton()}
              sx={{
                backgroundColor: isCompanyRegistered ? 'grey' : '#00C7B1',
                color: 'white',
                fontFamily: 'Medium',
                padding: 0,
                height: '40px',
                width: '95px',
                fontSize: '13px',
                marginLeft:'-10px',
                '&:hover': {
                  backgroundColor: isCompanyRegistered ? 'grey' : '#00C7B1'
                }
              }}
              disabled={isCompanyRegistered}
            >
              {headerButtonTitle}
            </Button>
          )}
          {isOpen ? (
            <KeyboardArrowUpOutlinedIcon style={{ fontSize: '36px', color: '#ABACB0', cursor: 'pointer' }} onClick={() => toggleForm()} />
          ) : (
            <KeyboardArrowDownOutlinedIcon style={{ fontSize: '36px', color: '#ABACB0', cursor: 'pointer' }} onClick={() => toggleForm()} />
          )}
        </span>
      </div>

      {isOpen && (
        <form className="form-bodyy">
          <Grid container spacing={2}>
            {children}
          </Grid>
        </form>
      )}
    </div>
  );
};

export default CardLayout;
