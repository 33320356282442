// types
import { createSlice } from '@reduxjs/toolkit';
import { currentDate1, formattedDate } from 'modules/packages & premium/static';

let initialObj = {
  //agent fields
  UEM: '',
  UEMSTATUS: null,
  POIStartDate: currentDate1,
  POIEndDate: formattedDate,
  isCompanyRegistered: false,
  isFoodParcel: false,
  businessType: 0,
  cm: '',
  email: '',
  phone: '',
  vehicleId: '',
  cckw: '',
  ageOfVehicle: '',
  vehicleRegistration: '',
  chasisNumber: '',
  engineNumber: '',
  nameOfHirePurchase: '',
  workShopType: 'Authorised Workshop',
  NCD: '',
  claimDetails: '',
  claimcount: '',
  claimamount: '',
  RegistrationDate: '',
  riderDataSection: {},
  transactionType: 'New',
  basisofcover: '',
  maincontractor: '',
  uen: '',
  CPF: '',
  dischargeDate: '',
  transmissionstatus: '',
  bondcall: '',
  bondduration: '',
  LOIstatus: '',
  LOItype: '',
  fromDate: currentDate1,
  todate: '',
  durationInMonths: '',
  durationInDays: '',
  subcategory: '',
  durationwise: '',
  addOn: [],
  workerSection: {},
  name: '',
  gender: '',
  nationality: '',
  nric: '',
  dob: '',
  phone: '',
  email: '',
  address: '',
  addOn: [],
  ndw: '',
  lastname: '',
  fin: '',
  passportno: '',
  workpermit: '',
  nationalityDHInformation: '',
  UENDHInsurance: '',
  fromdateDHInsurance: currentDate1,
  noofmonthsDHInsurance: '',
  noofdaysDHInsurance: '',
  todateDHinsurance: '',
  subcategoryDHInsurance: '',
  compnaynameDHInsurance: '',
  emailDHInsurance: '',
  phoneDHInsurance: '',
  ownerGender: '',
  ownerDob: '',
  ownerAge: '',
  ownerLicense: '',
  ownerExperience: '',

  //customerfield

  CustomerriderDataSection: {},

  policytype: '',
  dhcustomergender: '',
  dhcustomernationality: ''
};
// initial state
const initialState = {
  activeParams: {},
  uenData: {},
  otherState: initialObj,
  policy: {},
  activeSteps: 0,
  flag: false,
  isPremiumCalculated: false,
  isPremiumCalculatedForeignWorker: false,
  premiumCalculation: {},
  finalResponse: {},
  showMotorVehicleForm: false,
  isWrongDateTimeModalOpen: false, // Add this line
  isInactivityTokenModalOpen: false, // Add this line
  motorQrRefrenceNumber: '',
  profileSteps: 0
};

// ==============================|| SLICE - premiumPolicy ||============================== //

const premiumPolicy = createSlice({
  name: 'premiumPolicy',
  initialState,
  reducers: {
    addActiveParams(state, action) {
      state.activeParams = action.payload;
    },
    removeActiveParams(state) {
      state.activeParams = {};
    },
    addOtherIndividualState(state, action) {
      state.otherState[action.payload[0]] = action.payload[1];
    },
    addOtherCompleteState(state, action) {
      state.otherState = action.payload;
    },
    addRiderData(state, action) {
      let obj = { ...state.otherState['riderDataSection'] };
      let tempObj = {};
      tempObj[action.payload[0]] = action.payload[1];
      let newObj = { ...obj, ...tempObj };
      state.otherState = { ...state.otherState, ['riderDataSection']: newObj };
    },
    CustomerAddRiderData(state, action) {
      let obj = { ...state.otherState['CustomerriderDataSection'] };
      let tempObj = {};
      tempObj[action.payload[0]] = action.payload[1];
      let newObj = { ...obj, ...tempObj };
      state.otherState = { ...state.otherState, ['CustomerriderDataSection']: newObj };
    },
    addWorkerSection(state, action) {
      let obj = { ...state.otherState['workerSection'] };
      let tempObj = {};
      tempObj[action.payload[0]] = action.payload[1];
      let newObj = { ...obj, ...tempObj };
      state.otherState = { ...state.otherState, ['workerSection']: newObj };
    },

    addOtherState(state, action) {
      state.otherState = action.payload;
    },
    removeOtherIndividualState(state, action) {
      delete state.otherState['workerSection'][action.payload];
    },
    removeOtherIndividualState1(state, action) {
      delete state.otherState['riderDataSection'][action.payload];
    },
    removeOtherIndividualState2(state, action) {
      delete state.otherState['CustomerriderDataSection'][action.payload];
    },
    removeOtherState(state, action) {
      state.otherState = initialObj;
    },

    addUenData(state, action) {
      state.uenData = {};
      state.uenData = action.payload;
    },
    removeUenData(state) {
      state.uenData = {};
    },
    addPolicyData(state, action) {
      state.policy = action.payload;
    },
    removePolicyData(state) {
      state.policy = {};
    },

    removeAllRenewalData(state) {
      state.uenData = {};
      state.policy = {};
      state.otherState = initialObj;
      state.activeParams = {};
    },
    AddaddOnsData(state, action) {
      let obj = { ...state.otherState };
      let existedAddons = { ...obj.addOn };
      existedAddons = { ...existedAddons, [action.payload[0]]: action.payload[1] };
      let updatedState = { ...obj, ['addOn']: existedAddons };
      state.otherState = updatedState;
    },
    removeAddOnData(state, action) {
      let obj = { ...state.otherState };
      let existedAddons = { ...obj.addOn };
      delete existedAddons[action.payload];
      let updatedState = { ...obj, ['addOn']: existedAddons };
      state.otherState = updatedState;
    },
    setActiveStep(state, action) {
      state.activeSteps = action.payload;
    },
    setShowMotorVehicleForm(state, action) {
      state.showMotorVehicleForm = action.payload;
    },
    clearRiderDataSection(state) {
      state.otherState.riderDataSection = {};
    },
    setPremiumCalculated(state, action) {
      state.isPremiumCalculated = action.payload;
    },
    setPremiumCalculatedForeignWorker(state, action) {
      state.isPremiumCalculatedForeignWorker = action.payload;
    },
    premiumCalculationResponse(state, action) {
      state.premiumCalculation = action.payload;
    },
    finalSubmissionResponse(state, action) {
      state.finalResponse = action.payload;
    },
    resetActiveSteps: (state) => {
      state.activeSteps = 0;
    },
    setWrongDateTimeModalOpen(state, action) {
      state.isWrongDateTimeModalOpen = action.payload;
    },
    setInactivityTokenModalOpen(state, action) {
      state.isInactivityTokenModalOpen = action.payload;
    },
    setMotorQrRefrenceNumber(state, action) {
      // Add this line
      state.motorQrRefrenceNumber = action.payload; // Add this line
    },
    clearCustomerRiderData(state) {
      state.otherState.CustomerriderDataSection = {};
    },
    setProfileStep(state, action) {
      state.profileSteps = action.payload;
    },
    resetProfileSteps: (state) => {
      state.profileSteps = 0;
    }
  }
});

export default premiumPolicy.reducer;

export const {
  addActiveParams,
  removeActiveParams,
  addOtherIndividualState,
  addOtherState,
  removeOtherIndividualState,
  removeOtherState,
  setSelectedAddons,
  addUenData,
  removeUenData,
  addWorkerSection,
  clearRiderDataSection,
  addOtherCompleteState,
  addRiderData,
  removeOtherIndividualState1,
  addPolicyData,
  removePolicyData,
  removeAllRenewalData,
  AddaddOnsData,
  removeAddOnData,
  setActiveStep,
  clearClaimsAmount,
  CustomerAddRiderData,
  setFlag,
  setPremiumCalculated,
  premiumCalculationResponse,
  finalSubmissionResponse,
  setShowMotorVehicleForm,
  resetActiveSteps,
  removeOtherIndividualState2,
  setPremiumCalculatedForeignWorker,
  setWrongDateTimeModalOpen,
  setInactivityTokenModalOpen,
  setMotorQrRefrenceNumber,
  clearCustomerRiderData,
  setProfileStep,
  resetProfileSteps
} = premiumPolicy.actions;
