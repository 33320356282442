// import React from 'react';
// import { Grid, Button, TextField, IconButton } from '@mui/material';
// import sliderpicture1 from '../../../../../assets/images/Anda New Images/ANDA_homepage_HomepageBanner.png';
// import NavBar from './NavBar';
// import Footer from './Footer';

// const AboutAndaTemplate = () => {
//   return (
//     <Grid xs={12} md={12} lg={12} mb={2} container style={{ display: 'flex', justifyContent: 'center' }}>
//       <Grid item xs={12} backgroundColor="#ffffff">
//         <NavBar />
//       </Grid>
//       <div
//         style={{
//           position: 'absolute',
//           display: 'flex',
//           justifyContent: 'center',
//           width: '80%',
//           alignItems: 'center',
//           flexDirection: 'column',
//           marginTop: '1%',
//           backgroundColor: '#ffffff'
//         }}
//       >
//         {/* <p
//           style={{
//             fontSize: '20px',
//             fontFamily: 'Medium',
//             fontWeight: '800',
//             textDecorationLine: 'underline',
//             //   textDecorationColor: '#000',
//             textDecorationThickness: '2px'
//           }}
//         >
//           {' '}
//           About Anda
//         </p> */}
//         <div style={{ position: 'relative', width: '100%', height: '35vh' }}>
//           <img src={sliderpicture1} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
//           <div
//             style={{
//               position: 'absolute',
//               top: '20%',
//               left: '35%',
//               transform: 'translateX(-50%)',
//               textAlign: 'center',
//               color: 'white',
//               zIndex: 1
//             }}
//           >
//             <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium' }}>About Anda</p>
//             <hr />
//             {/* <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored,Reliable,Always Ready</p> */}
//           </div>
//         </div>

//         <Grid
//           item
//           xs={12}
//           md={10}
//           sm={12}
//           lg={10}
//           style={{ marginTop: '3%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column' }}
//         >
//           <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
//             {
//               'ANDA is a leading insurance intermediary who has been delighting clients with unparalleled level of service for three decades. Leveraging on its strong and established relationships with industry partners, ANDA has built its reputation on providing a wide array of insurance solutions at competitive prices. '
//             }
//           </p>
//           <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
//             {
//               'We deliver value in creating bespoke insurance solutions that provide the necessary coverage for clients’ businesses to operate optimally. We ensure you have the cover you really need. '
//             }
//           </p>
//           <p
//             style={{
//               fontSize: '14px',
//               fontFamily: 'Medium',
//               fontWeight: '800',
//               textDecorationLine: 'underline',
//               //   textDecorationColor: '#000',
//               textDecorationThickness: '2px'
//             }}
//           >
//             {'Why ANDA Insurance '}
//           </p>
//           <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
//             {
//               'With more than 30 years of experience’ in securing our customers’ insurance needs, we are committed to providing you with unparalleled service! '
//             }
//           </p>
//           <ul>
//             <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Competitive Premiums</li>
//             <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Get your Policy Documents issued instantly</li>
//             <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Assured and Dedicated Service</li>
//           </ul>
//         </Grid>
//       </div>
//       <Grid item xs={12} mt={2}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default AboutAndaTemplate;

// import React from 'react';
// import { Grid } from '@mui/material';
// import sliderpicture1 from '../../../../../assets/images/Anda New Images/ANDA_homepage_HomepageBanner.png';
// import NavBar from './NavBar';
// import Footer from './Footer';

// const AboutAndaTemplate = () => {
//   return (
//     <Grid container direction="column" style={{ minHeight: '100vh', backgroundColor: '#ffffff' }}>
//       {/* NavBar at the top */}
//       <Grid item xs={12}>
//         <NavBar />
//       </Grid>

//       {/* Content Section */}
//       <Grid item xs={12} style={{ flexGrow: 1 }}>
//         <div
//           style={{
//             position: 'relative',
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             backgroundColor: '#ffffff'
//           }}
//         >
//           {/* Image with text on bottom 10% */}
//           <div style={{ position: 'relative', width: '100%', height: '35vh' }}>
//             <img src={sliderpicture1} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
//             <div
//               style={{
//                 position: 'absolute',
//                 bottom: '50%', // Start text from bottom 10%
//                 left: '45%',
//                 transform: 'translateX(-50%)',
//                 textAlign: 'center',
//                 color: 'white',
//                 zIndex: 1,
//                 width: '80%' // Constrain the width of the text
//               }}
//             >
//               <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium' }}>About Anda</p>
//               <hr />
//               {/* <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored,Reliable,Always Ready</p> */}
//             </div>
//           </div>

//           {/* Text Content Section */}
//           <Grid
//             item
//             xs={12}
//             md={10}
//             sm={12}
//             lg={10}
//             style={{
//               marginTop: '3%',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'start',
//               flexDirection: 'column',
//               top:'40%',
//               position: 'absolute',
//               backgroundColor:'#ffffff',
//               border:'1px solid #ffffff',
//               borderRadius:'12px',
//               padding:'4%'
//             }}
//           >
//             <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
//               {
//                 'ANDA is a leading insurance intermediary who has been delighting clients with unparalleled level of service for three decades. Leveraging on its strong and established relationships with industry partners, ANDA has built its reputation on providing a wide array of insurance solutions at competitive prices. '
//               }
//             </p>
//             <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
//               {
//                 'We deliver value in creating bespoke insurance solutions that provide the necessary coverage for clients’ businesses to operate optimally. We ensure you have the cover you really need. '
//               }
//             </p>
//             <p
//               style={{
//                 fontSize: '14px',
//                 fontFamily: 'Medium',
//                 fontWeight: '800',
//                 textDecorationLine: 'underline',
//                 textDecorationThickness: '2px'
//               }}
//             >
//               {'Why ANDA Insurance '}
//             </p>
//             <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
//               {
//                 'With more than 30 years of experience’ in securing our customers’ insurance needs, we are committed to providing you with unparalleled service! '
//               }
//             </p>
//             <ul>
//               <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Competitive Premiums</li>
//               <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Get your Policy Documents issued instantly</li>
//               <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Assured and Dedicated Service</li>
//             </ul>
//           </Grid>
//         </div>
//       </Grid>

//       {/* Footer at the bottom */}
//       <Grid item xs={12}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default AboutAndaTemplate;

import React from 'react';
import { Grid } from '@mui/material';
import sliderpicture1 from '../../../../../assets/images/Anda New Images/ANDA_homepage_HomepageBanner.png';
import NavBar from './NavBar';
import Footer from './Footer';
import aboutus from '../../../../../assets/images/Anda New Images/aboutus.png';
import { Typography } from '../../../../../../node_modules/@mui/material/index';

const AboutAndaTemplate = () => {
  return (
    <Grid
      container
      direction="column"
      style={{ minHeight: '100vh', backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column' }}
      wrap="nowrap" // Prevents the Grid from wrapping
    >
      {/* NavBar at the top */}
      <Grid item xs={12}>
        <NavBar />
      </Grid>

      {/* Content Section */}
      <Grid
        item
        xs={12}
        style={{
          flexGrow: 1, // This makes the content section expand to fill available space
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div style={{ width: '100%', position: 'relative' }}>
          {/* Image with text on bottom 10% */}
          <img src={sliderpicture1} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          <div
            style={{
              position: 'absolute',
              bottom: '62%', // Start text from bottom 10% of the image
              left: '50%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
              color: 'white',
              zIndex: 1,
              width: '100%' // Constrain the width of the text
            }}
          >
            <Typography style={{ fontSize: 'clamp(16px, 6vw, 45px)', fontFamily: 'Medium' }}>About Us</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
              <div
                style={{
                  width: '13%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottom: '1.5px solid #ffffff'
                }}
              ></div>
              <div
                style={{
                  width: '13%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottom: '1.5px solid #ffffff',
                  height: '3px'
                }}
              ></div>
            </div>
          </div>
        </div>

        {/* Text Content Section Positioned Over Image */}
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={10}
          style={{
            width: '100%',
            backgroundColor: '#ffffff',
            border: '1px solid #ffffff',
            borderRadius: '12px',

            marginTop: '-8%', // Pull up the text content over the image
            zIndex: 2, // Ensure the content appears above the image
            position: 'relative',
            display: 'flex'
          }}
        >
          <Grid item xs={12} md={12} sm={12} lg={6} padding="1%">
            <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
              {
                'ANDA is a leading insurance intermediary who has been delighting clients with unparalleled level of service for three decades. Leveraging on its strong and established relationships with industry partners, ANDA has built its reputation on providing a wide array of insurance solutions at competitive prices.'
              }
            </p>
            <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
              {
                'We deliver value in creating bespoke insurance solutions that provide the necessary coverage for clients’ businesses to operate optimally. We ensure you have the cover you really need.'
              }
            </p>
            <p
              style={{
                fontSize: '14px',
                fontFamily: 'Medium',
                fontWeight: '800',
                textDecorationLine: 'underline',
                textDecorationThickness: '2px'
              }}
            >
              {'Why ANDA Insurance '}
            </p>
            <p style={{ fontSize: '14px', fontFamily: 'Medium' }}>
              {
                'With more than 30 years of experience’ in securing our customers’ insurance needs, we are committed to providing you with unparalleled service! '
              }
            </p>
            <ul>
              <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Competitive Premiums</li>
              <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Get your Policy Documents issued instantly</li>
              <li style={{ fontSize: '14px', fontFamily: 'Medium' }}>Assured and Dedicated Service</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={6} display="flex" justifyContent="end">
            <img src={aboutus} alt="" style={{ width: '100%', padding: '1%' }} />
          </Grid>
        </Grid>
      </Grid>

      {/* Footer at the bottom */}
      <Grid item xs={12} style={{ marginTop: 'auto' }}>
        {' '}
        {/* marginTop: 'auto' pushes the footer to the bottom */}
        <Footer />
      </Grid>
    </Grid>
  );
};

export default AboutAndaTemplate;
