import React, { useEffect, useState } from 'react';
import { Grid } from '../../../../../node_modules/@mui/material/index';
import Stepper from '../../domestic helper policy view/components/Stepper';
import ForeignWorkerCustomerPackageInformation from './ForeignWorkerCustomerPackageInformation';
import ForeignWorkerDetailsCustomer from './ForeignWorkerDetailsCustomer';
import ForeignWorkerPremiumAmount from './ForeignWorkerPremiumAmount';
import { store } from 'store/index';
import { usePostFWDHCalculationMutation, usePostFinalSubmissionMutation } from 'store/services/DomesticHelperCustomerRTK';
import {
  finalSubmissionResponse,
  premiumCalculationResponse,
  removeOtherState,
  resetActiveSteps,
  setActiveStep,
  setPremiumCalculated,
  setPremiumCalculatedForeignWorker
} from 'store/reducers/premiumPolicy';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import { useSelector, useDispatch } from 'react-redux';
import useNetworkStatus from 'hooks/useNetworkStatus';
import OTPResetModal from 'components/modals/contentmodal/OTPResetModal';
import CustomerOtpModal from 'modules/customer/customerOTP/CustomerOtpModal';
import CustomerResetPassword from 'modules/customer/customerpasswordreset/CustomerResetPassword';
import CustomerLoginModal from 'modules/customer/corporate/customerlandingpage/components/CustomerLoginModal';
import ForeignWorkerQRCode from './ForeignWorkerQRCode';
import { getLoginDetailsId, getUserId } from 'helper/utils';

const ForeignWorkerPolicyCoverages = () => {
  const dispatch = useDispatch();
  const tsaTypeParam = 'Foreign Worker';
  const { makeRequestWithoutLogin } = useApiCall();
  const network = useNetworkStatus();
  const activeSteps = useSelector((state) => state.persistedReducer.premiumPolicy.activeSteps);
  const { otherState, activeParams, premiumCalculation } = useSelector((state) => state?.persistedReducer?.premiumPolicy);
  const { addOn } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const { workerSection } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const [productAmount, setProductAmount] = useState('');
  const [show, setShow] = useState(false);
  const [resetShow, setResetShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [emailId, setEmailId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeSteps]);

  const validateEmail = (email) => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email) && !email.includes('..');
  };

  const [postFWDHCalculation, { error: postError }] = usePostFWDHCalculationMutation();
  const [postFinalSubmission, { error: finalError }] = usePostFinalSubmissionMutation();

  const handleFWDHCalculationPost = async () => {
    try {
      let userId = await getUserId();
      let loginDetailsId = await getLoginDetailsId();

      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      const currentDate = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD
      const fromDate = otherState?.fromDate;

      if (fromDate && fromDate < currentDate) {
        setLoading(false);
        showWarning('Start date cannot be earlier than today.');
        return;
      }
      if (!otherState?.policytypeFWCustomer) {
        setLoading(false);
        showWarning('Please fill Required Fields');
        return;
      } else if (!otherState?.noofworkerFWCustomer) {
        setLoading(false);
        showWarning('Please fill Required Fields');
        return;
      } else {
        let body = {
          userId: userId || null,
          productId: activeParams?.productID,
          loginDetailsId: loginDetailsId || 0,
          basisOfCover: null,
          nameOfMainContractor: null,
          uenNumber: null,
          companyName: null,
          email: null,
          phone: null,
          cpfSubmissionNo: null,
          dischargeDate: null,
          transmissionStatus: null,
          bondCallStatus: null,
          bondDuration: null,
          loiStatus: null,
          loiType: null,
          fromDate: otherState.fromDate,
          toDate: otherState?.todate,
          durationInMonth: activeParams?.duration,
          durationInDays: null,
          subCategory: null,
          totalRiderCount: otherState?.noofworkerFWCustomer,
          addonFWDHProductPricingListId: Object?.keys(addOn)?.length > 0 ? Object?.keys(addOn)?.map((key) => key) : ['0']
        };

        const res = await postFWDHCalculation(body).unwrap();
        if (res?.code === 200) {
          // setPremiumResponse(res);
          setLoading(false);
          store.dispatch(premiumCalculationResponse(res?.data));
          setProductAmount(res?.data);
          dispatch(setActiveStep(activeSteps + 1));
        } else {
          showDanger('Error');
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      showDanger('Error');
    }
  };

  const handleFinalSubmissionDHCustomer = async () => {
    let userData = localStorage.getItem('@user');
    let user = JSON.parse(userData);
    let userID = user?.userId;
    const totalWorkerTableSection = Object?.values(workerSection)?.length;

    let obj = {
      itemCode: `${activeParams.productID}`,
      salesPrice: productAmount?.productAmount,
      quantity: 1,
      isAdd_On: false
    };
    const arr = [];
    {
      arr?.push(obj);
      if (addOn !== undefined) {
        Object.values(addOn)?.map((itm) => {
          arr.push({
            itemCode: `${itm?.itemCode}`,
            salesPrice: itm?.salesPrice * Number(otherState?.noofworkerFWCustomer || 0),
            quantity: 1,
            isAdd_On: true
          });
        });
      }
    }

    const requestBodyWorker = Object.values(workerSection)?.map((worker) => {
      return {
        firstName: worker?.firstName || null,
        lastName: worker?.lastName || null,
        nric: worker?.nric || null,
        dob: worker?.dob || null,
        gender: worker?.gender || null,
        nationality: worker?.nationality || null,
        contact: worker?.contactNumber || null,
        email: worker?.email || null,
        workPermitNo: worker?.workPermit || null,
        passportNo: worker?.passport || null,
        fin: worker?.fin || null,
        active: true
      };
    });
    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      if (!otherState?.policytypeFWCustomer) {
        setLoading(false);
        // setIsButtonDisabled(false);
        showWarning('Please fill in all required fields');
        return; // Add return here to exit the function
      } else if (
        (otherState?.policytypeFWCustomer === 'Company' && !otherState?.UENnoCompanyForeign) ||
        (otherState?.policytypeFWCustomer === 'Company' && !otherState?.companyNameCompanyForeign) ||
        (otherState?.policytypeFWCustomer === 'Company' && !otherState?.emailaddressCompanyForeign)
      ) {
        setLoading(false);
        // setIsButtonDisabled(false);
        showWarning('Please fill in all required Company Detail fields');
        return; // Add return here to exit the function
      } /* else if (otherState?.noofworkerFWCustomer != totalWorkerTableSection) {
        setLoading(false);
        // setIsButtonDisabled(false);
        showWarning('Please fill all worker details!');
        return; // Add return here to exit the function
      } */ else if (!validateEmail(otherState?.emailaddressCompanyForeign) && otherState?.policytypeFWCustomer === 'Company') {
        showWarning('Please enter a valid Email Address! ');
        setLoading(false);
      } else {
        setLoading(true);
        let body = {
          nric: user?.nricNo,
          uen: otherState?.UENnoCompanyForeign || null,
          isIndividual: otherState?.UENnoCompanyForeign ? false : true,
          insuranceStart: otherState?.fromDate,
          insuranceEnd: otherState?.todate,
          opportunityType: tsaTypeParam,
          portalUserId: userID,
          creditTerm: otherState?.UENnoCompanyForeign ? true : false,
          durationInMonths: `${activeParams?.duration} Months`,
          transactionType: null,
          companyRegistered: otherState?.UENnoCompanyForeign ? true : false,
          vehicleId: null,
          vrn: null,
          coverageType: null,
          deliveryExtension: null,
          chasisNo: null,
          engineNo: null,
          drivingLicenseDate: null,
          excess: 0,
          claimsDetails: null,
          nameOfHirePurchase: null,
          workshopType: null,
          ncdAmount: 0,
          ncd: null,
          reg_manufactureDate: null,
          bondDuration: null,
          reTransmissionReminder: null,
          reviewedByInsurer: true,
          basisOfCover: null,
          mainContractorName: null,
          cpfSubmissionNo: null,
          transmissionStatus: null,
          dischargeDate: null,
          loiStatus: null,
          bondCallStatus: null,
          loiType: null,
          account: {
            accName: otherState?.companyNameCompanyForeign || null,
            accEmail: otherState?.emailaddressCompanyForeign || null,
            phone: null,
            source: null,
            bankAccountName: null,
            bankCode: null,
            bankName: null,
            bankAccountNo: null,
            street: null,
            city: null,
            state: null,
            postalCode: null,
            country: null,
            uen: otherState?.UENnoCompanyForeign || null
          },
          contact: {
            nricNo: user?.nricNo || null,
            firstName: user?.firstName || null,
            lastName: user?.lastName || null,
            gender: null,
            email: user?.finance_Email__c || null,
            maritalStatus: null,
            source: null,
            mobile: null,
            designation: null,
            occupation: null,
            licensePassDate: '',
            street: null,
            city: null,
            state: null,
            postalCode: null,
            country: null,
            remarks: null
          },
          items: arr,
          insuredParty: requestBodyWorker,
          productId: productAmount?.productId,
          loginDetailsId: productAmount?.loginDetailsId || 0,
          customerPolicyId: null,
          subCategory: null,
          opportunityId: null,
          totalPremiumAmount: premiumCalculation?.premiumWithGST
        };

        const res = await postFinalSubmission(body).unwrap();
        if (res?.code === 200) {
          setLoading(true);
          showSuccess('Policy request saved successfully.');
          store.dispatch(finalSubmissionResponse(res?.data));
          dispatch(setActiveStep(activeSteps + 1));
        } else {
          setLoading(false);
          showDanger('Error');
        }
      }
    } catch (error) {
      console.error(error);
      showDanger('Error');
      setLoading(false);
    }
  };

  const handleNextButton = async () => {
    if (activeSteps === 0) {
      // setLoading(true);
      handleFWDHCalculationPost();
      // dispatch(setActiveStep(activeSteps + 1));
    } else if (activeSteps === 2) {
      let loginFlag = localStorage.getItem('@login');
      if (!loginFlag) {
        setShow(true);
      } else {
        setShow(false);
        // setLoading(true);
        handleFinalSubmissionDHCustomer();
        // dispatch(setActiveStep(activeSteps + 1));
      }
    } else {
      dispatch(setActiveStep(activeSteps + 1));
    }
  };

  const handlePreviousButton = () => {
    if (activeSteps === 0) {
      // handleGoBack();
      store.dispatch(removeOtherState());
      store.dispatch(resetActiveSteps());
      store.dispatch(setPremiumCalculatedForeignWorker(false));
      store.dispatch(premiumCalculationResponse());
      store.dispatch(finalSubmissionResponse());
      setLoading(false);
      const isCalculated = false;
      store.dispatch(setPremiumCalculated(isCalculated));
    } else {
      setLoading(false);
      dispatch(setActiveStep(activeSteps - 1));
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleResetPasswordModalOpen = () => {
    setResetShow(true);
    setShow(false);
  };

  // const handleCloseResetModal = () => {
  //   setIsOpen(false);
  //   setShow(true);
  // };

  // const handleOTP = ({ OTP, UserId, emailId }) => {
  //   setEmailId(emailId);
  //   setUserId(UserId);
  //   setResetShow(false);
  //   setOtpShow(true);
  // };

  // const handleVerifyOtp = () => {
  //   setOtpShow(false);
  //   setIsOpen(true);
  // };

  // const handleClickOnResendOTP = async () => {
  //   if (network) {
  //     try {
  //       let params = {
  //         UserId: userId,
  //         emailId: emailId
  //       };
  //       let res = await makeRequestWithoutLogin({ method: 'POST', url: API.PasswordChangeOTPSent, params });
  //       if (res?.code == 200) {
  //         showSuccess('OTP sent successfully');
  //       } else {
  //         showWarning(res?.message);
  //       }
  //     } catch (e) {
  //       showDanger(`${e}`);
  //     }
  //   } else {
  //     showDanger('No internet');
  //   }
  // };

  const renderPageComponent = () => {
    switch (activeSteps) {
      case 0:
        return <ForeignWorkerCustomerPackageInformation handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 1:
        return <ForeignWorkerPremiumAmount handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 2:
        return (
          <ForeignWorkerDetailsCustomer loading={loading} handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />
        );
      case 3:
        return <ForeignWorkerQRCode handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;

      default:
        return null;
    }
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
        // marginTop: '20px'
      }}
    >
      <Grid item xs={12} md={12} lg={9} style={{ position: 'relative', width: '100%', height: 'auto' }}>
        <Stepper width="100%" steps={StepperSteps} activeSteps={activeSteps} boxwidth={'100%'} />
      </Grid>
      <Grid item xs={12} md={12} lg={9} mt={4} sx={{ width: '100%' }}>
        {renderPageComponent()}
      </Grid>
      <CustomerLoginModal
        isOpen={show}
        setShow={setShow}
        onClose={handleClose}
        handleResetPasswordModalOpen={handleResetPasswordModalOpen}
      />
      {/* <CustomerResetPassword isOpen={resetShow} onClose={() => setResetShow(false)} handleOTP={handleOTP} />
      <CustomerOtpModal
        isOpen={otpshow}
        onClose={() => setOtpShow(false)}
        handleVerifyOtp={handleVerifyOtp}
        handleClickOnResendOTP={handleClickOnResendOTP}
      />
      <OTPResetModal isOpen={isOpen} handleCloseModal={handleCloseResetModal} /> */}
    </Grid>
  );
};

export default ForeignWorkerPolicyCoverages;

const StepperSteps = ['Add-ons Benefits', 'Get Quote', 'Worker Details', 'Payment'];
