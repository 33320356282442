import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from './components/Theme/ThemeProvider';
import { print } from 'helper/utils';
import WrongDateTimeModal from 'components/modals/contentmodal/WrongDateTimeModal';
import { useEffect, useState } from 'react';
import { setInactivityTokenModalOpen, setWrongDateTimeModalOpen } from 'store/reducers/premiumPolicy';
import { useDispatch, useSelector } from 'react-redux';
import InactivityTokenModal from 'components/modals/contentmodal/InactivityTokenModal';

const App = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state?.persistedReducer?.premiumPolicy?.isWrongDateTimeModalOpen);
  const isOpenInactivity = useSelector((state) => state?.persistedReducer?.premiumPolicy?.isInactivityTokenModalOpen);
  console.log('WrongDateandTimemodal---------->', isOpen);
  console.log('isOpenInactivity---------->', isOpenInactivity);

  const [showInactivityTokenModal, setShowInactivityTokenModal] = useState(false);

  const handleCloseModal = () => {
    dispatch(setWrongDateTimeModalOpen(false));
  };

  const handleInactivityToken = () => {
    dispatch(setInactivityTokenModalOpen(false));
    setShowInactivityTokenModal(false);
  };

  useEffect(() => {
    if (isOpenInactivity) {
      setShowInactivityTokenModal(true);
      // Hide the modal after 5 seconds
      const timer = setTimeout(() => {
        setShowInactivityTokenModal(false);
        handleInactivityToken();
      }, 5000);

      // Cleanup timer if component unmounts or isOpenInactivity changes
      return () => clearTimeout(timer);
    }
  }, [isOpenInactivity, dispatch]);

  return (
    <>
      <ThemeCustomization>
        <ThemeProvider>
          <ScrollTop>
            <ToastContainer />
            <Routes />
          </ScrollTop>
        </ThemeProvider>
      </ThemeCustomization>
      <WrongDateTimeModal
        isOpen={isOpen}
        setIsOpen={handleCloseModal}
        title="Wrong Date or Time"
        subtitle="To continue, please ensure the device's date and time settings are accurate."
        handleCloseModal={handleCloseModal}
      />
      <InactivityTokenModal
        isOpen={showInactivityTokenModal}
        setIsOpen={handleInactivityToken}
        title="Token Expired!"
        subtitle="You have been logged out because your session expired due to inactivity"
        handleCloseModal={handleInactivityToken}
      />
    </>
  );
};

export default App;
