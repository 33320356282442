import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';

export default function useCustomerPolicesGet() {
  const { makeRequest } = useApiCall();
  const navigate = useNavigate();
  let userData = localStorage.getItem('@user');
  let user = JSON.parse(userData);
  let userID = user?.userId;
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const handleRaiseEndorsement = (policy) => {
    setSelectedPolicy(policy);
    setIsOpen(true);
  };

  useEffect(() => {
    (async () => {
      try {
        let UserId = userID;
        let params = { UserId };
        let res = await makeRequest({ method: 'POST', url: API.CustomerSpecificPolicies, params });
        console.log('resssssss', res);
        if (res?.code === 200) {
          let arr = [];
          res?.data?.data?.forEach((item, index) => {
            arr.push({
              sr: index + 1,
              psd: item?.poi_start_date || '-',
              ped: item?.poi_end_date || '-',
              pa: `$${item?.premium_with_gst.toFixed(2) || '-'}`,
              createdat: item?.purchaseDate || '-',
              Pno: item?.policy_number || '-',
              policystatus: item?.policyStatus || '-',
              Action: (
                <Button
                  onClick={() => handleRaiseEndorsement(item)}
                  variant="contained"
                  style={{
                    backgroundColor: '#0174BF',
                    color: '#fff',
                    borderRadius: 20,
                    fontSize: 12,
                    cursor: 'pointer',
                    width: '140px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '3px',
                    fontFamily: 'Medium',
                    border: 'none'
                  }}
                >
                  Raise Endorsements
                </Button>
              )
            });
          });
          setData(arr);
        }
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    })();
  }, [userID]);

  return [data, loading, isOpen, setIsOpen, selectedPolicy];
}
