import { showSuccess, showWarning } from 'components/nudges/Alert';
import { nricValidator } from 'helper/utils';
import { useDispatch, useSelector } from 'react-redux';
import { addRiderData } from 'store/reducers/premiumPolicy';

export default function useHandleRider(
  buttonLabel,
  selectedRowData,
  setShow,
  firstName,
  lastName,
  nric,
  dob,
  gender,
  license,
  nationality,
  contactNumber,
  email,
  isCheckboxChecked,
  isRiderCheckboxChecked,
  isCheckboxDisabled,
  mode,
  setIsCheckboxDisabled
) {
  const dispatch = useDispatch();
  const riderDataSection = useSelector((state) => state.persistedReducer.premiumPolicy.otherState.riderDataSection);
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);

  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();
    return yearsDifference * 12 + monthsDifference;
  };

  const calculateAge = (dob) => {
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - new Date(dob);
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(ageInYears);
  };

  const validateEmail = (email) => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email) && !email.includes('..');
  };

  const handleAddRider = () => {
    if (!validateRequiredFields()) return;
    if (!validateLicense()) return;
    if (!validateEmailIfNeeded()) return;
    if (!validateAge()) return;
    if (!validateAgeForFoodParcel()) return;
    if (!validateOwnerSelection()) return;

    const totalExperienceMonths = calculateDuration(license, new Date());
    const experienceString = `${totalExperienceMonths} month${totalExperienceMonths !== 1 ? 's' : ''}`;

    const key = selectedRowData?.id || new Date().toString();
    const riderData = {
      id: key,
      firstName,
      lastName,
      nric,
      gender,
      nationality,
      contactNumber,
      email,
      dob,
      license,
      age: calculateAge(dob),
      experience: experienceString,
      isCheckboxChecked,
      isRiderCheckboxChecked
    };

    dispatch(addRiderData([key, riderData]));

    const successMessage = mode === 'edit' ? 'Rider edited successfully' : 'Rider added successfully';
    showSuccess(successMessage);
    setShow(false);
  };

  const validateRequiredFields = () => {
    if (!firstName || !lastName || !nric || !dob || !gender) {
      showWarning('Please fill in all required fields');
      return false;
    }

    return true;
  };

  const validateLicense = () => {
    const dobDate = new Date(dob);
    const licenseDate = new Date(license);
    const minLicenseDate = new Date(dobDate);
    minLicenseDate.setFullYear(minLicenseDate.getFullYear() + 18);
    if (licenseDate < minLicenseDate) {
      showWarning("The driver's license date must be at least 18 years after date of birth.");
      return false;
    }

    if (!license && !isCheckboxChecked) {
      showWarning('Please fill Driving License Date!');
      return false;
    }

    if (dobDate >= licenseDate) {
      showWarning('Date of birth must be earlier than the driving license issue date');
      return false;
    }

    if (!license && isRiderCheckboxChecked && isCheckboxChecked) {
      showWarning('Please fill in all required fields');
      return false;
    }

    return true;
  };

  const validateEmailIfNeeded = () => {
    if (!email && isCheckboxChecked) {
      showWarning('Please fill email address!');
      return false;
    }
    if (email && !validateEmail(email)) {
      showWarning('Please enter a valid email address');
      return false;
    }
    return true;
  };

  const validateAge = () => {
    const age = calculateAge(dob);
    const dobYear = new Date(dob).getFullYear();
    if (age < 18) {
      showWarning('Age must be 18 or more');
      return false;
    }
    if (dobYear <= 1900) {
      showWarning('Please enter a valid date of birth ');
      return false;
    }
    if (age > 75 && dobYear > 1900) {
      showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
      return false;
    }
    return true;
  };

  const validateAgeForFoodParcel = () => {
    console.log('isFoodParcel:', otherState?.isFoodParcel);
    console.log('age:', otherState?.age);

      if (otherState?.isFoodParcel && otherState.age > 65) {
        showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
        return false;
      } else if (!otherState?.isFoodParcel && otherState.age > 75) {
        showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
        return false;
      }
    return true;
  };

  const validateOwnerSelection = () => {
    const riders = Object.values(riderDataSection);
    if (riders.length >= 0) {
      const existingOwner = riders.some((rider) => rider.isCheckboxChecked);
      if (!existingOwner && !isCheckboxChecked) {
        showWarning('First rider must be selected as the owner');
        return false;
      }
    }
    return true;
  };

  return [handleAddRider];
}
