import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import logo from '../../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import '../styles/NavBar.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { activeItem, navRoutesOptions } from 'store/reducers/menu';
import Rightsidebar from 'components/rightsidebar/Rightsidebar';
import NavBarSidebar from './NavBarSidebar';
import CustomerLoginModal from './CustomerLoginModal';
import CustomerResetPassword from 'modules/customer/customerpasswordreset/CustomerResetPassword';
import CustomerOtpModal from 'modules/customer/customerOTP/CustomerOtpModal';
import OTPResetModal from 'components/modals/contentmodal/OTPResetModal';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { updateUserAuth } from 'store/reducers/auth';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import useNetworkStatus from 'hooks/useNetworkStatus';
import {
  finalSubmissionResponse,
  premiumCalculationResponse,
  removeOtherState,
  resetActiveSteps,
  setPremiumCalculated,
  setPremiumCalculatedForeignWorker,
  setShowMotorVehicleForm
} from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import SignupModal from './SignupModal';
import useBodyScrollLock from 'hooks/useBodyScrollLock';

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { navRoutesOption } = useSelector((state) => state.persistedReducer.menu);
  const { isUserLogin, user } = useSelector((state) => state.persistedReducer.auth);
  const [selectedRoute, setSelectedRoute] = useState(location.pathname);
  const network = useNetworkStatus();
  const [show, setShow] = useState(false);
  const [isMobileOrTab, setIsMobileOrTab] = useState(false);
  const [showNavbarSidebar, setShowNavbarSidebar] = useState(false);
  const [resetShow, setResetShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [scrollDisable, setScrollDisable] = useState(false);
  const [userId, setUserId] = useState('');
  const [emailId, setEmailId] = useState('');
  const { makeRequest } = useApiCall();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTab(window.innerWidth < 920);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleOptions = () => {
    setShowNavbarSidebar(true);
  };

  // const handleClose = () => {
  //   setShow(false);
  // };

  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location.pathname]);

  const handleOptionClick = (route) => {
    store.dispatch(removeOtherState());
    store.dispatch(resetActiveSteps());
    store.dispatch(setPremiumCalculated(false));
    store.dispatch(setPremiumCalculatedForeignWorker(false));
    store.dispatch(setShowMotorVehicleForm(false));
    store.dispatch(premiumCalculationResponse());
    store.dispatch(finalSubmissionResponse());
    setSelectedRoute(route);
    navigate(route);
  };

  const closeSidebar = () => {
    setShowNavbarSidebar(false);
  };

  const handleResetPasswordModalOpen = () => {
    setResetShow(true);
    setShow(false);
  };

  const handleCloseResetModal = () => {
    setIsOpen(false);
    setShow(true);
  };

  const handleOTP = ({ OTP, UserId, emailId }) => {
    setEmailId(emailId);
    setUserId(UserId);
    setResetShow(false);
    setOtpShow(true);
  };

  const handleVerifyOtp = () => {
    setOtpShow(false);
    setIsOpen(true);
  };

  const handleLoginClick = () => {
    setSignUpOpen(false);
    setShow(true);
    if (show === true) {
      setScrollDisable(true);
    }
  };

  const handleSignupLinkClick = () => {
    setShow(false);
    setSignUpOpen(true);
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem('@user');
      localStorage.removeItem('@login');
      showSuccess('Logout successfully');
      dispatch(updateUserAuth({ status: false, data: {} }));
      dispatch(navRoutesOptions({ navRoutesOption: '/' }));
      navigate('/');
    } catch (e) {
      showDanger(e);
    }
  };

  const handleClickOnResendOTP = async () => {
    if (network) {
      try {
        let params = {
          UserId: userId,
          emailId: emailId
        };
        let res = await makeRequest({ method: 'POST', url: API.PasswordChangeOTPSent, params });
        if (res?.code == 200) {
          showSuccess('OTP sent successfully');
        } else {
          showWarning(res?.message);
        }
      } catch (e) {
        showDanger(`${e}`);
      }
    } else {
      showDanger('No internet');
    }
  };

  return (
    <Grid container xs={12} className="navbar-container" alignItems="center" style={{ padding: '10px' }}>
      {isMobileOrTab && (
        <Grid item xs={1} sm={0} md={0} lg={0} alignItems="center">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={toggleOptions} aria-label="Toggle Menu">
              <MenuIcon />
            </Button>
          </div>
        </Grid>
      )}
      <Grid item xs={4} sm={2} md={3} lg={3.25} justifyContent="center" alignItems="center">
        <Button
          className={`img-containerrr-navbarrr ${navRoutesOption === '/' ? 'selectedddd-route-text' : ''}`}
          onClick={() => handleOptionClick('/')}
          aria-label="Home"
          sx={{
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '&:focus': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <img
            src={logo}
            alt="LOGO"
            className="imagesizedifferscreen_navbarr"
            style={{ width: '100px', height: 'auto', maxWidth: '100%' }}
          />
        </Button>
      </Grid>
      {!isMobileOrTab && (
        <Grid item xs={0} sm={7} md={6} lg={5.5} alignItems="center">
          <div className="text-containerrrr-navbarrrr">
            <Typography
              className={`optionsss-styling-navbarrr ${selectedRoute === '/' ? 'selectedddd-route-text' : ''}`}
              onClick={() => handleOptionClick('/')}
              role="button"
              tabIndex={0}
            >
              Home
            </Typography>
            <Typography
              className={`optionsss-styling-navbarrr ${selectedRoute === '/DomesticHelper' ? 'selectedddd-route-text' : ''}`}
              onClick={() => handleOptionClick('/DomesticHelper')}
              role="button"
              tabIndex={0}
            >
              Domestic Helper
            </Typography>
            <Typography
              className={`optionsss-styling-navbarrr ${selectedRoute === '/MotorVehicle' ? 'selectedddd-route-text' : ''}`}
              onClick={() => handleOptionClick('/MotorVehicle')}
              role="button"
              tabIndex={0}
            >
              Motor Vehicle
            </Typography>
            <Typography
              className={`optionsss-styling-navbarrr ${selectedRoute === '/ForeignWorkers' ? 'selectedddd-route-text' : ''}`}
              onClick={() => handleOptionClick('/ForeignWorkers')}
              role="button"
              tabIndex={0}
            >
              Foreign Workers
            </Typography>
          </div>
        </Grid>
      )}
      <Grid item xs={7} sm={isMobileOrTab ? 9 : 3} md={3} lg={3.25} alignItems="center">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginRight: isMobileOrTab ? '0' : '5%' }}>
          {isUserLogin ? (
            <>
              <Button size="small" className="buttonStyling_navbarrr" onClick={() => navigate('/ProfileView')} aria-label="Profile">
                Profile
              </Button>
              <Button
                size="small"
                variant="contained"
                className="buttonStyling_navbarrr_agentportal"
                onClick={handleLogout}
                aria-label="Logout"
              >
                Logout
              </Button>
            </>
          ) : (
            <Button size="small" className="buttonStyling_navbarrr" onClick={() => setShow(true)} aria-label="Customer Login">
              Customer Login
            </Button>
          )}

          {!isUserLogin && (
            <Button
              size="small"
              variant="contained"
              className="buttonStyling_navbarrr_agentportal"
              onClick={() => navigate('/login')}
              aria-label="Agent Login"
            >
              Agent Login
            </Button>
          )}
        </div>
      </Grid>

      <CustomerLoginModal
        isOpen={show}
        setShow={setShow}
        onClose={() => setShow(false)}
        handleResetPasswordModalOpen={handleResetPasswordModalOpen}
        handleSignupLinkClick={handleSignupLinkClick}
        scrollLock={scrollDisable}
      />
      <CustomerResetPassword isOpen={resetShow} onClose={() => setResetShow(false)} handleOTP={handleOTP} scrollLock={true} />
      <CustomerOtpModal
        isOpen={otpshow}
        onClose={() => setOtpShow(false)}
        handleVerifyOtp={handleVerifyOtp}
        handleClickOnResendOTP={handleClickOnResendOTP}
        scrollLock={true}
      />
      <OTPResetModal isOpen={isOpen} handleCloseModal={handleCloseResetModal} scrollLock={true} />
      <SignupModal
        isOpen={signUpOpen}
        setSignUpOpen={setSignUpOpen}
        onClose={() => setSignUpOpen(false)}
        handleLoginClick={handleLoginClick}
        scrollLock={true}
      />
      {
        <Rightsidebar
          Rcontent={<NavBarSidebar />}
          show={showNavbarSidebar}
          closeSidebar={closeSidebar}
          maxWidth="250px"
          placement={'start'}
          borderTopLeftRadius={0}
          borderBottomLeftRadius={0}
        />
      }
    </Grid>
  );
};

export default NavBar;
