import ReusableModal from 'components/modals/routemodal/ResuableModal';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, FormHelperText, Grid, Link, IconButton, InputAdornment, OutlinedInput, Stack, Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'components/@extended/AnimateButton';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useNetworkStatus from 'hooks/useNetworkStatus';
import logo from '../../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import SignupModal from './SignupModal';
import OverlayLoader from 'components/loader/OverlayLoader';
import useApiCall from 'api/useApiCall';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { EncryptValue } from 'helper/utils';
import { API } from 'api/API';
import { dispatch } from 'store/index';
import { updateUserAuth } from 'store/reducers/auth';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const CustomerLoginModal = (props) => {
  const navigate = useNavigate();
  const network = useNetworkStatus();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { makeRequest, makeRequestWithoutLogin } = useApiCall();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(320));

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSignupLinkClick = () => {
    setShowSignup(true);
    props.onClose();
  };

  const handleLoginClick = () => {
    setShowSignup(false);
    props.setShow(true);
  };

  return (
    <>
      <OverlayLoader show={loading} />
      <ReusableModal
        isOpen={props.isOpen}
        width={isMobile ? '95%' : '310px'}
        height="auto"
        showCloseButton={false}
        onClose={props.onClose}
        ModalContent={
          <Box p={3}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={12} textAlign="center">
                <img
                  src={logo}
                  alt="Anda Logo"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '12rem',
                    marginBottom: '10%'
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                <h6 style={{ fontFamily: 'Medium', fontSize: 14, color: '#000000', textAlign: 'start' }}>Login to your account</h6>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    submit: null
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().max(255).required('User Id is required'),
                    password: Yup.string().max(255).required('Password is required')
                  })}
                  onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                      if (!network) {
                        showDanger('No internet connection.');
                        return;
                      } else {
                        setLoading(true);
                        const body = {
                          UserId: values.email,
                          Password: EncryptValue(values.password)
                        };
                        let loginResponse = await makeRequest({ method: 'POST', url: API.UserLogin, body });
                        if (loginResponse.code == 200) {
                          try {
                            let params = {
                              UserId: loginResponse?.data?.userMasterId
                            };
                            let CustomerMasterUserIdWiseGet = await makeRequest({
                              method: 'GET',
                              url: API.CustomerMasterUserIdWiseGet,
                              params,
                              token: loginResponse?.data?.access_token
                            });
                            if (CustomerMasterUserIdWiseGet.code == 200) {
                              localStorage.setItem('@user', JSON.stringify(CustomerMasterUserIdWiseGet?.data));
                              localStorage.setItem('@login', JSON.stringify(loginResponse?.data));
                              showSuccess('Login successfully');
                              dispatch(updateUserAuth({ status: true, data: CustomerMasterUserIdWiseGet?.data }));
                              setLoading(false);
                              props.onClose();
                            } else {
                              setLoading(false);
                              showWarning(CustomerMasterUserIdWiseGet?.message);
                            }
                          } catch (err) {
                            setLoading(false);
                            showDanger(`${err}`);
                          }
                        } else {
                          setLoading(false);
                          showWarning(loginResponse?.message);
                        }
                      }
                    } catch (e) {
                      setLoading(false);
                      showDanger(`${e}`);
                    }
                  }}
                >
                  {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={3} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <OutlinedInput
                              sx={{
                                width: '100%'
                              }}
                              id="email-login"
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter User ID"
                              error={Boolean(touched.email && errors.email)}
                            />
                            {touched.email && errors.email && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.email}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <OutlinedInput
                              sx={{ width: '100%' }}
                              error={Boolean(touched.password && errors.password)}
                              id="-password-login"
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="small"
                                  >
                                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              placeholder="Enter password"
                            />
                            {touched.password && errors.password && (
                              <FormHelperText error id="standard-weight-helper-text-password-login">
                                {errors.password}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                        {errors.submit && (
                          <Grid item xs={12}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <AnimateButton>
                            <Button
                              sx={{
                                width: '100%'
                              }}
                              style={{ backgroundColor: '#002d8c', fontFamily: 'Medium' }}
                              disableElevation
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Login
                            </Button>
                          </AnimateButton>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems="left">
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: '5px' }}>
                      <p style={{ fontFamily: 'Medium', fontSize: 13, color: '#000000' }}>Forgot Your Password ? </p>
                    </div>
                    <span
                      variant="h6"
                      onClick={props.handleResetPasswordModalOpen}
                      component={RouterLink}
                      to="/CustomerResetPassword"
                      color="text.primary"
                      style={{
                        fontFamily: 'Medium',
                        fontSize: 13,
                        color: '#4868ac',
                        cursor: 'pointer'
                      }}
                    >
                      Reset Password
                    </span>
                  </div>
                </Stack>
                <Stack alignItems="left">
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: '5px' }}>
                      <p style={{ fontFamily: 'Medium', fontSize: 13, color: '#000000' }}>New to ANDA? Create </p>
                    </div>
                    <Link
                      variant="h6"
                      component={RouterLink}
                      color="text.primary"
                      sx={{
                        fontFamily: 'Medium',
                        fontSize: 13,
                        color: '#4868ac'
                      }}
                      onClick={handleSignupLinkClick}
                    >
                      a new account
                    </Link>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        }
      />
      <SignupModal
        isOpen={showSignup}
        onClose={() => setShowSignup(false)}
        setShow={props.setShow}
        // setShowLogin={setShowLogin}
        setShowSignup={setShowSignup}
        handleLoginClick={handleLoginClick}
      />
    </>
  );
};

export default CustomerLoginModal;
