import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, TextField, Grid, Typography, Paper } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import '../../../packages & premium/styles/SelectCustomerSideBar.css';
import { useTheme } from 'components/Theme/ThemeProvider';
import { useGetCustomerMasterbyNRICGetQuery } from 'store/services/VehicleDetails';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { API } from 'api/API';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApiCall from 'api/useApiCall';
import { activeItem } from 'store/reducers/menu';
import { store } from 'store/index';
import OverlayLoader from 'components/loader/OverlayLoader';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { nricValidator } from 'helper/utils';
import { removeOtherState } from 'store/reducers/premiumPolicy';

const ForeignAddCustomerSidebar = (props) => {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const { workerSection } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const { addOn } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const { uenData } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { otherState, activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const [searchNric, setSearchNric] = useState('');
  const [formNric, setFormNric] = useState('');
  const [nric, setNric] = useState('');
  const [showList, setShowList] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const network = useNetworkStatus();
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setCustomerDetails({ ...(customerDetails || {}), email: email });
  };

  const validateEmail = (email) => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email) && !email.includes('..');
  };

  const validateNric = (nric) => {
    const nricPattern = /^[STFG]\d{7}[A-Z]$/;
    return nricPattern.test(nric);
  };

  const customerBody = {
    firstName: otherState['contact']?.firstName,
    lastName: otherState['contact']?.lastName,
    gender: '',
    email: otherState['contact']?.email,
    maritalStatus: '',
    source: '',
    mobile: otherState['contact']?.mobile || null,
    designation: '',
    occupation: '',
    licensePassDate: '',
    street: otherState['contact']?.street || '',
    city: '',
    state: '',
    postalCode: otherState['contact']?.postalCode || '',
    country: otherState['contact']?.country || 'Singapore',
    remarks: '',
    nricNo: otherState['contact']?.nricNo
  };
  const [customerDetails, setCustomerDetails] = useState(customerBody);
  const { data } = useGetCustomerMasterbyNRICGetQuery({ nric: searchNric || null });

  useEffect(() => {
    if (!network) {
      showDanger('No internet connection.');
      navigate('/dashboard/Policies');
    }
  }, [network]);

  const handleSubmitCustomer = async () => {
    if (!network) {
      // showDanger('No internet connection.');
      return;
    }
    setLoading(true);
    let obj = { itemCode: `${activeParams.productID}`, salesPrice: props?.apiData?.productAmount, quantity: 1, isAdd_On: false };
    const arr = [];
    let workerSectionLength = Object?.keys(otherState?.workerSection)?.length;
    {
      arr.push(obj);
      if (addOn != undefined) {
        Object?.values(addOn)?.forEach((itm) => {
          arr.push({
            itemCode: `${itm?.itemCode}`,
            salesPrice: itm?.salesPrice * workerSectionLength,
            quantity: itm?.quantity,
            isAdd_On: true
          });
        });
      }
    }
    const duration = props?.params?.duration;
    try {
      if (isButtonDisabled) return;

      setIsButtonDisabled(true);
      if (
        !customerDetails?.firstName ||
        !customerDetails?.lastName ||
        !customerDetails?.nricNo ||
        !customerDetails.email ||
        !customerDetails.street ||
        !customerDetails.country ||
        !customerDetails.postalCode
      ) {
        setIsButtonDisabled(false);
        showWarning('Please fill in all required fields');
        setLoading(false);
        return;
      } else if (!validateEmail(customerDetails.email)) {
        setIsButtonDisabled(false);
        showWarning('Please enter a valid email address');
        setLoading(false);
        return;
      } else if (!nricValidator(customerDetails.nricNo)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid NRIC');
        return;
      } else {
        const requestBodyWorker = Object.values(workerSection)?.map((worker) => {
          return {
            firstName: worker?.firstName,
            lastName: worker?.lastName,
            nric: worker?.nric,
            dob: worker?.dob,
            age: worker?.age === '-' ? '0' : worker?.age,
            gender: worker?.gender,
            nationality: worker?.nationality,
            contact: worker?.contactNumber,
            email: worker?.email,
            workPermitNo: worker.workPermit,
            passportNo: worker.passport,
            active: true
          };
        });
        let body = {
          nric: customerDetails.nricNo,
          uen: otherState.uen,
          isIndividual: otherState.uen === '' ? true : false,
          insuranceStart: otherState.fromDate,
          insuranceEnd: otherState.todate,
          opportunityType: 'Foreign Worker',
          portalUserId: props?.apiData?.userId,
          creditTerm: true,
          durationInMonths: `${duration} Months`,
          transactionType: otherState?.transactionType,
          companyRegistered: false,
          vehicleId: null,
          vrn: null,
          coverageType: null,
          deliveryExtension: null,
          chasisNo: null,
          engineNo: null,
          drivingLicenseDate: null,
          excess: 0,
          claimsDetails: null,
          nameOfHirePurchase: null,
          workshopType: null,
          // vehicleTonnage: '0',
          ncdAmount: 0,
          ncd: null,
          // ncdProtector: null,
          reg_manufactureDate: null,
          bondDuration: null,
          reTransmissionReminder: null,
          reviewedByInsurer: true,
          basisOfCover: otherState.basisofcover,
          mainContractorName: otherState.maincontractor,
          cpfSubmissionNo: otherState.CPF,
          transmissionStatus: 'Waiting on User',
          dischargeDate: '2025-12-23',
          loiStatus: 'PENDING',
          bondCallStatus: 'Premium',
          loiType: 'BLANKET',
          contact: customerDetails,
          items: arr,
          insuredParty: requestBodyWorker,
          productId: props.apiData.productId,
          loginDetailsId: props?.apiData?.loginDetailsId,
          customerPolicyId: null,
          opportunityId: props?.params?.opportunityId || null,
          totalPremiumAmount: props?.apiData.premiumWithGST || 0
        };
        if (Object.keys(uenData).length > 0 && otherState.uen) {
          body['account'] = {
            accName: uenData.name || null,
            accEmail: uenData.email_Address__c || null,
            phone: uenData.phone.toString() || null,
            source: uenData.accountSource,
            bankAccountName: uenData.bank_Account_Name__c,
            bankCode: uenData.bank_Code__c,
            bankName: uenData.bank_Name__c,
            bankAccountNo: uenData.bank_Account_No__c,
            street: uenData.billingStreet,
            city: uenData.billingCity,
            state: uenData.billingState,
            postalCode: uenData.billingPostalCode,
            country: uenData.billingCountry
          };
        } else {
          body['account'] = {
            accName: otherState?.cm || '',
            accEmail: otherState?.email || '',
            phone: otherState?.phone?.toString() || '',
            source: null,
            bankAccountName: null,
            bankCode: null,
            bankName: null,
            bankAccountNo: null,
            street: null,
            city: null,
            state: null,
            postalCode: null,
            country: null
          };
        }
        const response = await makeRequest({ method: 'POST', url: API.FinalSubmissionCustomerPortalForFWDHAPI, body });
        if (response?.code == 200) {
          showSuccess(response?.data?.message);
          props.setShow(false);
          store.dispatch(activeItem({ openItem: ['policies'] }));
          setLoading(false);
          store.dispatch(removeOtherState());
          navigate('/dashboard/Policies');
        } else {
          setLoading(false);
          showDanger('Policy Not Created');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleNricChange = (e) => {
  //   const value = e.target.value.toUpperCase();
  //   const regex = /^[A-Z0-9]*$/;
  //   if (regex.test(value)) {
  //     setNric(value);
  //     setCustomerDetails({ ...customerDetails, nricNo: value });
  //   }
  // };

  const handleSearchNRICChange = (event) => {
    const value = event.target.value.toUpperCase();
    const regex = /^[A-Z0-9]*$/;
    if (regex.test(value)) {
      setSearchNric(value);
      setShowList(true);
    }
  };

  // const handleSearchNRICChange = (event) => {
  //   const value = event.target.value.toUpperCase();
  //   const regex = /^[A-Z0-9]*$/;
  //   if (regex.test(value)) {
  //     if (!value) {
  //       if (searchNric === '') {
  //         setFormNric('');
  // setCustomerDetails({
  //   firstName: '',
  //   lastName: '',
  //   mobile: '',
  //   email: '',
  //   nricNo: '',
  //   postalCode: '',
  //   street: '',
  //   country: '',
  //   nricNo: ''
  // });
  //       } // Clear formNric when searchNric is cleared
  //     }
  //     setSearchNric(value);
  //     setShowList(true);
  //   }
  // };

  const handleFormNRICChange = (event) => {
    const nric = event.target.value.toUpperCase();
    const regex = /^[A-Z0-9]*$/;
    if (regex.test(nric)) {
      setFormNric(nric);
      setCustomerDetails({ ...customerDetails, nricNo: nric });
    }
  };

  const handleNameClick = (name) => {
    setShowList(false);
    if (name) {
      setSearchNric(name?.nricNo);
      setFormNric(name?.nricNo);
      setCustomerDetails({
        firstName: name?.firstName,
        lastName: name?.lastName,
        mobile: name?.mobilePhone,
        email: name?.finance_Email__c,
        nricNo: name?.nricNo,
        postalCode: name?.postalCode,
        street: name?.street,
        country: name?.country || 'Singapore'
      });
    }
  };

  useEffect(() => {
    if (data && data?.success === true && data?.data) {
      const customer = data.data;
      setCustomerDetails({
        ...customerDetails,
        firstName: customer.firstName || otherState['contact']?.firstName || '',
        lastName: customer.lastName || otherState['contact']?.lastName || '',
        gender: customer.gender || otherState['contact']?.gender || '',
        email: customer.email || otherState['contact']?.email || '',
        maritalStatus: customer.maritalStatus || otherState['contact']?.maritalStatus || '',
        source: customer.source || otherState['contact']?.source || '',
        mobile: customer.mobile || otherState['contact']?.mobile || '',
        designation: customer.designation || otherState['contact']?.designation || '',
        occupation: customer.occupation || otherState['contact']?.occupation || '',
        licensePassDate: customer.licensePassDate || otherState['contact']?.licensePassDate || '',
        street: customer.street || otherState['contact']?.street || '',
        city: customer.city || otherState['contact']?.city || '',
        state: customer.state || otherState['contact']?.state || '',
        postalCode: customer.postalCode || otherState['contact']?.postalCode || '',
        country: customer.country || otherState['contact']?.country || 'Singapore' || '',
        remarks: customer.remarks || otherState['contact']?.remarks || '',
        nricNo: formNric
      });
    } else if (searchNric === '') {
      setFormNric('');
      setCustomerDetails({
        firstName: otherState['contact']?.firstName || '',
        lastName: otherState['contact']?.lastName || '',
        mobile: otherState['contact']?.mobile || '',
        email: otherState['contact']?.email || '',
        nricNo: otherState['contact']?.nricNo || '',
        postalCode: otherState['contact']?.postalCode || '',
        street: otherState['contact']?.street || '',
        country: otherState['contact']?.country || 'Singapore' || '',
        nricNo: otherState['contact']?.nricNo || ''
      });
    }
  }, [data]);

  // useEffect(() => {
  //   if (!searchNric) {
  //     setFormNric('');
  //     setCustomerDetails({
  //       firstName: '',
  //       lastName: '',
  //       gender: '',
  //       email: '',
  //       maritalStatus: '',
  //       source: '',
  //       mobile: '',
  //       designation: '',
  //       occupation: '',
  //       licensePassDate: '',
  //       street: '',
  //       city: '',
  //       state: '',
  //       postalCode: '',
  //       country: '',
  //       remarks: '',
  //       nricNo: ''
  //     });
  //   }
  // }, [searchNric]);
  // const handleSearch = (e) => {
  //   setShowList(true);
  //   // refetch();
  //   const searchTerm = e?.target?.value;
  //   setNric(searchTerm);

  //   setCustomerDetails({
  //     firstName: '',
  //     lastName: '',
  //     mobile: '',
  //     email: '',
  //     nricNo: '',
  //     street: '',
  //     postalCode: '',
  //     country: ''
  //   });
  // };

  // const handleNameClick = (name) => {
  //   setShowList(false);
  //   if (name) {
  //     setNric(name?.nricNo);
  //     setCustomerDetails({
  //       firstName: name?.firstName,
  //       lastName: name?.lastName,
  //       mobile: name?.mobilePhone,
  //       email: name?.finance_Email__c,
  //       nricNo: name?.nricNo,
  //       street: name?.street,
  //       postalCode: name?.postalCode,
  //       country: name?.country
  //     });
  //   }
  // };

  function handleNumericKeyPress(event) {
    const keyPressed = event.key;
    if (!/^\d$/.test(keyPressed) && !event.ctrlKey && !event.metaKey && !event.altKey) {
      event.preventDefault();
    }
  }

  console.log('Customer Details Foreign Worker ----->', customerDetails);
  return (
    <Grid className="topContainer_SelectCustomerSideBar">
      <Grid className="heading_SelectCustomerSideBar">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Selected Customer
        </Typography>
      </Grid>

      <hr />

      <Grid className="searchbarGrid_SelectCustomerSideBar">
        <TextField
          placeholder="Search"
          className="textfield_SelectCustomerSideBar"
          variant="standard"
          size="small"
          value={searchNric}
          onChange={handleSearchNRICChange}
          InputProps={{
            disableUnderline: true,
            endAdornment: <SearchIcon color="action" className="inputPropStyle_SelectCustomerSideBar" />
          }}
          inputProps={{
            autoComplete: 'new-password', // Use a unique value
            name: `random-name-${Math.random()}`, // Randomize name attribute
            style: {
              fontSize: '13px'
            }
          }}
        />
      </Grid>
      {showList && data != '' && (
        <Paper
          style={{
            padding: '2%',
            margin: '0.5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '100%',
            position: 'absolute',
            zIndex: 4,
            maxHeight: '30%',
            overflowY: 'auto'
          }}
        >
          {data?.map((item, index) => (
            <Grid item onClick={() => handleNameClick(item)} style={{ width: '100%' }}>
              <Typography key={index} style={{ width: '100%', cursor: 'pointer', fontSize: '13px' }}>
                {item?.nricNo}
              </Typography>
              <hr style={{ width: '100%', border: 'none', borderBottom: '1px solid lightgray' }} />
            </Grid>
          ))}
        </Paper>
      )}

      <Grid className="stylinghrtags_SelectCustomerSideBar">
        <Grid style={{ width: '20%', marginRight: '2%' }}>
          <hr />
        </Grid>
        <p style={{ marginTop: '1%' }}>Or</p>
        <Grid style={{ width: '20%', marginLeft: '2%' }}>
          <hr />
        </Grid>
      </Grid>

      <Grid className="addCustomersGridStyling">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Add Customer
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="allinputpositioningstyling">
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>First Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.firstName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, firstName: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Last Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.lastName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, lastName: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Phone Number</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.mobile}
              onKeyPress={handleNumericKeyPress}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, mobile: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Email Address</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              type="email"
              size="small"
              variant="standard"
              value={customerDetails?.email}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={handleEmailChange}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          {/* Last Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>NRIC</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              placeholder="e.g. S3272118D"
              value={customerDetails?.nricNo !== '' ? customerDetails?.nricNo : formNric}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={handleFormNRICChange}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Address</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.street}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, street: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Postal Code</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.postalCode}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, postalCode: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Country</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.country}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, country: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} lg={12} mt={-2}>
        <Button
          size="small"
          variant="contained"
          disabled={isButtonDisabled}
          style={{
            borderRadius: '8px',
            color: isButtonDisabled ? '#000' : '#fff',
            backgroundColor: isButtonDisabled ? '#dfe6e9' : '#002D8C',
            minWidth: '40%',
            marginRight: '10px',
            padding: '2%',
            fontFamily: 'Medium'
          }}
          onClick={() => handleSubmitCustomer()}
        >
          Complete Purchase
        </Button>
        <Button
          size="small"
          variant="contained"
          style={{
            borderRadius: '8px',
            color: '#002D8C',
            backgroundColor: '#fff',
            minWidth: '40%',
            marginRight: '10px',
            padding: '2%',
            fontFamily: 'Medium',
            border: '1px solid #002D8C'
          }}
          onClick={() => props.setShow(false)}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
          <OverlayLoader show={true} />
        </div>
      )}
    </Grid>
  );
};

export default ForeignAddCustomerSidebar;
