// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
 isAgendLogin:false,
 agent:{},
 isUserLogin:false,
 user:{},
 activePersona:'Agent'
};

// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAgendAuth(state, action) {
      console.log("UpdateAgentAuth -->",action.payload)
      state.isAgendLogin = action.payload.status;
      state.agent = action.payload.data
    },
    updateUserAuth(state, action) {
      state.isUserLogin = action.payload.status;
      state.user = action.payload.data
    },
    changePersona(state, action) {
      if(state.isAgendLogin){
        state.activePersona = 'Agent';
      }
      else {
        state.activePersona = 'Customer';
      }
    },
  }
});

export default auth.reducer;

export const { updateAgendAuth ,updateUserAuth,changePersona} = auth.actions;
