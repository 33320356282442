import SubHeader from 'components/subHeader/SubHeader';
import React, {useState } from 'react';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { useSelector } from 'react-redux';
import Successmodal from 'components/modals/contentmodal/Successmodal';

const PremiumCalculateSubHeader = React.memo(({ isRightEnable = true, title = 'Packages and Premium', url, key }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleBackClick = () => {
    if (location?.pathname === '/dashboard/PremiumCalculation') {
      navigate('/dashboard/PremiumCalculateView'); 
    } else if (otherState?.navigateFrom === 'renewal' && location?.pathname === '/dashboard/PremiumCalculateView') {
      navigate('/dashboard/ViewPolicy');
    } else if (location?.pathname === '/dashboard/PremiumCalculateView') {
      navigate('/dashboard/Premium');
    } else {
      navigate(-1);
    }
  };

  

  return (
    <>
      <SubHeader
        title={title}
        isLeftEnable={true}
        leftComponent={
          <Button
            size="small"
            variant="contained"
            style={{ borderRadius: 12, color: '#fff', backgroundColor: '#2d2d2d', minWidth: '30px', marginRight: '10px' }}
            onClick={handleBackClick}
          >
            <ArrowBackIcon />
          </Button>
        }
        isRightEnable={isRightEnable}
        rightComponent={
          isRightEnable ? (
            <a
              href={url}
              onClick={() => setIsOpen(true)}
              download={`document_${key}.pdf`}
              target="_blank"
              rel="noreferrer"
              style={{
                backgroundColor: '#0174BF',
                color: '#fff',
                borderRadius: 20,
                fontSize: 12,
                marginLeft: '10px',
                cursor: 'pointer',
                width: '90px',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                padding: '3px',
                textDecoration: 'none'
              }}
            >
              <SimCardDownloadOutlinedIcon style={{ fontSize: 14 }} />
              Download
            </a>
          ) : null
        }
      />
      <Successmodal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'Download'}
        subtitle={'Download started'}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
});

export default PremiumCalculateSubHeader;
