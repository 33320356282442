import React, { useState, useEffect } from 'react';
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';
import { LogoutOutlined, HomeOutlined, TextSnippetOutlined, AccountCircleOutlined } from '@mui/icons-material';
export default function usePages() {
  const icons = {
    LoginOutlined,
    ProfileOutlined,
    HomeOutlined,
    LogoutOutlined,
    TextSnippetOutlined,
    AccountCircleOutlined
  };

  const [pages, setPages] = useState([]);

  function createMenuItem({ id, title, icon, url = [], condition = true }) {
    return {
      id,
      title,
      type: 'item',
      icon: icons[icon],
      url: url,
      breadcrumbs: true,
      condition
    };
  }
  const loginData = localStorage?.getItem('@login');
  const data = JSON.parse(loginData);
  const isFinanceAccess = data?.finance_Access__c;

  useEffect(() => {
    const pages = {
      id: 'ANDA',
      title: 'ANDA',
      type: 'group',
      children: [
        isFinanceAccess && createMenuItem({
          id: 'dashboard1',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard/default',
          icon: 'HomeOutlined'
        }),
        createMenuItem({
          id: 'policies',
          title: 'My Policies',
          icon: 'TextSnippetOutlined',
          url: '/dashboard/Policies'
        }),
        createMenuItem({
          id: 'Premium',
          title: 'Products',
          icon: 'TextSnippetOutlined',
          url: '/dashboard/Premium'
        }),
        createMenuItem({
          id: 'Profile',
          title: 'My Profile',
          icon: 'AccountCircleOutlined',
          url: '/dashboard/Profile'
        }),

        isFinanceAccess &&
          createMenuItem({
            id: 'IncorporateFinance',
            title: 'Finance',
            icon: 'AccountCircleOutlined',
            url: '/dashboard/IncorporateFinance'
          }),

        createMenuItem({
          id: 'logout',
          title: 'Logout',
          icon: 'LogoutOutlined',
          url: '/login'
        })
      ]
    };
    setPages(pages);
  }, [isFinanceAccess]);
  return pages;
}
