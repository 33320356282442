import React from 'react';
import '../styles/PrivacyPolicy.css';
import NavBar from './NavBar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
const PrivacyPolicy2 = () => {
  return (
    <div
      className="policy-container"
      style={{
        // padding: '12px 40px',
        fontFamily: 'Medium',
        fontSize: '15px',
        letterSpacing: '1.3px',
        color: '#1B222F',
        backgroundColor: '#fff',
        display: 'contents'
      }}
    >
      <NavBar />
      <div style={{ maxWidth: '1340px', textAlign: 'left', margin: '0 auto' ,marginTop:20}}>
        <h4>Privacy Policy</h4>
        <p>
          The purpose of this document (“<strong>Privacy Policy</strong>”) is to inform you of how Anda Insurance Agencies Pte Ltd manages
          Personal Data (as defined below). Please take a moment to read this Privacy Policy so that you know and understand the purposes
          for which we collect, use and disclose your Personal Data.
        </p>
        <p>
          By interacting with us, submitting information to us (including on or through our website), or signing up for any products or
          services offered by us, you agree and consent to Anda Insurance Agencies Pte Ltd, as well as its representatives, staff and/or
          agents (collectively referred to herein as “<strong>Anda</strong>”, “<strong>us</strong>”, “<strong>we</strong>” or “
          <strong>our</strong>”), collecting, using, disclosing and sharing amongst themselves your Personal Data, and disclosing such
          Personal Data to our authorised service providers and relevant third parties in the manner set forth in this Privacy Policy.
        </p>
        <p>
          This Privacy Policy supplements but does not supersede nor replace any other consents you may have previously provided to us in
          respect of your Personal Data, and your consents herein are additional to any rights which Anda may have at law to collect, use or
          disclose your Personal Data. This Privacy Policy does not affect any rights which we may have at law in connection with the
          collection, use or disclosure of your Personal Data.
        </p>
        <p>
          Anda may from time to time update this Privacy Policy to ensure that this Privacy Policy is consistent with our future
          developments, industry trends and/or any changes in legal or regulatory requirements. Subject to your rights at law, you agree to
          be bound by the prevailing terms of this Privacy Policy as updated from time to time on our website at{' '}
          <Link to="/PrivacyPolicy">https://www.anda.com.sg/static/privacy-policy.html</Link>. Please check back regularly for updated
          information on the handling of your Personal Data.
        </p>
        <p> </p>
        <p>
          <strong>1. Your Personal Data</strong>
        </p>
        <p>
          <strong>1.1</strong> In this Privacy Policy, “<strong>Personal Data</strong>” means (a) all data that falls within the definition
          of personal information, personal data, personally identifiable information or similar language under the Singapore Personal Data
          Protection Act 2012 and any applicable law related to the protection, privacy and security, collection, use or disclosure of
          sensitive or other personally identification information (collectively, “<strong>Data Protection Laws</strong>“); (b) all
          personally identifiable information including name, residential or business address, email address, telephone number, photograph,
          account number, driver’s license, passport, social security or government issued identification number; and (c) all other
          information relating to or associated with such personally identifiable information.
        </p>
        <p>
          <strong>1.2</strong> Examples of such Personal Data which you may provide us include (depending on the nature of your interaction
          with us):
        </p>
        <p>
          a) your name, identification numbers such as NRIC, FIN, work permit and birth certificate, date of birth, telephone number(s),
          mailing address, email address and any other information relating to you which you have provided us in any form you may have
          submitted to us, conversations with Anda’s representatives, staff or agents, or in other forms of interaction with you;
        </p>
        <p>
          b) information about your use of our websites, mobile sites and applications, our social media sites and applications and
          services, including cookies, information about your domain name, IP addresses and interactions with our online advertisement; and
        </p>
        <p>c) your underwriting, claims and payment related information, such as your bank account or credit card information.</p>
        <p> </p>
        <p>
          <strong>2. Collection of Personal Data</strong>
        </p>
        <p>
          <strong>2.1</strong> Generally<strong>, </strong>we collect your Personal Data in the following ways:
        </p>
        <p>a) when you submit forms (online or otherwise) relating to any of our products and services, or submit any online queries;</p>
        <p>b) when you enter into any agreement or provide other documentation or information in respect of your interactions with us;</p>
        <p>c) when you register for and/or use any of our services on websites owned or operated by us;</p>
        <p>
          d) when you interact with our staff, including customer service officers, for example, via telephone calls or video conferencing
          calls (which may be recorded), letters, fax, face-to-face meetings, social media platforms and emails;
        </p>
        <p>e) when you request that we contact you or request that you be included in an email or other mailing list;</p>
        <p>f) when you respond to our promotions, initiatives or to any request for additional Personal Data;</p>
        <p>g) when we receive references from business partners and third parties, for example, where you have been referred by them;</p>
        <p>
          h) when your images are captured by us via CCTV cameras while you are within our property and/or premises, or via photographs or
          videos taken by us or our representatives when you attend our events;
        </p>
        <p>i) when you are contacted by, and respond to, our marketing representatives and customer service officers;</p>
        <p>
          j) when we seek information about you and receive your Personal Data in connection with your relationship with us, including for
          our services or job applications, for example, from business partners, public agencies, your ex-employer, referral intermediaries
          and the relevant authorities; and/or
        </p>
        <p>k) when you submit your Personal Data to us for any other reason.</p>
        <p>
          <strong>2.2</strong> When you browse our website, you generally do so anonymously but please see paragraph 6 below for information
          on cookies and other technologies which we have implemented on our website. We do not, at our website, automatically collect
          Personal Data unless you provide such information to us.
        </p>
        <p>
          <strong>2.3</strong> Without prejudice to the generality of Clause 2.1, we may collect information about the computer, mobile
          telephone, smart phone or other device you use to access our website.
        </p>
        <p>
          <strong>2.4</strong> If you provide us with any Personal Data relating to a third party (e.g. information of your dependent,
          spouse, children and/or parents), by submitting such information to us, you represent and warrant that the collection, use and
          disclosure of that Personal Data to us, as well as the further processing of that Personal Data by us for the purposes set out
          below, is lawful, and you have obtained the consent of the third party to provide us with their Personal Data for the respective
          purposes.
        </p>
        <p> </p>
        <p>
          <strong>3. Purposes for the Collection, Use and Disclosure of Personal Data</strong>
        </p>
        <p>
          <strong>3.1</strong> In general, we collect, use and disclose your Personal Data for the following purposes:
        </p>
        <p>
          a) to provide you with the products or services that you have requested, including sourcing for quotes, arranging for placement of
          your policies, handling claims on your behalf, servicing your policies and account;
        </p>
        <p>
          b) to help us review, develop, improve, manage the delivery of and to the extent this requires the use of Personal Data, enhance
          our products and services, including analysing customer behaviour and future customer needs, conducting market research, list
          processing and data analytics (including by requesting feedback from you or your participation in surveys);
        </p>
        <p>c) to help us administer, maintain, manage, renew and operate our products and services, including to carry out billing;</p>
        <p>d) for authentication and verification purposes;</p>
        <p>e) to communicate with you and respond to your queries, requests and complaints;</p>
        <p>f) to handle disputes and conduct and facilitate investigations and proceedings;</p>
        <p>g) to protect and enforce our contractual and legal rights and obligations;</p>
        <p>
          h) to prevent, detect and investigate crime, including fraud and money-laundering, and to analyse and manage other commercial
          risks;
        </p>
        <p>i) conduct checks to comply with anti-money laundering and anti-terrorism financing requirements;</p>
        <p>
          j) to manage the safety and security of our premises and services (including but not limited to carrying out CCTV surveillance and
          conducting security clearances);
        </p>
        <p>k) to manage our administrative operations and business operations and to comply with internal policies and procedures;</p>
        <p>l) to organise, facilitate, and administer roadshows, tours, promotional events, contests and/or competitions;</p>
        <p>m) to comply with any contractual terms and conditions to which Anda is bound by;</p>
        <p>
          n) to commence, respond to, or act in connection with any claims, actions or proceedings (including but not limited to drafting
          and reviewing documents, transaction documentation, obtaining legal advice, and facilitating dispute resolution);
        </p>
        <p>
          o) to monitor or record phone calls and customer-facing interactions for quality assurance, employee training and performance
          evaluation and identity verification purposes;
        </p>
        <p>p) to match any Personal Data held which relates to you for any of the purposes listed herein;</p>
        <p>q) to facilitate business asset transactions (which may extend to any merger, acquisition or asset sales);</p>
        <p>
          r) to comply with any applicable rules, laws and regulations, codes of practice or guidelines or to assist in law enforcement and
          investigations by relevant authorities; and/or
        </p>
        <p>s) purposes which are reasonably related to the aforesaid.</p>
        <p>
          <strong>3.2</strong> In addition, we may collect, use and disclose your Personal Data for the following purposes, depending on the
          nature of our relationship with you:
        </p>
        <p>a) If you have an account with and/or policy serviced by us:</p>
        <p>
          (i) to process your application for the account and/or arrange for placement of policy and to maintain your account and/or service
          your policy;
        </p>
        <p>
          (ii) to verify your personal particulars and process payment requests in relation to provision of the services which you may be
          entitled to or which you may have requested for;
        </p>
        <p>
          (iii) to communicate with you of changes and development to our policies, terms and conditions and other administrative
          information, including for the purposes of servicing you in relation to products and services offered to you;
        </p>
        <p>
          (iv) providing customer service and support (including but not limited to customer relationship management and processing and
          settlement of invoices and bills);
        </p>
        <p>
          (v) administering and processing your requests including creating and maintaining profiles of our customers in our system database
          for administrative purposes (including but not limited to tracking your use of the website);
        </p>
        <p>
          (vi) personalising your experience on the website and conducting market research, understanding and analysing customer behaviour,
          location, preferences and demographics in order to improve our service offerings;
        </p>
        <p>
          (vii) if you use the website or our application, sending you notifications and facilitating the provision of our services to you;
        </p>
        <p>(viii) administering debt recovery and debt management; and/or</p>
        <p>(ix) for purposes which are reasonably related to the aforesaid.</p>
        <p>
          b) If you are an employee, officer or owner of a vendor, contractor or other external service provider, or prospective vendor,
          prospective contractor, or other prospective external service provider of Anda:
        </p>
        <p>(i) to conduct appropriate due diligence checks;</p>
        <p>
          (ii) to evaluate your organisation’s suitability as a vendor, contractor, and external service provider for Anda and to conduct
          background checks on you;
        </p>
        <p>(iii) to create and maintain profiles of our vendors, contractors, and external service providers in our system databases;</p>
        <p>
          (iv) to process and facilitate necessary actions and processes for the purposes of the work or engagement of said vendor,
          contractor, and external service provider;
        </p>
        <p>(v) to process and facilitate payment of invoices and bills;</p>
        <p>(vi) to respond to emergencies;</p>
        <p>
          (vii) to communicate with you changes and development to our policies, terms and conditions and other administrative information;
        </p>
        <p>
          (viii) for facilities management purposes (including but not limited to issuing visitor access passes and facilitating security
          clearance);
        </p>
        <p>
          (ix) to communicate with your deployed staff, after award of contract, who are in our properties to carry out work or services,
          and for any emergency or/and security concerns; and
        </p>
        <p>(x) for purposes which are reasonably related to the aforesaid.</p>
        <p>
          <strong>3.3</strong> Furthermore, where permitted under the Data Protection Laws, Anda may also collect, use and disclose your
          Personal Data for the following “Additional Purposes”:
        </p>
        <p>
          a) taking or filming photographs and videos for corporate publicity or marketing purposes, and featuring your photographs and/or
          testimonials in our articles and publicity materials;
        </p>
        <p>
          b) providing or marketing services and benefits to you, including promotions, service upgrades, loyalty and/or reward programmes
          (including event invitations, newsletters and marketing and promotional information to you pursuant to such programmes);
        </p>
        <p>c) organising roadshows, tours, campaigns and promotional or events and administering contests and competitions;</p>
        <p>
          d) matching Personal Data with other data collected for other purposes and from other sources (including third parties) in
          connection with the provision or offering of services;
        </p>
        <p>
          e) sending you details of services, services updates and rewards, either to our customers generally, or which we have identified
          may be of interest to you;
        </p>
        <p>
          f) conducting market research, aggregating and analysing customer profiles and data to determine health-related patterns and
          trends, understanding and analysing customer behaviour, location, preferences and demographics for us to offer you other products
          and services as well as special offers and marketing programmes which may be relevant to your preferences and profile; and/or
        </p>
        <p>g) purposes which are reasonably related to the aforesaid.</p>
        <p>
          <strong>3.4</strong> If you have provided us with your Singapore telephone number(s), email address(es), mailing address(es) or
          such other contact information and have indicated that you consent to receiving marketing or promotional information via your
          Singapore telephone number(s), email address(es), mailing address(es) or such other contact information, then from time to time,
          Anda may contact you using such Singapore telephone number(s), email address(es), mailing address(es) or such other contact
          information (including via voice calls, text, fax or other means) with information about our products and services.
        </p>
        <p>
          <strong>3.5</strong> In relation to particular products or services or in your interactions with us, we may also have specifically
          notified you of other purposes for which we collect, use or disclose your Personal Data. If so, we will collect, use and disclose
          your Personal Data for these additional purposes as well, unless we have specifically notified you otherwise.
        </p>
        <p>
          <strong>3.6</strong> You may withdraw your consent for receiving marketing or promotional materials/communication by contact us
          using the contact details below.
        </p>
        <p>
          <strong>3.7</strong> Once we receive confirmation that you wish to withdraw your consent for marketing or promotional
          materials/communication, it may take up to 30 calendar days for your withdrawal to be reflected in our systems. Therefore, you may
          still receive marketing or promotional materials/communication during this period of time.
        </p>
        <p>
          <strong>3.8</strong> Please note that even if you withdraw your consent for the receipt of marketing or promotional materials, we
          may still contact you for other purposes in relation to the services that you have requested or purchased from Anda.
        </p>
        <p> </p>
        <p>
          <strong>4. Disclosure of Personal Data</strong>
        </p>
        <p>
          <strong>4.1</strong> Subject to the provisions of any applicable law, your Personal Data may be disclosed, for the purposes listed
          above (where applicable), to the following entities or parties, whether they are located overseas or in Singapore:
        </p>
        <p>a) amongst Anda and its related corporations or affiliates (including their respective staff);</p>
        <p>b) companies providing services relating to insurance or other service providers to Anda;</p>
        <p>
          c) agents, contractors, sub-contractors or third party service providers who provide operational services to Anda, such as courier
          services, telecommunications, information technology, payment, printing, billing, debt recovery, processing, technical services,
          transportation, training, market research, call centre, security, or other services to Anda;
        </p>
        <p>
          d) vendors or third party service providers and our marketing and business partners in connection with marketing promotions,
          products and services;
        </p>
        <p>
          e) any other insurer(s), other intermediaries, risk management assessors, uninsured loss recovery agencies, service providers, and
          other third parties involved directly or indirectly in the provision of our products and services to you;
        </p>
        <p>
          f) any business partner, investor, assignee or transferee (actual or prospective) to facilitate business asset transactions (which
          may extend to any merger, acquisition or asset sale);
        </p>
        <p>g) external banks, credit card companies, other financial institutions and their respective service providers;</p>
        <p>h) our professional advisers such as consultants, auditors and lawyers;</p>
        <p>
          i) relevant government ministries, regulators, statutory boards or authorities or law enforcement agencies to comply with any
          laws, rules, guidelines and regulations or schemes imposed by any governmental authority; and/or
        </p>
        <p>j) any other party to whom you authorise us to disclose your Personal Data to.</p>
        <p>
          <strong>4.2</strong> We may share information about you in aggregate or anonymised form, for example, we may want to know how long
          the average reader spends on our website, or which pages or features get the most attention.
        </p>
        <p> </p>
        <p>
          <strong>5. Keeping Your Personal Data Accurate and Up-To-Date</strong>
        </p>
        <p>
          We encourage you to contact us as soon as possible to enable us to update any Personal Data we have about you. Incomplete or
          outdated Personal Data may result in our inability to provide, or delays in providing you with products and services you have
          requested, or processing any requests and applications you may have made to us.
        </p>
        <p> </p>
        <p>
          <strong>6. Use of Cookies, Web Beacons, and Similar Technologies</strong>
        </p>
        <p>
          <strong>6.1</strong> When you visit or interact with our sites, services, we or our authorised service providers may use cookies,
          web beacons, and other similar technologies for collecting and storing information to help provide you with a better, faster, and
          safer web experience.
        </p>
        <p>
          <strong>6.2</strong> The information collected by us or our authorised service providers may recognise a visitor as a unique user
          and may collect information such as how a visitor arrives at our sites, what kind of browser a visitor is on, what operating
          system a visitor is using, a visitor’s IP address and a visitor’s click stream information and time stamp (for example, which
          pages they have viewed, the time the pages were accessed and the time spent per web page).
        </p>
        <p>
          <strong>6.3</strong> The use of cookies, web beacons and similar technologies by us on our website has different functions. They
          are either necessary for the functioning of our services, help us improve our performance, or serve to provide you with extra
          functionalities. They may also be used to deliver content that is more relevant to you and your interests, or to target
          advertising to you on or off our sites.
        </p>
        <p>
          <strong>Cookies</strong> – Small text files (typically made up of letters and numbers) placed in the memory of your browser or
          device when you visit a website or view a message. Cookies allow a website to recognise a particular device or browser. There are
          several types of cookies:
        </p>
        <ul>
          <li>
            Session cookies expire at the end of your browser session and allow us to link your actions during that particular browser
            session.
          </li>
          <li>
            Persistent cookies are stored on your device in between browser sessions, allowing us to remember your preferences or actions
            across multiple sites.
          </li>
          <li>First-party cookies are set by the site you are visiting.</li>
          <li>Third-party cookies are set by a third party site separate from the site you are visiting.</li>
        </ul>
        <p>
          Cookies can be disabled or removed by tools that are available in most commercial browsers. The preferences for each browser you
          use will need to be set separately and different browsers offer different functionality and options.
        </p>
        <p>
          <strong>Web beacons</strong> – Small graphic images (also known as “pixel tags” or “clear GIFs”) may be included on our sites and
          services. Web beacons typically work in conjunction with cookies to profile each unique user and user behaviour.
        </p>
        <p>
          <strong>Similar technologies</strong> – Technologies that store information in your browser or device utilising local shared
          objects or local storage, such as flash cookies, HTML 5 cookies, and other web application software methods. These technologies
          can operate across all of your browsers.
        </p>
        <p>
          We may use the terms “cookies” or “similar technologies” interchangeably in our policies to refer to all technologies that we may
          use to collect or store information in your browser or device or that collect information or assist in identifying you as a unique
          user in the manner described above.
        </p>
        <p>
          <strong>6.4</strong> The website and certain site features and services are available only through the use of these technologies.
          You are always free to block, delete, or disable these technologies if your browser so permits.
        </p>
        <p>
          However, if you decline cookies or other similar technologies, you may not be able to take advantage of the website and certain
          site features or services tools. For more information on how you can block, delete, or disable these technologies, please review
          your browser settings.
        </p>
        <p> </p>
        <p>
          <strong>7. Third-Party Sites</strong>
        </p>
        <p>
          Our website may contain links to other websites operated by third parties, including for example our business partners. We are not
          responsible for the data protection practices of websites operated by third parties that are linked to our website. We encourage
          you to learn about the data protection practices of such third party websites. Some of these third party websites may be
          co-branded with our logo or trade mark, even though they are not operated or maintained by us. Once you have left our website, you
          should check the applicable privacy policy of the third party website to determine how they will handle any information they
          collect from you.
        </p>
        <p> </p>
        <p>
          <strong>8. Contacting Us – Withdrawal of Consent, Access and Correction of your Personal Data</strong>
        </p>
        <p>
          <strong>8.1</strong> If you:
        </p>
        <p>a) have any questions or feedback relating to your Personal Data or about this Privacy Policy;</p>
        <p>b) would like to withdraw your consent to any use of your Personal Data as set out in this Privacy Policy; or</p>
        <p>c) would like to obtain access and make correction to your Personal Data records,</p>
        <p>please contact us as follows:</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>Method</p>
              </th>
              <th></th>
              <th>
                <p>Contact Particulars</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>By mail</p>
              </td>
              <td>
                <p>&nbsp;:&nbsp;</p>
              </td>
              <td>
                <p>Data Protection Officer</p>
                <p>Anda Insurance Agencies Pte Ltd</p>
                <p>
                  1 King George’s Avenue
                  <br />
                  &nbsp;#06-00 Rehau Building
                  <br /> &nbsp;Singapore 208557
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>By email</p>
              </td>
              <td>
                <p>&nbsp;:&nbsp;</p>
              </td>
              <td>
                <p>dataprotectionofficer@anda.com.sg</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>8.2</strong> Please note that if your Personal Data has been provided to us by a third party (e.g. your employer), you
          should contact that organisation or individual to make such queries, complaints, and access and correction requests to Anda on
          your behalf.
        </p>
        <p>
          <strong>8.3</strong> If you withdraw your consent to any or all use of your Personal Data, depending on the nature of your
          request, Anda may not be in a position to continue to provide its products and services to you, or administer any contractual
          relationship in place, which in turn may also result in the termination of any agreements with Anda, and your being in breach of
          your contractual obligations or undertakings. Anda’s legal rights and remedies in such event are expressly reserved.
        </p>
        <p> </p>
        <p>
          <strong>9. Governing Law</strong>
        </p>
        <p>This Privacy Policy shall be governed in all respects by the laws of Singapore.</p>
        <p> </p>
        <p> </p>
        <br />
        <p>PRIVACY POLICY LAST UPDATED: 16 Dec 2021</p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy2;
