import ReusableModal from 'components/modals/routemodal/ResuableModal';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, FormHelperText, Grid, Link, OutlinedInput, Stack, Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'components/@extended/AnimateButton';
import useNetworkStatus from 'hooks/useNetworkStatus';
import logo from '../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import OverlayLoader from 'components/loader/OverlayLoader';

const CustomerOtpModal = (props) => {
  const network = useNetworkStatus();
  const { makeRequest } = useApiCall();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <OverlayLoader show={loading} />
      <ReusableModal
        isOpen={props.isOpen}
        width="70%"
        maxWidth="500px"
        height="auto"
        showCloseButton={false}
        onClose={props.onClose}
        ModalContent={
          <Box p={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Formik
              initialValues={{
                otp: '',
                Password: '',
                VerifyPassword: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                otp: Yup.string().max(10).required('OTP is required'),
                Password: Yup.string().max(255).required('Password is required'),
                VerifyPassword: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match')
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  if (!network) {
                    showDanger('No internet connection.');
                    return;
                  } else {
                    setLoading(true);
                    let params = {
                      otp: values.otp,
                      Password: values.Password
                    };
                    let res = await makeRequest({ method: 'POST', url: API.OtpWiseChangePassword, params });
                    if (res.code === 200) {
                      setLoading(false);
                      props?.handleVerifyOtp();
                    } else {
                      setLoading(false);
                      showWarning(res?.message);
                    }
                  }
                } catch (e) {
                  setLoading(false);
                  showDanger(`${e}`);
                }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched }) => (
                <form noValidate onSubmit={handleSubmit} style={{display:'flex',justifyContent:'center'}}>
                  <Grid container spacing={3} xs={12} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={8} justifyContent={'center'}>
                      <img
                        src={logo}
                        alt="Anda Logo"
                        style={{
                          width: '100%',
                          height: 'auto',
                          maxWidth: '14rem'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '-10px' }}>
                      <p style={{ fontFamily: 'Thin', fontSize: 15, color: '#CACACB', fontWeight: 700, marginBottom: '2px' }}>Verify OTP</p>
                      <Stack spacing={1}>
                        <OutlinedInput
                          sx={{
                            width: '100%',
                            maxWidth: '18rem',
                            marginTop: '10px'
                          }}
                          id="otp"
                          name="otp"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter OTP"
                          error={Boolean(touched.otp && errors.otp)}
                        />
                        {touched.otp && errors.otp && (
                          <FormHelperText error id="standard-weight-helper-text-email-login">
                            {errors.otp}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '-10px' }}>
                      <Stack spacing={1}>
                        <OutlinedInput
                          sx={{
                            width: '100%',
                            maxWidth: '18rem'
                          }}
                          id="Password"
                          name="Password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Set new password"
                          error={Boolean(touched.Password && errors.Password)}
                        />
                        {touched.Password && errors.Password && (
                          <FormHelperText error id="standard-weight-helper-text-email-login">
                            {errors.Password}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '-10px' }}>
                      <Stack spacing={1}>
                        <OutlinedInput
                          sx={{
                            width: '100%',
                            maxWidth: '18rem'
                          }}
                          id="VerifyPassword"
                          name="VerifyPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Verify new password"
                          error={Boolean(touched.VerifyPassword && errors.VerifyPassword)}
                        />
                        {touched.VerifyPassword && errors.VerifyPassword && (
                          <FormHelperText error id="standard-weight-helper-text-email-login">
                            {errors.VerifyPassword}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    {errors.submit && (
                      <Grid item xs={12}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Grid>
                    )}
                    <Grid item xs={12} sx={{ width: '100%', alignItems: 'center', display: 'flex', marginTop: '-10px' }}>
                      <AnimateButton>
                        <Button
                          sx={{
                            width: '100%',
                            maxWidth: '18rem'
                          }}
                          style={{ backgroundColor: '#002d8c', fontFamily: 'Medium' }}
                          disableElevation
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Verify OTP
                        </Button>
                      </AnimateButton>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '-10px' }}>
                      <Stack alignItems="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ marginRight: '5px' }}>
                            <p style={{ fontFamily: 'Light', color: '#C9C9C9', fontWeight: 600 }}>Didn't receive OTP yet?</p>
                          </div>
                          <Link
                            variant="h6"
                            component={RouterLink}
                            to="#"
                            onClick={props?.handleClickOnResendOTP}
                            color="text.primary"
                            sx={{
                              fontFamily: 'Medium',
                              fontSize: 13,
                              color: '#4868ac'
                            }}
                          >
                            Resend OTP
                          </Link>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        }
      />
    </>
  );
};

export default CustomerOtpModal;
