import { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { showDanger } from 'components/nudges/Alert';

const DataChart = ({ filterBy }) => {
  const { makeRequest } = useApiCall();
  const [chartDataComission, setChartDataComission] = useState({ categories: [], data: [] });
  const [loading, setLoading] = useState(true);
  const network = useNetworkStatus();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 580);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const fetchChartCommisionBreakUpData = useCallback(async () => {
    const loginData = localStorage.getItem('@login');

    if (loginData) {
      const loginDataObj = JSON.parse(loginData);
      const userMasterId = loginDataObj?.userMasterId;
      // console.log('🚀 ~ fetchChartCommisionBreakUpData ~ userMasterId:', userMasterId);
      try {
        const response = await makeRequest({
          method: 'GET',
          url: `${API.CommissionBreakUpDetailsGet}?UserId=${userMasterId}&filterBy=${filterBy}`,
          skipDateTimeWarning: true
        });
        if (response?.data) {
          setChartDataComission(response?.data);
          setShowScrollbar(response?.data?.categories?.length > 1);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [filterBy]);

  useEffect(() => {
    if (!network) {
      // showDanger('No internet connection.');
      setLoading(false);
      return;
    }

    fetchChartCommisionBreakUpData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 580);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fetchChartCommisionBreakUpData, filterBy]);

  const formatNumber = (number) => {
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + 'k';
    } else {
      return number;
    }
  };

  // const barChartOptions = {
  //   chart: {
  //     type: 'bar',
  //     height: 365,
  //     toolbar: {
  //       show: false
  //     },
  //     zoom: {
  //       enabled: false
  //     },
  //     pan: {
  //       enabled: false
  //     },
  //     animations: {
  //       enabled: false
  //     }
  //   },
  //   plotOptions: {
  //     bar: {
  //       columnWidth: isMobile ? '70%' : '45px',
  //       borderRadius: 4
  //     }
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     style: {
  //       fontSize: '12px',
  //       colors: ['#2f3640']
  //     },
  //     formatter: function (val) {
  //       return formatNumber(val);
  //     }
  //   },
  //   xaxis: {
  //     categories: chartDataComission?.categories,
  //     axisBorder: {
  //       show: true
  //     },
  //     axisTicks: {
  //       show: false
  //     }
  //   },

  //   yaxis: {
  //     show: true,
  //     min: 0,
  //     labels: {
  //       style: {
  //         fontFamily: 'Medium',
  //         fontSize: 13,
  //         color: '#76777D'
  //       }
  //     }
  //   },
  //   grid: {
  //     show: true
  //   },
  //   theme: {
  //     mode: 'light',
  //     palette: 'palette1',
  //     monochrome: {
  //       enabled: false,
  //       color: '#4cb748',
  //       shadeTo: 'light',
  //       shadeIntensity: 0.65
  //     }
  //   },
  //   states: {
  //     normal: {
  //       filter: {
  //         type: 'none'
  //       }
  //     },
  //     hover: {
  //       filter: {
  //         type: 'none'
  //       }
  //     },
  //     active: {
  //       allowMultipleDataPointsSelection: false,
  //       filter: {
  //         type: 'none'
  //       }
  //     }
  //   }
  // };

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 365,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      pan: {
        enabled: false
      },
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '50px',
        borderRadius: 4
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#2f3640']
      },
      formatter: function (val) {
        return formatNumber(val);
      }
    },
    xaxis: {
      categories: chartDataComission?.categories,
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: true,
      min: 0,
      labels: {
        style: {
          fontFamily: 'Medium',
          fontSize: 13,
          color: '#76777D'
        }
      }
    },
    grid: {
      show: true
    },
    theme: {
      mode: 'light',
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: '#4cb748',
        shadeTo: 'light',
        shadeIntensity: 0.65
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none'
        }
      }
    },
    tooltip: {
      x: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          return chartDataComission.categories[dataPointIndex];
        }
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex }) {
          return `Value: ${formatNumber(value)}`;
        }
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const category = chartDataComission.categories[dataPointIndex];
        const value = series[seriesIndex][dataPointIndex];
        return `
          <div style="max-width: 240px; word-wrap: break-word; white-space: normal; border-radius: 5px;">
            <div style="margin: 5px; background-color: #f9f9f9; border: 1px solid #ddd; padding: 5px; border-radius: 5px;">
              <strong style="color: #333; font-size: 14px;">${category}</strong><br />
              <span style="color: #008FFB; font-size: 13px; font-weight: bold;">Value:</span>
              <span style="color: #008FFB; font-size: 13px;">$${formatNumber(value)}</span>
            </div>
          </div>`;
      }
    }
  };

  const series = [
    {
      data: chartDataComission?.data
    }
  ];

  return (
    <>
      {loading ? (
        <Grid item xs={12} sm={6} md={12} lg={12}>
          <Skeleton variant="rectangular" width="100%" height={350} animation="wave" style={{ borderRadius: '10px' }} />
        </Grid>
      ) : (
        <div className="chart-container">
          <div className="chart-wrapper">
            <ReactApexChart options={barChartOptions} series={series} type="bar" height={350} />
          </div>
        </div>
      )}
      <style jsx>{`
        .chart-container {
          width: 100%;
          overflow-x: ${showScrollbar ? 'auto' : 'hidden'};
          padding-top: 15px;
          padding-bottom: 15px;
        }
        .chart-wrapper {
          min-width: ${chartDataComission.categories.length * 60}px;
          max-width: 100%;
        }
        @media (max-width: 580px) {
          .chart-wrapper {
            min-width: ${Math.max(chartDataComission.categories.length * 60, 100)}%;
          }
        }
      `}</style>
    </>
  );
};

export default DataChart;
