import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, TextField, Grid, Typography, Paper } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import '../../../packages & premium/styles/SelectCustomerSideBar.css';
import { useTheme } from 'components/Theme/ThemeProvider';
import { useGetCustomerMasterbyNRICGetQuery } from 'store/services/VehicleDetails';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { API } from 'api/API';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApiCall from 'api/useApiCall';
import { activeItem } from 'store/reducers/menu';
import { store } from 'store/index';
import OverlayLoader from 'components/loader/OverlayLoader';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { removeOtherState } from 'store/reducers/premiumPolicy';

const MotorAddCustomerSidebar = (props) => {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const { riderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const { otherState, uenData } = useSelector((state) => state.persistedReducer.premiumPolicy);
  console.log('otherStatessssss', otherState);
  const [searchNric, setSearchNric] = useState('');
  const [formNric, setFormNric] = useState('');

  const [nric, setNric] = useState('');
  const [showList, setShowList] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOwnerSelected, setIsOwnerSelected] = useState(false);
  const network = useNetworkStatus();
  const { data } = useGetCustomerMasterbyNRICGetQuery({ nric: searchNric || null });

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setCustomerDetails({ ...customerDetails, email: email });
  };

  const validateEmail = (email) => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email) && !email.includes('..');
  };
  const validateNric = (nric) => {
    const nricPattern = /^[STFG]\d{7}[A-Z]$/;
    return nricPattern.test(nric);
  };

  useEffect(() => {
    if (riderDataSection) {
      Object.values(riderDataSection).forEach((rider) => {
        if (rider?.isCheckboxChecked) {
          setCustomerDetails({
            firstName: rider.firstName,
            lastName: rider.lastName,
            mobile: rider.contactNumber,
            email: rider.email || '',
            nricNo: rider.nric,
            licensePassDate: rider.license,
            street: rider.street || otherState['contact']?.street,
            postalCode: rider.postalCode || otherState['contact']?.postalCode,
            country: rider.country || otherState['contact']?.country || 'Singapore'
          });
          setIsOwnerSelected(true);
        }
      });
    }
  }, [riderDataSection]);

  useEffect(() => {
    if (data && data?.success === true && data?.data) {
      const customer = data.data;
      setCustomerDetails({
        ...customerDetails,
        firstName: customer.firstName || '',
        lastName: customer.lastName || '',
        gender: customer.gender || '',
        email: customer.email || '',
        maritalStatus: customer.maritalStatus || '',
        source: customer.source || '',
        mobile: customer.mobile || '',
        designation: customer.designation || '',
        occupation: customer.occupation || '',
        licensePassDate: customer.licensePassDate || '',
        street: customer.street,
        city: customer.city || '',
        state: customer.state || '',
        postalCode: customer.postalCode,
        country: customer.country || 'Singapore',
        remarks: customer.remarks || '',
        nricNo: formNric
      });
    }
  }, [data, formNric]);

  useEffect(() => {
    if (!network) {
      showDanger('No internet connection.');
      navigate('/dashboard/Policies');
    }
  }, [network]);

  const customerBody = {
    firstName: otherState['contact']?.firstName,
    lastName: otherState['contact']?.lastName,
    gender: '',
    email: otherState['contact']?.email,
    maritalStatus: '',
    source: '',
    mobile: otherState['contact']?.mobile || null,
    designation: '',
    occupation: '',
    licensePassDate: otherState['contact']?.licensePassDate,
    street: otherState['contact']?.street,
    city: '',
    state: '',
    postalCode: otherState['contact']?.postalCode || '',
    country: otherState['contact']?.country || 'Singapore' || '',
    remarks: '',
    nricNo: otherState['contact']?.nricNo
  };
  console.log('customerBody', customerBody);

  const [customerDetails, setCustomerDetails] = useState(customerBody);
  console.log('customerDetails', customerDetails);
  // const { data } = useGetCustomerMasterbyNRICGetQuery({ nric: searchNric || null });

  const handleSubmitCustomer = async () => {
    setLoading(true);
    try {
      if (isButtonDisabled) return;

      setIsButtonDisabled(true);
      if (
        !customerDetails?.firstName ||
        !customerDetails?.lastName ||
        !customerDetails?.nricNo ||
        !customerDetails.email ||
        !customerDetails.street ||
        !customerDetails.country ||
        !customerDetails.postalCode
      ) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please fill in all required fields');
      } else if (!validateEmail(customerDetails.email)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid email address');
      } else if (!validateNric(customerDetails.nricNo)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid NRIC');
      } else {
        
        const requestBodyRider = Object.values(riderDataSection)?.map((rider) => {
          return {
            firstName: rider?.firstName,
            lastName: rider?.lastName,
            nric: rider?.nric,
            dob: rider?.dob,
            gender: rider?.gender,
            drivingLicense: rider?.license,
            nationality: rider?.nationality,
            contact: rider?.contactNumber,
            email: rider?.email,
            // claimsAmount: rider?.claims,
            active: true,
            workPermitNo: '',
            passportNo: '',
            claimsIn3Year: 0,
            amountOfClaimsIn3Year: 0,
            age: rider?.age,
            experienceInMonths: rider?.experience,
            ageOfDate: '',
            experienceInMonthsOfDate: '',
            fin: '',
            isOwner: rider?.isCheckboxChecked || false,
            isRider: rider?.isRiderCheckboxChecked || false
          };
        });

        let isFoodParcel = otherState?.isFoodParcel;
        if (isFoodParcel === true) {
          isFoodParcel = 'yes';
        } else if (isFoodParcel === false) {
          isFoodParcel = 'no';
        }
        let finalAmount = parseFloat(props?.apiData?.finalPremiumAmount) + parseFloat(props?.apiData?.fdExtentionAmountWithoutGST);
        let body = {
          nric: customerDetails.nricNo,
          uen: null,
          isIndividual: otherState.uen === '' ? true : false,
          insuranceStart: otherState.POIStartDate,
          insuranceEnd: otherState.POIEndDate,
          opportunityType: 'Motor',
          portalUserId: props.apiData.userId,
          creditTerm: true,
          durationInMonths: '',
          transactionType: otherState.transactionType,
          companyRegistered: otherState.isCompanyRegistered,
          vehicleId: otherState.vehicleId,
          vrn: otherState.vehicleRegistration,
          coverageType: props?.apiData.productSubType,
          deliveryExtension: isFoodParcel,
          chasisNo: otherState.chasisNumber,
          engineNo: otherState.engineNumber,
          drivingLicenseDate: customerDetails.licensePassDate,
          excess: props.apiData.excessAmount,
          claimsDetails: otherState.claimDetails,
          nameOfHirePurchase: otherState.nameOfHirePurchase,
          workshopType: otherState.workShopType,
          // vehicleTonnage: otherState.vehicleTonnage || '0',
          ncdAmount: props.apiData.ncdAmount,
          ncd: otherState?.NCD.toString(),
          // ncdProtector: '',
          reg_manufactureDate: otherState?.RegistrationDate,
          bondDuration: '',
          reTransmissionReminder: null,
          reviewedByInsurer: false,
          basisOfCover: '',
          mainContractorName: '',
          cpfSubmissionNo: '',
          transmissionStatus: '',
          dischargeDate: null,
          loiStatus: '',
          bondCallStatus: '',
          loiType: '',
          contact: customerDetails,
          items: [
            {
              itemCode: props?.apiData?.productID,
              salesPrice: finalAmount,
              quantity: 1
            }
          ],
          insuredParty: requestBodyRider,
          productId: props.apiData.productID,
          loginDetailsId: props?.apiData?.loginDetailsId,
          customerPolicyId: props?.params?.customerPolicyId || null,
          opportunityId: props?.params?.opportunityId || null,
          totalPremiumAmount: props?.apiData.finalTotalPremiumAmount || 0
        };
        if (Object.keys(uenData).length > 0) {
          body['account'] = {
            accName: uenData.name,
            accEmail: uenData.email_Address__c,
            phone: uenData.phone?.toString(),
            source: uenData.accountSource,
            bankAccountName: uenData.bank_Account_Name__c,
            bankCode: uenData.bank_Code__c,
            bankName: uenData.bank_Name__c,
            bankAccountNo: uenData.bank_Account_No__c,
            street: uenData.billingStreet,
            city: uenData.billingCity,
            state: uenData.billingState,
            postalCode: uenData.billingPostalCode,
            country: uenData.billingCountry
          };
        } else {
          body['account'] = {
            accName: otherState.UEMSTATUS == null ? '' : otherState.cm,
            accEmail: otherState.UEMSTATUS == null ? '' : otherState.email,
            phone: otherState.UEMSTATUS == null ? '' : otherState.phone?.toString(),
            source: '',
            bankAccountName: '',
            bankCode: '',
            bankName: '',
            bankAccountNo: '',
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: ''
          };
        }

        const response = await makeRequest({ method: 'POST', url: API.FinalSubmissionCustomerPortalForFWDHAPI, body });
        if (response?.code == 200) {
          showSuccess(response?.data?.message);
          props.setShow(false);
          store.dispatch(activeItem({ openItem: ['policies'] }));
          setLoading(true);
          store.dispatch(removeOtherState());
          navigate('/dashboard/Policies');
        } else {
          setIsButtonDisabled(false);
          setLoading(false);
          showDanger('Policy Not Created');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSearch = (e) => {
  //   setShowList(true);
  //   const searchTerm = e?.target?.value;
  //   setNric(searchTerm);

  //   setCustomerDetails({
  //     firstName: '',
  //     lastName: '',
  //     mobile: '',
  //     email: '',
  //     nricNo: '',
  //     licensePassDate: '',
  //     postalCode: '',
  //     country: '',
  //     street: ''
  //   });
  // };

  const handleSearchNRICChange = (event) => {
    const value = event.target.value.toUpperCase();
    const regex = /^[A-Z0-9]*$/;
    if (regex.test(value)) {
      setSearchNric(value);
      setShowList(true);
    }
    if (!searchNric) {
      setFormNric('');
      setCustomerDetails({
        firstName: '',
        lastName: '',
        gender: '',
        email: '',
        maritalStatus: '',
        source: '',
        mobile: '',
        designation: '',
        occupation: '',
        licensePassDate: '',
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        remarks: '',
        nricNo: ''
      });
    }
  };

  const handleFormNRICChange = (event) => {
    const nric = event.target.value.toUpperCase();
    const regex = /^[A-Z0-9]*$/;
    if (regex.test(nric)) {
      setFormNric(nric);
      setCustomerDetails({ ...customerDetails, nricNo: nric });
    }
  };

  const handleNameClick = (name) => {
    setShowList(false);
    if (name) {
      setSearchNric(name?.nricNo);
      setFormNric(name?.nricNo);
      setCustomerDetails({
        firstName: name?.firstName,
        lastName: name?.lastName,
        mobile: name?.mobilePhone,
        email: name?.finance_Email__c,
        nricNo: name?.nricNo,
        postalCode: name?.postalCode,
        street: name?.street,
        country: name?.country
      });
    }
  };

  // useEffect(() => {}, [searchNric, riderDataSection]);

  function handleNumericKeyPress(event) {
    const keyPressed = event.key;
    if (!/^\d$/.test(keyPressed) && !event.ctrlKey && !event.metaKey && !event.altKey) {
      event.preventDefault();
    }
  }
  return (
    <Grid className="topContainer_SelectCustomerSideBar">
      <Grid className="heading_SelectCustomerSideBar">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Selected Customer
        </Typography>
      </Grid>

      <hr />

      <Grid className="searchbarGrid_SelectCustomerSideBar">
        <TextField
          placeholder="Search"
          className="textfield_SelectCustomerSideBar"
          variant="standard"
          size="small"
          value={searchNric}
          onChange={handleSearchNRICChange}
          InputProps={{
            disableUnderline: true,
            endAdornment: <SearchIcon color="action" className="inputPropStyle_SelectCustomerSideBar" />
          }}
          inputProps={{
            autoComplete: 'new-password', // Use a unique value
            name: `random-name-${Math.random()}`, // Randomize name attribute
            style: {
              fontSize: '13px'
            }
          }}
          disabled={isOwnerSelected}
        />
      </Grid>
      {showList && data != '' && (
        <Paper
          style={{
            padding: '2%',
            margin: '0.5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '100%',
            position: 'absolute',
            zIndex: 4,
            maxHeight: '30%',
            overflowY: 'auto'
          }}
        >
          {data?.map((item, index) => (
            <Grid item onClick={() => handleNameClick(item)} style={{ width: '100%' }}>
              <Typography key={index} style={{ width: '100%', cursor: 'pointer', fontSize: '13px' }}>
                {item?.nricNo}
              </Typography>
              <hr style={{ width: '100%', border: 'none', borderBottom: '1px solid lightgray' }} />
            </Grid>
          ))}
        </Paper>
      )}

      <Grid className="stylinghrtags_SelectCustomerSideBar">
        <Grid style={{ width: '20%', marginRight: '2%' }}>
          <hr />
        </Grid>
        <p style={{ marginTop: '1%' }}>Or</p>
        <Grid style={{ width: '20%', marginLeft: '2%' }}>
          <hr />
        </Grid>
      </Grid>

      <Grid className="addCustomersGridStyling">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Add Customer
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="allinputpositioningstyling">
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>First Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.firstName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, firstName: e.target.value })}
              disabled={isOwnerSelected}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Last Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.lastName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, lastName: e.target.value })}
              disabled={isOwnerSelected}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Phone Number</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.mobile}
              onKeyPress={handleNumericKeyPress}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              style={{ marginTop: 5 }}
              onChange={(e) => setCustomerDetails({ ...customerDetails, mobile: e.target.value })}
              disabled={isOwnerSelected}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Email Address</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              type="email"
              size="small"
              variant="standard"
              value={customerDetails?.email}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={handleEmailChange}
              disabled={isOwnerSelected}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          {/* Last Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>NRIC</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              placeholder="e.g. S3272118D"
              value={isOwnerSelected ? customerDetails?.nricNo : formNric}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={handleFormNRICChange}
              disabled={isOwnerSelected}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Driving License Date</InputLabel>
            </div>

            <TextField
              type="date"
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px', marginTop: 5 }}
              value={customerDetails?.licensePassDate}
              onChange={(e) => {
                setCustomerDetails({ ...customerDetails, licensePassDate: e.target.value });
              }}
              required
              inputProps={{
                max: new Date().toISOString().split('T')[0],

                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              disabled={isOwnerSelected}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Address</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.street}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, street: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              // disabled={isOwnerSelected}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Postal Code</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.postalCode}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, postalCode: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              // disabled={isOwnerSelected}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Country</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.country}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, country: e.target.value })}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              // disabled={isOwnerSelected}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} lg={12} mt={-2}>
        <Button
          size="small"
          variant="contained"
          disabled={isButtonDisabled}
          style={{
            borderRadius: '8px',
            color: isButtonDisabled ? '#000' : '#fff',
            backgroundColor: isButtonDisabled ? '#dfe6e9' : '#002D8C',
            minWidth: '40%',
            marginRight: '10px',
            padding: '2%',
            fontFamily: 'Medium'
          }}
          onClick={() => handleSubmitCustomer()}
        >
          Complete Purchase
        </Button>
        <Button
          size="small"
          variant="contained"
          style={{
            borderRadius: '8px',
            color: '#002D8C',
            backgroundColor: '#fff',
            minWidth: '40%',
            marginRight: '10px',
            padding: '2%',
            fontFamily: 'Medium',
            border: '1px solid #002D8C'
          }}
          onClick={() => props.setShow(false)}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
          <OverlayLoader show={true} />
        </div>
      )}
    </Grid>
  );
};

export default MotorAddCustomerSidebar;
