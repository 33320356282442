import React, { useState, useEffect } from 'react';
import { Grid, TextField, InputLabel } from '@mui/material';
import DropDown from 'components/dropdown/DropDown';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector } from 'react-redux';
import { useOwnerDetails } from 'modules/packages & premium/hooks/useVehicle';

function RenderFunction({ item, index, otherState }) {
  const [value, setValue] = useState(otherState[item?.value]);
  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    if (item.value === 'ownerDob') {
      setDisplayValue(otherState[item?.value]);
    } else if (item.value === 'ownerLicense') {
      setDisplayValue(otherState[item?.value]);
    } else {
      setValue(otherState[item?.value]);
    }
  }, [item.value, otherState]);

  const calculateMonthsOfExperience = (selectedDate) => {
    const currentDate = new Date();
    const date = new Date(selectedDate);
    const yearsDifference = currentDate.getFullYear() - date.getFullYear();
    const monthsDifference = currentDate.getMonth() - date.getMonth();
    return yearsDifference * 12 + monthsDifference;
  };
  const calculateAge = (dob) => {
    const currentDate = new Date();
    const birthDate = new Date(dob);
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    const dayDifference = currentDate.getDate() - birthDate.getDate();
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
    return age;
  };

  const onChangeText = (txt, value) => {
    try {
      let dataToStore;
      if (value === 'ownerDob') {
        setDisplayValue(txt);
        dataToStore = txt;
        const age = calculateAge(txt);

        store.dispatch(addOtherIndividualState(['ownerAge', age]));
      } else if (value === 'ownerLicense') {
        setDisplayValue(txt);
        dataToStore = txt;
        const monthsOfExperience = calculateMonthsOfExperience(txt);
        const experienceString = `${monthsOfExperience} month${monthsOfExperience !== 1 ? 's' : ''}`;
        store.dispatch(addOtherIndividualState(['ownerExperience', experienceString]));
      } else {
        dataToStore = txt;
      }
      store.dispatch(addOtherIndividualState([value, dataToStore]));
      setValue(txt);
    } catch (e) {
      console.error('Error during change text inside motorVehiclePolicyInformation ->', e);
    }
  };

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.value);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };
  return (
    <Grid item xs={12} md={4} alignItems={'center'} key={index}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <InputLabel className="inputs_styless_">{item.label}</InputLabel>
        {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
      </div>

      {item.type == 'textField' && (
        <TextField
          type={item?.texttype}
          // disabled={claimCount == 0 && item?.value == 'claimamount'}
          onChange={(txt) => onChangeText(txt.target.value, item?.value)}
          required={item?.isRequired}
          size="small"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          className="txtinputs_stylingg"
          value={item.value === 'ownerDob' || item.value === 'ownerLicense' ? displayValue : value}
          inputProps={{
            max: new Date().toISOString().split('T')[0]
            // max: (() => {
            //   const maxDate = new Date();
            //   maxDate.setFullYear(maxDate.getFullYear() + 10);
            //   return maxDate.toISOString().split('T')[0];
            // })()
          }}
        />
      )}

      {item.type == 'dropdown' && (
        <DropDown
          width={'96%'}
          marginTop={'10px'}
          heightsss={'37px'}
          height={'40px'}
          backgroundColor="#F8FAFB"
          options={item?.ddoptions || []}
          onValueChange={onValueChange}
          defaultValue={otherState[item?.value]}
        />
      )}
    </Grid>
  );
}
const OnwerRiderDetails = () => {
  const { data } = useOwnerDetails();
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#000', fontWeight: 600 }}>
          Owner Details
        </InputLabel>
      </Grid>
      {data.map((item, index) => {
        return <RenderFunction key={index} item={item} index={index} otherState={otherState} />;
      })}
    </Grid>
  );
};

export default OnwerRiderDetails;
