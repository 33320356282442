import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import '../../packages & premium/styles/SelectCustomerSideBar.css';
import { useTheme } from 'components/Theme/ThemeProvider';
import DropDown from 'components/dropdown/DropDown';
import useGetCountriesList from '../hooks/useGetCountriesList';
import useHandleRider from '../hooks/useHandleRider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { showWarning } from 'components/nudges/Alert';
import { nricValidator } from 'helper/utils';

const AddRiderSidebar = (props) => {
  const { buttonLabel, isEditMode } = props;
  const { currentTheme } = useTheme();
  const selectedRowData = props?.selectedRow;
  const { riderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const getCountriesListData = useGetCountriesList();

  const [firstName, setFirstName] = useState(selectedRowData ? selectedRowData.firstName : '');
  const [lastName, setLastName] = useState(selectedRowData ? selectedRowData.lastName : '');
  const [nric, setNric] = useState(selectedRowData ? selectedRowData.nric : '');
  const [dob, setDob] = useState(selectedRowData ? selectedRowData.dob : '');
  const [gender, setGender] = useState(selectedRowData ? selectedRowData?.gender : '');
  const [license, setLicense] = useState(selectedRowData ? selectedRowData?.license : '');
  const [nationality, setNationality] = useState(selectedRowData ? selectedRowData.nationality : '');
  const [contactNumber, setContactNumber] = useState(selectedRowData ? selectedRowData.contactNumber : '');
  const [email, setEmail] = useState(selectedRowData ? selectedRowData.email : '');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(selectedRowData ? selectedRowData.isCheckboxChecked : false);
  const [isRiderCheckboxChecked, setIsRiderCheckboxChecked] = useState(selectedRowData ? selectedRowData.isRiderCheckboxChecked : false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);

  useEffect(() => {
    if (selectedRowData?.isCheckboxChecked) {
      setIsCheckboxDisabled(false);
    } else {
      let temp = Object.values(riderDataSection)?.map((rider) => rider?.isCheckboxChecked);
      if (temp?.includes(true)) setIsCheckboxDisabled(true);
    }

    let temp = Object.values(riderDataSection)?.map((rider) => rider?.isCheckboxChecked);
    if (temp?.includes(true)) setIsCheckboxDisabled(true);
  }, []);

  const [handleAddRider] = useHandleRider(
    buttonLabel,
    selectedRowData,
    props.setShow,
    firstName,
    lastName,
    nric,
    dob,
    gender,
    license,
    nationality,
    contactNumber,
    email,
    isCheckboxChecked,
    isRiderCheckboxChecked,
    isCheckboxDisabled,
    isEditMode ? 'edit' : 'add',
    setIsCheckboxDisabled
  );
  const options = [
    { label: 'Male', id: 1 },
    { label: 'Female', id: 2 }
  ];

  const handleDOBChange = (dob) => {
    setDob(dob);
  };

  const handleDOBBlur = (dob) => {
    const currentDate = new Date().toISOString().split('T')[0];
    if (new Date(dob) > new Date(currentDate)) {
      setDob(currentDate);
    }
  };

  function handleNumericKeyPress(event) {
    const keyPressed = event.key;
    if (!/^\d$/.test(keyPressed) && !event.ctrlKey && !event.metaKey && !event.altKey) {
      event.preventDefault();
    }
  }

  const handleChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setIsRiderCheckboxChecked(false);
    }
  };
  const handleLicenseDate = (newValue) => {
    const currentDate = new Date();
    const selectedDate = new Date(newValue);
    if (selectedDate > currentDate) {
      newValue = currentDate.toISOString().split('T')[0];
    }
    setLicense(newValue);
  };
  const handleButtonClick = () => {
    if (nricValidator(nric)) {
      handleAddRider();
    } else {
      showWarning('Please enter a valid NRIC Number');
    }
  };

  const handleNricChange = (e) => {
    const value = e.target.value.toUpperCase();
    const regex = /^[A-Z0-9]*$/;
    if (regex.test(value)) {
      setNric(value);
    }
  };
  return (
    <Grid className="topContainer_SelectCustomerSideBar">
      <Grid className="heading_SelectCustomerSideBar">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          {isEditMode ? 'Edit Rider' : 'Add Rider'}
        </Typography>
      </Grid>

      <hr />
      <Grid item xs={12} sm={12} md={12} className="allinputpositioningstyling">
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">First Name</InputLabel>
              <span className="asteriskContainer">
                <span className={`requiredIndicator ${firstName ? 'visible' : 'visible'}`}>*</span>
              </span>
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              className="commonInput"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">Last Name</InputLabel>
              <span className="asteriskContainer">
                <span className={`requiredIndicator ${lastName ? 'visible' : 'visible'}`}>*</span>
              </span>
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              className="commonInput"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">NRIC/FIN No.</InputLabel>
              <span className="asteriskContainer">
                <span className={`requiredIndicator ${nric ? 'visible' : 'visible'}`}>*</span>
              </span>
            </div>
            <TextField
              size="small"
              variant="standard"
              placeholder="e.g. S3272118D"
              // InputProps={{ disableUnderline: true }}
              className="commonInput"
              value={nric}
              onChange={handleNricChange}
              required
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">DOB</InputLabel>
              <span className="asteriskContainer">
                <span className={`requiredIndicator ${dob ? 'visible' : 'visible'}`}>*</span>
              </span>
            </div>
            <TextField
              type="date"
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              className="commonInput"
              value={dob}
              // onChange={(e) => handleDOBChange(e.target.value)}
              onChange={(e) => handleDOBChange(e.target.value)}
              onBlur={(e) => handleDOBBlur(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                max: new Date().toISOString().split('T')[0],
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">Gender</InputLabel>
              <span className="asteriskContainer">
                <span className={`requiredIndicator ${gender ? 'visible' : 'visible'}`}>*</span>
              </span>
            </div>
            <DropDown
              marginTop={'1px'}
              width={'98%'}
              heightsss={'37px'}
              placeholder="Gender"
              height={'40px'}
              options={options}
              value={gender}
              onClick={(e) => e.stopPropagation()}
              onValueChange={(i) => {
                setGender(i.label);
              }}
            />
          </Grid>
          {/* Third Row */}
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">Driving License Date</InputLabel>
              {(!isCheckboxChecked || isRiderCheckboxChecked) && (
                <span className="asteriskContainer">
                  <span className={`requiredIndicator ${license ? 'visible' : 'visible'}`}>*</span>
                </span>
              )}
            </div>
            <TextField
              type="date"
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              className="commonInput"
              style={{ marginTop: 3 }}
              value={license}
              onChange={(e) => handleLicenseDate(e.target.value)}
              required={isCheckboxChecked || isRiderCheckboxChecked}
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                max: new Date().toISOString().split('T')[0],
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">Nationality</InputLabel>
            </div>
            <DropDown
              marginTop={'1px'}
              width={'99%'}
              heightsss={'37px'}
              height={'40px'}
              placeholder="Nationality"
              options={getCountriesListData}
              onValueChange={(i) => {
                setNationality(i.label);
              }}
              value={nationality}
              onClick={(e) => e.stopPropagation()}
            />
          </Grid>

          {/* Fourth Row */}
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">Contact Number</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              className="commonInput"
              value={contactNumber}
              onKeyPress={handleNumericKeyPress}
              onChange={(e) => setContactNumber(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                // max: new Date().toISOString().split('T')[0],
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="inputContainer">
            <div className="inputLabels">
              <InputLabel className="inputLabel">Email Address</InputLabel>
              {(isCheckboxChecked || (isCheckboxChecked && isRiderCheckboxChecked)) && (
                <span className="asteriskContainer">
                  <span className={`requiredIndicator ${email ? 'visible' : 'visible'}`}>*</span>
                </span>
              )}
            </div>
            <TextField
              type="email"
              size="small"
              variant="standard"
              // InputProps={{ disableUnderline: true, required: true }}
              className="commonInput"
              // style={{ marginTop: isCheckboxChecked ? 4.5 : 2 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                // max: new Date().toISOString().split('T')[0],
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Medium' // Change the font family
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={7} className="inputContainer">
            <FormControlLabel
              style={{ fontFamily: 'Medium', fontSize: '13px' }}
              control={<Checkbox checked={isCheckboxChecked} onChange={(e) => handleChange(e)} disabled={isCheckboxDisabled} />}
              label="Owner"
            />

            {isCheckboxChecked && (
              <FormControlLabel
                style={{ fontFamily: 'Medium', fontSize: '13px' }}
                control={
                  <Checkbox
                    checked={isRiderCheckboxChecked}
                    onChange={(e) => {
                      setIsRiderCheckboxChecked(e.target.checked);
                    }}
                  />
                }
                label="Insured Person Riding"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              size="small"
              variant="contained"
              style={{
                borderRadius: '8px',
                color: '#fff',
                backgroundColor: '#002d8c',
                minWidth: '50px',
                marginRight: '10px',
                padding: '2px 6px 2px 6px',
                fontFamily: 'Medium'
              }}
              onClick={() => handleButtonClick()}
            >
              {isEditMode ? 'Edit Rider' : 'Add Rider'}
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{
                borderRadius: '8px',
                color: '#002d8c',
                backgroundColor: '#ffffff',
                minWidth: '50px',
                marginRight: '10px',
                padding: '2px 6px 2px 6px',
                fontFamily: 'Medium',
                border: '1px solid #002d8c'
              }}
              onClick={() => props.setShow(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddRiderSidebar;
