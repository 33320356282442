import ReusableCard from 'components/cards/ResuableCard';
import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, IconButton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from 'react-redux';
import { DomesticCustomerEmployeeInformation } from 'modules/packages & premium/static';
import { store } from 'store/index';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import WestIcon from '@mui/icons-material/West';
import DropDown from 'components/dropdown/DropDown';
import useGetCountriesList from 'modules/packages & premium/hooks/useGetCountriesList';
import OverlayLoader from 'components/loader/OverlayLoader';

const RenderFunction = ({ item, index, otherState, dropdownData }) => {
  const [value, setValue] = useState(otherState[item?.value]);

  // const onChangeText = (txt, value) => {
  //   try {
  //     store.dispatch(addOtherIndividualState([value, txt]));
  //     setValue(txt);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const onChangeText = (txt, value) => {
    if (value === 'dhcustomernricfin') {
      txt = txt.toUpperCase();
    }
    setValue(txt);
  };

  const onBlur = (value) => {
    const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
    const currentDate = new Date().toISOString().split('T')[0];
    if (item?.texttype === 'date') {
      if (allowedCharsForDate.test(value)) {
        if (value <= currentDate) {
          store.dispatch(addOtherIndividualState([item.value, value]));
        } else {
          console.warn('Invalid date. Please enter a date not greater than today.');
          setValue(currentDate);
          store.dispatch(addOtherIndividualState([item.value, currentDate]));
        }
      } else {
        console.warn('Invalid date format. Please enter in YYYY-MM-DD format.');
      }
    } else {
      store.dispatch(addOtherIndividualState([item.value, value]));
    }
  };

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };

  return (
    <Grid item xs={12} md={4} key={index}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <InputLabel className="inputs_styless_">{item.label}</InputLabel>
        {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
      </div>

      {item.type === 'textField' ? (
        <TextField
          type={item?.texttype}
          onChange={(txt) => onChangeText(txt.target.value, item?.value)}
          onBlur={() => onBlur(value)}
          required={item?.isRequired}
          size="small"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          className="txtinputs_stylingg"
          value={value}
          style={{ marginTop: '13px' }}
          inputProps={{
            autoComplete: 'new-password', // Use a unique value
            name: `random-name-${Math.random()}`, // Randomize name attribute
            pattern: /^\d{4}$/,
            max: new Date().toISOString().split('T')[0]
          }}
        />
      ) : (
        <></>
      )}
      {item?.type === 'dropdown' && (
        <div style={{ marginTop: '12px' }}>
          <DropDown
            width={'96%'}
            heightsss={'38px'}
            backgroundColor="#F8FAFB"
            options={item?.ddoptions || dropdownData}
            onValueChange={onValueChange}
            defaultValue={otherState[item?.value]}
          />
        </div>
      )}
    </Grid>
  );
};

const DomesticHelperPolicyAddOnForm = React.memo(({ handleNextButton, handlePreviousButton, loading, setLoading }) => {
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [getUpdatedCountriesListData, setUpdatedCountriesListData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const getCountriesListData = useGetCountriesList();
  const { finalResponse } = useSelector((state) => state?.persistedReducer?.premiumPolicy);
  const { premiumCalculation } = useSelector((state) => state?.persistedReducer?.premiumPolicy);

  const additionalCompanyFields = [
    { label: 'UEN Number', value: 'UENnoCompany', type: 'textField', astric: true },
    { label: 'Company Name', value: 'companyNameCompany', type: 'textField', astric: true },
    { label: 'Email Address', value: 'emailaddressCompany', type: 'textField', astric: true }
  ];

  useEffect(() => {
    const updatedCountriesListData = getCountriesListData.map((country) => ({
      ...country,
      value: 'dhcustomernationality'
    }));
    setUpdatedCountriesListData(updatedCountriesListData);
  }, [getCountriesListData]);

  useEffect(() => {
    setEmployeeData(DomesticCustomerEmployeeInformation);
    if (otherState.policytype === 'Company') {
      setCompanyData(additionalCompanyFields);
    } else {
      setCompanyData([]);
    }
  }, [otherState.policytype]);

  const handleProceedPayment = () => {
    handleNextButton();
  };

  return (
    <>
      {(!finalResponse?.qrCode || !premiumCalculation) && <OverlayLoader show={loading} />}
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'5%'}
        boxShadow={'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '700' }}>Employee Details</p>
            </Grid>
            {employeeData.map((item, index) => (
              <RenderFunction
                key={index}
                item={item}
                index={index}
                setVerificationMessage={setVerificationMessage}
                verificationMessage={verificationMessage}
                otherState={otherState}
                dropdownData={getUpdatedCountriesListData}
                loading={loading}
              />
            ))}

            {companyData.length > 0 && (
              <>
                <Grid item xs={12}>
                  <p style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '700' }}>Company Details</p>
                </Grid>
                {companyData.map((item, index) => (
                  <RenderFunction
                    key={index}
                    item={item}
                    index={index}
                    setVerificationMessage={setVerificationMessage}
                    verificationMessage={verificationMessage}
                    otherState={otherState}
                    dropdownData={getUpdatedCountriesListData}
                    loading={loading}
                  />
                ))}
              </>
            )}

            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={2.5} style={{ marginLeft: 20 }}>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    backgroundColor: '#f7f7f7',
                    width: '100%',
                    padding: '5%',
                    color: '#000000',
                    height: '100%',
                    border: '1px solid #ddd'
                  }}
                  onClick={handlePreviousButton}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item xs={12} md={2.5}>
                <Button
                  size="small"
                  variant="contained"
                  style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff', height: '100%' }}
                  onClick={handleProceedPayment}
                >
                  Proceed To Payment
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
});

export default DomesticHelperPolicyAddOnForm;
