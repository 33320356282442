import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePackagePremiumState } from 'modules/packages & premium/provider/PackagePremiumStateProvider';
import { useSelector } from 'react-redux';
import { getLoginDetailsId, getUserId } from 'helper/utils';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import { showDanger, showWarning } from 'components/nudges/Alert';
import { removeOtherState } from '../../../../store/reducers/premiumPolicy';
import { store } from 'store/index';
import OverlayLoader from 'components/loader/OverlayLoader';
import useNetworkStatus from 'hooks/useNetworkStatus';
const validateEmail = (email) => {
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  return emailRegex.test(email);
};
const ForeignWorkerBottomPortion = () => {
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const { params } = usePackagePremiumState();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { otherState } = useSelector((state) => state?.persistedReducer?.premiumPolicy);
  const network = useNetworkStatus();
  let workerSectionLength = Object?.keys(otherState?.workerSection)?.length;
  const handleResetClick = () => {
    store.dispatch(removeOtherState());
    window.location.reload();
  };
  const handleClick = async () => {
    const POIStartDates = new Date(otherState.fromDate);
    let currentDate = new Date().toISOString().split('T')[0];
    const currentDat = new Date();
    const maxAcceptableDate = new Date(currentDat);
    maxAcceptableDate.setDate(currentDat.getDate() + 90);

    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      if (isButtonDisabled) return;
      setIsButtonDisabled(true);
      const loginDetailsId = await getLoginDetailsId();
      const userId = await getUserId();
      if (otherState.UEMSTATUS === false && otherState.uen != '' && otherState.cm == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter company name');
      } else if (otherState.UEMSTATUS === false && otherState.uen != '' && otherState.email == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter email');
      } else if (otherState.UEMSTATUS === false && otherState.uen != '' && !validateEmail(otherState.email)) {
        setIsButtonDisabled(false);
        showWarning('Please enter valid email address');
      } else if (POIStartDates > maxAcceptableDate) {
        setIsButtonDisabled(false);
        showWarning('Please select a start date that is within the next 90 days.');
      } else if (otherState.fromDate < currentDate) {
        setIsButtonDisabled(false);
        showWarning("The start date must be the same as today's date");
        return;
      } else {
        setIsLoading(true);
        const body = {
          userId: userId,
          productId: params.productID,
          loginDetailsId,
          basisOfCover: otherState.basisofcover,
          nameOfMainContractor: otherState.maincontractor,
          uenNumber: otherState.uen,
          companyName: otherState?.cm,
          email: otherState?.email,
          phone: otherState?.phone,
          cpfSubmissionNo: otherState.CPF,
          dischargeDate: otherState.dischargeDate,
          transmissionStatus: 'Waiting on User',
          bondCallStatus: 'Premium',
          bondDuration: otherState.bondduration,
          loiStatus: 'RECEIVED',
          loiType: 'BLANKET',
          fromDate: otherState.fromDate,
          toDate: otherState.toDate,
          durationInMonth: otherState?.durationInMonths || '0',
          // durationInMonth: '2 Months',
          // durationInDays: otherState.durationInDays,
          subCategory: otherState.subcategory,
          totalRiderCount: workerSectionLength,
          // subCategory: "3000",
          addonFWDHProductPricingListId:
            Object?.keys(otherState.addOn)?.length > 0 ? Object?.keys(otherState.addOn)?.map((key) => key) : ['0']
        };
        const res = await makeRequest({ method: 'POST', url: API.FWDHCalculationPost, body });
        if (res?.code === 200) {
          setIsButtonDisabled(true);
          setIsLoading(false);
          navigate('/dashboard/PremiumCalculation', { state: { data: params, apiData: res.data } });
        } else {
          showDanger('Error');
          setIsLoading(false);
          setIsButtonDisabled(false);
        }
      }
    } catch (e) {
      showDanger(e);
      console.error('Error in Foreign Worker Bottom Portion', e);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
      <OverlayLoader show={isLoading} />
      <Grid
        container
        xs={12}
        sm={6}
        md={4}
        lg={4}
        mt={2}
        ml={2}
        mb={1}
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginLeft: '4.8%', padding: '1% 0% 0.5% 0% ' }}
      >
        <Button
          variant="contained"
          disabled={workerSectionLength === 0 || isButtonDisabled}
          sx={{
            backgroundColor: workerSectionLength === 0 || isButtonDisabled ? 'grey' : '#002D8C',
            borderRadius: '5px',
            fontFamily: 'Medium',
            color: workerSectionLength === 0 || isButtonDisabled ? '#ffffff' : '#FFF',
            width: '48%',
            padding: 0,
            fontSize: '13px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: '#002D8C'
            },
            '&.Mui-disabled': {
              backgroundColor: 'grey',
              color: '#FFFFFF'
            }
          }}
          onClick={handleClick}
        >
          Calculate Premium
        </Button>
        <Button
          onClick={() => handleResetClick()}
          variant="contained"
          sx={{
            backgroundColor: '#fff',
            borderRadius: '5px',
            color: '#002D8C',
            fontFamily: 'Medium',
            width: '48%',
            border: '1px solid #002D8C',
            fontSize: '13px',
            padding: 0,
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: '#fff'
            }
          }}
        >
          Reset
        </Button>
      </Grid>
    </div>
  );
};

export default ForeignWorkerBottomPortion;
