import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';

export default function useEndorsementGet() {
  const { makeRequest } = useApiCall();
  let userData = localStorage.getItem('@user');
  let user = JSON.parse(userData);
  let userID = user?.userId;
  const [endorsementData, setEndorsementData] = useState([]);
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        let UserId = userID;
        let params = { UserId };
        let res = await makeRequest({ method: 'GET', url: API.EndorsementDetailsGet, params });

        if (res?.code === 200) {
          let arr = [];
          res?.data?.forEach((item, index) => {
            arr.push({
              sr: index + 1,
              Pt: item?.productName || '-',
              et: item?.category || '-',
              rd: item?.raisedDate || '-',
              pno: item?.policynumber || '-',
              status: item?.status || '-',
            });
          });
          setEndorsementData(arr);
        }
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    })();
  }, [userID]);

  return [endorsementData, loading];
}
