import * as React from 'react';
import Box from '@mui/material/Box';
import ReusableCard from 'components/cards/ResuableCard';
import { Grid } from '@mui/material';
import '../styles/ImageTabProductPlans.css';
import Domestic_Helper from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/ANDA_Homepage_helperThumbnail.jpg';
import Foreign_Worker from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/ANDA_Homepage_FWThumbnail.jpg';
import Motor_Vehicle from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/ANDA_Homepage_MotorThumbnail.jpg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '../../../../../../node_modules/@mui/material/index';

const staticProductPlans = [
  {
    displayUrl: Domestic_Helper,
    productName: 'Domestic Helper',
    grossPremium: 'From $490.50 to $367.88',
    description: 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.',
    route: '/DomesticHelper'
  },
  {
    displayUrl: Motor_Vehicle,
    productName: 'Motor Vehicle',
    grossPremium: '',
    description: 'Insure your motorcycle with affordable and comprehensive coverage',
    route: '/MotorVehicle'
  },
  {
    displayUrl: Foreign_Worker,
    productName: 'Foreign Worker',
    grossPremium: 'From $490.50 to $367.88',
    description: 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.',
    route: '/ForeignWorkers'
  }
];

const ProductCard = ({ item, onClick }) => {
  const belowMobile = useMediaQuery('(max-width:767px)');
  const isMobile = useMediaQuery('(min-width:500px) and (max-width:767px)');

  return (
    <div
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick();
      }}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer', margin: '10px' }}
    >
      <ReusableCard
        width={belowMobile ? '100%' : '250px'}
        border="none"
        backgroundColor="white"
        content={
          <div style={{ display: 'flex', flexDirection: isMobile ? 'row ' : 'column', width: '100%', height: '320px' }}>
            <div className="image_container_productPlans_imagetab">
              <img src={item.displayUrl} className="image_styling_productPlans_imageTab" alt={item.productName} />
            </div>
            <div
              className="card_content_container_productPlans_imageTab"
              style={{ lineHeight: item.productName === 'Motor Vehicle' ? 2.25 : '', marginLeft: '2%' }}
            >
              <span className="card_planname_styling_productPlans_imageTab">{item.productName}</span>
              <span className="card_priceLimit_styling_productPlans_imageTab" style={{ fontWeight: 'bold' }}>
                {item.grossPremium}
              </span>
              <span className="card_priceLimit_styling_productPlans_imageTab" style={{ fontFamily: 'Medium' }}>
                {item.description}
              </span>
              <div className="explore_container_productPlans_imageTab">
                <span className="explore_text_productPlans_imageTab">{item.productName === 'Motor Vehicle' ? 'Get Quote' : 'Explore'}</span>
                <ArrowForwardIosIcon className="explore_icon_productPlans_imageTab" />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

const ProductPlans = ({ data }) => {
  const navigate = useNavigate();
  const handleCardClick = (route) => {
    navigate(route);
  };

  const isMobile = useMediaQuery('(max-width:767px)');
  const isTab = useMediaQuery('(min-width:768px) and (max-width:1080px)');
  const isLaptop = useMediaQuery('(min-width:1081px) and (max-width:1280px)');

  return (
    <Grid container spacing={2} className="maincontainer_productPlans_imageTab">
      {data.map((item, index) => (
        <Grid
          item
          md={3}
          sm={3}
          lg={2}
          key={index}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: isMobile ? 'center' : isTab ? 'center' : 'center',
            margin: isMobile ? '0% 1% 0% 1%' : isTab ? '0% 4% 0% 4%' : isLaptop ? '0% 2.4% 0% 2.4%' : '0% 1.6% 0% 1.6%'
          }}
        >
          <ProductCard item={item} onClick={() => handleCardClick(item.route)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default function ImageTabProductPlans() {
  return (
    // <Box
    //   sx={{
    //     // maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
    //     display: 'flex',
    //     width: '100vw',
    //     bgcolor: 'transparent',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     marginBottom: '3%'
    //   }}
    // >
    <Box
      sx={{
        p: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: '1%',
        width: '100vw',
        marginBottom: '3%'
      }}
    >
      <ProductPlans data={staticProductPlans} />
    </Box>
    // </Box>
  );
}
