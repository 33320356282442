import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Paper from '@mui/material/Paper';
const CustomPaper = (props) => {
  return <Paper {...props} style={{ backgroundColor: '#F8FAFB' }} />
};


const AutoCompleteDropdown = (props) => {
  const { options, value, label, width, height, borderRadius, onChange, marginTop, backgroundColor } = props;
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (value) {
      setInputValue(options.find((option) => option.id === value)?.label || '');
    } else {
      setInputValue('');
    }
  }, [value, options]);

  const handleOnChange = (event, selectedValue) => {
    onChange(selectedValue);
    setIsFocused(false);
    if (!selectedValue) {
      setInputValue('');
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const getOptionLabel = (option) => option.label || '';

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 40, marginTop: marginTop, cursor: 'pointer' }}>
      <Autocomplete
        size="small"
        popupIcon={<KeyboardArrowDownIcon sx={{ color: '#32385C' }} />}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        options={options}
        getOptionLabel={getOptionLabel}
        inputValue={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        PaperComponent={CustomPaper}
        style={{ width: width || 220, color: '#A79FA4' }}
        
        renderInput={(params) => (
          <TextField
            {...params}
            label={!isFocused && !inputValue ? label || 'Select an option' : ''}
            fullWidth
            InputLabelProps={{
              shrink: false,
              sx: {
                transform: 'translate(14px, 12px) scale(1)',
                transition: 'none',
                color: '#636468',
                '&.Mui-focused': {
                  color: '#636468',
                  backgroundColor:'green'
                }
              }
            }}
            sx={{
              '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
                marginTop: -0.5
              },
              '& .MuiFormLabel-root.MuiInputLabel-root': {
                fontSize: 13,
                fontFamily: 'Medium',
                backgroundColor: 'transparent',
                marginTop: -0.1,
                color: '#636468'
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'inherit'
                },
                '&.Mui-focused': {
                  boxShadow: 'none'
                }
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.Mui-focused.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot':
                {
                  border: 'none !important'
                },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important'
              },
              
            }}
            InputProps={{
              ...params.InputProps,
              focused: false,
              style: {
                height: height || '35px',
                borderRadius: borderRadius || '10px',
                backgroundColor: backgroundColor || '#f8f8f8',
                color: '#32385C',
                marginTop: 0,
                fontSize:13
              }
            }}
          />
        )}
        value={options?.find((option) => option.id === value) || null}
      />
    </div>
  );
};

AutoCompleteDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string
};

export default AutoCompleteDropdown;
