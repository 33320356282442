import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import ForgotPassword from 'pages/authentication/ForgotPassword';
import VerifyOtpForm from 'pages/authentication/VerifyOtpForm';
import CustomerLogin from 'modules/packages & premium/components/CustomerLogin';
import DomesticHelper from 'modules/customer/domestic helper/pages/DomesticHelper';
import Corporate from 'modules/customer/corporate/pages/Corporate';
import ForeignWorkers from 'modules/customer/foreignworkers/ForeignWorkers';
import MotorVehicle from 'modules/customer/motorvehicle/MotorVehicle';
import MainLayout from 'layout/MainLayout/index';
import DashboardDefault from 'pages/dashboard/index';
import Policies from 'modules/policies/pages/Policies';
import ViewPolicy from 'modules/policies/pages/ViewPolicy';
import Premium from 'modules/packages & premium/pages/Premium';
import PremiumCalculateView from 'modules/packages & premium/pages/PremiumCalculateView';
import PremiumCalculation from 'modules/packages & premium/pages/PremiumCalculation';
import Profile from 'modules/myprofile/pages/Profile';
import DomesticHelperPolicyCoverage from 'modules/customer/domestic helper policy view/pages/DomesticHelperPolicyCoverage';
import { useSelector } from 'react-redux';
import IncorporateFinance from 'modules/incorporatefinance/pages/IncorporateFinance';
import CustomerResetPassword from 'modules/customer/customerpasswordreset/CustomerResetPassword';
import SamplePage from 'pages/extra-pages/SamplePage';
import ForeignWorkerPolicyCoverages from 'modules/customer/foreignworkers/components/ForeignWorkerPolicyCoverages';
import CustomerLandingPage from 'modules/customer/corporate/customerlandingpage/pages/CustomerLandingPage';
import AboutAndaTemplate from 'modules/customer/corporate/customerlandingpage/components/AboutAndaTemplate';
import BecomeAgentTemplate from 'modules/customer/corporate/customerlandingpage/components/BecomeAgentTemplate';
import TermsOfUse from 'modules/customer/corporate/customerlandingpage/components/TermsOfUse';
import ContactUs from 'modules/customer/corporate/customerlandingpage/components/ContactUs';
import PrivacyPolicy from 'modules/customer/corporate/customerlandingpage/components/PrivacyPolicy';
import PrivacyPolicy2 from 'modules/customer/corporate/customerlandingpage/components/PrivacyPolicy2';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));

// ==============================|| AUTH ROUTING ||============================== //
const Routes = (props) => {
  const { isAgendLogin, isUserLogin } = useSelector((state) => state.persistedReducer.auth);
  const loginData = localStorage?.getItem('@login');
  const data = JSON.parse(loginData);
  const isFinanceAccess = data?.finance_Access__c;

  if (isAgendLogin) {
    return {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <DashboardDefault />
        },
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: isFinanceAccess ? <DashboardDefault /> : <Policies />
            },
            {
              path: 'policies',
              element: <Policies />
            },
            {
              path: 'viewpolicy',
              element: <ViewPolicy />
            },
            {
              path: 'Premium',
              element: <Premium />
            },
            {
              path: 'PremiumCalculateView',
              element: <PremiumCalculateView />
            },
            {
              path: 'PremiumCalculation',
              element: <PremiumCalculation />
            },
            {
              path: 'Profile',
              element: <Profile />
            },
            {
              path: 'IncorporateFinance',
              element: <IncorporateFinance />
            }
          ]
        }
      ]
    };
  } else {
    return {
      path: '/',
      element: <MinimalLayout />,
      children: [
        {
          path: '/',
          element: <CustomerLandingPage />
        },
        {
          path: '/',
          element: isUserLogin ? <SamplePage /> : <AuthLogin />
        },
        {
          path: 'login',
          element: isUserLogin ? <SamplePage /> : <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgotPassword',
          element: <ForgotPassword />
        },
        {
          path: 'verifyotp',
          element: <VerifyOtpForm />
        },
        {
          path: 'CustomerLogin',
          element: <CustomerLogin />
        },
        {
          path: 'DomesticHelper',
          element: <DomesticHelper />
        },
        {
          path: 'Corporate',
          element: <Corporate />
        },

        {
          path: 'ForeignWorkers',
          element: <ForeignWorkers />
        },
        {
          path: 'MotorVehicle',
          element: <MotorVehicle />
        },
        {
          path: 'DomesticHelperPolicyCoverage',
          element: <DomesticHelperPolicyCoverage />
        },
        {
          path: 'ProfileView',
          element: <CustomerLogin />
        },
        {
          path: 'CustomerResetPassword',
          element: <CustomerResetPassword />
        },
        {
          path: 'ForeignWorkerPolicyCoverages',
          element: <ForeignWorkerPolicyCoverages />
        },
        {
          path: 'AboutAnda',
          element: <AboutAndaTemplate />
        },
        {
          path: 'BecomeAgent',
          element: <BecomeAgentTemplate />
        },
        {
          path: 'TermsOfUse',
          element: <TermsOfUse />
        },
        {
          path: 'ContactUs',
          element: <ContactUs />
        },
        {
          path: 'PrivacyPolicy',
          element: <PrivacyPolicy />
        },
        {
          path: 'privacy-policy',
          element: <PrivacyPolicy2 />
        }
      ]
    };
  }
};

export default Routes;

//THis part needs to be add else part
// return {
//   path: '/',
//   element: <MinimalLayout />,
//   children: [
//     {
//       path: '/',
//       element: <CustomerLandingPage />
//     },
//     {
//       path: 'login',
//       element: isUserLogin ? <SamplePage /> : <AuthLogin />
//     },
//     {
//       path: 'register',
//       element: <AuthRegister />
//     },
//     {
//       path: 'forgotPassword',
//       element: <ForgotPassword />
//     },
//     {
//       path: 'verifyotp',
//       element: <VerifyOtpForm />
//     },
//     {
//       path: 'CustomerLogin',
//       element: <CustomerLogin />
//     },
//     {
//       path: 'DomesticHelper',
//       element: <DomesticHelper />
//     },
//     {
//       path: 'Corporate',
//       element: <Corporate />
//     },

//     {
//       path: 'ForeignWorkers',
//       element: <ForeignWorkers />
//     },
//     {
//       path: 'MotorVehicle',
//       element: <MotorVehicle />
//     },
//     {
//       path: 'DomesticHelperPolicyCoverage',
//       element: <DomesticHelperPolicyCoverage />
//     },
//     {
//       path: 'ProfileView',
//       element: <CustomerLogin />
//     },
//     {
//       path: 'CustomerResetPassword',
//       element: <CustomerResetPassword />
//     },
//     {
//       path: 'ForeignWorkerPolicyCoverages',
//       element: <ForeignWorkerPolicyCoverages />
//     }
//   ]
// };

//THis part needs to be add else part
// return {
//   path: '/',
//   element: <MinimalLayout />,
//   children: [
//     {
//       path: '/',
//       element: <CustomerLandingPage />
//     },
//     {
//       path: 'login',
//       element: isUserLogin ? <SamplePage /> : <AuthLogin />
//     },
//     {
//       path: 'register',
//       element: <AuthRegister />
//     },
//     {
//       path: 'forgotPassword',
//       element: <ForgotPassword />
//     },
//     {
//       path: 'verifyotp',
//       element: <VerifyOtpForm />
//     },
//     {
//       path: 'CustomerLogin',
//       element: <CustomerLogin />
//     },
//     {
//       path: 'DomesticHelper',
//       element: <DomesticHelper />
//     },
//     {
//       path: 'Corporate',
//       element: <Corporate />
//     },

//     {
//       path: 'ForeignWorkers',
//       element: <ForeignWorkers />
//     },
//     {
//       path: 'MotorVehicle',
//       element: <MotorVehicle />
//     },
//     {
//       path: 'DomesticHelperPolicyCoverage',
//       element: <DomesticHelperPolicyCoverage />
//     },
//     {
//       path: 'ProfileView',
//       element: <CustomerLogin />
//     },
//     {
//       path: 'CustomerResetPassword',
//       element: <CustomerResetPassword />
//     },
//     {
//       path: 'ForeignWorkerPolicyCoverages',
//       element: <ForeignWorkerPolicyCoverages />
//     }
//   ]
// };
