import React from 'react';
import { Grid } from '@mui/material';
import '../styles/InformationForm.css';
import DomesticCalculationAmountCard from './domestichelper/DomesticCalculationAmountCard';
import { usePremiumCalculationState } from '../provider/PremiumCalculationProvider';

const DomesticHelperCalculationView = React.memo(() => {
  const { params } = usePremiumCalculationState();
  return (
    <Grid container xs={12} width="100%">
      <DomesticCalculationAmountCard params={params}/>
    </Grid>
  );
});

export default DomesticHelperCalculationView;
