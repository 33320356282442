import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import anda_logo from '../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';

function createData(name, thirdPartyFireTheft, thirdPartyOnly) {
  return { name, thirdPartyFireTheft, thirdPartyOnly };
}

const rows = [
  createData('Accidental loss or damage by fire', true, false),
  createData('Loss or damage by theft', true, false),
  createData('Accidental loss or damage by other insured causes', true, false),
  createData('Policy Excess', true, true),
  createData('Towing to repairers or places of safety following accident', true, true),
  createData('Vehicle stolen outside Singapore subject to applicable excess', true, false),
  createData('Death or bodily injury to third party', false, true),
  createData('Loss or damage to third party property (up to $5,00,000)', false, true),
  createData('Legal costs and expenses', false, true)
];

export default function PlanPremiumTable(props) {
  const { rows, headerData, width, bottomButtons } = props;
  return (
    <TableContainer
      component={Paper}
      style={{
        width: props.width,
        margin: 'auto',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        overflow: 'hidden',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px'
      }}
    >
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#012D8C', height: '40px' }}>
            <TableCell
              align="left"
              style={{
                padding: '3%',
                // justifyContent: 'center',
                alignItems: 'center'
                // overflow: 'hidden'
              }}
            >
              <div style={{ width: '120px' }}>
                <img
                  src={anda_logo}
                  alt="ANDA logo"
                  style={{
                    width: '100%',
                    filter: 'brightness(0) invert(1)',
                    mixBlendMode: 'screen',
                    margin: 0
                  }}
                />
              </div>
            </TableCell>
            {headerData.map((header, index) => (
              <TableCell key={index} align="left" style={{ color: '#FFFFFF', fontSize: '14px' }}>
                <span style={{ display: 'flex', flexDirection: 'column',justifyContent:'center' }}>
                  <p style={{ marginBottom: '-3px' }}>{header.title}</p>
                  <span>{header.price}</span>
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5'
              }}
            >
              <TableCell align="left" component="th" scope="row" style={{ padding: '2%', fontSize: '14px' }}>
                {row.name}
              </TableCell>
              <TableCell align="left">
                {row.thirdPartyFireTheft ? (
                  <CheckIcon style={{ color: '#ffffff', backgroundColor: '#01C7B1', borderRadius: '15px' }} />
                ) : (
                  <CloseIcon style={{ color: '#ffffff', backgroundColor: '#C4C4C4', borderRadius: '15px' }} />
                )}
              </TableCell>
              <TableCell align="left">
                {row.thirdPartyOnly ? (
                  <CheckIcon style={{ color: '#ffffff', backgroundColor: '#01C7B1', borderRadius: '15px' }} />
                ) : (
                  <CloseIcon style={{ color: '#ffffff', backgroundColor: '#C4C4C4', borderRadius: '15px' }} />
                )}
              </TableCell>
            </TableRow>
          ))}
          {props.bottomButtons ? (
            <TableRow
              style={{ backgroundColor: '#012D8C', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', padding: '2%' }}
            >
              <TableCell></TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px' }}>Buy Now</button>
              </TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px' }}>Buy Now</button>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

PlanPremiumTable.defaultProps = {
  width: '70%',
  bottomButtons: false,
  rows: [
    { name: 'Accidental loss or damage by fire', thirdPartyFireTheft: true, thirdPartyOnly: false },
    { name: 'Loss or damage by theft', thirdPartyFireTheft: true, thirdPartyOnly: false },
    { name: 'Accidental loss or damage by other insured causes', thirdPartyFireTheft: true, thirdPartyOnly: false },
    { name: 'Policy Excess', thirdPartyFireTheft: true, thirdPartyOnly: true },
    { name: 'Towing to repairers or places of safety following accident', thirdPartyFireTheft: true, thirdPartyOnly: true },
    { name: 'Vehicle stolen outside Singapore subject to applicable excess', thirdPartyFireTheft: true, thirdPartyOnly: false },
    { name: 'Death or bodily injury to third party', thirdPartyFireTheft: false, thirdPartyOnly: true },
    { name: 'Loss or damage to third party property (up to $5,00,000)', thirdPartyFireTheft: false, thirdPartyOnly: true },
    { name: 'Legal costs and expenses', thirdPartyFireTheft: false, thirdPartyOnly: true }
  ],
  headerData: [
    { title: 'Third-Party Fire & Theft', price: '$531.48' },
    { title: 'Third-Party Only', price: '$484.79' }
  ]
};
